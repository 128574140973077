import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'
import { DefaultContext } from 'Contexts'
import ActionButtons from './ActionButtons'
import { AvatarMenu, MenuButton, RoundedNavigation } from 'Elements'
import { NotificationsMenu } from 'Molecules'
import { MODAL_TYPES } from 'Constants'
import * as styles from './styles.module.scss'

interface PrimaryNavigationProps {
  displayAuthButtons?: boolean
  displayGroupsMenu?: boolean
  displayLogo?: boolean
  displayMainMenu?: boolean
  displayNotifications?: boolean
  displayPrimaryItems?: boolean
  title?: string
}

const PrimaryNavigation = observer(
  ({
    displayAuthButtons = false,
    displayGroupsMenu = false,
    displayLogo = true,
    displayMainMenu = false,
    displayNotifications = false,
    displayPrimaryItems = false,
    title
  }: PrimaryNavigationProps) => {
    const {
      applicationStore: { modalStore, sessionStore, uiStore }
    } = React.useContext(DefaultContext)
    const [_transparent, _setTransparency] = useState(true)

    const _displayActionButtons =
      displayAuthButtons && !sessionStore.authenticatedPersonId

    const _displayGroupsMenu =
      sessionStore.authenticatedPersonId && displayGroupsMenu

    const _displayNotifications =
      sessionStore.authenticatedPersonId && displayNotifications

    const _displayOnlyIcons = !displayLogo && !displayPrimaryItems

    const _openMainMenuModal = () => {
      modalStore.openModal({ category: MODAL_TYPES.MAIN_MENU })
    }

    const _updateTransparency = () => {
      if (document.documentElement.getAttribute('locked')) return

      if (window.pageYOffset > 10) {
        if (_transparent) {
          _setTransparency(false)
        }
      } else {
        if (!_transparent) {
          _setTransparency(true)
        }
      }
    }

    useEffect(() => {
      window.addEventListener('scroll', _updateTransparency)

      return () => {
        window.removeEventListener('scroll', _updateTransparency)
      }
    }, [_transparent])

    useEffect(() => _updateTransparency(), [])

    return (
      <nav
        className={classnames(styles.Navigation, {
          [styles['Navigation--transparent']]:
            _transparent || _displayOnlyIcons,
          [styles['Navigation--opaque']]: !_transparent && !_displayOnlyIcons
        })}>
        <div className={styles.Navbar}>
          {displayLogo && (
            <a
              href="/"
              className={styles.Navbar__logo}
              data-tour-id="Navbar__logo">
              Giki Zero
            </a>
          )}
          <CSSTransition
            in={!_transparent && !!title}
            className={classnames(
              styles.Navbar__title,
              'fade-in',
              'hide-on-mobile'
            )}
            timeout={400}
            unmountOnExit>
            <div>{title}</div>
          </CSSTransition>
          {displayPrimaryItems &&
            uiStore.primaryItems.length > 0 &&
            !uiStore.switchToSectionItems && (
              <div className={styles.Navbar__primary}>
                <RoundedNavigation
                  items={uiStore.primaryItems}
                  transparent={_transparent}
                />
              </div>
            )}
          {displayPrimaryItems &&
            uiStore.sectionItems.length > 0 &&
            uiStore.switchToSectionItems && (
              <div className={styles.Navbar__primary}>
                <RoundedNavigation
                  items={uiStore.sectionItems}
                  transparent={_transparent}
                />
              </div>
            )}
          <div className={styles.Navbar__right}>
            {_displayActionButtons && <ActionButtons />}
            {_displayNotifications && <NotificationsMenu />}
            {_displayGroupsMenu && <AvatarMenu />}
            {displayMainMenu && <MenuButton onClick={_openMainMenuModal} />}
          </div>
        </div>
      </nav>
    )
  }
)

export default PrimaryNavigation
