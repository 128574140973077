import { action, computed, observable } from 'mobx'
import { API, APIRoutes, uploadFile } from 'API'
import { standardizeError } from 'Utilities/errors'
import {
  ACTION_ROLES,
  CAMPAIGN_CONTEXTS,
  MODEL_TYPES,
  SerializedCampaign,
  SerializedCampaignsTarget
} from 'Constants'
import { SingleItemStore, TranformContextType } from 'Stores/Base'
import ApplicationStore from 'Stores/ApplicationStore'
import OrganizationStore from 'Stores/OrganizationStore'
import ActionsStore from './ActionsStore'
import TargetStore from './TargetStore'

interface CampaignParams {
  image?: string
  imageFile?: string
  organization_campaigns_attributes?: Array<{ organization_id: string }>
  template_id?: string
}

type rootStoreType = typeof ApplicationStore | OrganizationStore

class CampaignStore extends SingleItemStore {
  protected _superReset = this.reset
  public context: string
  public actions: {
    excluded: ActionsStore
    included: ActionsStore
  }

  @observable initialized: boolean
  @observable loaded: boolean
  @observable loading: boolean
  @observable public attributes: SerializedCampaign['attributes']
  @observable public links: SerializedCampaign['links']
  public target: TargetStore

  constructor(
    public rootStore: rootStoreType,
    context: string
  ) {
    super()
    this._itemType = MODEL_TYPES.CAMPAIGN
    this.rootStore = rootStore
    this.context = context
    this.actions = {
      excluded: new ActionsStore(this, ACTION_ROLES.EXCLUDED, context),
      included: new ActionsStore(this, ACTION_ROLES.INCLUDED, context)
    }
    this.target = new TargetStore(this)
    this.reset()
  }

  @action
  _postInit = async () => {
    await this.actions.included.init()
    await this.actions.excluded.init()
  }

  @action
  cloneItem = async () => {
    try {
      const _transformedParams = await this._transformParams(
        {
          template_id: this.id
        },
        'create'
      )
      const { data } = await API.post(this._itemsRoute, {
        [this._itemType]: _transformedParams
      })
      this.rootStore.campaignsStore.receiveItems([data.data])
      return { ...data.data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  receiveIncluded = (included: SerializedCampaignsTarget[] = []) => {
    included.forEach((item) => {
      if (item.type === MODEL_TYPES.CAMPAIGNS_TARGET) {
        void this.target.init(Object.assign({}, item, { loaded: true }))
      }
    })
  }

  @action
  _postCreate = async (item) => {
    this.rootStore.campaignsStore.receiveItems([item])
    await this._postInit()
    return await Promise.resolve(item)
  }

  @action
  _postDelete = async (itemId) => {
    this.rootStore.campaignsStore.removeItemsById([itemId])
    return await Promise.resolve()
  }

  @action
  _postUpdate = async (item) => {
    this.rootStore.campaignsStore.receiveItems([item])
    return await Promise.resolve(item)
  }

  _transformParams = async (
    params: CampaignParams,
    context?: TranformContextType
  ) => {
    const _transformedParams = Object.assign({}, params)
    const _createContext = context === 'create'
    const _organizationContext = this.context === CAMPAIGN_CONTEXTS.ORGANIZATION
    const _shouldAddOrganizationId = _createContext && _organizationContext

    if (_transformedParams.imageFile) {
      const imageUrl = await uploadFile(params.imageFile, 'image')
      _transformedParams.image = imageUrl
      delete _transformedParams.imageFile
    }

    if (_shouldAddOrganizationId && 'id' in this.rootStore) {
      _transformedParams.organization_campaigns_attributes = [
        {
          organization_id: this.rootStore.id
        }
      ]
    }

    return _transformedParams
  }

  @computed
  get _itemRoute() {
    return APIRoutes.campaign.show(this.id)
  }

  @computed
  get _itemsRoute() {
    return APIRoutes.campaigns()
  }

  @computed
  get _shouldFetchAttributes() {
    return !!this.id
  }

  @action
  reset = () => {
    this._superReset()
    this.attributes = {
      action_updates_restricted: false,
      description: '',
      draft: false,
      ends_at: '',
      name: '',
      participants_count: 0,
      pending_commitments_count: 0,
      public: false,
      selected_commitments_count: 0,
      starts_at: '',
      template: false,
      title: '',
      underway: false
    }
    this.links = {
      html_admin_actions: null,
      html_admin_settings: null,
      html_admin_summary: null,
      html_public: null
    }
    this.actions.excluded.reset()
    this.actions.included.reset()
  }
}

export default CampaignStore
