import React from 'react'
import * as styles from '../styles.module.scss'

interface SettingsInputGroupProps {
  children: React.ReactNode | React.ReactNode[]
}

const SettingsInputGroup = ({ children }: SettingsInputGroupProps) => {
  return (
    <div className={styles.SettingsList__group}>
      {children}
    </div>
  )
}

export default SettingsInputGroup
