import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import {
  LoadMoreButton,
  LinksList,
  Selector,
  Spinner,
  Button,
  ButtonIcons,
  TagColors
} from 'Elements'
import { DEFAULT_STATE, FILTER_STATES } from './filters'
import { useListFocus } from 'Hooks/ListFocus'
import * as styles from './styles.module.scss'

const GroupMembershipRequests = observer(
  ({
    allMembershipRequestsSelected,
    anyMembershipRequestSelected,
    fetchMembershipRequests,
    filters,
    getPerson,
    getUser,
    getTeam,
    membershipRequests,
    isMembershipRequestSelected,
    loaded,
    loading,
    onFilterUpdate,
    onSelectNone,
    onSelectAll,
    onToggleSelect,
    onUpdate,
    pagination
  }) => {
    const { I18n } = useLocalI18n('molecules/GroupMembershipRequests/Lang')

    const { _listRef, _listFocus } = useListFocus()

    const _fetchMembershipRequests = () => {
      fetchMembershipRequests({ number: pagination.next })?.then(_listFocus)
    }

    const _onStateChange = (state) => onFilterUpdate('state', state.value)

    const _updateable = filters.state === DEFAULT_STATE

    return (
      <div className={styles.GroupMembershipRequests}>
        <section className={styles.GroupMembershipRequests__filters}>
          <div className={styles.GroupMembershipRequests__filter}>
            <label htmlFor="state-select">
              {I18n.t('group_membership_requests.filters.state')}
            </label>
            <Selector
              inputId="state-select"
              isSmall
              onChange={_onStateChange}
              options={FILTER_STATES}
            />
          </div>
        </section>
        {_updateable && (
          <section className={styles.GroupMembershipRequests__actions}>
            <Button
              onClick={onSelectAll}
              ariaLabel={I18n.t('group_membership_requests.actions.select_all')}
              icon={ButtonIcons.SelectAllIcon}
              large
              disabled={allMembershipRequestsSelected}
            />
            <Button
              onClick={onSelectNone}
              ariaLabel={I18n.t(
                'group_membership_requests.actions.select_none'
              )}
              icon={ButtonIcons.SelectNoneIcon}
              large
              disabled={!anyMembershipRequestSelected}
            />
            <Button
              onClick={onUpdate}
              ariaLabel={I18n.t('group_membership_requests.actions.edit')}
              icon={ButtonIcons.PencilIcon}
              large
              disabled={!anyMembershipRequestSelected}
            />
          </section>
        )}
        <section className={styles.GroupMembershipRequests__content}>
          {loading && (
            <div className={styles.GroupMembershipRequests__loader}>
              <Spinner absolute />
            </div>
          )}
          {loaded && membershipRequests.length > 0 && (
            <LinksList
              items={membershipRequests.map((membershipRequest) => {
                const _sender = getPerson(
                  membershipRequest.relationships.sender.data?.id
                )
                const _user = getUser(_sender.relationships.user.data?.id)
                const _team = getTeam(
                  membershipRequest.relationships.team.data?.id
                )
                const _verificationAnswer =
                  membershipRequest.attributes.verification_answer

                const _tags = []

                _tags.push({
                  text: I18n.t(
                    `group_membership_requests.state.${membershipRequest.attributes.state}.title`
                  ),
                  color: TagColors.NEUTRAL
                })

                const _teamTags = []

                if (_team) {
                  _teamTags.push({
                    title: _team.attributes.name
                  })
                }

                return {
                  onClick: () => onToggleSelect(membershipRequest.id),
                  linkLabel: _user.attributes.email,
                  title: _user.attributes.email,
                  subTitle: _verificationAnswer,
                  tags: _tags,
                  additionalTags: _teamTags,
                  avatar: _sender.attributes.avatar,
                  avatarFirstName: _sender.attributes.first_name,
                  avatarLastName: _sender.attributes.last_name,
                  selectable: true,
                  selected: isMembershipRequestSelected(membershipRequest.id)
                }
              })}
              ref={_listRef}
              wide
            />
          )}
          {loaded && !membershipRequests.length && (
            <div className={styles.GroupMembershipRequests__placeholder}>
              {I18n.t('group_membership_requests.placeholder')}
            </div>
          )}
          {pagination.next && (
            <div className={styles.GroupMembershipRequests__pagination}>
              <LoadMoreButton
                loading={loading}
                onLoadMore={_fetchMembershipRequests}
              />
            </div>
          )}
        </section>
      </div>
    )
  }
)

GroupMembershipRequests.defaultProps = {
  allMembershipRequestsSelected: false,
  anyMembershipRequestSelected: false
}

GroupMembershipRequests.propTypes = {
  allMembershipRequestsSelected: PropTypes.bool,
  anyMembershipRequestSelected: PropTypes.bool,
  fetchMembershipRequests: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getPerson: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getTeam: PropTypes.func.isRequired,
  membershipRequests: PropTypes.array.isRequired,
  isMembershipRequestSelected: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSelectNone: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onToggleSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired
}

export default GroupMembershipRequests
