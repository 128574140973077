import { action, computed } from 'mobx'
import { BaseStore } from 'Stores/Base'
import { CAMPAIGN_TYPES } from 'Constants'
import TypeStore from './TypeStore'
import ApplicationStore from 'Stores/ApplicationStore'
import OrganizationStore from 'Stores/OrganizationStore'

type rootStoreType = typeof ApplicationStore | OrganizationStore

class CampaignsStore extends BaseStore {
  public context: string
  standardStore: TypeStore
  templateStore: TypeStore

  constructor(public rootStore: rootStoreType, context: string) {
    super()
    this.rootStore = rootStore
    this.context = context
    this.standardStore = new TypeStore(this, context, CAMPAIGN_TYPES.STANDARD)
    this.templateStore = new TypeStore(this, context, CAMPAIGN_TYPES.TEMPLATE)
  }

  @action
  init = async () => {
    return await Promise.all([
      this.standardStore.init(),
      this.templateStore.init()
    ])
  }

  @action
  receiveItems = (items = []) => {
    const standardItems = []
    const templateItems = []
    items.forEach((item) => {
      if (item.attributes.template) {
        templateItems.push(item)
      } else {
        standardItems.push(item)
      }
    })
    if (standardItems.length) {
      this.standardStore.receiveItems(standardItems)
    }
    if (templateItems.length) {
      this.templateStore.receiveItems(templateItems)
    }
  }

  @action
  removeItemsById = (itemIds = []) => {
    this.standardStore.removeItemsById(itemIds)
    this.templateStore.removeItemsById(itemIds)
  }

  @action
  reset = () => {
    this.standardStore.reset()
    this.templateStore.reset()
  }

  @computed
  get anyUnderway() {
    return !!this.standardStore.items.filter(
      campaign => campaign.attributes.underway
    ).length;
  }

  @computed
  get organizationId() {
    if ('id' in this.rootStore) return this.rootStore.id

    return null
  }
}

export default CampaignsStore
