import React from 'react'

const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm2.5 16.004H13c-.552 0-1-.448-1-1v-6.5c0-.276-.224-.5-.5-.5H10m1.745-2.505c-.138 0-.25.112-.25.25s.112.25.25.25.25-.112.25-.25-.112-.25-.25-.25"
    />
  </svg>
)

export default InfoIcon
