import { action, extendObservable } from 'mobx'
import { standardizeError } from 'Utilities/errors'

const initialState = {
  initialized: false,
  rawData: '',
  review: false,
  teamNames: []
}

class CreateTeamsStore {
  constructor() {
    extendObservable(this, initialState)
  }

  @action
  init = async (values = {}) => {
    try {
      await Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  cancelReview = () => {
    this.review = false
  }

  _extractTeamNamesFromRawData = (rawData) => {
    return rawData
      .split(/\n/)
      .filter((line) => {
        return line !== undefined && line !== null && /\S+/.test(line)
      })
      .map((line) => line.trim())
      .filter((name, index, _names) => {
        return _names.findIndex((_name) => _name === name) === index
      })
  }

  @action
  processAndReviewData = () => {
    this.teamNames = this._extractTeamNamesFromRawData(this.rawData)
    this.review = true
  }

  @action
  receiveRawData = (data) => {
    this.rawData = data
  }

  @action
  updateTeamName = (index, name) => {
    this.teamNames[index] = name
  }

  @action
  reset = async () => {
    await Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key]
    })
  }
}

export default CreateTeamsStore
