import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import { Button, ButtonTypes } from 'Elements'

interface OptionProps {
  id: string
  min_bedrooms: string
  max_bedrooms: string
}

interface BedroomListingProps {
  onSelect: (option: OptionProps) => void
  options: OptionProps[]
  value: {
    id: string
  }
}

const BedroomListing = ({ onSelect, options, value }: BedroomListingProps) => {
  const { I18n } = useLocalI18n('elements/BedroomListing/Lang')

  return (
    <fieldset className="footprint-wizard__row">
      <legend className="h3 h--bs">{I18n.t('bedroom_listing.title')}</legend>

      <div className="form-row form-row--inline-center form-row--buttons">
        {options
          .sort((item1, item2) => {
            if (item1.min_bedrooms < item2.min_bedrooms) return -1
            if (item1.min_bedrooms > item2.min_bedrooms) return 1
            return 0
          })
          .map((option, index) => {
            let label: string
            if (option.max_bedrooms) {
              label = `${option.min_bedrooms}–${option.max_bedrooms}`
            } else {
              label = `${option.min_bedrooms}+`
            }
            return (
              <Button
                key={index}
                variant={
                  option.id === value.id
                    ? ButtonTypes.RADIOPILLSELECTED
                    : ButtonTypes.RADIOPILL
                }
                onClick={() => onSelect(option)}
                label={label}
              />
            )
          })}
      </div>
    </fieldset>
  )
}

export default BedroomListing
