import { action, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { BaseStore } from 'Stores/Base'
import { standardizeError } from 'Utilities/errors'
import { SerializedOrganizationsBenchmark } from 'Constants'
import OrganizationStore from './index'

class BenchmarkStore extends BaseStore {
  @observable attributes: SerializedOrganizationsBenchmark['attributes']

  constructor(public rootStore: OrganizationStore) {
    super()
    this.rootStore = rootStore
    this.reset()
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return await Promise.resolve()

      await this.fetchBenchmark()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchBenchmark = async () => {
    try {
      this.loading = true
      const route = APIRoutes.organizations.benchmarks(this.rootStore.id)
      const { data } = await API.get(route)
      this.attributes = data.data.attributes
      this.loaded = true
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loading = false
    }
  }

  @action
  reset = () => {
    this.attributes = {
      average_members_kg_co2e_emissions: 0,
      average_members_score: 0,
      total_members_count: 0,
      total_members_count_one_month_ago: 0,
      members_count_change_since_one_month_ago: 0,
      total_teams_count: 0,
      average_pro_kg_co2e_emissions: 0,
      lowest_pro_kg_co2e_emissions: 0,
      average_pro_score: 0,
      highest_pro_score: 0
    }
    this.initialized = false
    this.loaded = false
    this.loading = false
  }
}

export default BenchmarkStore
