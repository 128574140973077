import { useRef, useState, useEffect } from 'react'

export const useListFocus = (tagType = 'button') => {
  const _listRef = useRef(null)
  const [_listChildrenCount, _setListChildrenCount] = useState(0)

  const _listFocus = () => {
    _listRef.current?.children[_listChildrenCount]?.querySelector(tagType)?.focus()
    _setListChildrenCount(_listRef.current?.children?.length)
  }

  useEffect(() => {
    if (!_listRef.current || _listChildrenCount > 0) return

    _setListChildrenCount(_listRef.current?.children?.length)
  })

  return {_listRef, _listFocus}
}