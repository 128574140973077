import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import * as styles from './styles.module.scss'

interface MenuButtonProps {
  onClick: () => void
}

const MenuButton = ({ onClick }: MenuButtonProps) => {
  const { I18n } = useLocalI18n('elements/MenuButton/Lang')

  return (
    <button
      className={styles.MenuButton}
      onClick={onClick}
      type="button"
      aria-label={I18n.t('menu_button.label')}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <line
          x1="2"
          y1="2"
          x2="22"
          y2="2"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"></line>
        <line
          x1="2"
          y1="12"
          x2="22"
          y2="12"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"></line>
        <line
          x1="2"
          y1="22"
          x2="22"
          y2="22"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"></line>
      </svg>
    </button>
  )
}

export default MenuButton
