import useLocalI18n from 'Hooks/LocalI18n'
import React, { createRef, useEffect, useState } from 'react'
import { Spinner } from 'Elements'
import BreakdownBarChartItem from './BreakdownBarChartItem'
import BreakdownBarChartFilter from './BreakdownBarChartFilter'
import * as styles from './styles.module.scss'
import { SerializedPersonSection } from 'Constants'

interface Props {
  loaded?: boolean
  loading?: boolean
  items: SerializedPersonSection[]
  onLoadItems: () => void
  unit: string
}

const BreakdownBarChart = ({ loaded = false, loading = false, items, onLoadItems, unit }: Props) => {
  const { I18n } = useLocalI18n('elements/BreakdownBarChart/Lang')

  const _container = createRef<HTMLDivElement>()
  const _content = createRef<HTMLDivElement>()

  const [_isCollapsed, _setIsCollapsed] = useState(true)

  const [_items, _setItems] = useState([])

  const [_selectedItems, _setSelectedItems] = useState([])

  const [_selectedItem, _setSelectedItem] = useState()

  const [_filterItems, _setFilterItems] = useState([])

  const _getLabel = (slug: string) => {
    return I18n.t(`common.footprint_labels.${slug}`)
  }

  const _sortData = (data) => {
    data.sort((item1, item2) => {
      const _diff = item2.value - item1.value
      if (_diff !== 0) return _diff
      if (item1._label.toUpperCase() < item2._label.toUpperCase()) return -1
      if (item1._label.toUpperCase() > item2._label.toUpperCase()) return 1
      return 0
    })

    return data
  }

  const toggleCollapse = () => {
    if (!loading && !loaded && onLoadItems !== undefined) onLoadItems()

    _setIsCollapsed(!_isCollapsed)
  }

  const _updateData = (data) => {
    data.forEach((item) => {
      item._label = _getLabel(item.slug)
      if (item.items) {
        item._items = _updateData(item.items)
      }
    })

    data = _sortData(data)

    return data
  }

  const _initialize = () => {
    const _tempItems = _updateData(items.slice())

    _setItems(_tempItems)
    _setSelectedItems(_tempItems)

    const _tempFilterItems = _tempItems
      .filter((item) => item.items)
      .map((item) => ({
        slug: item.slug,
        _label: item._label
      }))

    _setFilterItems(_tempFilterItems)
  }

  const _handleFilter = (slug) => {
    const _tempSelectedItem = _items.find((item) => item.slug === slug)

    _setSelectedItem(_tempSelectedItem)

    if (_tempSelectedItem) {
      _setSelectedItems(_tempSelectedItem.items)
    } else {
      _setSelectedItems(_items)
    }
  }

  useEffect(() => {
    _initialize()
  }, [items])

  useEffect(() => {
    if (_container.current && _content.current) {
      _container.current.style.height = `${_content.current.offsetHeight}px`
    }
  }, [_selectedItem])

  useEffect(() => {
    if (!_container.current) return

    if (_isCollapsed) {
      _container.current.style.overflow = 'hidden'
      _container.current.style.height = '0'
    } else {
      _container.current.style.height = `${_content.current.scrollHeight}px`
      setTimeout(() => {
        if (_container.current) {
          _container.current.style.overflow = 'visible'
        }
      }, 300)
    }
  }, [_isCollapsed])

  return (
    <div className={styles.BreakdownBarChart}>
      {loading && (
        <div className={styles.BreakdownBarChart__loader} data-testid="spinner">
          <Spinner />
        </div>
      )}
      {loaded && (
        <div className={styles.BreakdownBarChart__container} ref={_container}>
          <div className={styles.BreakdownBarChart__content} ref={_content}>
            {_filterItems.length > 0 && (
              <BreakdownBarChartFilter
                isCollapsed={_isCollapsed}
                items={_filterItems}
                onClick={_handleFilter}
                selectedItem={_selectedItem}
              />
            )}
            {_selectedItems.map((item, index) => (
              <BreakdownBarChartItem
                key={`${item.slug as string}_${index}`}
                item={item}
                firstItem={_selectedItems[0]}
                co2eUnit={unit}
              />
            ))}
          </div>
        </div>
      )}
      <button
        type="button"
        className={`button button--size-s ${styles.BreakdownBarChart__button}`}
        onClick={toggleCollapse}
        aria-expanded={!_isCollapsed}>
        {_isCollapsed
          ? I18n.t('breakdown_bar_chart.show')
          : I18n.t('breakdown_bar_chart.hide')}
      </button>
    </div>
  )
}

export default BreakdownBarChart
