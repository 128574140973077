import React from 'react'
import { observer } from 'mobx-react'
import { DefaultContext } from 'Contexts'
import { RoundedNavigation } from 'Elements'
import * as styles from './styles.module.scss'

const SectionNavigation = observer(() => {
  const {
    applicationStore: { uiStore }
  } = React.useContext(DefaultContext)

  return (
    <div className={styles.SectionNavigation}>
      <RoundedNavigation
        items={uiStore.sectionItems}
        transparent
        variant="sections"
      />
    </div>
  )
})

export default SectionNavigation
