import React from 'react'
import classnames from 'classnames'
import { LockIcon } from 'Constants/icons'
import * as styles from './styles.module.scss'

export const TagColors = {
  DEFAULT: 'positive',
  BRAND: 'brand',
  BRAND_REVERSE: 'brand-reverse',
  NEUTRAL: 'neutral'
}
export interface TagProps {
  color?: string
  Icon?: React.ElementType
  text: string
  uppercase?: boolean
}

const Tag = ({
  color = TagColors.DEFAULT,
  Icon,
  text,
  uppercase = false
}: TagProps) => {
  return (
    <span
      className={classnames(styles.Tag, styles[`Tag--${color}`], {
        [styles['Tag--uppercase']]: uppercase
      })}>
      {Icon && (
        <span className={styles.Tag__icon}>
          <Icon />
        </span>
      )}
      {text}
    </span>
  )
}

export default Tag
