import { computed, observable, IObservableArray } from 'mobx'
import { APIRoutes, uploadFile } from 'API'
import { PaginatedStore, TranformContextType } from 'Stores/Base'
import { SerializedSuperGroup } from 'Constants'

interface SuperGroupParams {
  banner?: string
  bannerFile?: string
  logo?: string
  logoFile?: string
  name?: string
}

export class SuperGroupsStore extends PaginatedStore {
  @observable public items: IObservableArray<SerializedSuperGroup>

  constructor() {
    super()
    this._itemType = 'super_group'
  }

  _itemRoute = (itemId: string) => {
    return APIRoutes.superGroups.item(itemId)
  }

  @computed
  get _itemsRoute() {
    return APIRoutes.superGroups.index()
  }

  _transformParams = async (
    params: SuperGroupParams,
    context?: TranformContextType
  ) => {
    const _transformedParams = Object.assign({}, params)

    if (_transformedParams.bannerFile) {
      const bannerUrl = await uploadFile(
        _transformedParams.bannerFile,
        'banner'
      )
      _transformedParams.banner = bannerUrl
      delete _transformedParams.bannerFile
    }

    if (_transformedParams.logoFile) {
      const logoUrl = await uploadFile(_transformedParams.logoFile, 'logo')
      _transformedParams.logo = logoUrl
      delete _transformedParams.logoFile
    }

    return _transformedParams
  }
}

export default SuperGroupsStore
