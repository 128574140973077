import{ useEffect, useRef } from 'react'
import {  ShowFootprintMessageModal } from 'clarity'

interface FootprintProps {
  footprintParam: string
  isApp?: boolean
  kgCo2eChange: number
  regionName: string
  existing: boolean
  household: boolean
}

const Footprint = ({
  footprintParam,
  isApp = false,
  kgCo2eChange,
  regionName,
  existing,
  household
}: FootprintProps) => {
  const doOnce = useRef(false)

  useEffect(() => {
    if (doOnce.current) return

    doOnce.current = true

    ShowFootprintMessageModal({
      household,
      kgCo2eChange,
      existing,
      regionName,
      footprintParam,
      isApp
    })
  }, [])

  return null
}

export default Footprint
