import { action, computed, extendObservable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { GROUP_MEMBERSHIP_REQUEST_STATES, MODEL_TYPES } from 'Constants'
import {
  filterByType,
  findById,
  findIndexById,
  addOrReplace
} from 'Utilities/arrays'

const initialState = {
  filters: {
    query: '',
    state: GROUP_MEMBERSHIP_REQUEST_STATES.PENDING
  },
  initialized: false,
  membershipRequests: [],
  loaded: false,
  loading: false,
  pagination: {
    current: 1,
    first: 1,
    last: null,
    next: null,
    previous: null
  },
  people: [],
  users: [],
  teams: [],
  selectedMembershipRequestIds: []
}

class MembershipRequestStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    extendObservable(this, initialState)
  }

  @action
  init = async (values = {}) => {
    try {
      if (this.initialized) return Promise.resolve()

      await Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })

      await this.fetchMembershipRequests()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @computed
  get allMembershipRequestsSelected() {
    return (
      this.selectedMembershipRequestIds.length ===
      this.membershipRequests.length
    )
  }

  @computed
  get anyMembershipRequestSelected() {
    return this.selectedMembershipRequestIds.length > 0
  }

  @action
  deselectAll = () => {
    this.selectedMembershipRequestIds.clear()
  }

  @action
  fetchMembershipRequests = async (page = { number: 1 }) => {
    try {
      this.loading = true
      const route = APIRoutes[this.rootStore.type].membership_requests.all(
        this.rootStore.id
      )
      const { data } = await API.get(route, {
        params: Object.assign({}, this.filtersAsParams, {
          'page[number]': page.number
        })
      })
      if (data.included && data.included.length) {
        this.receiveIncludedData(data.included)
      }
      if (page.number === 1) {
        this.membershipRequests = data.data
      } else {
        this.receiveMembershipRequests(data.data)
      }
      this.pagination = data.meta.pagination
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @computed
  get filtersAsParams() {
    const _populatedFilters = Object.keys(this.filters).filter(
      (key) =>
        this.filters[key] !== null &&
        this.filters[key] !== undefined &&
        this.filters[key].length !== 0
    )
    return _populatedFilters.reduce((accumulator, filter) => {
      return Object.assign(accumulator, { [filter]: this.filters[filter] })
    }, {})
  }

  @action
  getPerson = (personId) => {
    return findById(this.people, personId)
  }

  @action
  getUser = (userId) => {
    return findById(this.users, userId)
  }

  @action
  getTeam = (teamId) => {
    return findById(this.teams, teamId)
  }

  isMembershipRequestSelected = (membershipRequestId) => {
    return this.selectedMembershipRequestIds.includes(membershipRequestId)
  }

  @action
  receiveIncludedData = (included = []) => {
    this.receivePeople(filterByType(included, MODEL_TYPES.PERSON))
    this.receiveUsers(filterByType(included, MODEL_TYPES.USER))
    this.receiveTeams(filterByType(included, MODEL_TYPES.TEAM))
  }

  @action
  receiveMembershipRequests = (membershipRequests) => {
    membershipRequests.forEach((membershipRequest) => {
      const membershipRequestIndex = findIndexById(
        this.membershipRequests,
        membershipRequest.id
      )
      if (membershipRequestIndex !== -1) {
        this.membershipRequests[membershipRequestIndex] = membershipRequest
      } else {
        this.membershipRequests.push(membershipRequest)
      }
    })
  }

  @action
  receivePeople = (people) => {
    addOrReplace(people, this.people)
  }

  @action
  receiveUsers = (users) => {
    addOrReplace(users, this.users)
  }

  @action
  receiveTeams = (teams) => {
    addOrReplace(teams, this.teams)
  }

  @action
  reset = async () => {
    await Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key]
    })
  }

  @action
  approveSelectedMembershipRequests = async () => {
    try {
      const route = APIRoutes[
        this.rootStore.type
      ].membership_requests.batch.approve(this.rootStore.id)
      const { data } = await API.patch(route, {
        batch: { ids: this.selectedMembershipRequestIds }
      })
      data.data.forEach((membershipRequest) => {
        const _loadedMembershipRequest = findById(
          this.membershipRequests,
          membershipRequest.id
        )
        this.membershipRequests.remove(_loadedMembershipRequest)
      })
      this.deselectAll()
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  declineSelectedMembershipRequests = async () => {
    try {
      const route = APIRoutes[
        this.rootStore.type
      ].membership_requests.batch.decline(this.rootStore.id)
      const { data } = await API.patch(route, {
        batch: { ids: this.selectedMembershipRequestIds }
      })
      data.data.forEach((membershipRequest) => {
        const _loadedMembershipRequest = findById(
          this.membershipRequests,
          membershipRequest.id
        )
        this.membershipRequests.remove(_loadedMembershipRequest)
      })
      this.deselectAll()
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  selectAll = () => {
    this.selectedMembershipRequestIds = this.membershipRequests.map(
      ({ id }) => id
    )
  }

  @action
  toggleSelect = (membershipRequestId) => {
    if (this.selectedMembershipRequestIds.includes(membershipRequestId)) {
      this.selectedMembershipRequestIds.remove(membershipRequestId)
    } else {
      this.selectedMembershipRequestIds.push(membershipRequestId)
    }
  }

  @action
  updateFilter = async (filter, value) => {
    if (this.loading) return Promise.resolve()

    this.filters[filter] = value

    this.deselectAll()

    return this.fetchMembershipRequests()
  }
}

export default MembershipRequestStore
