import React from 'react'
import { ActivityIndicator } from 'clarity'

interface SpinnerProps {
  absolute?: boolean
  centered?: boolean
  disabled?: boolean
}

const Spinner = ({ absolute = false, centered = true }: SpinnerProps) => (
  <ActivityIndicator
    style={[
      absolute && {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      },
      centered && {
        margin: 'auto'
      }
    ]}
  />
)

export default Spinner
