import useLocalI18n from 'Hooks/LocalI18n'
import React, { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import SettingsListItem from './SettingsListItem'
import SettingsInput from './SettingsInput'
import SettingsInputGroup from './SettingsInputGroup'
import { Button, ButtonColors, ImageFileInput } from 'Elements'
import * as styles from './styles.module.scss'
import PublicUrl from './PublicUrl'
import { SerializedAlert } from 'Constants'

interface AttributesProps {
  banner?: {
    small: string
    medium: string
    large: string
  }
  logo?: string
  name: string
}

interface SaveAttributesProps {
  banner?: string
  bannerFile?: Blob
  logo?: string
  logoFile?: Blob
  name: string
}

export enum ShareUrlTypes {
  'empty' = 'empty',
  'sign_up' = 'sign_up',
  'portal_page' = 'portal_page'
}

interface SettingsProps {
  compulsory_teams: boolean
  public: boolean
  public_greeting: string
  sso_greeting: string
  public_slug: string
  public_teams: boolean
  requires_team_verification: boolean
  requires_verification: boolean
  team_verification_question: string
  verification_question: string
  share_url_type: ShareUrlTypes
}

interface SettingsListProps {
  attributes: AttributesProps
  availableLocales: string[]
  onAlert: (alert: SerializedAlert) => void
  onSave: (attributes: SaveAttributesProps, settings: SettingsProps) => void
  settings: SettingsProps
  ssoEnabled: boolean
}

const SettingsList = observer(
  ({
    attributes,
    availableLocales = [],
    onAlert,
    onSave,
    settings,
    ssoEnabled
  }: SettingsListProps) => {
    const { I18n } = useLocalI18n('elements/SettingsList/Lang')

    const [_attributes, _setAttributes] = useState(attributes)

    const [_bannerFile, _setBannerFile] = useState(null)

    const [_logoFile, _setLogoFile] = useState(null)

    const [_removeBannerValue, _setRemoveBannerValue] = useState(false)

    const [_removeLogoValue, _setRemoveLogoValue] = useState(false)

    const [_settings, _setSettings] = useState({ ...settings })

    const _removeBanner = () => {
      _setAttributes((prevAttributes) => ({ ...prevAttributes, banner: null }))
      _setRemoveBannerValue(true)
      _setBannerFile(null)
    }

    const _removeLogo = () => {
      _setAttributes((prevAttributes) => ({ ...prevAttributes, logo: null }))
      _setRemoveLogoValue(true)
      _setLogoFile(null)
    }

    const _updateAttributesValue = (key, value) => {
      _setAttributes((prevAttributes) => ({ ...prevAttributes, [key]: value }))
    }

    const _updateSettingsValue = (key, value) => {
      const _newSettings: Record<string, string> = {
        [key]: value
      }

      // reset share_url_type when the public sign up link setting toggled
      if (key === 'public' && value === false && _settings.share_url_type === ShareUrlTypes.sign_up) {
        _newSettings.share_url_type = ShareUrlTypes.empty
      }

      _setSettings((prevSettings) => ({ ...prevSettings, ..._newSettings }))
    }

    const [
      _requiresVerificationQuestionSetting,
      _setRequiresVerificationQuestionSetting
    ] = useState(
      _settings.verification_question !== null &&
        _settings.verification_question.length > 0
    )

    const [
      _requiresTeamVerificationQuestionSetting,
      _setRequiresTeamVerificationQuestionSetting
    ] = useState(
      _settings.team_verification_question !== null &&
        _settings.team_verification_question.length > 0
    )

    const _publicUrl = [
      window.location.origin,
      'public-organizations',
      _settings.public_slug
    ].join('/')

    const _publicSlugSaved = settings.public_slug === _settings.public_slug

    const _onSubmit = (e: React.FormEvent) => {
      e.preventDefault()

      const _saveAttributes: SaveAttributesProps = {
        name: _attributes.name
      }

      if (_removeBannerValue) {
        _saveAttributes.banner = null
      }

      if (_bannerFile) {
        _saveAttributes.bannerFile = _bannerFile
      }

      if (_removeLogoValue) {
        _saveAttributes.logo = null
      }

      if (_logoFile) {
        _saveAttributes.logoFile = _logoFile
      }

      onSave(_saveAttributes, {
        public: _settings.public,
        public_greeting: _settings.public_greeting,
        sso_greeting: _settings.sso_greeting,
        public_slug: _settings.public_slug,
        requires_verification: _settings.requires_verification,
        verification_question: _requiresVerificationQuestionSetting
          ? _settings.verification_question
          : null,
        public_teams: _settings.public_teams,
        requires_team_verification: _settings.requires_team_verification,
        team_verification_question: _requiresTeamVerificationQuestionSetting
          ? _settings.team_verification_question
          : null,
        compulsory_teams: _settings.compulsory_teams,
        share_url_type: _settings.share_url_type
      })
    }

    const shareUrlTypeOptions = useMemo(() => {
      const options = [
        {
          label: I18n.t('settings_list.items.share_url_type.options.disable'),
          value: ShareUrlTypes.empty
        }
      ]

      if (_settings.public) {
        options.push({
          label: I18n.t(
            'settings_list.items.share_url_type.options.public_link'
          ),
          value: ShareUrlTypes.sign_up
        })
      }

      if (ssoEnabled) {
        options.push({
          label: I18n.t(
            'settings_list.items.share_url_type.options.portal_page'
          ),
          value: ShareUrlTypes.portal_page
        })
      }

      return options
    }, [_settings.public, ssoEnabled])

    const selectedOptionIndex = useMemo(() => {
      const index = shareUrlTypeOptions.findIndex(
        (item) => item.value === _settings.share_url_type
      )

      if (index === -1) {
        return 0
      }

      return index
    }, [shareUrlTypeOptions, _settings.share_url_type])

    useEffect(() => {
      _setSettings({ ...settings })
    }, [settings])

    useEffect(() => {
      _setAttributes({ ...attributes })

      if (attributes.banner) {
        _setRemoveBannerValue(false)
      }

      if (attributes.logo) {
        _setRemoveLogoValue(false)
      }
    }, [attributes])

    return (
      <form onSubmit={_onSubmit}>
        <div className={styles.SettingsList}>
          <SettingsInputGroup>
            <SettingsListItem
              title={I18n.t('settings_list.items.identity.title')}>
              <SettingsInput
                label={I18n.t('settings_list.items.name.label')}
                type="text"
                name="name"
                value={_attributes.name}
                onChange={_updateAttributesValue}
              />
              <SettingsInput
                label={I18n.t('settings_list.items.share_url_type.label')}
                type="select"
                name="share_url_type"
                value={_settings.share_url_type}
                onChange={_updateSettingsValue}
                options={shareUrlTypeOptions}
                selectedOptionIndex={selectedOptionIndex}
                disabled={shareUrlTypeOptions.length < 2}
              />
              <ImageFileInput
                current={_attributes.logo}
                guidance={I18n.t('settings_list.items.logo.guidance')}
                label={I18n.t('settings_list.items.logo.label')}
                maxFileSize={100 * 1024}
                minHeight={600}
                minWidth={600}
                maxHeight={1200}
                maxWidth={1200}
                onFileChange={_setLogoFile}
                onRemoveImage={_removeLogo}
                previewCircle
                square
              />
              <ImageFileInput
                current={_attributes.banner?.medium}
                guidance={I18n.t('settings_list.items.banner.guidance')}
                label={I18n.t('settings_list.items.banner.label')}
                maxFileSize={300 * 1024}
                minHeight={720}
                minWidth={2880}
                maxHeight={720}
                maxWidth={2880}
                onFileChange={_setBannerFile}
                onRemoveImage={_removeBanner}
              />
            </SettingsListItem>
          </SettingsInputGroup>
          <SettingsInputGroup>
            <SettingsListItem
              title={I18n.t('settings_list.items.public.label')}
              tooltip={I18n.t('settings_list.items.public.tooltip')}>
              <SettingsInput
                type="toggle"
                name="public"
                value={_settings.public}
                onChange={_updateSettingsValue}
              />
            </SettingsListItem>
            {_settings.public && (
              <>
                <SettingsListItem
                  title={I18n.t('settings_list.items.public_slug.label')}
                  tooltip={I18n.t('settings_list.items.public_slug.tooltip')}>
                  <SettingsInput
                    type="text"
                    name="public_slug"
                    value={_settings.public_slug || ''}
                    onChange={_updateSettingsValue}
                  />
                  {_settings.public && _publicSlugSaved && (
                    <PublicUrl
                      onAlert={onAlert}
                      disabled={!_settings.public}
                      url={_publicUrl}
                    />
                  )}
                </SettingsListItem>
                <SettingsListItem
                  title={I18n.t('settings_list.items.public_greeting.label')}
                  tooltip={I18n.t(
                    'settings_list.items.public_greeting.tooltip'
                  )}>
                  <SettingsInput
                    type="textarea"
                    name="public_greeting"
                    value={_settings.public_greeting || ''}
                    onChange={_updateSettingsValue}
                  />
                </SettingsListItem>
                <SettingsListItem
                  title={I18n.t(
                    'settings_list.items.requires_verification.label'
                  )}
                  tooltip={I18n.t(
                    'settings_list.items.requires_verification.tooltip'
                  )}>
                  <SettingsInput
                    type="toggle"
                    name="requires_verification"
                    value={_settings.requires_verification}
                    onChange={_updateSettingsValue}
                  />
                </SettingsListItem>
                {_settings.requires_verification && (
                  <>
                    <SettingsListItem
                      title={I18n.t(
                        'settings_list.items.verification_question.label'
                      )}
                      tooltip={I18n.t(
                        'settings_list.items.verification_question.tooltip'
                      )}>
                      <SettingsInput
                        type="toggle"
                        name="verification_question_setting"
                        value={_requiresVerificationQuestionSetting}
                        onChange={(_, value) => {
                          _setRequiresVerificationQuestionSetting(
                            value.toString().toLowerCase() === 'true'
                          )
                        }}
                      />
                      {_requiresVerificationQuestionSetting && (
                        <SettingsInput
                          type="text"
                          name="verification_question"
                          value={_settings.verification_question}
                          required
                          onChange={_updateSettingsValue}
                        />
                      )}
                    </SettingsListItem>
                  </>
                )}
              </>
            )}
          </SettingsInputGroup>
          <SettingsInputGroup>
            <SettingsListItem
              title={I18n.t('settings_list.items.public_teams.label')}
              tooltip={I18n.t('settings_list.items.public_teams.tooltip')}>
              <SettingsInput
                type="toggle"
                name="public_teams"
                value={_settings.public_teams}
                onChange={_updateSettingsValue}
              />
            </SettingsListItem>
            {_settings.public_teams && (
              <>
                <SettingsListItem
                  title={I18n.t('settings_list.items.compulsory_teams.label')}
                  tooltip={I18n.t(
                    'settings_list.items.compulsory_teams.tooltip'
                  )}>
                  <SettingsInput
                    type="toggle"
                    name="compulsory_teams"
                    value={_settings.compulsory_teams}
                    onChange={_updateSettingsValue}
                  />
                </SettingsListItem>
                <SettingsListItem
                  title={I18n.t(
                    'settings_list.items.requires_team_verification.label'
                  )}
                  tooltip={I18n.t(
                    'settings_list.items.requires_team_verification.tooltip'
                  )}>
                  <SettingsInput
                    type="toggle"
                    name="requires_team_verification"
                    value={_settings.requires_team_verification}
                    onChange={_updateSettingsValue}
                  />
                </SettingsListItem>
                {_settings.requires_team_verification && (
                  <>
                    <SettingsListItem
                      title={I18n.t(
                        'settings_list.items.team_verification_question.label'
                      )}
                      tooltip={I18n.t(
                        'settings_list.items.team_verification_question.tooltip'
                      )}>
                      <SettingsInput
                        type="toggle"
                        name="team_verification_question_setting"
                        value={_requiresTeamVerificationQuestionSetting}
                        onChange={(_, value) => {
                          _setRequiresTeamVerificationQuestionSetting(
                            value.toString().toLowerCase() === 'true'
                          )
                        }}
                      />
                      {_requiresTeamVerificationQuestionSetting && (
                        <SettingsInput
                          type="text"
                          name="team_verification_question"
                          value={_settings.team_verification_question}
                          required
                          onChange={_updateSettingsValue}
                        />
                      )}
                    </SettingsListItem>
                  </>
                )}
              </>
            )}
          </SettingsInputGroup>
          {ssoEnabled && (
            <SettingsInputGroup>
              <SettingsListItem
                title={I18n.t('settings_list.items.sso_greeting.label')}
                tooltip={I18n.t('settings_list.items.sso_greeting.tooltip')}>
                <SettingsInput
                  type="textarea"
                  name="sso_greeting"
                  value={_settings.sso_greeting || ''}
                  onChange={_updateSettingsValue}
                />
              </SettingsListItem>
            </SettingsInputGroup>
          )}
          <div className={styles.SettingsList__actions}>
            <Button
              type="submit"
              color={ButtonColors.BRAND}
              label={I18n.t('settings_list.actions.save')}
            />
          </div>
        </div>
      </form>
    )
  }
)

export default SettingsList
