import React from 'react'

const NotificationsIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.206 14.55a1.99 1.99 0 0 1-1.028 2.627 1.999 1.999 0 0 1-2.632-1.025 1.969 1.969 0 0 1-.164-.828l3.325-1.454c.205.184.38.41.499.68z"
      fill="currentColor"
    />
    <path
      d="M.94 9.28c-.938-2.353-.46-4.597.942-6.065-.058-.088-.116-.17-.16-.272A1.989 1.989 0 0 1 2.75.319a2.001 2.001 0 0 1 2.633 1.024c.041.096.064.195.09.293 2.1-.042 4.174 1.233 5.257 3.404 0 0 1.606 3.676 3.369 3.969.054.003 1 .026 1.288.714.272.65-.288 1.52-1.262 1.939L3.576 16.196c-.972.419-2.047.251-2.277-.414-.228-.657.27-1.267.318-1.321.995-1.526-.677-5.18-.677-5.18z"
      fill="currentColor"
    />
  </svg>
)

export default NotificationsIcon
