import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import { Spinner, Button } from 'Elements'

interface LoadMoreButtonProps {
  label?: string
  loading?: boolean
  onLoadMore: () => void
}

const LoadMoreButton = ({
  label,
  loading = false,
  onLoadMore
}: LoadMoreButtonProps) => {
  const { I18n } = useLocalI18n('elements/LoadMoreButton/Lang')

  const _label = label ?? I18n.t('load_more_button.label')

  return (
    <>
      {loading && <Spinner />}
      {!loading && <Button label={_label} onClick={onLoadMore} />}
    </>
  )
}

export default LoadMoreButton
