import React from 'react'

const PencilIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M20.107 3.897a2.89 2.89 0 00-4.102.028L4.5 15.428l-1.448 5.52 5.522-1.446L20.08 7.999a2.886 2.886 0 00.028-4.101h0zm-4.431.358l4.074 4.072m-5.736-2.411l4.074 4.072m-13.59 5.44l2.857 1.219 1.221 2.851m7.475-11.546l-8.696 8.694"
      stroke="currentColor"
      strokeWidth="1.667"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PencilIcon
