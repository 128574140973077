import React from 'react'
import { observer } from 'mobx-react'
import { ItemInput } from 'Elements'
import styles from './styles.module.scss'

interface ItemProps {
  description: string
  id: string
  onChange: (key: string, value: string | number | boolean) => void
  subscribed: boolean
  title: string
}

const Item = observer(
  ({ description, id, onChange, subscribed, title }: ItemProps) => {
    return (
      <li className={styles.Item}>
        <legend className={styles.Item__legend}>
          <h3 className={styles.Item__title}>{title}</h3>
          <p className={styles.Item__description}>{description}</p>
        </legend>
        <span className={styles.Item__input}>
          <ItemInput
            name={id}
            type="toggle"
            onChange={onChange}
            value={subscribed}
          />
        </span>
      </li>
    )
  }
)

export default Item
