import { computed } from 'mobx'
import { APIRoutes, uploadFile } from 'API'
import { PaginatedStore, TranformContextType } from '../Base'

interface OrganizationParams {
  banner?: string
  bannerFile?: string
  logo?: string
  logoFile?: string
  name?: string
}

class OrganizationsStore extends PaginatedStore {
  constructor(_itemType?: string) {
    super()
    this._itemType = 'organization'
    this.reset()
  }

  _itemRoute = (itemId: string) => {
    return APIRoutes.admin.organization(itemId)
  }

  @computed
  get _itemsRoute() {
    return APIRoutes.admin.organizations()
  }

  _transformParams = async (
    params: OrganizationParams,
    context?: TranformContextType
  ) => {
    const _transformedParams = Object.assign({}, params)

    if (_transformedParams.bannerFile) {
      const bannerUrl = await uploadFile(
        _transformedParams.bannerFile,
        'banner'
      )
      _transformedParams.banner = bannerUrl
      delete _transformedParams.bannerFile
    }

    if (_transformedParams.logoFile) {
      const logoUrl = await uploadFile(_transformedParams.logoFile, 'logo')
      _transformedParams.logo = logoUrl
      delete _transformedParams.logoFile
    }

    return _transformedParams
  }
}

export default OrganizationsStore
