import { action, computed, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { PaginatedStore } from '../Base'

class UsersStore extends PaginatedStore {
  @observable public query: string

  constructor(_itemType?: string) {
    super()
    this._itemType = 'user'
    this.reset()
  }

  @action
  fetchItems = async (page = { number: 1 }) => {
    try {
      this.setLoading()
      const { data } = await API.get(this._itemsRoute, {
        params: {
          'page[number]': page.number,
          query: this.query || null
        }
      })
      this.receiveItems(data.data)
      this.setPagination(data.meta.pagination)
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }

  @action
  updateQuery = async (value) => {
    if (this.loading) return await Promise.resolve()

    this.query = value

    this.items.clear()

    return await this.fetchItems()
  }

  _itemRoute = (itemId: string) => {
    return APIRoutes.admin.user(itemId)
  }

  @computed
  get _itemsRoute() {
    return APIRoutes.admin.users()
  }
}

export default UsersStore
