import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { SerializedNotification } from 'Constants'
import Notifications from './Notifications'
import useLocalI18n from 'Hooks/LocalI18n'
import * as styles from './styles.module.scss'
import { Icons } from 'clarity'

interface PanelProps {
  buttonRef: React.RefObject<HTMLButtonElement>
  containerRef: React.RefObject<HTMLDivElement>
  display: boolean
  loaded?: boolean
  loading?: boolean
  menuRef: React.RefObject<HTMLDivElement>
  notifications: SerializedNotification[]
  onMarkItemsRead: (id: string | string[]) => Promise<void>
  onMarkItemsUnread: (id: string | string[]) => Promise<void>
  personId: string
  unreadCount: number
}

const Panel = ({
  buttonRef,
  containerRef,
  display,
  loaded = false,
  loading = false,
  menuRef,
  notifications,
  onMarkItemsRead,
  onMarkItemsUnread,
  personId,
  unreadCount
}: PanelProps) => {
  const { I18n } = useLocalI18n('molecules/Notifications/Lang')

  const [_markerStyle, _setMarkerStyle] = useState({
    left: 0,
    top: 0
  })

  const [_menuStyle, _setMenuStyle] = useState({
    right: 0,
    top: 0
  })

  const _notifications = notifications.slice(0, 3)

  const _unpopulated = unreadCount === 0

  const _indexHref = `/people/${personId}/notifications`

  const _settingsHref = `/people/${personId}/settings/notifications`

  const _onMarkAllRead = async () => {
    const _allItemIds = _notifications.map(({ id }) => id)
    return await onMarkItemsRead(_allItemIds)
  }

  const _markerRef = useRef(null)

  const _computeAndSetMarkerStyle = () => {
    if (!buttonRef || !buttonRef.current || !buttonRef.current.parentElement) {
      return
    }

    const _targetBoundingClientRect = buttonRef.current.getBoundingClientRect()

    const _targetBottom = _targetBoundingClientRect.bottom

    const _targetLeft = _targetBoundingClientRect.left

    const _targetWidth = _targetBoundingClientRect.width

    const _topOffset = 20

    const _elementWidth = _markerRef.current?.clientWidth || 32

    const _elementTop = _targetBottom + _topOffset

    const _elementLeft = _targetLeft + _targetWidth / 2 - _elementWidth / 2

    _setMarkerStyle({
      left: _elementLeft,
      top: _elementTop
    })
  }

  const _computeAndSetMenuStyle = () => {
    if (
      !containerRef ||
      !containerRef.current ||
      !containerRef.current.parentElement
    ) {
      return
    }

    const _targetBoundingClientRect =
      containerRef.current.parentElement.getBoundingClientRect()

    const _targetBottom = _targetBoundingClientRect.bottom

    const _targetRight = _targetBoundingClientRect.right

    const _topOffset = 20

    const _elementTop = _targetBottom + _topOffset

    const _elementRight = window.innerWidth - _targetRight

    _setMenuStyle({
      right: _elementRight,
      top: _elementTop
    })
  }

  const _computeAndSetStyles = () => {
    _computeAndSetMenuStyle()
    _computeAndSetMarkerStyle()
  }

  const _initialize = () => {
    _computeAndSetStyles()
    window.addEventListener('resize', _computeAndSetStyles)
  }

  const _reset = () => {
    window.removeEventListener('resize', _computeAndSetStyles)
  }

  useEffect(() => {
    if (!display) return

    _initialize()

    return _reset
  }, [display])

  useEffect(() => {
    _computeAndSetStyles()
  }, [])

  return (
    <CSSTransition
      classNames="fade-in"
      in={display}
      mountOnEnter
      timeout={400}
      unmountOnExit>
      <div
        className={styles.Panel}
        style={_menuStyle}
        tabIndex={-1}
        ref={menuRef}>
        <div
          className={styles.Panel__marker}
          style={_markerStyle}
          ref={_markerRef}
        />
        <header className={styles.Panel__header}>
          <h2 className={styles.Panel__title}>
            {I18n.t('notifications.panel.title', { count: unreadCount })}
          </h2>
          <a
            className={styles.Panel__settings}
            href={_settingsHref}
            title={I18n.t('notifications.panel.settings')}>
            <Icons.Gear color="#fff" style={{ width: '100%', height: '100%' }} />
          </a>
        </header>
        <main className={styles.Panel__main}>
          <Notifications
            loaded={loaded}
            loading={loading}
            notifications={_notifications}
            onMarkItemsRead={onMarkItemsRead}
            onMarkItemsUnread={onMarkItemsUnread}
          />
        </main>
        <footer className={styles.Panel__actions}>
          <a
            className={styles.Panel__action}
            href={_indexHref}>
            {I18n.t('notifications.panel.show_all')}
          </a>
          <button
            className={styles.Panel__action}
            disabled={_unpopulated}
            onClick={_onMarkAllRead}
            type="button">
            {I18n.t('notifications.panel.mark_all_read', {
              count: _notifications.length
            })}
          </button>
        </footer>
      </div>
    </CSSTransition>
  )
}

export default Panel
