import { action, computed, IObservableArray, observable } from 'mobx'
import isEqual from 'lodash/isEqual'
import { SerializedPagination } from 'Constants'
import { API } from 'API'
import { standardizeError } from 'Utilities/errors'
import { addOrReplace, findById } from 'Utilities/arrays'

export type TranformContextType = 'create' | 'update' | null

export class BaseStore {
  @observable public initialized: boolean
  @observable public loaded: boolean
  @observable public loading: boolean

  constructor() {
    this.reset()
  }

  @action
  receiveIndex = (from = [], into = []) => addOrReplace(from, into)

  @action
  reset = () => {
    this.initialized = false
    this.loaded = false
    this.loading = false
  }

  @action
  setLoaded = (loaded = true) => {
    this.loaded = loaded
    this.loading = !loaded
  }

  @action
  setLoading = (loading = true) => {
    this.loading = loading
  }
}

export class SingleItemStore extends BaseStore {
  _itemType: string
  @observable public attributes: Record<string, unknown>
  @observable public id: string
  @observable public links: Record<string, unknown>
  @observable public relationships: Record<string, unknown>

  constructor(_itemType?: string) {
    super()
    this._itemType = _itemType
    this.reset()
  }

  @action
  init = async (values = {}) => {
    try {
      const _update = Object.keys(values).some(
        (key) => !isEqual(this[key], values[key])
      )

      if (this.initialized && !_update) return await Promise.resolve()

      if (this.initialized && _update) this.reset()

      Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })

      if (!this._shouldFetchAttributes) return await Promise.resolve()

      await this.fetchItemAttributes()
      await this._postInit()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  createItem = async (params) => {
    try {
      const _transformedParams = await this._transformParams(params, 'create')
      const { data } = await API.post(this._itemsRoute, {
        [this._itemType]: _transformedParams
      })
      this.receiveItem(data.data)
      if (data?.included) this.receiveIncluded(data.included)
      await this._postCreate(data.data)
      return { ...data.data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  deleteItem = async () => {
    try {
      await API.delete(this._itemRoute)
      await this._postDelete(this.id)
      this.reset()
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  _postCreate = async (item) => {
    return await Promise.resolve(item)
  }

  @action
  _postDelete = async (itemId: string) => {
    return await Promise.resolve()
  }

  @action
  _postInit = async () => {
    return await Promise.resolve()
  }

  @action
  _postUpdate = async (item) => {
    return await Promise.resolve(item)
  }

  @action
  fetchItemAttributes = async () => {
    this.loading = true
    try {
      const { data } = await API.get(this._itemRoute)
      this.receiveItem(data.data)
      if (data?.included) this.receiveIncluded(data.included)
      return { ...data.data }
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @action
  receiveIncluded = (included: any[]) => undefined

  @action
  receiveItem = (data) => {
    if (data.id) this.id = data.id

    if (data.attributes) this.attributes = data.attributes

    if (data.links) this.links = data.links

    if (data.relationships) this.relationships = data.relationships
  }

  _transformParams = async (params, context?: TranformContextType) => {
    return await Promise.resolve(params)
  }

  @action
  updateItem = async (params) => {
    try {
      const _transformedParams = await this._transformParams(params, 'update')
      const { data } = await API.patch(this._itemRoute, {
        [this._itemType]: _transformedParams
      })
      this.receiveItem(data.data)
      if (data?.included) this.receiveIncluded(data.included)
      await this._postUpdate(data.data)
      return { ...data.data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @computed
  get _itemRoute() {
    return '/'
  }

  @computed
  get _itemsRoute() {
    return '/'
  }

  @computed
  get _shouldFetchAttributes() {
    return true
  }

  @action
  reset = () => {
    this.attributes = {}
    this.id = null
    this.initialized = false
    this.links = {}
    this.loaded = false
    this.loading = false
    this.relationships = {}
  }
}

export class PaginatedStore extends BaseStore {
  _itemType: string
  @observable public items: IObservableArray<unknown>
  @observable public pagination: SerializedPagination

  constructor(_itemType?: string) {
    super()
    this._itemType = _itemType
    this.reset()
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return await Promise.resolve()

      await this.fetchItems()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  createItem = async (params) => {
    try {
      const _transformedParams = await this._transformParams(params, 'create')
      const { data } = await API.post(this._itemsRoute, {
        [this._itemType]: _transformedParams
      })
      this.receiveItems([data.data])
      if (data?.included) this.receiveIncluded(data.included)
      return { ...data.data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  deleteItem = async (itemId: string) => {
    try {
      const route = this._itemRoute(itemId)
      await API.delete(route)
      const item = this.getItem(itemId)
      this.items.remove(item)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  fetchItems = async (page = { number: 1 }) => {
    if (this.loading) return await Promise.resolve()

    this.loading = true
    try {
      const { data } = await API.get(this._itemsRoute, {
        params: {
          'page[number]': page.number
        }
      })
      this.receiveItems(data.data)
      this.setPagination(data.meta.pagination)
      if (data?.included) this.receiveIncluded(data.included)
      return { ...data.data }
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @action
  fetchMoreItems = async () => {
    if (!this.moreItemsAvailable) return await Promise.resolve()

    return await this.fetchItems({ number: this.pagination.next })
  }

  getItem = (itemId: string) => {
    return findById(this.items, itemId)
  }

  _itemRoute = (itemId: string) => {
    return '/'
  }

  @computed
  get _itemsRoute() {
    return '/'
  }

  @computed
  get populated() {
    return this.loaded && this.items.length > 0
  }

  @computed
  get moreItemsAvailable() {
    return this.loaded && !!this.pagination.next
  }

  @action
  receiveIncluded = (included: any[]) => true

  @action
  receiveItems = (items = []) => {
    return this.receiveIndex(items, this.items)
  }

  @action
  removeItemsById = (itemIds = []) => {
    itemIds.forEach((itemId) => {
      const _item = this.getItem(itemId)

      if (!_item) return

      this.items.remove(_item)
    })
  }

  @action
  reset = () => {
    this.initialized = false
    this.items = [] as IObservableArray
    this.loaded = false
    this.loading = false
    this.pagination = {
      current: 1,
      first: 1,
      last: null,
      next: null,
      previous: null
    }
  }

  @action
  setPagination = (update: SerializedPagination) => {
    this.pagination = update
  }

  _transformParams = async (params, context?: TranformContextType) => {
    return await Promise.resolve(params)
  }

  @computed
  get unpopulated() {
    return this.loaded && this.items.length === 0
  }
}