import { action, extendObservable } from 'mobx'

const initialState = {
  closeTooltip: null
}

class TooltipStore {
  constructor() {
    extendObservable(this, initialState)
  }

  @action
  setCloseTooltip = (callback) => {
    if (this.closeTooltip) {
      this.closeTooltip()
    }

    this.closeTooltip = callback
  }

  @action
  unsetCloseTooltip = () => {
    this.closeTooltip = null
  }

  @action
  reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key]
    })
  }
}

export default TooltipStore
