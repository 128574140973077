import { action, extendObservable } from 'mobx'
import { standardizeError } from 'Utilities/errors'

const initialState = {
  attributes: {},
  id: null,
  initialized: false,
  loaded: false,
  loading: false
}

class RegionStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    extendObservable(this, initialState)
  }

  @action
  init = async (props = {}) => {
    try {
      const _update = props?.id !== this.id
      if (this.initialized && !_update) return Promise.resolve()

      if (this.initialized && _update) await this.reset()

      if (props?.id) this.id = props.id

      if (props?.attributes) this.attributes = props.attributes

      this.loaded = true
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  reset = async () => {
    await Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key]
    })
  }
}

export default RegionStore
