import { action, computed, observable } from 'mobx'
import { API, APIRoutes, uploadFile } from 'API'
import { standardizeError } from 'Utilities/errors'
import Memberships from 'Stores/Common/Memberships'
import MembershipRequests from 'Stores/Common/MembershipRequests'
import { MODEL_TYPES, SerializedOrganization } from 'Constants'
import { findByType } from 'Utilities/arrays'
import { ApplicationStore } from 'Stores/ApplicationStore'

class TeamStore {
  @observable public id: string
  @observable public admin: boolean
  @observable public attributes: any
  @observable public initialized: boolean
  @observable public links: any
  @observable public loading: boolean
  @observable public loaded: boolean
  @observable public relationships: any
  @observable public organization: SerializedOrganization
  public type = 'teams'
  public memberships = new Memberships(this)
  public membershipRequests = new MembershipRequests(this)

  constructor(public rootStore: ApplicationStore) {
    void this.reset()
  }

  @action
  init = async (id: string, admin: boolean) => {
    try {
      if (this.initialized) {
        if (this.id === id && this.admin === admin) {
          return
        }
        await this.reset()
      }
      this.id = id
      this.admin = admin
      await this.fetchTeamAttributes()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchTeamAttributes = async () => {
    this.loading = true
    try {
      const route = APIRoutes.team(this.id)
      const { data } = await API.get(route)
      this.attributes = data.data.attributes
      this.links = data.data.links
      this.relationships = data.data.relationships
      if (data.included) {
        const organization = findByType(data.included, MODEL_TYPES.ORGANIZATION)
        if (organization) {
          this.organization = organization
        }
      }
      return this.attributes
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @action
  receiveTeamAttributes = async (attributes) => {
    this.attributes = {
      ...this.attributes,
      ...attributes
    }
  }

  @action
  reset = async () => {
    this.admin = false
    this.attributes = {}
    this.id = null
    this.initialized = false
    this.links = {}
    this.loading = false
    this.loaded = false
    this.organization = undefined
    this.relationships = {}

    await this.memberships.reset()
    await this.membershipRequests.reset()
  }

  @action
  updateTeam = async (params) => {
    try {
      const route = APIRoutes.team(this.id)
      if (params.bannerFile) {
        const bannerUrl = await uploadFile(params.bannerFile, 'banner')
        params.banner = bannerUrl
        delete params.bannerFile
      }

      if (params.logoFile) {
        const logoUrl = await uploadFile(params.logoFile, 'logo')
        params.logo = logoUrl
        delete params.logoFile
      }

      const { data } = await API.patch(route, { team: params })
      this.attributes = data.data.attributes
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @computed
  get pendingCommitmentsPopulated() {
    return (
      this.loaded &&
      this.relationships.commitments.meta.all_time.pending.total > 0
    )
  }

  @computed
  get personId() {
    return this.rootStore.personStore.id
  }

  @computed
  get successfulCommitmentsPopulated() {
    return (
      this.loaded &&
      this.relationships.commitments.meta.all_time.successful.total > 0
    )
  }

  @computed
  get logoWithFallback() {
    return this.attributes?.logo || this.organization?.attributes?.logo
  }

  @computed
  get bannerWithFallback() {
    return this.attributes?.banner?.large || this.organization?.attributes?.banner?.large
  }
}

export default TeamStore
