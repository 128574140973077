import React, { memo } from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import { DefaultContext } from '@giki_zero/javascript/contexts'
import { FullScreenDialog, SvgImage, STYLES } from 'clarity'

interface LeaderboardEnqueueSuccessProps {
  onClose: () => void
}

const EmailDownload: typeof SvgImage = memo(function EmailDownload(props) {
  return <SvgImage image={require('modals/email.svg')} {...props} />
})

const LeaderboardEnqueueSuccess = ({
  onClose
}: LeaderboardEnqueueSuccessProps) => {
  const { I18n } = useLocalI18n('modals/LeaderboardEnqueueSuccess/Lang')

  const {
    applicationStore: { userStore }
  } = React.useContext(DefaultContext)

  return (
    <FullScreenDialog
      isHidden={false}
      closeCallback={onClose}
      title={I18n.t('leaderboard_enqueue_success.title')}
      text={I18n.t('leaderboard_enqueue_success.text', {
        email: userStore.attributes?.email
      })}
      primaryActionLabel={I18n.t('leaderboard_enqueue_success.button')}
      primaryActionCallback={onClose}
      Image={EmailDownload}
      cardStyle={{
        maxWidth: STYLES.grid.maxTextWidth
      }}
    />
  )
}

export default LeaderboardEnqueueSuccess
