import { ACTION_STATES } from 'Constants'
import { SerializedAction, SerializedCommitment } from 'Constants'

export const computeAnimationDelay = (
  index,
  paginationLimit = 10,
  baseDelay = 0.1
) => {
  if (index === 0) return 0

  if (index < paginationLimit) return index * baseDelay

  return (index % paginationLimit) * baseDelay
}

export const computeCommitmentStatus = (commitment: SerializedCommitment) => {
  if (!commitment || !commitment.attributes || !commitment.attributes.state) {
    return ACTION_STATES.SUGGESTED
  }

  return commitment.attributes.state
}

export const getCommitmentFromAction = (
  action: SerializedAction,
  commitments: SerializedCommitment[]
) => {
  const currentCommitment = action.relationships.current_commitment
  if (
    !currentCommitment ||
    !currentCommitment.data ||
    !currentCommitment.data.id
  ) {
    return null
  }

  return commitments.find((commitment) => {
    return commitment.id === currentCommitment.data.id
  })
}
