/**
 * Support for older versions of Safari, in which setting the overflow of the
 * body is insufficient, and the entire document must be fixed to the size of
 * the viewport. This update that sets a CSS variable takes account of the
 * showing/hiding of the Safari navigation bar at the bottom of the screen.
 * See https://pqina.nl/blog/how-to-prevent-scrolling-the-page-on-ios-safari/
 */

const syncHeight = () => {
  document.documentElement.style.setProperty(
    '--window-inner-height',
    `${window.innerHeight}px`
  )
}

let scrollY: number

export const lockScroll = () => {
  scrollY = window.pageYOffset
  window.addEventListener('resize', syncHeight)
  syncHeight()
  document.documentElement.setAttribute('locked', 'true')
  document.documentElement.style.top = `-${scrollY}px`
}

export const unlockScroll = () => {
  document.documentElement.style.removeProperty('top')
  document.documentElement.removeAttribute('locked')
  window.scrollTo(0, scrollY)
  window.removeEventListener('resize', syncHeight)
}
