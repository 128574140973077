export const CAMPAIGN_CONTEXTS = {
  ADMIN: 'admin',
  ORGANIZATION: 'organization'
}

export const CAMPAIGN_TYPES = {
  STANDARD: 'standard',
  TEMPLATE: 'template'
}

export const ACTION_ROLES = {
  EXCLUDED: 'excluded',
  INCLUDED: 'included'
}

export const CAMPAIGN_TARGET_ATTRIBUTES = [
  'participants_count',
  'pending_commitments_count',
  'successful_commitments_count'
]
