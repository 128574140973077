import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useLocalI18n from 'Hooks/LocalI18n'
import { DefaultContext } from 'Contexts'
import { Button, ButtonColors, Spinner } from 'Elements'
import AdminStore from './AdminStore'
import Tabs from './Tabs'
import * as styles from './styles.module.scss'

interface AdminProps {
  availableLocales: string[]
}

const Admin = observer(({ availableLocales = ['en'] }: AdminProps) => {
  const { I18n } = useLocalI18n('molecules/Announcements/Lang')

  const {
    applicationStore: { modalStore }
  } = React.useContext(DefaultContext)

  const [_attributes, _setAttributes] = useState(AdminStore.attributes)

  const _attributesMatch = _attributes === AdminStore.attributes

  const _disableForm = AdminStore.loading || _attributesMatch

  const _initialize = () => {
    void AdminStore.init()
  }

  const _onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault()
    try {
      if (_attributesMatch) return await Promise.resolve()

      await AdminStore.createAnnouncement(_attributes)
      modalStore.createAlert({
        message: I18n.t('announcements.admin.success'),
        type: 'notice'
      })
    } catch (err) {
      modalStore.handleError(err)
    }
  }

  const _onTabsChange = (key: string, value: string) => {
    _setAttributes({ ..._attributes, [key]: value })
  }

  const _reset = () => {
    void AdminStore.reset()
  }

  useEffect(() => {
    _initialize()

    return _reset
  }, [])

  useEffect(() => {
    _setAttributes(AdminStore.attributes)
  }, [AdminStore.attributes])

  return (
    <section className={styles.Admin}>
      {AdminStore.loading && <Spinner absolute />}
      <form className={styles.Admin__form} onSubmit={_onSubmit}>
        <Tabs
          attributes={_attributes}
          availableLocales={availableLocales}
          disabled={AdminStore.loading}
          onChange={_onTabsChange}
        />
        <div className={styles.Admin__actions}>
          <Button
            color={ButtonColors.BRAND}
            disabled={_disableForm}
            label={I18n.t('announcements.admin.submit')}
            type="submit"
          />
        </div>
      </form>
    </section>
  )
})

export default Admin