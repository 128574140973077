import useLocalI18n from 'Hooks/LocalI18n'
import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { Tooltip, Spinner, Button, ButtonColors, ToggleButton } from 'Elements'
import * as styles from './styles.module.scss'
import { DefaultContext } from 'Contexts'
import { CsvUploadState } from './CsvUploadState'

export interface CsvUploadProps {
  title: string
  description: string
  placeholder: string
  tooltip: string
  defaultSuccessMessage: string
  onClose: () => void
  uploadUrl: string
}

const CsvUpload = observer((props: CsvUploadProps) => {
  const { I18n } = useLocalI18n('molecules/CsvUpload/Lang')

  const {
    applicationStore: { modalStore }
  } = React.useContext(DefaultContext)

  const [_state] = useState(() => new CsvUploadState(modalStore))

  const [_toggleTab, _setToggleTab] = useState(true)

  const _rootRef = useRef(null)
  const _textareaRef = useRef<HTMLTextAreaElement>(null)

  const _onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const result = await _state.submitData(
      props.uploadUrl,
      props.defaultSuccessMessage
    )
    if (result) props.onClose()
  }

  const _onKeyDown = (e: React.KeyboardEvent) => {
    const textarea = _textareaRef.current

    if (!textarea) return

    if (e.key === 'm' && e.ctrlKey) {
      _setToggleTab(!_toggleTab)
    }

    if (_toggleTab && !e.ctrlKey && !e.altKey && e.key === 'Tab') {
      e.preventDefault()
      const value = textarea.value
      const selectionStart = textarea.selectionStart
      const selectionEnd = textarea.selectionEnd

      if (e.shiftKey) {
        textarea.value =
          value.substring(0, selectionStart - 1) + value.substring(selectionEnd)
        textarea.selectionEnd = selectionStart - 1
      } else {
        textarea.value =
          value.substring(0, selectionStart) +
          '\t' +
          value.substring(selectionEnd)
        textarea.selectionStart =
          selectionEnd + 1 - (selectionEnd - selectionStart)
        textarea.selectionEnd =
          selectionEnd + 1 - (selectionEnd - selectionStart)
      }
    }
  }

  return (
    <div className={classnames(styles.CsvUpload)} ref={_rootRef}>
      <header className="header">
        <div className="header__primary">
          <h1 className="h1 h--center  modal__title">{props.title}</h1>
        </div>
      </header>
      {(_state.processing && <Spinner centered />) ||
        (_state.page === 'entry' && (
          <form onSubmit={_onFormSubmit}>
            <div
              className={classnames(
                styles.FormRow,
                styles['FormRow--bs--large']
              )}>
              <h3 className="h3 h--primary h--center h--separated">
                <label htmlFor="csvData">{props.description}</label>{' '}
                <Tooltip
                  getScrollableAncestor={() => _rootRef.current}
                  inline
                  scrollableAncestor={null}
                  text={props.tooltip}
                  label={props.description}
                />
              </h3>
            </div>

            <div
              className={classnames(
                styles.FormRow,
                styles['FormRow--inline-center'],
                styles['FormRow--bs--large']
              )}>
              <label
                htmlFor="tabToggle"
                aria-label={I18n.t('csv_upload.toggle_tab.aria_label')}>
                {I18n.t('csv_upload.toggle_tab.label')}
              </label>
              <ToggleButton
                toggle={() => {
                  _setToggleTab(!_toggleTab)
                }}
                untoggledLabel={I18n.t('csv_upload.toggle_tab.untoggled_label')}
                toggledLabel={I18n.t('csv_upload.toggle_tab.toggled_label')}
                toggled={_toggleTab}
                id="tabToggle"
              />
            </div>

            <div
              className={classnames(
                styles.FormRow,
                styles['FormRow--inline-center'],
                styles['FormRow--bs--large']
              )}>
              <textarea
                className={styles.Textarea}
                id="csvData"
                data-testid="CsvUpload--data-input"
                name="csvData"
                ref={_textareaRef}
                placeholder={props.placeholder}
                rows={12}
                required={true}
                value={_state.csvData}
                onChange={(event) => _state.setCsvData(event.target.value)}
                onKeyDown={_onKeyDown}
              />
            </div>
            <div
              className={classnames(
                styles.FormRow,
                styles['FormRow--inline-center'],
                styles['FormRow--mobile-column']
              )}>
              <Button
                className={styles.Button}
                data={{ testid: 'CsvUpload--submit-button' }}
                type="submit"
                color={ButtonColors.BRAND}
                label={I18n.t('csv_upload.buttons.submit')}
              />
              <Button
                className={styles.Button}
                data={{ testid: 'CsvUpload--cancel-button' }}
                onClick={props.onClose}
                label={I18n.t('csv_upload.buttons.cancel')}
              />
            </div>
          </form>
        )) ||
        (_state.page === 'errors' && (
          <>
            <div
              className={classnames(
                styles.FormRow,
                styles['FormRow--bs--large']
              )}>
              <h3 className="h3 h--primary h--center h--separated">
                {I18n.t('csv_upload.errors.title')}
              </h3>
              <p className="h4 h--center ">
                {I18n.t('csv_upload.errors.description')}
              </p>
            </div>
            {_state.errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
            <div
              className={classnames(
                styles.FormRow,
                styles['FormRow--inline-center'],
                styles['FormRow--mobile-column']
              )}>
              <Button
                className={styles.Button}
                data={{ testid: 'CsvUpload--back-button' }}
                label={I18n.t('csv_upload.buttons.back')}
                onClick={() => _state.goToPage('entry')}
                disabled={_state.processing}
              />
              <Button
                className={styles.Button}
                onClick={props.onClose}
                label={I18n.t('csv_upload.buttons.cancel')}
              />
            </div>
          </>
        ))}
    </div>
  )
})

export default CsvUpload
