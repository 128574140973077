export interface UnformattedError {
  message?: string;
  response?: {
    data?: {
      errors?: Array<{
        detail: string
      }>
    };
  };
}

const formatErrorMessages = (error: UnformattedError) => {
  if (error.response?.data?.errors) {
    return error.response.data.errors.map(err => err.detail)
  }
  return [error.message]
}

// TODO: this obviously replicates a lot of the functionality above, but
// returns a string rather than an array. We should review how the errors
// are being handled and see if we can resolve this into a single function.
const standardizeError = (error: UnformattedError) => {
  if (error.response?.data?.errors) {
    return error.response.data.errors.map(err => err.detail).join(', ')
  }
  
  return error.message
}

export { formatErrorMessages, standardizeError }
