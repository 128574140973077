import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

interface ListProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  items?: number
  tag?: keyof JSX.IntrinsicElements
}

const List = ({
  children,
  className,
  items = 5,
  tag: Tag = 'ul'
}: ListProps) => {
  const _items = Array.from(Array(items).keys())

  return (
    <Tag
      data-testid="ListSkeleton"
      className={classnames(styles.List, {
        [className]: !!className
      })}>
      {_items.map(() => children)}
    </Tag>
  )
}

export default List
