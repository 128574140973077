import React from 'react'
import classnames from 'classnames'
import useLocalI18n from 'Hooks/LocalI18n'
import { Tooltip } from 'Elements'
import * as styles from './styles.module.scss'

interface StatisticProps {
  figure: number
  highlight?: boolean
  prefix?: string
  prefixTooltip?: string
  slug?: string
  suffix?: string
  unit?: string
}

const Statistic = ({
  figure,
  highlight,
  prefix,
  prefixTooltip,
  slug,
  suffix,
  unit
}: StatisticProps) => {
  const { I18n } = useLocalI18n()

  const _numberFormatter = (value: number) =>
    I18n.toNumber(value, { precision: 0 })

  return (
    <div
      className={classnames(styles.Statistic, {
        [styles[`Statistic--${slug}`]]: slug !== undefined,
        [styles[`Statistic--highlight`]]: highlight
      })}>
      {prefix && (
        <div className={styles.Statistic__prefix}>
          <p>{prefix}</p>
          {prefixTooltip && <Tooltip inline text={prefixTooltip} />}
        </div>
      )}
      <div className={styles.Statistic__main}>
        <div className={styles.Statistic__figure}>
          {_numberFormatter(figure)}
        </div>
        {unit && <div className={styles.Statistic__unit}>{unit}</div>}
      </div>
      {suffix && (
        <div className={styles.Statistic__suffix}>
          <p>{suffix}</p>
        </div>
      )}
    </div>
  )
}

export default Statistic
