import React from 'react'

const SignOutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <g>
        <line
          x1="6.5"
          y1="7"
          x2="13.5"
          y2="7"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"></line>
        <polyline
          points="11.5 5 13.5 7 11.5 9"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"></polyline>
        <path
          d="M11.7,11.49a6.5,6.5,0,1,1,0-9"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="geometricPrecision"></path>
      </g>
    </g>
  </svg>
)

export default SignOutIcon
