import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'

const Content = () => {
  const { I18n } = useLocalI18n('elements/FeatureFallback/Lang')

  return (
    <div className="Fallback">
      <header className="Fallback__header">
        <h1 className="Fallback__title">{I18n.t('fallback.title')}</h1>
        <h1 className="Fallback__subtitle">{I18n.t('fallback.subtitle')}</h1>
      </header>
      <section className="Fallback__section">
        <h2 className="Fallback__title">
          {I18n.t('fallback.explanation.title')}
        </h2>
        <p>{I18n.t('fallback.explanation.body')}</p>
      </section>
      <section className="Fallback__section">
        <h2 className="Fallback__title">{I18n.t('fallback.solution.title')}</h2>
        <p>{I18n.t('fallback.solution.prefix')}</p>
        <ul className="Fallback__list">
          {I18n.t('fallback.solution.browsers')?.map((browser) => {
            return (
              <li key={browser.name}>
                <a
                  href={browser.link}
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Download ${browser.name}`}>
                  {browser.name}
                </a>
              </li>
            )
          })}
        </ul>
        <p>{I18n.t('fallback.solution.suffix')}</p>
      </section>
      <section className="Fallback__section">
        <h2 className="Fallback__title">{I18n.t('fallback.support.title')}</h2>
        <p>{I18n.t('fallback.support.body')}</p>
        <p>
          <a
            className="button button--size-s button--color-brand"
            href="mailto:contact.zero@giki.earth">
            {I18n.t('fallback.contact')}
          </a>
        </p>
      </section>
      <section className="Fallback__section">
        <h2 className="Fallback__title">
          {I18n.t('fallback.volunteers.title')}
        </h2>
        <p>{I18n.t('fallback.volunteers.body')}</p>
        <p>
          <a
            className="button button--size-s button--color-brand"
            href="mailto:contact.zero@giki.earth">
            {I18n.t('fallback.contact')}
          </a>
        </p>
      </section>
    </div>
  )
}

const FeatureFallback = () => {
  try {
    const FEATURES = [Proxy, Symbol]
    const ALL_FEATURES_SUPPORTED = FEATURES.every(
      (feature) => feature !== undefined
    )
    if (ALL_FEATURES_SUPPORTED) return null

    return <Content />
  } catch {
    return <Content />
  }
}

export default FeatureFallback
