import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles.module.scss'
import classnames from 'classnames'
import { Tooltip } from 'Elements'

const SettingsHeader = ({ title, tooltip }) => {
  return (
    <header className={styles.SettingsList__header}>
      <h3 className={classnames('h4', styles.SettingsList__title)}>{title}</h3>
      {tooltip && <Tooltip text={tooltip} label={title} />}
    </header>
  )
}

SettingsHeader.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string
}

export default SettingsHeader
