import useLocalI18n from 'Hooks/LocalI18n'
import React, { createRef, useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { pathIsActive } from 'Utilities/path'
import { Button, ButtonIcons } from 'Elements'
import * as styles from './styles.module.scss'

interface RoundedNavigationProps {
  items: Array<{
    inclusive?: boolean | string
    label: string
    path: string
    truncate?: boolean
  }>
  transparent?: boolean
  variant?: string
}

const RoundedNavigation = ({
  variant,
  transparent = false,
  items
}: RoundedNavigationProps) => {
  const { I18n } = useLocalI18n('elements/RoundedNavigation/Lang')

  const _moreButtonRef = useRef<HTMLButtonElement>()
  const _moreMenuRef = createRef<HTMLDivElement>()
  const [_showMore, _setShowMore] = useState(false)
  const [_showMoreButton, _setShowMoreButton] = useState(true)
  const _timerRef = useRef<undefined | number>()
  const [_visibleItems, _setVisibleItems] = useState<number[]>([])
  const _itemsRef = useRef(null)
  const _containerRef = useRef(null)

  const _docClick = (e: MouseEvent) => {
    if (
      _showMore &&
      _moreMenuRef.current &&
      !_moreMenuRef.current.contains(e.target as Node)
    ) {
      _setShowMore(false)
    }
  }

  const _docKeyup = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && _showMore) {
      _setShowMore(false)
      _moreButtonRef?.current?.focus()
    }
  }

  const _checkVisibleItems = () => {
    const _parentHeight = _itemsRef.current?.offsetHeight
    const _items = _itemsRef.current?.querySelectorAll('a') || []

    if (_items.length === 0) return

    const _newVisibleItems = []

    for (let i = 0; i < items.length; i++) {
      if (_items[i].offsetHeight > 0 && _items[i].offsetTop < _parentHeight) {
        _newVisibleItems.push(i)
      }
    }

    _setVisibleItems(_newVisibleItems)
    _setShowMore(false)
  }

  useEffect(() => {
    _setShowMoreButton(_visibleItems.length < items.length)
  }, [_visibleItems])

  useEffect(() => {
    const resizeObserver = new window.ResizeObserver(() => {
      if (_timerRef.current) {
        window.cancelAnimationFrame(_timerRef.current)
      }

      _timerRef.current = window.requestAnimationFrame(_checkVisibleItems)
    })

    if (_containerRef.current) resizeObserver?.observe(_containerRef.current)

    return () => {
      if (_containerRef.current) resizeObserver?.unobserve(_containerRef.current)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', _docClick)
    document.addEventListener('keyup', _docKeyup)

    return () => {
      document.removeEventListener('click', _docClick)
      document.removeEventListener('keyup', _docKeyup)
    }
  })

  if (items.length === 0) return null

  return (
    <div
      ref={_containerRef}
      className={classNames(styles.RoundedNavigation, {
        [styles[`RoundedNavigation--${variant}`]]: variant,
        [styles['RoundedNavigation--hide-on-mobile']]: !transparent
      })}
      data-count={items.length}>
      <div className={styles.RoundedNavigation__items} ref={_itemsRef}>
        {items.map((item, index) => {
          const _active = pathIsActive(item.path, item.inclusive)
          const _visible = _visibleItems.includes(index)

          return (
            <a
              className={classNames(styles.RoundedNavigation__item, {
                [styles['RoundedNavigation__item--active']]: _active
              })}
              data-tour-id={`RoundedNavigation__item--${index}`}
              key={`rounded-navigation-item_${index}`}
              href={item.path}
              aria-hidden={!_visible}
              tabIndex={_visible ? 0 : -1}>
              <span
                className={classNames({
                  [styles['RoundedNavigation__item--truncate']]: item.truncate
                })}>
                {item.label}
              </span>
            </a>
          )
        })}
      </div>
      <Button
        ariaLabel={I18n.t('rounded_navigation.more_label')}
        actionType="more"
        onClick={() => _setShowMore(!_showMore)}
        icon={ButtonIcons.VerticalDotsIcon}
        ref={_moreButtonRef}
        ariaHidden={!_showMoreButton}
        tabIndex={_showMoreButton ? 0 : -1}
      />
      <div
        className={styles.RoundedNavigation__moreMenu}
        ref={_moreMenuRef}
        data-testid="RoundedNavigation__moreMenu"
        style={{
          display: _showMore ? '' : 'none'
        }}>
        {items.map((item, index) => {
          const _active = pathIsActive(item.path, item.inclusive)
          const _visible = _visibleItems.includes(index)

          return (
            <a
              className={classNames(styles.RoundedNavigation__moreMenu__item, {
                [styles['RoundedNavigation__moreMenu__item--active']]: _active,
                [styles['RoundedNavigation__moreMenu__item--hidden']]: _visible
              })}
              key={`rounded-navigation-moremenu-item_${index}`}
              href={item.path}>
              {item.label}
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default RoundedNavigation
