import { action, IObservableArray, observable } from 'mobx'
import { standardizeError } from 'Utilities/errors'
import { findById } from 'Utilities/arrays'
import {
  ElectricityEstimate,
  FootprintRefinement,
  GasEstimate,
  Refinement
} from 'Constants/models'

class EnergyFootprintStore {
  @observable public initialized: boolean
  @observable public loading: boolean
  @observable public estimates: IObservableArray<
    ElectricityEstimate | GasEstimate
  >

  @observable public footprint: {
    object: { estimate_id: string; source: string }
    type: string
  }

  @observable public footprintRefinements: FootprintRefinement[]
  @observable public refinements: Refinement[]
  @observable public selectedValues: {
    estimateId: string
    houseType: string
    lookup_slug: string
    bedrooms: {
      id: string
    }
  }

  constructor() {
    this.reset()
  }

  groupBy = (data, key) =>
    data.reduce((memo, item) => {
      const group = item[key]
      memo[group] = memo[group] || []
      memo[group].push(item)
      return memo
    }, {})

  @action
  init = async (values) => {
    try {
      this.loading = true
      await Object.keys(values).forEach((key) => {
        if (key === 'estimates') {
          this[key] = this.groupBy(values[key], 'lookup_slug')
        } else {
          this[key] = values[key]
        }
      })

      if (this.footprint?.object?.estimate_id) {
        const selectedEstimateId = this.footprint.object.estimate_id
        const selectedEstimate = findById(values.estimates, selectedEstimateId)
        if (!selectedEstimate) return

        this.selectedValues.estimateId = selectedEstimateId
        this.selectedValues.bedrooms = selectedEstimate
        this.selectedValues.lookup_slug = selectedEstimate.lookup_slug
      }
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loading = false
      this.initialized = true
    }
  }

  @action
  submitStep = (key, value) => {
    try {
      this.selectedValues[key] = value
      if (key === 'bedrooms') {
        this.selectedValues.estimateId = value.id
      } else {
        this.selectedValues.estimateId = ''
        this.selectedValues.bedrooms = { id: null }
      }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  reset = () => {
    this.initialized = false
    this.estimates = [] as IObservableArray
    this.footprint = { object: { estimate_id: null, source: null }, type: null }
    this.footprintRefinements = []
    this.refinements = []
    this.loading = false
    this.selectedValues = {
      estimateId: '',
      houseType: null,
      bedrooms: { id: null },
      lookup_slug: null
    }
  }
}

export default EnergyFootprintStore
