import { action, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { ApplicationStore } from 'Stores/ApplicationStore'
import { BaseStore } from 'Stores/Base'
import { SerializedCountry, SerializedRegion } from 'Constants'

class LocationStore extends BaseStore {
  @observable public countries: SerializedCountry[] = []
  @observable public regions: SerializedRegion[] = []

  constructor(public rootStore: ApplicationStore) {
    super()
    this.rootStore = rootStore
    this.setDefaults()
  }

  @action
  setDefaults() {
    this.loading = false
    this.countries.length = 0
    this.regions.length = 0
  }

  @action
  fetchCountries = async () => {
    this.loading = true
    try {
      const route = APIRoutes.locations.countries()
      const { data } = await API.get(route)
      this.countries = data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loading = false
    }
  }

  @action
  fetchRegions = async (countryId) => {
    this.loading = true
    try {
      const route = APIRoutes.locations.country.regions(countryId)
      const { data } = await API.get(route)
      this.regions = data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loading = false
    }
  }
}

export default LocationStore
