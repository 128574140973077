import { action, computed, IObservableArray, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import {
  ACTION_STATES,
  MODEL_TYPES,
  SerializedAction,
  SerializedCommitment,
  SerializedPaginationLinks
} from 'Constants'
import {
  filterByType,
  findById,
  findByType,
  findIndexById
} from 'Utilities/arrays'
import { PaginatedStore } from 'Stores/Base'

class ActionsStore extends PaginatedStore {
  public role: string
  @observable public commitments: IObservableArray<SerializedCommitment>
  @observable public items: IObservableArray<SerializedAction>
  @observable public links: SerializedPaginationLinks

  constructor(public rootStore: any, role: string) {
    super()
    this.rootStore = rootStore
    this.role = role
    this.reset()
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return await Promise.resolve()

      await this.fetchActions()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  abandonCommitment = async (actionId: string) => {
    try {
      const action = this.findAction(actionId)
      const apiPath = action.relationships.commitments.links.last
      const { data } = await API.patch(apiPath, {
        state: ACTION_STATES.ABANDONED
      })
      this.removeCommitments([data.data.id])
      this.removeActions([actionId])
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  createExistingCommitment = async (actionId: string) => {
    try {
      const action = this.findAction(actionId)
      const apiPath = action.relationships.commitments.links.create
      const { data } = await API.post(apiPath, {
        state: ACTION_STATES.EXISTING
      })
      this.rootStore.actionsStore.completeStore.receiveCommitments([data.data])
      if (data.included) {
        const action = findByType(data.included, MODEL_TYPES.ACTION)
        if (action) {
          this.rootStore.actionsStore.completeStore.receiveActions([action])
        }
      }
      this.rootStore.campaignsStore.removeActions([actionId])
      this.rootStore.actionsStore.suggestedStore.removeActions([actionId])
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  createPendingCommitment = async (actionId: string) => {
    try {
      const action = this.findAction(actionId)
      const apiPath = action.relationships.commitments.links.create
      const { data } = await API.post(apiPath, {
        state: ACTION_STATES.PENDING
      })
      this.rootStore.actionsStore.pendingStore.receiveCommitments([data.data])
      if (data.included) {
        const action = findByType(data.included, MODEL_TYPES.ACTION)
        if (action) {
          this.rootStore.actionsStore.pendingStore.receiveActions([action])
        }
      }
      this.rootStore.campaignsStore.removeActions([actionId])
      this.rootStore.actionsStore.suggestedStore.removeActions([actionId])
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  fetchActions = async (limit = 15, reFetch = false) => {
    this.setLoading()

    if (reFetch) {
      this.commitments.length = 0
      this.items.length = 0
    }

    try {
      const {
        data: { data, included, links, meta }
      } = await API.get(APIRoutes.person.actions(this.rootStore.id), {
        params: {
          state: this.role,
          'page[number]': 1,
          'page[size]': limit
        }
      })
      const commitments = filterByType(included, MODEL_TYPES.COMMITMENT)
      this.receiveCommitments(commitments)
      this.receiveActions(data)
      this.links = links
      this.setPagination(meta.pagination)
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }

  @action
  fetchMoreActions = async () => {
    this.setLoading()
    try {
      const {
        data: { data, included, links, meta }
      } = await API.get(this.links.next)
      const commitments = filterByType(included, MODEL_TYPES.COMMITMENT)
      this.receiveCommitments(commitments)
      this.receiveActions(data)
      this.links = links
      this.setPagination(meta.pagination)
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoading(false)
    }
  }

  findAction = (actionId) => {
    return findById(this.items, actionId)
  }

  getCommitmentFromAction = (action) => {
    const commitmentId = action.relationships?.current_commitment?.data?.id // eslint-disable-line

    if (!commitmentId) return undefined

    return findById(this.commitments, commitmentId)
  }

  @computed
  get populated() {
    return this.items.length > 0
  }

  @action
  receiveActions = (actions) => {
    actions.forEach((action) => {
      const actionIndex = findIndexById(this.items, action.id)
      if (actionIndex === -1) {
        this.items.push(action)
      } else {
        this.items[actionIndex] = action
      }
    })
  }

  @action
  receiveCommitments = (commitments) => {
    commitments.forEach((commitment) => {
      const commitmentIndex = findIndexById(this.commitments, commitment.id)
      if (commitmentIndex === -1) {
        this.commitments.push(commitment)
      } else {
        this.commitments[commitmentIndex] = commitment
      }
    })
  }

  @action
  refreshActions = async () => {
    if (!this.initialized) return await Promise.resolve()

    return await this.fetchActions()
  }

  @action
  removeActions = (actionIds) => {
    actionIds.forEach((actionId) => {
      const action = findById(this.items, actionId)
      this.items.remove(action)
    })
  }

  @action
  removeCommitments = (commitmentIds) => {
    commitmentIds.forEach((commitmentId) => {
      const commitment = findById(this.commitments, commitmentId)
      this.commitments.remove(commitment)
    })
  }

  @action
  reset = () => {
    this.commitments = [] as IObservableArray
    this.initialized = false
    this.items = [] as IObservableArray
    this.links = {
      first: null,
      last: null,
      next: null,
      previous: null,
      self: null
    }
    this.loaded = false
    this.setLoading(false)
    this.pagination = {
      current: 1,
      first: 1,
      last: null,
      next: null,
      previous: null
    }
  }

  @action
  succeedAtCommitment = async (actionId: string) => {
    try {
      const action = this.findAction(actionId)
      const apiPath = action.relationships.commitments.links.last
      const { data } = await API.patch(apiPath, {
        state: ACTION_STATES.SUCCESSFUL
      })
      this.rootStore.actionsStore.completeStore.receiveCommitments([data.data])
      if (data.included) {
        const action = findByType(data.included, MODEL_TYPES.ACTION)
        if (action) {
          this.rootStore.actionsStore.completeStore.receiveActions([action])
        }
      }
      this.rootStore.actionsStore.pendingStore.removeActions([actionId])
      this.rootStore.actionsStore.pendingStore.removeCommitments([data.data.id])
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }
}

export default ActionsStore
