import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import { Selector, ToggleButton } from 'Elements'

type ItemInputKey = string

type ItemInputValue = string | boolean | number

interface ItemInputProps {
  bottomSpaced?: boolean
  value: ItemInputValue
  disabled?: boolean
  name: ItemInputKey
  placeholder?: string
  required?: boolean
  type?: string
  onChange: (key: ItemInputKey, value: ItemInputValue) => void
  options?: Array<{ value: string; label: string }>
  selectedOptionIndex?: number
  className?: string
}

const ItemInput = ({
  bottomSpaced = false,
  value,
  disabled = false,
  name,
  placeholder,
  required,
  type,
  onChange,
  options,
  selectedOptionIndex,
  className
}: ItemInputProps) => {
  const { I18n } = useLocalI18n('elements/ItemInput/Lang')

  const _castValue = (rawValue: ItemInputValue): string | number => {
    if (rawValue === undefined || rawValue === null) return ''

    switch (typeof rawValue) {
      case 'string':
      case 'number':
        return rawValue

      default:
        return rawValue.toString()
    }
  }

  switch (type) {
    case 'toggle':
      return (
        <ToggleButton
          toggled={!!value}
          untoggledLabel={I18n.t('item_input.toggle.refuse')}
          toggledLabel={I18n.t('item_input.toggle.accept')}
          toggle={(toggled) => onChange(name, toggled)}
          bottomSpaced={bottomSpaced}
          id={name}
        />
      )

    case 'textarea':
      return (
        <textarea
          className={classnames(
            'textarea',
            'textarea--constrained',
            {
              'textarea--bs': bottomSpaced
            },
            className
          )}
          disabled={disabled || false}
          id={name}
          name={name}
          onChange={(event) => onChange(name, event.target.value)}
          placeholder={placeholder || ''}
          required={required || false}
          rows={4}
          value={_castValue(value)}
        />
      )

    case 'select':
      return (
        <Selector
          options={options}
          inputId={name}
          onChange={(option) => onChange(name, option.value)}
          selectedIndex={selectedOptionIndex}
          isDisabled={disabled}
          required={required}
          className={className}
        />
      )

    default:
      return (
        <input
          className={classnames(
            'text-field',
            'text-field--constrained',
            {
              'text-field--bs': bottomSpaced
            },
            className
          )}
          disabled={disabled || false}
          id={name}
          name={name}
          onChange={(event) => onChange(name, event.target.value)}
          placeholder={placeholder || ''}
          required={required || false}
          type={type}
          value={_castValue(value)}
        />
      )
  }
}

export default ItemInput
