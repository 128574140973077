import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Waypoint } from 'react-waypoint'
import classnames from 'classnames'
import { Tooltip } from 'Elements'
import * as styles from './styles.module.scss'

interface Label {
  avatar?: string
  label: string
  tooltip?: string
  value: number
}

interface ProgressChartLabelsProps {
  animate?: boolean
  labels?: Label[]
  max: number
}

const ProgressChartLabels = ({
  animate = false,
  labels = [],
  max
}: ProgressChartLabelsProps) => {
  const _computeLeftValue = (value) => {
    const initialValue = (value / max) * 100

    if (initialValue > 100) return 100

    return initialValue
  }

  const _valuesWithinThreshold = (first: number, second: number) => {
    const _baseThreshold = max * 0.1
    const _largest = Math.max(first, second)
    const _smallest = Math.min(first, second)

    return _largest - _smallest <= _baseThreshold
  }

  const _computeClassNames = (
    currentValue: number,
    position: string,
    previousValues: number[],
    nextValues: number[]
  ) => {
    const _classList = [
      styles.ProgressChartLabel,
      styles[`ProgressChartLabel--${position}`]
    ]

    if (currentValue >= max - 200) {
      _classList.push(styles['ProgressChartLabel--upper-threshold'])
    }

    if (currentValue <= 200) {
      _classList.push(styles['ProgressChartLabel--lower-threshold'])
    }

    if (position === 'above') {
      /**
       * Try to find whether any of the previous values will overlap,
       * and whether those in turn will overlap their previous value.
       * This only needs to be done for the 'above' items, as they will
       * need to avoid the previous items.
       */
      if (_valuesWithinThreshold(currentValue, previousValues[0])) {
        const overlappingValues = previousValues.filter(
          (previousValue, index, array) => {
            const _canonical = index + 1
            const _followOnValue = array[_canonical]

            if (
              _valuesWithinThreshold(currentValue, previousValue) ||
              _valuesWithinThreshold(previousValue, _followOnValue)
            ) {
              return true
            }

            return false
          }
        )

        _classList.push(
          styles[`ProgressChartLabel--clear-${overlappingValues.length}`]
        )
      }
    }

    if (position === 'below') {
      /**
       * Try to find whether any of the next values will overlap,
       * and whether those in turn will overlap their next value.
       * This only needs to be done for the 'below' items, as they will
       * need to avoid the next items.
       */
      if (_valuesWithinThreshold(currentValue, nextValues[0])) {
        const overlappingValues = nextValues.filter(
          (nextValue, index, array) => {
            const _canonical = index + 1
            const _followOnValue = array[_canonical]

            if (
              _valuesWithinThreshold(currentValue, nextValue) ||
              _valuesWithinThreshold(nextValue, _followOnValue)
            ) {
              return true
            }

            return false
          }
        )

        _classList.push(
          styles[`ProgressChartLabel--clear-${overlappingValues.length}`]
        )
      }
    }

    return _classList.join(' ')
  }

  const _computeStyle = (value: number, index: number) => {
    return {
      left: `${_computeLeftValue(value)}%`,
      transitionDelay: `${index * 200}ms`
    }
  }

  const _sortLabels = (a: Label, b: Label) => {
    if (a.value < b.value) return -1

    if (a.value > b.value) return 1

    return 0
  }

  const _items = labels.sort(_sortLabels).map((item, index, array) => {
    // Get the next two items with the same position as the current item.
    const _previousValues = [array[index - 4], array[index - 2]]
      .filter((item) => {
        return item !== undefined
      })
      .map((item) => {
        return Math.max(0, item.value)
      })

    // Get the previous two items with the same position as the current item.
    const _nextValues = [array[index + 2], array[index + 4]]
      .filter((item) => {
        return item !== undefined
      })
      .map((item) => {
        return Math.max(0, item.value)
      })

    // Alternate the positions of the items either side of the chart to minimise
    // potential overlaps.
    const _position = index % 2 === 0 ? 'above' : 'below'

    // Ensure this is a safe value, and not a negative.
    const _positiveOrZeroCurrentValue = Math.max(0, item.value)

    return Object.assign({}, item, {
      classNames: _computeClassNames(
        _positiveOrZeroCurrentValue,
        _position,
        _previousValues,
        _nextValues
      ),
      style: _computeStyle(_positiveOrZeroCurrentValue, index)
    })
  })

  return (
    <div className={styles.ProgressChartLabels}>
      {_items.map((item, index) => (
        <CSSTransition
          in={animate}
          classNames="fade-in"
          timeout={400}
          key={index}
          unmountOnExit>
          <div className={item.classNames} style={item.style}>
            <div className={styles.ProgressChartLabel__icon}>
              <div className={styles.ProgressChartLabel__text}>
                {item.label}
              </div>
              {item.avatar && (
                <div className={styles.ProgressChartLabel__avatar}>
                  <img
                    className={styles.ProgressChartLabel__image}
                    src={item.avatar}
                    alt=""
                  />
                </div>
              )}
              {item.tooltip && <Tooltip text={item.tooltip} />}
            </div>
            <div className={styles['ProgressChartLabel__outer-marker']} />
            <div className={styles['ProgressChartLabel__inner-marker']} />
          </div>
        </CSSTransition>
      ))}
    </div>
  )
}

interface ProgressChartProps {
  current: number
  displayLabels?: boolean
  labels?: Array<{
    avatar?: string
    label: string
    tooltip?: string
    value: number
  }>
  max?: number
  slug: string
  title: string
}

const ProgressChart = ({
  current,
  displayLabels = false,
  labels = [],
  max,
  slug,
  title
}: ProgressChartProps) => {
  const [_visibleInViewport, _setVisibleInViewport] = useState(false)
  const [_intialized, _setInitialized] = useState(false)

  const _computeCurrentBarStyle = () => {
    const positiveOrZeroValue = Math.max(0, current)

    return { width: `${(positiveOrZeroValue / max) * 100}%` }
  }

  const _onChartEntered = () => _setInitialized(true)

  const _onWaypointEnter = () => _setVisibleInViewport(true)

  return (
    <Waypoint onEnter={_onWaypointEnter}>
      <div className={styles.ProgressChart}>
        {displayLabels && (
          <ProgressChartLabels
            animate={_intialized}
            labels={labels}
            max={max}
          />
        )}
        <div
          className={classnames(
            styles.ProgressChart__container,
            styles[`ProgressChart__container--${slug}`],
            {
              [styles[`ProgressChart__container--spaceAbove`]]: displayLabels,
              [styles[`ProgressChart__container--spaceBelow`]]:
                displayLabels && labels.length > 1
            }
          )}>
          <CSSTransition
            in={_visibleInViewport}
            classNames="slide-in"
            timeout={400}
            onEntered={_onChartEntered}
            mountOnEnter
            unmountOnExit>
            <div
              data-testid="ProgressChart--bar"
              className={styles.ProgressChart__bar}
              style={_computeCurrentBarStyle()}
            />
          </CSSTransition>
          {!displayLabels && <h2 className="h3">{title}</h2>}
        </div>
      </div>
    </Waypoint>
  )
}

export default ProgressChart
