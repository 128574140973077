import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'

interface CloseButtonProps {
  onClick: () => void
  'data-testid'?: string
}

const CloseButton = (props: CloseButtonProps) => {
  const { I18n } = useLocalI18n('elements/CloseButton/Lang')

  return (
    <button
      className="close-button"
      type="button"
      aria-label={I18n.t('close_button.label')}
      {...props}
    />
  )
}

export default CloseButton
