import React from 'react'
import classnames from 'classnames'
import { safelyNavigate } from 'Utilities/navigation'
import * as styles from './styles.module.scss'

declare global {
  interface Window {
    Turbolinks: {
      visit: (url: string) => void
      uuid: () => string
    }
  }
}

interface LinkProps {
  label: string
  path: string
}

interface TabMenuProps {
  links: LinkProps[]
}

const TabMenu = ({ links }: TabMenuProps) => {
  const _onClick = (evt: React.MouseEvent<HTMLElement>, link: LinkProps) => {
    evt.preventDefault()

    safelyNavigate(link.path)
  }

  return (
    <ul className={styles.TabMenu}>
      {links?.map((link, index) => {
        const _active =
          link.path?.toLowerCase() === window.location.pathname.toLowerCase()
        return (
          <li key={index} className={styles.TabMenu__item}>
            <a
              onClick={(evt) => _onClick(evt, link)}
              href={link.path}
              className={classnames(styles.TabMenu__item__link, {
                [styles['TabMenu__item__link--active']]: _active
              })}>
              {link.label}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default TabMenu
