import classnames from 'classnames'
import * as styles from './styles.module.scss'
import React from 'react'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  className?: string
}

export default function Checkbox({
  label,
  className,
  checked,
  disabled,
  ...props
}: CheckboxProps) {
  return (
    <label
      className={classnames(
        styles.container,
        checked && styles.checked,
        disabled && styles.disabled,
        className
      )}>
      <input checked={checked} disabled={disabled} {...props} type="checkbox" />
      <span>{label}</span>
    </label>
  )
}
