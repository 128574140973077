import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

interface AvatarProps {
  border?: boolean
  firstName: string
  imageSrc?: string
  lastName?: string
  size?: 'xsmall' | 'small' | 'medium' | 'large'
}

const Avatar = ({
  border = false,
  firstName,
  imageSrc,
  lastName,
  size = 'large'
}: AvatarProps) => {
  if (imageSrc === undefined || imageSrc === null || imageSrc === '') {
    return (
      <div
        className={classnames(styles.Avatar, styles[`Avatar--${size}`], {
          [styles['Avatar--border']]: border
        })}>
        <div className={styles.Avatar__placeholder}>
          {firstName?.[0]}
          {lastName?.[0]}
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={classnames(styles.Avatar, styles[`Avatar--${size}`], {
          [styles['Avatar--border']]: border
        })}>
        <img
          alt={`${firstName}’s avatar`}
          className={styles.Avatar__thumbnail}
          src={imageSrc}
        />
      </div>
    )
  }
}

export default Avatar
