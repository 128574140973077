import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'

interface FuelFootprintProps {
  allowAdditionalItems?: boolean
  fields: {
    actualConsumption: {
      default: number
      label: string
      maximum: Record<string, unknown>
      type: 'decimal' | 'numeric'
    }
    chosenTimePeriod?: {
      available: string[]
      default: string
    }
    chosenUnit: {
      available: string[]
      default: string
    }
  }
  values: {
    actualConsumption?: number
    chosenTimePeriod?: string
    chosenUnit?: string
  }
  modelType: string
}

const FuelFootprint = ({ fields, modelType, values }: FuelFootprintProps) => {
  const { I18n } = useLocalI18n('molecules/FuelFootprint/Lang')

  const _getFieldValue: (
    fieldName: string,
    fallback?: number | string
  ) => string | number = (fieldName, fallback) => {
    return values[fieldName] || fields[fieldName]?.default || fallback
  }

  const _getFieldOptions: (fieldName: string) => string[] = (fieldName) => {
    return fields[fieldName]?.available || []
  }

  const [_actualConsumption, _setActualConsumption] = useState(
    _getFieldValue('actualConsumption', 0.0)
  )

  const [_maximumActualConsumption, _setMaximumActualConsumption] = useState(0)

  const [_chosenUnit, _setChosenUnit] = useState(_getFieldValue('chosenUnit'))

  const [_chosenTimePeriod, _setChosenTimePeriod] = useState(
    _getFieldValue('chosenTimePeriod', 'year')
  )

  const _actualConsumptionInputMode =
    fields?.actualConsumption?.type || 'numeric'

  const _actualConsumptionStep =
    _actualConsumptionInputMode === 'decimal' ? 0.1 : 1

  const _availableChosenTimePeriods = _getFieldOptions('chosenTimePeriod')

  const _availableChosenUnits = _getFieldOptions('chosenUnit')

  const _computeAndSetMaximumQuantity = () => {
    if (fields.chosenTimePeriod) {
      const _maximumForChosenTimePeriodAndUnit =
        fields.actualConsumption.maximum[_chosenTimePeriod][_chosenUnit]

      return _setMaximumActualConsumption(_maximumForChosenTimePeriodAndUnit)
    }

    const _maximumForChosenTimeUnits = (fields.actualConsumption.maximum[
      _chosenUnit
    ] || 100) as number

    _setMaximumActualConsumption(_maximumForChosenTimeUnits)
  }

  useEffect(() => {
    _computeAndSetMaximumQuantity()
  }, [_chosenUnit, _chosenTimePeriod])

  return (
    <>
      <div className="form-row form-row--inline-center form-row--column">
        <label htmlFor={`${modelType}_actual_consumption`}>
          {fields?.actualConsumption?.label}
        </label>
        <input
          className="text-field text-field--no-arrows"
          inputMode={_actualConsumptionInputMode}
          id={`${modelType}_actual_consumption`}
          min="0"
          max={_maximumActualConsumption}
          name={`${modelType}[actual_consumption]`}
          required
          step={_actualConsumptionStep}
          type="number"
          value={_actualConsumption}
          onChange={(ev) => _setActualConsumption(ev.target.value)}
        />
      </div>
      {fields.chosenUnit && (
        <div className="radio-group">
          {_availableChosenUnits.map((chosenUnit) => {
            return (
              <label
                className="radio-pill radio-pill--small"
                htmlFor={`${modelType}_chosen_units_${chosenUnit}`}
                key={chosenUnit}>
                <input
                  checked={_chosenUnit === chosenUnit}
                  className="radio-pill__control"
                  id={`${modelType}_chosen_units_${chosenUnit}`}
                  name={`${modelType}[chosen_units]`}
                  onChange={() => _setChosenUnit(chosenUnit)}
                  type="radio"
                  value={chosenUnit}
                />
                <span className="radio-pill__label radio-pill--small">
                  {I18n.t(`fuel_footprint.chosen_units.${chosenUnit}`)}
                </span>
              </label>
            )
          })}
        </div>
      )}
      {fields.chosenTimePeriod && (
        <div className="footprint-wizard__subsection radio-group">
          <h4 className="h5 h--bold h--upper">
            {I18n.t('fuel_footprint.chosen_time_period.label')}
          </h4>
          {_availableChosenTimePeriods.map((timePeriod) => {
            return (
              <label
                className="radio-pill radio-pill--small"
                htmlFor={`${modelType}_chosen_time_period_${timePeriod}`}
                key={timePeriod}>
                <input
                  checked={_chosenTimePeriod === timePeriod}
                  className="radio-pill__control"
                  id={`${modelType}_chosen_time_period_${timePeriod}`}
                  name={`${modelType}[chosen_time_period]`}
                  onChange={() => _setChosenTimePeriod(timePeriod)}
                  type="radio"
                  value={timePeriod}
                />
                <span className="radio-pill__label">
                  {I18n.t(`fuel_footprint.chosen_time_period.${timePeriod}`)}
                </span>
              </label>
            )
          })}
        </div>
      )}
    </>
  )
}

export default FuelFootprint
