import { action, computed, observable } from 'mobx'
import { APIRoutes } from 'API'
import { SingleItemStore } from 'Stores/Base'
import { MODEL_TYPES, SerializedCampaignsTarget } from 'Constants'
import CampaignStore from './index'

class TargetStore extends SingleItemStore {
  @observable public attributes: SerializedCampaignsTarget['attributes']

  constructor(public rootStore: CampaignStore) {
    super()
    this._itemType = MODEL_TYPES.CAMPAIGNS_TARGET
    this.rootStore = rootStore
    this.reset()
  }

  @computed
  get _itemRoute() {
    return APIRoutes.campaign.target(this.rootStore.id, this.id)
  }

  @computed
  get _shouldFetchAttributes() {
    return false
  }

  @action
  reset = () => {
    this.attributes = {
      display_publicly: true,
      maximum_participants: 0,
      participants_count: 0,
      pending_commitments_count: 0,
      selected: 'participants_count',
      successful_commitments_count: 0,
      suggested_participants_count: 0,
      suggested_pending_commitments_count: 0,
      suggested_successful_commitments_count: 0
    }
    this.id = null
    this.initialized = false
    this.links = {}
    this.loaded = false
    this.loading = false
    this.relationships = {}
  }
}

export default TargetStore
