import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import { Button, ButtonTypes } from 'Elements'

interface OptionProps {
  value: string
  label: string
}

interface HouseTypeListingProps {
  onSelect: (value: string) => void
  options: OptionProps[]
  value?: string
}

const HouseTypeListing = ({
  onSelect,
  options,
  value = ''
}: HouseTypeListingProps) => {
  const { I18n } = useLocalI18n('elements/HouseTypeListing/Lang')

  return (
    <fieldset className="footprint-wizard__row">
      <legend className="h3 h--bs">{I18n.t('house_type_listing.title')}</legend>

      <div className="form-row form-row--inline-center form-row--buttons">
        {options.map((option, index) => (
          <Button
            key={index}
            variant={
              option.value === value
                ? ButtonTypes.RADIOPILLSELECTED
                : ButtonTypes.RADIOPILL
            }
            onClick={() => onSelect(option.value)}
            label={option.label}
          />
        ))}
      </div>
    </fieldset>
  )
}

export default HouseTypeListing
