import { action, observable } from 'mobx'

import GroupSnapshotRepository from './GroupSnapshotRepository';

export type DateRange = '30d' | '3m' | '6m' | '1y';

class GroupSnapshotStore {
  @observable data: { [key in DateRange]: GroupSnapshotRepository };

  constructor(private readonly urlFactory: (dateRange: DateRange) => string) {
    this.data = {
      '30d': new GroupSnapshotRepository(() => this.urlFactory('30d')),
      '3m': new GroupSnapshotRepository(() => this.urlFactory('3m')),
      '6m': new GroupSnapshotRepository(() => this.urlFactory('6m')),
      '1y': new GroupSnapshotRepository(() => this.urlFactory('1y'))
    };
  }

  @action reset() {
    this.data['30d'].reset()
    this.data['3m'].reset()
    this.data['6m'].reset()
    this.data['1y'].reset()
  }
}

export default GroupSnapshotStore
