import { action, computed, IObservableArray, observable } from 'mobx'
import isEqual from 'lodash/isEqual'
import { standardizeError } from 'Utilities/errors'
import { API, APIRoutes } from 'API'
import { receiveIndex } from 'Utilities/store'
import {
  SerializedEmailList,
  SerializedUsersEmailsListsSubscription,
  SerializedUsersEmailsSubscription
} from 'Constants'
class EmailPreferencesStore {
  @observable public authenticated: boolean
  @observable public currentUserId: string
  @observable public error: boolean
  @observable public initialized: boolean
  @observable public token: string
  @observable public subscriptions: {
    loaded: boolean
    loading: boolean
    attributes: SerializedUsersEmailsSubscription['attributes']
  }

  @observable public lists: {
    loaded: boolean
    loading: boolean
    all: IObservableArray<SerializedEmailList>
  }

  @observable public listSubscriptions: {
    loaded: boolean
    loading: boolean
    all: IObservableArray<SerializedUsersEmailsListsSubscription>
  }

  constructor() {
    this.reset()
  }

  @action
  init = async (
    values: {
      authenticated?: boolean
      currentUserId?: string
      token?: string
    } = {}
  ) => {
    try {
      const _update = Object.keys(values).some(
        (key) => !isEqual(this[key], values[key])
      )
      if (this.initialized && !_update) return await Promise.resolve()

      if (this.initialized && _update) this.reset()

      Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })

      if (!this.authorized) return await Promise.resolve()

      await this.fetchEmailSubscriptions()
      await this.fetchEmailLists()
      await this.fetchEmailListSubscriptions()
    } catch (err) {
      this.error = true
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @computed
  get authorized() {
    return (this.authenticated || !!this.token) && !this.error
  }

  @action
  createEmailListSubscription = async (listId: string) => {
    try {
      const route = APIRoutes.users.emails.lists.subscriptions(
        this.currentUserId
      )
      const { data } = await API.post(
        route,
        {
          email_list_id: listId
        },
        { params: { token: this.token } }
      )
      receiveIndex([data.data], this.listSubscriptions.all)
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  deleteAllEmailListSubscriptions = async () => {
    try {
      const route = APIRoutes.users.emails.lists.subscriptions(
        this.currentUserId
      )
      const { data } = await API.delete(route, {
        params: {
          token: this.token
        }
      })
      this.listSubscriptions.all.clear()
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  deleteEmailListSubscription = async (listId: string) => {
    try {
      const subscription = this.findEmailListSubscriptionByListId(listId)
      const route = APIRoutes.users.emails.lists.subscription(
        this.currentUserId,
        subscription.id
      )
      const { data } = await API.delete(route, {
        params: {
          token: this.token
        }
      })
      this.listSubscriptions.all.remove(subscription)
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  fetchEmailSubscriptions = async () => {
    try {
      this.subscriptions.loading = true
      const route = APIRoutes.users.emails.subscriptions(this.currentUserId)
      const { data } = await API.get(route, {
        params: {
          token: this.token
        }
      })
      this.subscriptions = Object.assign({}, this.subscriptions, data.data)
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.subscriptions.loaded = true
      this.subscriptions.loading = false
    }
  }

  @action
  fetchEmailLists = async () => {
    try {
      this.lists.loading = true
      const route = APIRoutes.users.emails.lists.all(this.currentUserId)
      const { data } = await API.get(route, {
        params: {
          token: this.token
        }
      })
      receiveIndex(data.data, this.lists.all)
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.lists.loaded = true
      this.lists.loading = false
    }
  }

  @action
  fetchEmailListSubscriptions = async () => {
    try {
      this.listSubscriptions.loading = true
      const route = APIRoutes.users.emails.lists.subscriptions(
        this.currentUserId
      )
      const { data } = await API.get(route, {
        params: {
          token: this.token
        }
      })
      receiveIndex(data.data, this.listSubscriptions.all)
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.listSubscriptions.loaded = true
      this.listSubscriptions.loading = false
    }
  }

  findEmailListSubscriptionByListId = (
    listId: string
  ): SerializedUsersEmailsListsSubscription => {
    return this.listSubscriptions.all.find((item) => {
      return item?.relationships?.email_list?.data?.id === listId
    })
  }

  @action
  reset = () => {
    this.authenticated = false
    this.currentUserId = null
    this.error = false
    this.initialized = false
    this.lists = {
      loaded: false,
      loading: false,
      all: [] as IObservableArray
    }
    this.listSubscriptions = {
      loaded: false,
      loading: false,
      all: [] as IObservableArray
    }
    this.subscriptions = {
      loaded: false,
      loading: false,
      attributes: {
        summary: false,
        pending_commitments: false
      }
    }
    this.token = null
  }

  @computed
  get subscribedToAny() {
    const emailSubscriptions = Object.values(
      this.subscriptions.attributes
    ).some((item) => item)
    const listSubscriptions = this.listSubscriptions.all.length > 0
    return emailSubscriptions || listSubscriptions
  }

  @action
  unsubscribeFromAll = async () => {
    try {
      await this.updateEmailSubscriptions({
        pending_commitments: false,
        summary: false
      })
      await this.deleteAllEmailListSubscriptions()
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  updateEmailSubscriptions = async (attributes: {
    pending_commitments?: boolean
    summary?: boolean
  }) => {
    try {
      const route = APIRoutes.users.emails.subscriptions(this.currentUserId)
      const { data } = await API.patch(route, attributes, {
        params: { token: this.token }
      })

      this.subscriptions = Object.assign({}, this.subscriptions, data.data)

      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }
}

export default EmailPreferencesStore
