import { DirectUpload } from '@rails/activestorage'
import { renameFile } from 'Utilities/files'

export default (file, newName) => {
  const _file = newName ? renameFile(file, newName) : file
  return new Promise((resolve) => {
    const upload = new DirectUpload(
      _file,
      '/rails/active_storage/direct_uploads'
    )
    return upload.create((error, blob) => {
      if (error) {
        return error
      }
      return resolve(blob.signed_id)
    })
  })
}
