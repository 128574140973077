import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import useLocalI18n from 'Hooks/LocalI18n'

import * as styles from '../styles.module.scss'

interface PublicUrlProps {
  disabled?: boolean
  onAlert: ({ message: string }) => void
  url: string
}

const PublicUrl = ({ disabled = false, onAlert, url }: PublicUrlProps) => {
  const { I18n } = useLocalI18n('elements/SettingsList/Lang')

  const [_disabled, _setDisabled] = useState(disabled)

  const _hasClipboard = navigator.clipboard?.writeText || false

  const _onCopy = () => {
    navigator.clipboard?.writeText(url)

    _setDisabled(true)
    setTimeout(() => {
      _setDisabled(false)
    }, 5000)

    onAlert({
      message: I18n.t('settings_list.public_url.success')
    })
  }

  useEffect(() => {
    _setDisabled(disabled)
  }, [disabled])

  return (
    <div className={styles.PublicUrl}>
      <label className={styles.PublicUrl__label}>
        {I18n.t('settings_list.public_url.label')}:
      </label>
      <div className={styles.PublicUrl__field}>
        <a
          className={styles.PublicUrl__link}
          href={url}
          rel="noopener noreferrer"
          target="_blank">
          {url}
        </a>
        {_hasClipboard && (
          <button
            type="button"
            className={classnames(
              styles.PublicUrl__button,
              styles['PublicUrl__button--color-brand'],
              styles['PublicUrl__button--size-l']
            )}
            onClick={_onCopy}
            disabled={_disabled}>
            {I18n.t('settings_list.public_url.button_label')}
          </button>
        )}
      </div>
    </div>
  )
}

export default PublicUrl
