import { action, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import ActionsStore from './ActionsStore'
import CampaignsStore from './CampaignsStore'
import GroupMembershipsStore from './GroupMembershipsStore'
import GroupMembershipRequestsStore from './GroupMembershipRequestsStore'
import MetricsStore from './MetricsStore'
import NotificationsStore from './NotificationsStore'
import RegionStore from './RegionStore'
import SectionsStore from './SectionsStore'
import { ACTION_STATES, MODEL_TYPES, SerializedPerson } from 'Constants'
import { findByType } from 'Utilities/arrays'
import { BaseStore } from 'Stores/Base'

class PersonStore extends BaseStore {
  @observable public attributes: SerializedPerson['attributes']
  @observable public id: string
  @observable public links: SerializedPerson['links']
  @observable public relationships: SerializedPerson['relationships']

  @observable actionsStore: {
    completeStore: any
    pendingStore: any
    suggestedStore: any
    trendingStore: any
  }

  @observable campaignsStore: any
  @observable groupMembershipsStore: any
  @observable groupMembershipRequestsStore: any
  @observable metricsStore: any
  @observable notificationsStore: any
  @observable regionStore: any
  @observable sectionsStore: any

  constructor(public rootStore: any) {
    super()
    this.rootStore = rootStore
    this.actionsStore = {
      completeStore: new ActionsStore(this, ACTION_STATES.COMPLETE),
      pendingStore: new ActionsStore(this, ACTION_STATES.PENDING),
      suggestedStore: new ActionsStore(this, ACTION_STATES.SUGGESTED),
      trendingStore: new ActionsStore(this, ACTION_STATES.TRENDING)
    }
    this.campaignsStore = new CampaignsStore(this)
    this.groupMembershipsStore = new GroupMembershipsStore(this)
    this.groupMembershipRequestsStore = new GroupMembershipRequestsStore(this)
    this.metricsStore = new MetricsStore(this)
    this.notificationsStore = new NotificationsStore(this)
    this.regionStore = new RegionStore(this)
    this.sectionsStore = new SectionsStore(this)
    this.setDefaults()
  }

  @action
  init = async ({ id }) => {
    try {
      const _update = id !== this.id
      if (this.initialized && !_update) return await Promise.resolve()

      if (this.initialized && _update) await this.reset()

      this.id = id
      await this.fetchPersonAttributes()
      this.notificationsStore.init()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchPersonAttributes = async () => {
    try {
      this.setLoading()
      const { data } = await API.get(APIRoutes.people.read(this.id))
      this.attributes = data.data.attributes
      this.links = data.data.links
      this.relationships = data.data.relationships
      const region = findByType(data.included, MODEL_TYPES.REGION)
      await this.regionStore.init(region)
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }

  @action
  findAndReceivePersonAttributes = (items: Array<Record<string, unknown>>) => {
    if (!items) return

    if (!items.length) return

    const update = findByType(items, MODEL_TYPES.PERSON)
    if (!update) return

    return this.receivePersonAttributes(update.attributes)
  }

  @action
  receivePersonAttributes = (attributes: SerializedPerson['attributes']) => {
    this.attributes = Object.assign({}, this.attributes, attributes)
  }

  @action
  refreshActions = () => {
    const childStores = [
      this.actionsStore.completeStore,
      this.actionsStore.pendingStore,
      this.actionsStore.suggestedStore,
      this.actionsStore.trendingStore
    ]
    childStores?.forEach((childStore) => {
      childStore.refreshActions()
    })
  }

  @action
  reset = async () => {
    this.setDefaults()

    await this.actionsStore.completeStore.reset()
    await this.actionsStore.pendingStore.reset()
    await this.actionsStore.suggestedStore.reset()
    await this.actionsStore.trendingStore.reset()
    await this.campaignsStore.reset()
    await this.groupMembershipsStore.reset()
    await this.groupMembershipRequestsStore.reset()
    await this.notificationsStore.reset()
    await this.metricsStore.reset()
    await this.regionStore.reset()
  }

  @action
  setDefaults = () => {
    this.attributes = {}
    this.id = null
    this.initialized = false
    this.links = {}
    this.loaded = false
    this.loading = false
    this.relationships = {}
  }

  @action
  updatePerson = async (attributes: {
    first_name?: string
    last_name?: string
  }) => {
    try {
      this.setLoading()
      const route = APIRoutes.people.update(this.id)
      const { data } = await API.patch(route, { person: attributes })

      this.attributes = Object.assign({}, this.attributes, data.data.attributes)

      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }
}

export default PersonStore
