import React, { Suspense, useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback, Spinner } from 'Elements'
import { MODAL_TYPES } from 'Constants'

const MODAL_COMPONENTS = {
  [MODAL_TYPES.COMPULSORY_TEAMS]: React.lazy(
    async () =>
      await import(/* webpackChunkName: 'modals__compulsory_teams' */ '../CompulsoryTeams')
  ),
  [MODAL_TYPES.COMMITMENT]: React.lazy(
    async () =>
      await import(/* webpackChunkName: 'modals__commitment' */ '../Commitment')
  ),
  [MODAL_TYPES.DELETE_ITEM]: React.lazy(
    async () =>
      await import(
        /* webpackChunkName: 'modals__delete-item' */ '../ItemDeletion'
      )
  ),
  [MODAL_TYPES.EDIT_GROUP]: React.lazy(
    async () =>
      await import(/* webpackChunkName: 'modals__edit-group' */ '../EditGroup')
  ),
  [MODAL_TYPES.EDIT_ORGANIZATION]: React.lazy(
    async () =>
      await import(
        /* webpackChunkName: 'modals__edit-organization' */ '../EditOrganization'
      )
  ),
  [MODAL_TYPES.MAIN_MENU]: React.lazy(
    async () =>
      await import(/* webpackChunkName: 'modals__main-menu' */ '../MainMenu')
  ),
  [MODAL_TYPES.FOOTPRINT]: React.lazy(
    async () =>
      await import(/* webpackChunkName: 'modals__footprint' */ '../Footprint')
  ),
  [MODAL_TYPES.PUBLIC_TEAM_REQUEST]: React.lazy(
    async () =>
      await import(
        /* webpackChunkName: 'modals__public-team-request' */ '../PublicTeamRequest'
      )
  ),
  [MODAL_TYPES.LEADERBOARD_ENQUEUE_SUCCESS]: React.lazy(
    async () =>
      await import(
        /* webpackChunkName: 'modals__leaderboard_enqueue_success' */ '../LeaderboardEnqueueSuccess'
      )
  ),
  [MODAL_TYPES.JEST]: () => (
    <div>
      <a href="#TestModalLink" data-testid="TestModalLink">
        Test
      </a>
    </div>
  )
}

interface ModalContentProps {
  onExit?: () => void
}

interface ModalProps {
  category: string
  content?: ModalContentProps
}

interface ModalContainerProps {
  onClose: () => void
  modal: ModalProps
}

const ModalContainer = observer(({ onClose, modal }: ModalContainerProps) => {
  const _modalPresent = !!modal
  const [_displayModal, _setDisplayModal] = useState(_modalPresent)
  const [_modalDisplayed, _setModalDisplayed] = useState(false)
  const _trigger = useRef(null)
  const _modalRef = useRef(null)
  const _modalDisplayedRef = useRef(false)

  const _hideModal = () => {
    _setDisplayModal(false)
    _modalDisplayedRef.current = false
  }

  const _handleEscClose = (evt) => {
    if (evt.key === 'Escape') {
      const _pathClassNames = evt?.path?.map((p) => p.className) || []

      if (
        _pathClassNames.includes('react-datepicker-popper') ||
        _pathClassNames.includes('react-datepicker-wrapper')
      )
        return

      _hideModal()
    }
  }

  const _onModalEntered = () => {
    _trigger.current = document.activeElement
    _modalRef?.current?.focus()
    _setModalDisplayed(true)
    _modalDisplayedRef.current = true
  }

  const _onModalExited = () => {
    if (modal.content?.onExit) {
      modal.content.onExit()
    }
    onClose()
    _trigger.current?.focus()
    _trigger.current = null
    _setModalDisplayed(false)
    _modalDisplayedRef.current = false
  }

  const _handleTab = (evt) => {
    if (
      evt.key === 'Tab' &&
      _modalDisplayedRef.current &&
      _modalRef.current &&
      !_modalRef.current.contains(evt.target)
    ) {
      evt.preventDefault()
      _modalRef.current?.focus()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', _handleEscClose)
    window.addEventListener('keyup', _handleTab)
    _setDisplayModal(!!modal)
    _modalDisplayedRef.current = true

    return () => {
      window.removeEventListener('keyup', _handleEscClose)
      window.removeEventListener('keyup', _handleTab)
      _setDisplayModal(false)
      _modalDisplayedRef.current = false
    }
  }, [modal])

  if (!_modalPresent) return null

  const Component = MODAL_COMPONENTS[modal.category]

  // footprint modal is handled by Clarity so we only need the Component
  if (modal.category === MODAL_TYPES.FOOTPRINT) {
    return (
      <Component
        {...modal.content}
      />
    )
  }

  return (
    <CSSTransition
      appear={_displayModal}
      classNames="bouncedown"
      in={_displayModal}
      timeout={{
        appear: 0,
        enter: 0,
        exit: 400
      }}
      onEntered={_onModalEntered}
      onExited={_onModalExited}
      mountOnEnter
      unmountOnExit>
      <div
        className="page-overlay"
        ref={_modalRef}
        tabIndex={0}
        data-testid="ModalContainer">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Spinner absolute />}>
            <Component
              modalDisplayed={_modalDisplayed}
              onClose={_hideModal}
              {...modal.content}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    </CSSTransition>
  )
})

export default ModalContainer
