import { action, computed, observable } from 'mobx'
import { APIRoutes, API } from 'API'
import { SerializedOrganizationSettings } from 'Constants'
import OrganizationStore from '.'
import { SingleItemStore } from '../Base'
import { standardizeError } from 'Utilities/errors'

class SettingsStore extends SingleItemStore {
  @observable public attributes: SerializedOrganizationSettings['attributes']

  constructor(public rootStore: OrganizationStore) {
    super()
    this.rootStore = rootStore
    this._itemType = 'setting'
    this.reset()
  }

  @computed
  get _itemRoute() {
    return APIRoutes.organizations.settings(this.rootStore.id)
  }

  @action
  updateItem = async (params) => {
    params.public_greeting_en = params.public_greeting
    params.sso_greeting_en = params.sso_greeting

    try {
      const _transformedParams = await this._transformParams(params, 'update')
      const { data } = await API.patch(this._itemRoute, {
        [this._itemType]: _transformedParams
      })
      this.receiveItem(data.data)
      if (data?.included) this.receiveIncluded(data.included)
      await this._postUpdate(data.data)
      return { ...data.data }
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  reset = () => {
    this.attributes = {
      campaigns: false,
      public: false,
      public_greeting: '',
      sso_greeting: '',
      public_slug: '',
      requires_verification: false,
      verification_question: '',
      public_teams: false,
      requires_team_verification: false,
      team_verification_question: '',
      compulsory_teams: false,
      share_url_type: ''
    }
    this.id = null
    this.initialized = false
    this.links = {}
    this.loaded = false
    this.loading = false
    this.relationships = {}
  }
}

export default SettingsStore
