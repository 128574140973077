import React from 'react'

const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14.748"
    viewBox="0 0 14 14.748"
    version="1.1"
    style={{ fill: 'none' }}>
    <rect
      x="0.5"
      y="3.6571801"
      width="13"
      height="9.8387098"
      rx="4.9193501"
      style={{ stroke: '#ffffff' }}
    />
    <rect
      x="0.5"
      y="4.4092903"
      width="13"
      height="9.8387098"
      rx="4.9193501"
      style={{ stroke: '#5f2b68' }}
    />
    <path
      d="M 4.51621,3.60861 C 4.29041,2.32904 4.42589,-0.2301 6.77428,-0.2301 7.6775,-0.380639 9.48396,0.17635 9.48396,3.60861"
      style={{ stroke: '#ffffff' }}
    />
    <path
      d="M 4.51621,4.36072 C 4.29041,3.08115 4.42589,0.52201 6.77428,0.52201 7.6775,0.371471 9.48396,0.92846 9.48396,4.36072"
      style={{ stroke: '#5f2b68' }}
    />
    <circle
      cx="6.7742701"
      cy="7.6731501"
      r="1.35484"
      style={{ fill: '#ffffff' }}
    />
    <circle
      cx="6.7742701"
      cy="7.6731501"
      r="1.35484"
      style={{ fill: '#5f2b68' }}
    />
    <rect
      x="6.3226299"
      y="8.1249504"
      width="0.90322602"
      height="2.7096801"
      style={{ fill: '#ffffff' }}
    />
    <rect
      x="6.3226299"
      y="8.1249504"
      width="0.90322602"
      height="2.7096801"
      style={{ fill: '#5f2b68' }}
    />
  </svg>
)

export default LockIcon 
