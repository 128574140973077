import React from 'react'
import PropTypes from 'prop-types'

const SectionIcon = ({ type }) => {
  switch (type) {
    case 'home':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M10 18.333c.917 0 1.667-.75 1.667-1.666H8.333c0 .916.75 1.666 1.667 1.666zM7.5 15.833h5c.458 0 .833-.375.833-.833 0-.459-.375-.834-.833-.834h-5c-.458 0-.833.375-.833.834 0 .458.375.833.833.833zM10 1.667c-3.45 0-6.25 2.8-6.25 6.25 0 3.183 2.217 4.883 3.142 5.416h6.216c.925-.533 3.142-2.233 3.142-5.417 0-3.45-2.8-6.25-6.25-6.25z"
          />
        </svg>
      )

    case 'food':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M13.333 5v5c0 .916.75 1.666 1.667 1.666h.833V17.5c0 .458.375.833.834.833.458 0 .833-.375.833-.833V2.608c0-.542-.508-.942-1.033-.817-1.8.442-3.134 1.967-3.134 3.209zM9.167 7.5H7.5v-5c0-.458-.375-.833-.833-.833-.459 0-.834.375-.834.833v5H4.167v-5c0-.458-.375-.833-.834-.833-.458 0-.833.375-.833.833v5c0 1.841 1.492 3.333 3.333 3.333V17.5c0 .458.375.833.834.833.458 0 .833-.375.833-.833v-6.667c1.842 0 3.333-1.492 3.333-3.333v-5c0-.458-.375-.833-.833-.833-.458 0-.833.375-.833.833v5z"
          />
        </svg>
      )

    case 'purchases':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M17.842 9.508L10.483 2.15c-.308-.308-.733-.483-1.175-.483H3.333c-.916 0-1.666.75-1.666 1.666v5.975c0 .442.175.867.491 1.175l7.359 7.358c.65.65 1.708.65 2.358 0l5.975-5.975c.65-.65.65-1.7-.008-2.358zM5.417 6.667c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .691-.559 1.25-1.25 1.25z"
          />
        </svg>
      )

    case 'services':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M9.292 2.833L6.192 7.9c-.342.55.058 1.266.708 1.266h6.191c.65 0 1.05-.716.709-1.266l-3.092-5.067c-.325-.533-1.091-.533-1.416 0zM14.583 18.334c2.071 0 3.75-1.68 3.75-3.75 0-2.072-1.679-3.75-3.75-3.75-2.07 0-3.75 1.678-3.75 3.75 0 2.07 1.68 3.75 3.75 3.75zM3.333 17.917h5c.459 0 .834-.375.834-.834v-5c0-.458-.375-.833-.834-.833h-5c-.458 0-.833.375-.833.833v5c0 .459.375.834.833.834z"
          />
        </svg>
      )

    case 'transport':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M10 3.333H4.167c-1.384 0-2.5 1.117-2.5 2.5V12.5c0 1.383 1.116 2.5 2.5 2.5l-.642.642c-.233.233-.233.6 0 .833.233.233.6.233.833 0L5.833 15H7.5v-4.166H3.75c-.233 0-.417-.184-.417-.417v-5c0-.233.184-.417.417-.417h6.667c.233 0 .416.184.416.417v1.25H12.5v-.833c0-1.384-1.117-2.5-2.5-2.5zm-5.833 8.334c.458 0 .833.375.833.833 0 .459-.375.834-.833.834-.459 0-.834-.375-.834-.834 0-.458.375-.833.834-.833zM17.142 8.05c-.117-.333-.434-.55-.809-.55h-5.991c-.384 0-.692.217-.817.55l-1.183 3.425v4.367c0 .458.375.825.833.825.458 0 .833-.375.833-.834V15h6.667v.834c0 .458.375.833.833.833.459 0 .825-.367.834-.825l-.009-4.367-1.191-3.425zm-6.5.284h5.4c.175 0 .333.116.391.283l.575 1.666c.092.267-.108.55-.391.55h-6.542c-.283 0-.483-.283-.392-.55l.575-1.666c.042-.167.2-.284.384-.284zm-.642 5c-.458 0-.833-.375-.833-.834 0-.458.375-.833.833-.833.458 0 .833.375.833.833 0 .459-.375.834-.833.834zm6.667 0c-.459 0-.834-.375-.834-.834 0-.458.375-.833.834-.833.458 0 .833.375.833.833 0 .459-.375.834-.833.834z"
          />
        </svg>
      )

    case 'trees':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M14.133 10h.067c.675 0 1.067-.758.683-1.308L10.65 2.65c-.333-.475-1.033-.475-1.367 0l-4.2 6.042C4.7 9.242 5.1 10 5.775 10h.033l-2.416 3.717c-.367.55.033 1.283.7 1.283h4.233v1.683c0 .909.742 1.65 1.65 1.65.908 0 1.65-.741 1.65-1.65V15h4.292c.666 0 1.066-.742.691-1.291L14.133 10z"
          />
        </svg>
      )
  }
}

SectionIcon.propTypes = {
  type: PropTypes.oneOf([
    'home',
    'food',
    'purchases',
    'services',
    'transport',
    'trees'
  ]).isRequired
}

export default SectionIcon
