import React, { useState, useEffect, useRef } from 'react'
import { Button, ButtonColors, Spinner } from 'Elements'
import { getCsrfToken } from 'Utilities/forms'
import useLocalI18n from 'Hooks/LocalI18n'
import styles from './styles.module.scss'

const DeleteAccount = () => {
  const { I18n } = useLocalI18n('molecules/Settings/Lang')

  const [_displayConfirmation, _setDisplayConfirmation] = useState(false)

  const [_loading, _setLoading] = useState(false)

  const _deleteButtonRef = useRef(null)

  const _confirmButtonRef = useRef(null)

  const _toggleDisplayConfirmation = () => {
    _setDisplayConfirmation(!_displayConfirmation)
  }

  const _toggleLoading = () => {
    _setLoading(!_loading)
  }

  const _csrfToken = getCsrfToken()

  useEffect(() => {
    if (_displayConfirmation) {
      _confirmButtonRef.current?.focus()
    } else {
      _deleteButtonRef.current?.focus()
    }
  }, [_displayConfirmation])

  return (
    <div className={styles.DeleteAccount}>
      {_loading && <Spinner absolute />}
      {!_displayConfirmation && (
        <Button
          ref={_deleteButtonRef}
          color={ButtonColors.BRAND}
          onClick={_toggleDisplayConfirmation}
          label={I18n.t('settings.delete_account.delete')}
        />
      )}
      {_displayConfirmation && (
        <form action="/users" method="post" onSubmit={_toggleLoading}>
          <p className="form-section__confirm-action-text">
            {I18n.t('settings.delete_account.confirmation')}
          </p>
          <input name="_method" type="hidden" value="delete" />
          {_csrfToken && (
            <input name="authenticity_token" type="hidden" value={_csrfToken} />
          )}
          <div className={styles.Actions}>
            <Button
              ref={_confirmButtonRef}
              disabled={_loading}
              color={ButtonColors.BRAND}
              type="submit"
              label={I18n.t('settings.delete_account.confirm')}
            />
            <Button
              disabled={_loading}
              onClick={_toggleDisplayConfirmation}
              label={I18n.t('settings.common.cancel')}
            />
          </div>
        </form>
      )}
    </div>
  )
}

export default DeleteAccount
