import React from 'react'

const RevokeIcon = () => (
  <svg viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
      <path
        d="M19.968,4.067c-.006-.007-.007-.015-.014-.022s-.015-.008-.021-.014A11.244,11.244,0,0,0,4.024,19.924c.009.009.012.022.021.031s.021.011.031.02A11.243,11.243,0,0,0,19.968,4.067ZM12,4.5a7.444,7.444,0,0,1,3.8,1.046L5.547,15.8A7.484,7.484,0,0,1,12,4.5Zm0,15a7.45,7.45,0,0,1-3.8-1.047L18.454,8.2A7.486,7.486,0,0,1,12,19.5Z"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
    </g>
  </svg>
)

export default RevokeIcon
