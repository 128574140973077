import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'

const COLORS = [
  '#5f2b68',
  '#c74272',
  '#f9a832',
  '#6abc82',
  '#fa8b76',
  '#39b6d1',
  '#a4cb26'
]

const GikiConfetti = () => {
  const [_run, _setRun] = useState(false)

  useEffect(() => {
    setTimeout(() => _setRun(true), 400)
  }, [_run])

  return (
    <Confetti
      width={document.documentElement.clientWidth}
      height={document.documentElement.clientHeight}
      numberOfPieces={document.documentElement.clientWidth <= 680 ? 100 : 200}
      colors={COLORS}
      gravity={0.1}
      opacity={1}
      run={_run}
      wind={0.01}
    />
  )
}

export default GikiConfetti
