export const ACTION = 'ACTION'
export const COMMITMENT = 'COMMITMENT'
export const COMPULSORY_TEAMS = 'COMPULSORY_TEAMS'
export const DELETE_ITEM = 'DELETE_ITEM'
export const EDIT_GROUP = 'EDIT_GROUP'
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION'
export const JEST = 'JEST'
export const MAIN_MENU = 'MAIN_MENU'
export const FOOTPRINT = 'FOOTPRINT'
export const PUBLIC_TEAM_REQUEST = 'PUBLIC_TEAM_REQUEST'
export const LEADERBOARD_ENQUEUE_SUCCESS = 'LEADERBOARD_ENQUEUE_SUCCESS'
