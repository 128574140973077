import { addOrReplace, findByAttribute } from './arrays'
import { standardizeError } from './errors'
import { API } from 'API'
import isEqual from 'lodash/isEqual'

export const createPaginatedState = (included = {}) => {
  return {
    initialized: false,
    loaded: false,
    loading: false,
    pagination: {
      current: 1,
      first: 1,
      last: null,
      next: null,
      previous: null
    },
    ...included
  }
}

// TODO This needs a better name...
export const createSingleState = (included = {}) => {
  return {
    initialized: false,
    loaded: false,
    loading: false,
    attributes: {},
    id: null,
    ...included
  }
}

export const reset = async (store, state) => {
  await Object.keys(state).forEach((key) => {
    store[key] = state[key]
  })
}

export const hasAttributes = (store) => {
  return store.attributes ? Object.keys(store.attributes).length > 0 : false
}

export const setLoaded = (store, loaded = true) => {
  store.loaded = loaded
  store.loading = !loaded
}

export const initalize = async (store, values = {}, onInitalization) => {
  try {
    const _updateRequired = Object.keys(values).some(
      (key) => !isEqual(store[key], values[key])
    )

    if (store.initialized && !_updateRequired) {
      return Promise.resolve()
    }

    if (store.initialized && _updateRequired) {
      await store.reset()
    }

    await Object.keys(values).forEach((key) => {
      store[key] = values[key]
    })

    if (hasAttributes(store)) {
      setLoaded(store)
    }

    if (onInitalization) {
      await onInitalization()
    }
  } catch (err) {
    throw new Error(standardizeError(err))
  } finally {
    store.initialized = true
  }
}

export const receiveIndex = (from = [], into = []) => {
  return addOrReplace(from, into)
}

export const findById = (array = [], id) => {
  return findByAttribute(array, 'id', id)
}

export const setLoading = (store, loading = true) => {
  store.loading = loading
}

export const _printDebug = async (request) => {
  if (request.debug) {
    console.log('Starting Request')
    console.log('Route: ', request.route)
    console.log('Params: ', request.params)
    console.log('onRecieve: ', request.onRecieve)
  }
}

export const _createGetRequest = async (route, params) => {
  if (params) {
    return await API.get(route, { params: params })
  } else {
    return await API.get(route)
  }
}

export const get = async (store, request, onSuccess) => {
  _printDebug(request)

  try {
    setLoading(store)

    const { data } = await _createGetRequest(request.route, request.params)

    if (request.onRecieve) {
      request.onRecieve(data.data)
    }

    if (onSuccess) {
      onSuccess(data)
    }

    return data.data
  } catch (err) {
    throw new Error(standardizeError(err))
  } finally {
    setLoaded(store)
  }
}

export const fetchIndex = async (store, request) => {
  return get(store, request, (data) => {
    store.pagination = data.meta.pagination
  })
}

export const fetchShow = async (store, request) => {
  return get(store, request)
}
