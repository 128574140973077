import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, ButtonColors, CloseButton } from 'Elements'
import * as styles from './styles.module.scss'

const ItemDeletion = ({
  confirmationPhrase,
  deleted,
  deleting,
  onClose,
  onDelete,
  paranoidMode,
  title
}) => {
  const { I18n } = useLocalI18n('modals/ItemDeletion/Lang')

  const [_confirm, _setConfirm] = useState(false)
  const [_manualConfirmation, _setManualConfirmation] = useState('')

  const _onManualConfirmationChange = (event) =>
    _setManualConfirmation(event.target.value)

  const _toggleConfirm = () => _setConfirm(!_confirm)

  const _valid =
    _confirm && (!paranoidMode || confirmationPhrase === _manualConfirmation)

  useEffect(() => {
    if (!deleted || deleting) return

    onClose()
  }, [deleted])

  return (
    <div className={styles.Modal}>
      <div className={styles.Modal__close}>
        <CloseButton onClick={onClose} />
      </div>
      <div className={classnames(styles.Modal__content, styles.ItemDeletion)}>
        <header>
          <h1 className="h1 h--center modal__title ">{title}</h1>
        </header>
        <section>
          {_confirm ? (
            <div className={styles.ItemDeletion__confirmation}>
              <p className="h3 h--center ">
                {I18n.t('item_deletion.confirmation_check')}
              </p>
              {paranoidMode && (
                <div className={styles.ItemDeletion__safeguard}>
                  <label htmlFor="manual-confirmation-phrase">
                    {I18n.t('item_deletion.manual_confirmation', {
                      phrase: confirmationPhrase
                    })}
                  </label>
                  <input
                    className="text-field text-field--constrained"
                    disabled={deleting}
                    id="manual-confirmation-phrase"
                    name="manual-confirmation-phrase"
                    onChange={_onManualConfirmationChange}
                    type="text"
                    value={_manualConfirmation}
                  />
                </div>
              )}
              <div className={styles.ItemDeletion__actions}>
                <Button
                  className={styles.ItemDeletion__actions__item}
                  onClick={onDelete}
                  color={ButtonColors.BRAND}
                  label={I18n.t('item_deletion.confirmation')}
                  disabled={!_valid || deleting}
                />
                <Button
                  className={styles.ItemDeletion__actions__item}
                  onClick={onClose}
                  label={I18n.t('item_deletion.cancel')}
                  disabled={deleting}
                />
              </div>
            </div>
          ) : (
            <div className={styles.ItemDeletion__interrogation}>
              <div className={styles.ItemDeletion__actions}>
                <Button
                  className={styles.ItemDeletion__actions__item}
                  onClick={_toggleConfirm}
                  color={ButtonColors.BRAND}
                  label={I18n.t('item_deletion.delete')}
                />
                <Button
                  className={styles.ItemDeletion__actions__item}
                  onClick={onClose}
                  label={I18n.t('item_deletion.cancel')}
                />
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

ItemDeletion.defaultProps = {
  confirmationPhrase: 'DELETE',
  deleted: false,
  deleting: false,
  paranoidMode: false
}

ItemDeletion.propTypes = {
  confirmationPhrase: PropTypes.string,
  deleted: PropTypes.bool,
  deleting: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  paranoidMode: PropTypes.bool,
  title: PropTypes.string.isRequired
}

export default ItemDeletion
