import useLocalI18n from 'Hooks/LocalI18n'
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import SettingsListItem from './SettingsListItem'
import SettingsInput from './SettingsInput'
import { Button, ButtonColors, ImageFileInput } from 'Elements'
import * as styles from './styles.module.scss'

interface AttributesProps {
  banner?: {
    small: string
    medium: string
    large: string
  }
  logo?: string
  name?: string
}

interface SaveAttributesProps {
  banner?: string
  bannerFile?: Blob
  logo?: string
  logoFile?: Blob
  name?: string
}

interface SettingsListProps {
  attributes: AttributesProps
  fields?: string[]
  onSave: (attributes: SaveAttributesProps) => void
}

const SettingsList = observer(
  ({
    attributes,
    fields = ['name', 'logo', 'banner'],
    onSave
  }: SettingsListProps) => {
    const { I18n } = useLocalI18n('elements/SettingsList/Lang')

    const [_attributes, _setAttributes] = useState(attributes)

    const [_bannerFile, _setBannerFile] = useState(null)

    const [_logoFile, _setLogoFile] = useState(null)

    const [_removeBannerValue, _setRemoveBannerValue] = useState(false)

    const [_removeLogoValue, _setRemoveLogoValue] = useState(false)

    const _updateAttributesValue = (key: string, value: string) => {
      _setAttributes({ ..._attributes, [key]: value })
    }

    const _onSubmit = (e: React.FormEvent) => {
      e.preventDefault()

      const _saveAttributes: SaveAttributesProps = {}

      if (_attributes.name) {
        _saveAttributes.name = _attributes.name
      }

      if (_removeBannerValue) {
        _saveAttributes.banner = null
      }

      if (_bannerFile) {
        _saveAttributes.bannerFile = _bannerFile
      }

      if (_removeLogoValue) {
        _saveAttributes.logo = null
      }

      if (_logoFile) {
        _saveAttributes.logoFile = _logoFile
      }

      onSave(_saveAttributes)
    }

    const _removeBanner = () => {
      _setAttributes({ ..._attributes, banner: null })
      _setRemoveBannerValue(true)
      _setBannerFile(null)
    }

    const _removeLogo = () => {
      _setAttributes({ ..._attributes, logo: null })
      _setRemoveLogoValue(true)
      _setLogoFile(null)
    }

    useEffect(() => {
      _setAttributes({ ...attributes })

      if (attributes.banner) {
        _setRemoveBannerValue(false)
      }

      if (attributes.logo) {
        _setRemoveLogoValue(false)
      }
    }, [attributes])

    return (
      <form onSubmit={_onSubmit}>
        <div className={styles.SettingsList}>
          <div className={styles.SettingsList__group}>
            <SettingsListItem
              title={I18n.t('settings_list.items.identity.title')}>
              {
                fields.includes('name') && <SettingsInput
                  label={I18n.t('settings_list.items.name.label')}
                  type="text"
                  name="name"
                  value={_attributes.name}
                  onChange={_updateAttributesValue}
                />
              }
              {
                fields.includes('logo') && <ImageFileInput
                  current={_attributes.logo}
                  guidance={I18n.t('settings_list.items.logo.guidance')}
                  label={I18n.t('settings_list.items.logo.label')}
                  maxFileSize={100 * 1024}
                  minHeight={600}
                  minWidth={600}
                  maxHeight={1200}
                  maxWidth={1200}
                  onFileChange={_setLogoFile}
                  onRemoveImage={_removeLogo}
                  previewCircle
                  square
                />
              }
              {
                fields.includes('banner') && <ImageFileInput
                  current={_attributes.banner?.medium}
                  guidance={I18n.t('settings_list.items.banner.guidance')}
                  label={I18n.t('settings_list.items.banner.label')}
                  maxFileSize={300 * 1024}
                  minHeight={720}
                  minWidth={2880}
                  maxHeight={720}
                  maxWidth={2880}
                  onFileChange={_setBannerFile}
                  onRemoveImage={_removeBanner}
                />
              }
            </SettingsListItem>
          </div>
          <div className={styles.SettingsList__actions}>
            <Button
              type="submit"
              color={ButtonColors.BRAND}
              label={I18n.t('settings_list.actions.save')}
            />
          </div>
        </div>
      </form>
    )
  }
)

export default SettingsList
