import React, { useState } from 'react'
import { Button } from 'Elements'
import * as styles from './styles.module.scss'
import { relativeTimestamp } from 'Utilities/dates'
import { safelyNavigate } from 'Utilities/navigation'
import useLocalI18n from 'Hooks/LocalI18n'
import Category from './Category'
import Status from './Status'

interface NotificationProps {
  body: string
  category: string
  createdAt: string
  onMarkRead: () => Promise<void>
  onMarkUnread: () => Promise<void>
  read?: boolean
  title: string
  url: string
}

const Notification = ({
  body,
  category,
  createdAt,
  onMarkRead,
  onMarkUnread,
  read = false,
  title,
  url
}: NotificationProps) => {
  const { I18n } = useLocalI18n('molecules/Notifications/Lang')

  const [_loading, _setLoading] = useState(false)

  // This isn't ideal, but any requests to mark the notification
  // read will be blocked by any navigation events, so the
  // notification must be marked as read before navigating.
  const _onClickUrl = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (!read) {
      _setLoading(true)
      await onMarkRead()
    }

    safelyNavigate(url)
  }

  const _onToggleReadStatus = () => {
    if (read) {
      void onMarkUnread()
    } else {
      void onMarkRead()
    }
  }

  return (
    <div className={styles.Notification}>
      <div className={styles.Notification__meta}>
        <span className={styles.Notification__category}>
          <Category category={category} read={read} />
        </span>
        <span className={styles.Notification__timestamp}>
          {relativeTimestamp(createdAt)}
        </span>
        <span className={styles.Notification__status}>
          <Status onToggleStatus={_onToggleReadStatus} read={read} />
        </span>
      </div>
      <div className={styles.Notification__main}>
        <div className={styles.Notification__text}>
          <header className={styles.Notification__header}>
            <h3 className={styles.Notification__title}>{title}</h3>
          </header>
          <p className={styles.Notification__body}>{body}</p>
        </div>
        <div className={styles.Notification__actions}>
          <Button
            disabled={_loading}
            href={url}
            label={I18n.t(`notifications.notification.cta.${category}`)}
            onClick={_onClickUrl}
          />
        </div>
      </div>
    </div>
  )
}

export default Notification
