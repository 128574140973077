import axios from 'axios'
import { getCsrfToken } from 'Utilities/forms'
import I18n from 'i18n-js'
import { safelyNavigate } from 'Utilities/navigation'

const AuthToken = getCsrfToken()

const API = axios.create({
  baseURL: '/',
  timeout: 10000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': AuthToken
  }
})

const assignLocaleParams = (params) => {
  params.locale = I18n.currentLocale()

  return params
}

API.interceptors.request.use(
  (config) => {
    config.data = { ...config.data, authenticity_token: AuthToken }
    config.params = config.params
      ? assignLocaleParams(config.params)
      : assignLocaleParams({})

    return config
  },
  (err) => Promise.reject(err)
)

/**
 * Add a response interceptor to pick up unauthorized requests and
 * redirect the user to the sign-in page. We may want to rethink
 * this in future, since there are other, legitimate reasons why
 * the user might be unauthorized for some operations, but still
 * legitimately logged in. This is the case with the user email
 * preferences (although these use a 403 forbidden status in part
 * due to this interceptor).
 */
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      safelyNavigate('/')
    }
    return Promise.reject(error)
  }
)

export default API
