import React from 'react'
import PropTypes from 'prop-types'

const FootprintSource = ({ footprintType, source }) => (
  <input
    id={`${footprintType}_footprint_source`}
    name={`${footprintType}_footprint[source]`}
    type="hidden"
    value={source || ''}
  />
)

FootprintSource.propTypes = {
  footprintType: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired
}

export default FootprintSource
