import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonColors, ImageFileInput, CloseButton } from 'Elements'

const EditOrganization = ({
  banner,
  logo,
  name,
  updated,
  updating,
  onClose,
  onUpdate
}) => {
  const { I18n } = useLocalI18n('modals/EditOrganization/Lang')

  const [_bannerFile, _setBannerFile] = useState('')
  const [_logoFile, _setLogoFile] = useState('')
  const [_name, _setName] = useState(name)

  const _onNameChange = (event) => _setName(event.target.value)

  const _onSubmit = (event) => {
    event.preventDefault()
    onUpdate({
      bannerFile: _bannerFile,
      logoFile: _logoFile,
      name: _name
    })
  }

  useEffect(() => {
    if (updated && !updating) {
      onClose()
    }
  }, [updated])

  return (
    <div className="modal">
      <div className="modal__close">
        <CloseButton onClick={onClose} />
      </div>
      <div className="modal__content">
        <header>
          <h1 className="h1 h--center modal__title ">
            {I18n.t('edit_organization.title')}
          </h1>
        </header>
        <form onSubmit={_onSubmit}>
          <div className="form-row form-row--inline-center form-row--column">
            <label htmlFor="name">
              {I18n.t('edit_organization.fields.name.label')}
            </label>
            <input
              className="text-field text-field--constrained text-field--bs"
              id="name"
              name="name"
              onChange={_onNameChange}
              placeholder={I18n.t('edit_organization.fields.name.placeholder')}
              type="text"
              value={_name}
            />
          </div>
          <div className="form-row form-row--bs form-row--inline-center form-row--column">
            <ImageFileInput
              current={logo}
              guidance={I18n.t('edit_organization.fields.logo.guidance')}
              label={I18n.t('edit_organization.fields.logo.label')}
              minHeight={600}
              minWidth={600}
              maxHeight={1200}
              maxWidth={1200}
              onFileChange={_setLogoFile}
              previewCircle
              square
            />
          </div>
          <div className="form-row form-row--bs form-row--inline-center form-row--column">
            <ImageFileInput
              current={banner}
              guidance={I18n.t('edit_organization.fields.banner.guidance')}
              label={I18n.t('edit_organization.fields.banner.label')}
              minHeight={720}
              minWidth={2880}
              maxHeight={720}
              maxWidth={2880}
              onFileChange={_setBannerFile}
            />
          </div>
          <div className="form-row form-row--inline-center">
            <Button
              disabled={updating}
              color={ButtonColors.BRAND}
              type="submit"
              label={I18n.t('edit_organization.submit')}
            />
            <Button
              disabled={updating}
              onClick={onClose}
              label={I18n.t('edit_organization.cancel')}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

EditOrganization.defaultProps = {
  banner: '',
  logo: '',
  name: '',
  updated: false,
  updating: false
}

EditOrganization.propTypes = {
  banner: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  updated: PropTypes.bool,
  updating: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default EditOrganization
