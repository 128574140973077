import React, { useState } from 'react'
import classnames from 'classnames'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import * as styles from './styles.module.scss'

interface TabSectionsProps {
  children: React.ReactNode[]
  labels?: string[]
}

const TabSections = ({ children, labels }: TabSectionsProps) => {
  const [_selectedIndex, _selectIndex] = useState(0)

  const _selectedChild = children[_selectedIndex]

  const _labels = labels || Array.from(Array(children.length), (_, x) => x + 1)

  return (
    <div className={styles.Wrapper}>
      <nav className={styles.Nav}>
        <ul className={styles.Tabs}>
          {_labels.map((label, index) => {
            const _active = index === _selectedIndex

            return (
              <li className={styles.Tab} key={index}>
                <button
                  className={classnames(styles.Button, {
                    [styles['Button--active']]: _active
                  })}
                  disabled={index === _selectedIndex}
                  onClick={() => _selectIndex(index)}
                  type="button">
                  {label}
                </button>
              </li>
            )
          })}
        </ul>
      </nav>
      <ul className={styles.Items}>
        <SwitchTransition>
          <CSSTransition
            classNames="fade-in"
            key={_selectedIndex}
            timeout={300}>
            <li className={styles.Item}>{_selectedChild}</li>
          </CSSTransition>
        </SwitchTransition>
      </ul>
    </div>
  )
}

export default TabSections
