import React from 'react'
import {
  CookieNotification,
  Footer,
  HelpButton,
  ImpactSummary,
  RegionSelector,
  SkipToContentLink,
  SkipToContentMain,
  Tooltip
} from 'Elements'
import { PublicTransportJourneys } from 'Molecules'

const items = {
  CookieNotification,
  Footer,
  HelpButton,
  ImpactSummary,
  PublicTransportJourneys,
  RegionSelector,
  SkipToContentLink,
  SkipToContentMain,
  Tooltip
}

const View = (props) => {
  const Component = items[props.Component]

  return <Component {...props} />
}

export default View
