import { action, computed, observable } from 'mobx'
import isEqual from 'lodash/isEqual'
import { API, APIRoutes } from 'API'
import { computeCommitmentStatus } from 'Utilities/actions'
import { standardizeError } from 'Utilities/errors'
import { BaseStore } from 'Stores/Base'
import {
  ACTION_STATES,
  MODEL_TYPES,
  SerializedAction,
  SerializedCommitment
} from 'Constants'
import { findByType } from 'Utilities/arrays'
import { Impact } from 'clarity'

const COMPLETE_COMMITMENTS_COUNT_STAT = 'complete-commitments-count'

class ActionStore extends BaseStore {
  @observable public attributes: SerializedAction['attributes']
  @observable public authenticated: boolean
  @observable public commitment: SerializedCommitment
  @observable public currentPersonId: string
  @observable public id: string = null
  @observable public links: SerializedAction['links']
  @observable public param: string = null
  @observable public relationships: SerializedAction['relationships']
  @observable public stats: {
    [COMPLETE_COMMITMENTS_COUNT_STAT]: number
  } = { [COMPLETE_COMMITMENTS_COUNT_STAT]: 0 }

  constructor() {
    super()
    this.reset()
  }

  @action
  init = async (values = {}) => {
    try {
      const _update = Object.keys(values).some(
        (key) => !isEqual(this[key], values[key])
      )

      if (this.initialized && !_update) return await Promise.resolve()

      if (this.initialized && _update) this.reset()

      Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })

      await this.fetchActionAttributes()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  abandonCommitment = async () => {
    try {
      const route = APIRoutes.person.commitment(
        this.currentPersonId,
        this.commitment.id
      )
      const { data } = await API.patch(route, {
        state: ACTION_STATES.ABANDONED
      })
      const commitment = data.data
      this.commitment = commitment

      this.markAllChecklistItemsAs(false)
      
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @computed
  get actionPath() {
    if (this.authenticated) {
      return APIRoutes.person.action(this.currentPersonId, this.param)
    }
    return APIRoutes.action(this.param)
  }

  @computed
  get commitmentCompleted() {
    return (
      this.commitmentStatus === ACTION_STATES.SUCCESSFUL ||
      this.commitmentStatus === ACTION_STATES.EXISTING
    )
  }

  @computed
  get commitmentStatus() {
    return computeCommitmentStatus(this.commitment)
  }

  @action
  createActionSuppression = async () => {
    try {
      const route = this.relationships.suppressions.links.create as string
      const { data } = await API.post(route)
      
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  createPendingCommitment = async () => {
    try {
      const route = this.relationships.commitments.links.create as string
      const { data } = await API.post(route, { state: ACTION_STATES.PENDING })
      const commitment = data.data
      this.commitment = commitment
      
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  createExistingCommitment = async () => {
    try {
      const apiPath = this.relationships.commitments.links.create as string
      const { data } = await API.post(apiPath, {
        state: ACTION_STATES.EXISTING
      })
      const commitment = data.data
      this.commitment = commitment
      
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  fetchActionAttributes = async () => {
    this.setLoading()
    try {
      const { data } = await API.get(this.actionPath)
      this.attributes = data.data.attributes
      this.id = data.data.id
      this.links = data.data.links
      this.relationships = data.data.relationships

      if (data.included) {
        this.commitment =
          findByType(data.included, MODEL_TYPES.COMMITMENT) || {}
      }
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }

  @action
  fetchActionStats = async () => {
    try {
      const path = APIRoutes.actions.stat(
        this.param,
        COMPLETE_COMMITMENTS_COUNT_STAT
      )
      const { data } = await API.get(path)
      this.stats[COMPLETE_COMMITMENTS_COUNT_STAT] =
        data[COMPLETE_COMMITMENTS_COUNT_STAT]
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  reset = () => {
    this.attributes = {
      benefits: [],
      checklist: [],
      completion_criterion: null,
      description: null,
      ease: {
        description: null,
        rating: 0,
        title: null
      },
      facts: [],
      improvements: [],
      information: [],
      resources: [],
      name: null,
      tags: [],
      tips: []
    }

    this.attributes.impact = {
      description: null,
      rating: 0,
      name: null
    } as unknown as Impact

    this.authenticated = false
    this.commitment = {
      id: null,
      type: 'commitment',
      attributes: {}
    }
    this.currentPersonId = null
    this.id = null
    this.initialized = false
    this.links = {
      html_summary: null,
      permalink: null
    }
    this.loaded = false
    this.loading = false
    this.param = null
    this.relationships = {
      commitments: {
        meta: {
          all_time: {
            complete: {
              total: 0
            },
            pending: {
              total: 0
            },
            successful: {
              total: 0
            }
          }
        }
      },
      current_commitment: {},
      last_pending_commitment: {},
      last_successful_commitment: {},
      suppressions: {}
    }
  }

  @action
  succeedAtCommitment = async () => {
    try {
      const route = APIRoutes.person.commitment(
        this.currentPersonId,
        this.commitment.id
      )
      const { data } = await API.patch(route, {
        state: ACTION_STATES.SUCCESSFUL
      })
      const commitment = data.data
      this.commitment = commitment
      
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  markAllChecklistItemsAs = (completed) => {
    this.attributes.checklist.forEach((checklistItem) => {
      checklistItem.completed = completed
    })
  }

  @action
  markChecklistItemAs = (checklistItemId, completed) => {
    this.attributes.checklist.find(
      (item) => item.id === checklistItemId
    ).completed = completed
  }

  @computed
  get allChecklistItemsCompleted() {
    return this.attributes.checklist.every((item) => {
      return item.completed
    })
  }

  @action
  completePersonalChecklistItem = async (checklistItemId) => {
    const route = APIRoutes.person.checklist_items.complete(
      this.currentPersonId,
      checklistItemId
    )

    try {
      const { data } = await API.post(route)
      this.markChecklistItemAs(checklistItemId, true)

      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  uncompletePersonalChecklistItem = async (checklistItemId) => {
    const route = APIRoutes.person.checklist_items.uncomplete(
      this.currentPersonId,
      checklistItemId
    )

    try {
      const { data } = await API.delete(route)
      this.markChecklistItemAs(checklistItemId, false)

      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  /**
   * Reconstitute the action from the store state to provide
   * the analytics method with the data it expects.
   */
  @computed
  get trackableAction() {
    return {
      id: this.id,
      attributes: this.attributes,
      type: MODEL_TYPES.ACTION
    }
  }
}

export default ActionStore
