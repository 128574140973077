import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Button, ButtonColors, CloseButton, ItemInput, Tooltip } from 'Elements'
import ImageFileInput from 'Elements/ImageFileInput'
import * as styles from './styles.module.scss'

interface SaveAttributesProps {
  banner?: {
    small: string
    medium: string
    large: string
  }
  bannerFile?: Blob
  logo?: string
  logoFile?: Blob
  name: string
  public: boolean
}

interface EditGroupProps {
  attributes?: {
    banner?: {
      small: string
      medium: string
      large: string
    }
    logo?: string
    name?: string
    public?: boolean
  }
  onClose: () => void
  onUpdate: (fieldValues: SaveAttributesProps) => void
  updated?: boolean
  updating?: boolean
}

const EditGroup = ({
  attributes,
  onClose,
  onUpdate,
  updated = false,
  updating = false
}: EditGroupProps) => {
  const { I18n } = useLocalI18n('modals/EditGroup/Lang')

  const [_attributes, _setAttributes] = useState(attributes)

  const [_bannerFile, _setBannerFile] = useState(null)

  const [_logoFile, _setLogoFile] = useState(null)

  const [_removeBannerValue, _setRemoveBannerValue] = useState(false)

  const [_removeLogoValue, _setRemoveLogoValue] = useState(false)

  const _removeBanner = () => {
    _setAttributes({ ..._attributes, banner: null })
    _setRemoveBannerValue(true)
    _setBannerFile(null)
  }

  const _removeLogo = () => {
    _setAttributes({ ..._attributes, logo: null })
    _setRemoveLogoValue(true)
    _setLogoFile(null)
  }

  const _updateAttributesValue = (key, value) => {
    _setAttributes({ ..._attributes, [key]: value })
  }

  const _onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const _updateAttributes: SaveAttributesProps = {
      name: _attributes.name || '',
      public: _attributes.public || false
    }

    if (_removeBannerValue) {
      _updateAttributes.banner = null
    }

    if (_bannerFile) {
      _updateAttributes.bannerFile = _bannerFile
    }

    if (_removeLogoValue) {
      _updateAttributes.logo = null
    }

    if (_logoFile) {
      _updateAttributes.logoFile = _logoFile
    }

    onUpdate(_updateAttributes)
  }

  useEffect(() => {
    if (updated && !updating) {
      onClose()
    }
  }, [updated])

  useEffect(() => {
    _setAttributes({ ...attributes })

    if (attributes.banner) {
      _setRemoveBannerValue(false)
    }

    if (attributes.logo) {
      _setRemoveLogoValue(false)
    }
  }, [attributes])

  return (
    <div className={styles.Modal}>
      <div className={styles.Modal__close}>
        <CloseButton onClick={onClose} />
      </div>
      <div className={styles.Modal__content}>
        <header>
          <h1 className="h1 h--center modal__title ">
            {I18n.t('edit_group.title')}
          </h1>
        </header>
        <form onSubmit={_onSubmit}>
          <div
            className={classnames(
              styles.FormRow,
              styles['FormRow--column'],
              styles['FormRow--inline-center'],
              styles['FormRow--bs']
            )}>
            <label className={styles.SettingsInput__label} htmlFor="name">
              {I18n.t('edit_group.name.label')}
            </label>
            <ItemInput
              bottomSpaced
              value={_attributes.name}
              type="text"
              name="name"
              placeholder={I18n.t('edit_group.name.placeholder')}
              required
              onChange={_updateAttributesValue}
            />
          </div>
          <div
            className={classnames(
              styles.FormRow,
              styles['FormRow--column'],
              styles['FormRow--inline-center'],
              styles['FormRow--bs']
            )}>
            <ImageFileInput
              current={_attributes.logo}
              guidance={I18n.t('edit_group.logo.guidance')}
              label={I18n.t('edit_group.logo.label')}
              maxFileSize={100 * 1024}
              minHeight={600}
              minWidth={600}
              maxHeight={1200}
              maxWidth={1200}
              onFileChange={_setLogoFile}
              onRemoveImage={_removeLogo}
              previewCircle
              square
            />
          </div>
          <div
            className={classnames(
              styles.FormRow,
              styles['FormRow--column'],
              styles['FormRow--inline-center'],
              styles['FormRow--bs']
            )}>
            <ImageFileInput
              current={_attributes.banner?.medium}
              guidance={I18n.t('edit_group.banner.guidance')}
              label={I18n.t('edit_group.banner.label')}
              maxFileSize={300 * 1024}
              minHeight={720}
              minWidth={2880}
              maxHeight={720}
              maxWidth={2880}
              onFileChange={_setBannerFile}
              onRemoveImage={_removeBanner}
            />
          </div>
          <div
            className={classnames(
              styles.FormRow,
              styles['FormRow--column'],
              styles['FormRow--inline-center'],
              styles['FormRow--bs']
            )}>
            <label className={styles.SettingsInput__label} htmlFor="public">
              {I18n.t('edit_group.public.label')}
              <Tooltip
                inline={true}
                text={I18n.t('edit_group.public.tooltip')}
              />
            </label>
            <ItemInput
              bottomSpaced
              value={_attributes.public}
              type="toggle"
              name="public"
              onChange={_updateAttributesValue}
            />
          </div>
          <div
            className={classnames(
              styles.FormRow,
              styles['FormRow--inline-center']
            )}>
            <Button
              disabled={updating}
              type="submit"
              label={I18n.t('edit_group.submit')}
              color={ButtonColors.BRAND}
            />
            <Button
              disabled={updating}
              onClick={onClose}
              label={I18n.t('edit_group.cancel')}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditGroup
