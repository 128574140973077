import { action, observable } from 'mobx'
import { formatErrorMessages } from 'Utilities/errors'
import ModalStore from 'Stores/ModalStore'
import { API } from 'API'

export type page = 'entry' | 'errors'

export class CsvUploadState {
  @observable public page: page = 'entry'
  @observable public processing = false
  @observable public errors: string[]
  @observable public csvData: string

  constructor(private readonly modalStore: ModalStore) {}

  @action
  public setCsvData(data: string) {
    this.csvData = data
  }

  @action
  public async submitData(
    uploadUrl: string,
    defaultSuccessMessage: string
  ): Promise<boolean> {
    if (this.processing) return
    this.processing = true

    try {
      const { data } = await API.post(uploadUrl, { csv: this.csvData })
      const alert = data.meta?.alert ?? {
        message: defaultSuccessMessage
      }
      this.modalStore.createAlert(alert)
      return true
    } catch (err) {
      this.errors = formatErrorMessages(err)
      this.page = 'errors'
    } finally {
      this.processing = false
    }

    return false
  }

  @action
  public goToPage(page: page) {
    this.page = page
  }
}
