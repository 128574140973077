/* eslint no-console:0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import ReactRailsUJS from 'react_ujs'
import Turbolinks from 'turbolinks'

require.context('images', true)

const reactRequireContext = require.context('javascript/views', true)

ReactRailsUJS.useContext(reactRequireContext)

Turbolinks.start()
// Add Turbolinks to the global namespace:
window.Turbolinks = Turbolinks
// Remove previous event handlers and add new ones:
ReactRailsUJS.detectEvents()
