import React from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import { observer } from 'mobx-react'
import {
  LinksList,
  LoadMoreButton,
  Spinner,
  TagColors
} from 'Elements'
import { SerializedCampaign, SerializedPagination } from 'Constants'
import { LockIcon } from 'Constants/icons'
import { useListFocus } from 'Hooks/ListFocus'
import { formatRange } from 'Utilities/dates'
import * as styles from './styles.module.scss'

interface LinksProps {
  campaigns: SerializedCampaign[]
  computeLink?: (campaign: SerializedCampaign) => string
  fetchCampaigns: ({ number }) => Promise<void>
  loaded: boolean
  loading: boolean
  pagination: SerializedPagination
  tagsToDisplay?: string[]
}

export const LinksTags = ['draft', 'public', 'underway']
export const LinksTagsByGiki = ['draft', 'public']

const LinksTagsIcons = {
  underway: LockIcon
}

const Links = observer(
  ({
    campaigns,
    computeLink = (campaign) => campaign?.links?.html_admin_summary,
    fetchCampaigns,
    loaded,
    loading,
    pagination,
    tagsToDisplay = LinksTags
  }: LinksProps) => {
    const { I18n } = useLocalI18n('molecules/Campaigns/Links/Lang')

    const { _listRef, _listFocus } = useListFocus('a')

    const _campaignsPopulated = campaigns.length > 0

    const _fetchCampaigns = () => {
      fetchCampaigns({ number: pagination.next })?.then(_listFocus)
    }

    const _computeLinkLabel = (
      attributes: SerializedCampaign['attributes']
    ) => {
      if (attributes.public) return I18n.t('campaigns.links.actions.view')

      return I18n.t('campaigns.links.actions.edit')
    }

    const _computeSubTitle = (attributes: SerializedCampaign['attributes']) => {
      if (attributes.template) return undefined

      return formatRange(attributes.starts_at, attributes.ends_at)
    }

    const _computeTags = (attributes: SerializedCampaign['attributes']) => {
      return LinksTags.filter(
        (tag) => tagsToDisplay.includes(tag) && attributes[tag]
      ).map((tag) => {
        return {
          color: TagColors.BRAND_REVERSE,
          Icon: LinksTagsIcons[tag],
          text: I18n.t(`campaigns.links.tags.${tag}`),
          uppercase: true
        }
      })
    }

    return (
      <div className={styles.Links}>
        <section className={styles.Links__content}>
          {loading && <Spinner absolute />}
          {loaded && _campaignsPopulated && (
            <LinksList
              items={campaigns.map((campaign) => {
                return {
                  link: computeLink(campaign),
                  linkLabel: _computeLinkLabel(campaign.attributes),
                  subTitle: _computeSubTitle(campaign.attributes),
                  tags: _computeTags(campaign.attributes),
                  title: campaign.attributes.name
                }
              })}
              ref={_listRef}
            />
          )}
          {loaded && !_campaignsPopulated && (
            <div className={styles.Links__placeholder}>
              {I18n.t('campaigns.links.placeholder')}
            </div>
          )}
          {pagination.next && (
            <div className={styles.Links__pagination}>
              <LoadMoreButton loading={loading} onLoadMore={_fetchCampaigns} />
            </div>
          )}
        </section>
      </div>
    )
  }
)

export default Links
