import React from 'react'

const SelectNoneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
    <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
      <path
        d="M11.250 17.261 A6.000 6.000 0 1 0 23.250 17.261 A6.000 6.000 0 1 0 11.250 17.261 Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M13.008 21.502L21.492 13.019"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M5.25 10.511L10.5 10.511"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M5.25 14.261L7.5 14.261"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M5.25 18.011L7.5 18.011"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M9.75,23.261H2.25a1.5,1.5,0,0,1-1.5-1.5V6.011a1.5,1.5,0,0,1,1.5-1.5H6a3.75,3.75,0,0,1,7.5,0h3.75a1.5,1.5,0,0,1,1.5,1.5v2.25"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M9.75,3.761a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
    </g>
  </svg>
)

export default SelectNoneIcon
