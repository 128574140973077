import { action, observable } from 'mobx'
import isEqual from 'lodash/isEqual'
import { lockScroll, unlockScroll } from 'Utilities/scroll'
import { formatErrorMessages, UnformattedError } from 'Utilities/errors'
import { findById } from 'Utilities/arrays'
import { generateId } from 'Utilities/ids'
import { MODAL_TYPES, SerializedAlert, SerializedModal } from 'Constants'
import { ShowMessage, MessageType } from 'clarity'

class ModalStore {
  @observable public initialized = false
  @observable public modal: SerializedModal | null = null
  @observable readonly alerts = observable<SerializedAlert>([])

  constructor() {
    this.reset()
  }

  @action
  init = async (
    values: { alerts?: SerializedAlert[]; modal?: SerializedModal } = {
      alerts: []
    }
  ) => {
    try {
      const _update = Object.keys(values).some(
        (key) => !isEqual(this[key], values[key])
      )
      if (this.initialized && !_update) return

      if (this.initialized && _update) this.reset()

      this.modal = values.modal

      if (this.modal && this.modal.category !== MODAL_TYPES.FOOTPRINT) lockScroll()

      if (!values.alerts) return

      values.alerts.forEach((alert) => {
        this.createAlert(alert)
      })
    } catch (err) {
      this.handleError(err)
    } finally {
      this.initialized = true
    }
  }

  @action
  closeModal = () => {
    this.modal = null
    unlockScroll()
    window.removeEventListener('turbolinks:click', this.closeModal)
  }

  @action
  closeAlert = (id: string) => {
    const alert = findById(this.alerts, id)

    if (!alert) return

    this.alerts.remove(alert)
  }

  @action
  createAlert = ({ message = '', type = 'notice' }) => {
    const id = generateId()

    this.alerts.push({ id, message, type })

    ShowMessage({
      text: message,
      type: type as MessageType,
      onClose: () => this.closeAlert(id),
      isCloseable: true
    })

    if (process?.env?.RAILS_ENV && process.env.RAILS_ENV === 'test') {
      // ShowMessage doesn't work for rspec tests so output the message to the dom instead.
      const div = document.createElement('div')
      div.innerHTML = `TEST - ${message}`
      document.body.appendChild(div)
    }
  }

  @action
  handleError = (err: UnformattedError) => {
    formatErrorMessages(err).forEach((message) => {
      this.createAlert({
        message: message || 'Sorry, something went wrong',
        type: 'alert'
      })
    })
  }

  @action
  openModal = (modal: SerializedModal) => {
    this.modal = modal
    lockScroll()
    window.addEventListener('turbolinks:click', this.closeModal)
  }

  @action
  reset = () => {
    this.initialized = false
    this.modal = null
    this.alerts.clear()
  }

  @action
  updateModalContent = (content: SerializedModal['content']) => {
    this.modal.content = { ...this.modal.content, ...content }
  }
}

export default ModalStore
