import React from 'react'
import classNames from 'classnames'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback, Tooltip } from 'Elements'
import ReactHtmlParser from 'react-html-parser'

interface SectionProps {
  actions?: React.ReactNode
  centered?: boolean
  children?: React.ReactNode
  className?: string
  constrained?: boolean
  noMargin?: boolean
  suffix?: React.ReactNode
  title?: string
  tooltip?: string
}

const Section = ({
  actions,
  centered = false,
  children,
  className = '',
  constrained = false,
  noMargin = false,
  suffix,
  title = null,
  tooltip = null
}: SectionProps) => {
  const _displayTitle = title !== null && title !== undefined
  const _displayTooltip = tooltip !== null && tooltip !== undefined

  return (
    <section
      className={classNames('section', {
        'section--centered': centered,
        'section--constrained': constrained,
        'section--no-margin': noMargin,
        [className]: !!className
      })}>
      {_displayTitle && (
        <header className="section__header">
          <div className="section__title">
            <h2 className="section__heading">{ReactHtmlParser(title)}</h2>
            {_displayTooltip && <Tooltip inline text={tooltip} label={title} />}
          </div>
          {actions && <span className="section__actions">{actions}</span>}
          {suffix && <span className="section__suffix">{suffix}</span>}
        </header>
      )}
      <div className="section__content">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>
      </div>
    </section>
  )
}

export default Section
