import React from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import { observer } from 'mobx-react'
import { Button, ButtonColors, ButtonTypes, Tag, TagColors } from 'Elements'
import { LockIcon } from 'Constants/icons'
import { formatDate } from 'Utilities/dates'
import * as styles from './styles.module.scss'
import classNames from 'classnames'

interface SummaryProps {
  draft?: boolean
  editable?: boolean
  editHref?: string
  endsAt?: string
  name: string
  onCancel?: () => void
  onClone?: () => void
  onDelete?: () => void
  onPublish?: () => void
  onShare?: () => void
  shareUrl?: string
  startsAt?: string
  template?: boolean
  underway?: boolean
  displayTags?: boolean
}

const Summary = observer(
  ({
    draft = false,
    editable = false,
    editHref,
    endsAt,
    name,
    onCancel,
    onClone,
    onDelete,
    onShare,
    onPublish,
    shareUrl,
    startsAt,
    template = false,
    underway = false,
    displayTags = true
  }: SummaryProps) => {
    const { I18n } = useLocalI18n('molecules/Campaigns/Summary/Lang')

    const _statusTag = draft
      ? I18n.t('campaigns.summary.status.draft')
      : I18n.t('campaigns.summary.status.publicly_available')

    return (
      <div className={styles.Summary} data-testid="CampaignSummary">
        <header className={styles.Summary__header}>
          <h2>{name}</h2>
        </header>
        {displayTags && (
          <div className={styles.Summary__tags}>
            <Tag color={TagColors.BRAND_REVERSE} text={_statusTag} />
            {template && (
              <Tag
                color={TagColors.BRAND_REVERSE}
                text={I18n.t('campaigns.summary.template')}
              />
            )}
            {underway && (
              <Tag
                color={TagColors.BRAND_REVERSE}
                Icon={LockIcon}
                text={I18n.t('campaigns.summary.underway')}
              />
            )}
          </div>
        )}

        {!template && (
          <div className={styles.Summary__dateSpan}>
            <p className={styles.Summary__range}>
              {I18n.t('campaigns.summary.from')}
              <span className={styles.Summary__date}>
                {formatDate(startsAt)}
              </span>
              &ndash;
              <span className={styles.Summary__date}>{formatDate(endsAt)}</span>
            </p>
          </div>
        )}
        {editable && (
          <div
            className={classNames(
              styles.Summary__actions,
              styles['Summary__actions--edit']
            )}>
            <Button
              href={editHref}
              label={I18n.t('campaigns.summary.edit')}
              variant={ButtonTypes.BLANK}
            />
            <Button
              onClick={onDelete}
              label={I18n.t('campaigns.summary.delete')}
              variant={ButtonTypes.BLANK}
            />
          </div>
        )}
        {template && !editable && (
          <div className={styles.Summary__clone}>
            <p className={styles.Summary__cloneDescription}>
              {I18n.t('campaigns.summary.clone.description')}
            </p>
            <div
              className={classNames(
                styles.Summary__actions,
                styles['Summary__actions--clone']
              )}>
              <Button
                color={ButtonColors.BRAND}
                label={I18n.t('campaigns.summary.clone.submit')}
                onClick={onClone}
              />
              <Button
                color={ButtonColors.DEFAULT}
                label={I18n.t('campaigns.summary.clone.cancel')}
                onClick={onCancel}
              />
            </div>
          </div>
        )}
        {draft && !template && (
          <p className={styles.Summary__publicationWarning}>
            {I18n.t('campaigns.summary.publication_warning')}
          </p>
        )}
        {draft && editable && (
          <div
            className={classNames(
              styles.Summary__actions,
              styles['Summary__actions--publication']
            )}>
            <Button
              color={ButtonColors.DEFAULT}
              label={I18n.t('campaigns.summary.draft')}
              onClick={onCancel}
            />
            <Button
              color={ButtonColors.BRAND}
              label={I18n.t('campaigns.summary.publish')}
              onClick={onPublish}
            />
          </div>
        )}
        {underway && shareUrl != null && (
          <div className={styles.Summary__share}>
            <label className={styles.Summary__shareLabel}>
              {I18n.t('campaigns.summary.share.label')}
            </label>
            <input
              className={styles.Summary__shareField}
              readOnly
              type="text"
              value={shareUrl}
            />
            <Button
              color={ButtonColors.BRAND}
              label={I18n.t('campaigns.summary.share.button')}
              onClick={onShare}
            />
          </div>
        )}
      </div>
    )
  }
)

export default Summary
