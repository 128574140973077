const _extensionFromFilename = (filename) => {
  const _filenameParts = filename.split('.')
  if (_filenameParts.length === 1) return null

  return _filenameParts[_filenameParts.length - 1]
}

const _extensionFromFile = (originalFile) => {
  const _originalExtension = _extensionFromFilename(originalFile.name)
  if (_originalExtension) return _originalExtension

  const _mimeTypeParts = originalFile.type.split('/')
  return _mimeTypeParts[_mimeTypeParts.length - 1]
}

export const renameFile = (originalFile, newName) => {
  const _newExtension = _extensionFromFile(originalFile)
  const _newQualifiedName = [newName, _newExtension].join('.')
  return new File([originalFile], _newQualifiedName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified
  })
}
