import { action, observable } from 'mobx'
import isEqual from 'lodash/isEqual'
import { standardizeError } from 'Utilities/errors'

export interface NavigationItem {
  label: string
  path: string
  button?: string
  alias?: string
}

interface UIStoreProps {
  primaryItems?: NavigationItem[]
  secondaryPriorityItems?: NavigationItem[]
  secondaryItems?: NavigationItem[]
  sectionItems?: NavigationItem[]
  switchToSectionItems?: boolean
  tertiaryItems?: NavigationItem[]
  accountItems?: { [key: string]: NavigationItem }
}

type Init = (values: UIStoreProps) => Promise<void>

class UIStore {
  @observable public initialized: boolean
  @observable public switchToSectionItems: boolean
  @observable public displayNotificationsMenu: boolean
  @observable primaryItems: NavigationItem[] = []
  @observable secondaryPriorityItems: NavigationItem[] = []
  @observable secondaryItems: NavigationItem[] = []
  @observable sectionItems: NavigationItem[] = []
  @observable tertiaryItems: NavigationItem[] = []
  @observable accountItems: { [key: string]: NavigationItem } = {}

  constructor() {
    this.setDefaults()
  }

  private setDefaults() {
    this.displayNotificationsMenu = false
    this.initialized = false
    this.switchToSectionItems = false
    this.primaryItems.length = 0
    this.secondaryPriorityItems.length = 0
    this.secondaryItems.length = 0
    this.sectionItems.length = 0
    this.tertiaryItems.length = 0
    this.accountItems = {}
  }

  @action
  init: Init = async (values = {}) => {
    if (values.secondaryPriorityItems == null) {
      values.secondaryPriorityItems = []
    }

    try {
      const _update = Object.keys(values).some(
        (key) => !isEqual(this[key], values[key])
      )
      if (this.initialized && !_update) return await Promise.resolve()

      if (this.initialized && _update) await this.reset()

      Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  displayNotifications = () => {
    this.displayNotificationsMenu = true
    window.addEventListener('scroll', this.hideNotifications)
    window.addEventListener('turbolinks:click', this.hideNotifications)
  }

  @action
  displaySectionItems = () => {
    this.switchToSectionItems = true
    window.addEventListener('turbolinks:click', this.hideSectionItems)
  }

  @action
  hideNotifications = () => {
    this.displayNotificationsMenu = false
    window.removeEventListener('scroll', this.hideNotifications)
    window.removeEventListener('turbolinks:click', this.hideNotifications)
  }

  @action
  hideSectionItems = () => {
    this.switchToSectionItems = false
    window.removeEventListener('turbolinks:click', this.hideSectionItems)
  }

  @action
  receiveSectionItems = (sectionItems: NavigationItem[]) => {
    this.sectionItems = sectionItems
  }

  @action
  receiveSecondaryPriorityItems = (
    secondaryPriorityItems: NavigationItem[]
  ) => {
    this.secondaryPriorityItems = secondaryPriorityItems
  }

  @action
  addSecondaryPriorityItem = (secondaryPriorityItem: NavigationItem) => {
    this.secondaryPriorityItems.push(secondaryPriorityItem)
  }

  @action
  reset = async () => {
    this.setDefaults()
  }

  @action
  toggleNotifications = () => {
    if (this.displayNotificationsMenu) {
      return this.hideNotifications()
    }
    return this.displayNotifications()
  }
}

export default UIStore
