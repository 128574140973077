import React from 'react'
import { RadioPill, Tooltip } from 'Elements'
import useLocalI18n from 'Hooks/LocalI18n'
import { FootprintRefinement, Refinement } from 'Constants/models'

interface FootprintRefinementFieldProps {
  footprintType: string
  id: string
  index: number
  interrogation: string
  refinementId: string
  selected: boolean
  tooltipHint: string
}

const FootprintRefinementField = ({
  footprintType,
  id,
  index,
  interrogation,
  refinementId,
  selected,
  tooltipHint
}: FootprintRefinementFieldProps) => {
  const { I18n } = useLocalI18n('elements/FootprintRefinements/Lang')

  const name = 'footprint_refinements_attributes'
  return (
    <fieldset className="footprint-wizard__row">
      <legend className="h3 h--bs" aria-label={interrogation}>
        {interrogation}
        {tooltipHint && (
          <Tooltip inline={true} text={tooltipHint} label={interrogation} />
        )}
      </legend>
      <input
        id={`${footprintType}_footprint_${name}_${index}_refinement_id`}
        name={`${footprintType}_footprint[${name}][${index}][refinement_id]`}
        type="hidden"
        value={refinementId}
      />
      <input
        id={`${footprintType}_footprint_${name}_${index}_id`}
        name={`${footprintType}_footprint[${name}][${index}][id]`}
        type="hidden"
        value={id || ''}
      />

      <RadioPill
        label={I18n.t('footprint_refinements.yes')}
        name={name}
        footprintType={footprintType}
        selected={selected}
        value="false"
        index={index}
      />
      <RadioPill
        label={I18n.t('footprint_refinements.no')}
        name={name}
        footprintType={footprintType}
        selected={!selected}
        value="true"
        index={index}
      />
    </fieldset>
  )
}

interface FootprintRefinementsProps {
  footprintRefinements: FootprintRefinement[]
  footprintType: string
  refinements: Refinement[]
  visible: boolean
}

const FootprintRefinements = ({
  footprintRefinements,
  footprintType,
  refinements,
  visible = false
}: FootprintRefinementsProps) => (
  <div
    className="refinements"
    style={{
      display: visible ? 'block' : 'none'
    }}>
    {refinements.map((refinement, index) => {
      const footprintRefinement = footprintRefinements.find(
        (footprintRefinement) => {
          return footprintRefinement.refinement_id === refinement.id
        }
      )
      const selected = !!footprintRefinement
      return (
        <FootprintRefinementField
          key={index}
          index={index}
          footprintType={footprintType}
          id={footprintRefinement?.id}
          interrogation={refinement.interrogation}
          refinementId={refinement.id}
          selected={selected}
          tooltipHint={refinement.tooltip_hint}
        />
      )
    })}
  </div>
)

export default FootprintRefinements
