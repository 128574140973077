import React from 'react'
import classNames from 'classnames'
import { SerializedPersonSection } from 'Constants'
import { ProgressChart, BreakdownBarChart } from 'Elements'
import * as styles from './styles.module.scss'
import { MassValue } from 'clarity'

interface ImpactSummaryProps {
  bySection?: {
    loaded: boolean
    loading: boolean
    sections: SerializedPersonSection[]
  }
  current: number
  displayBreakdown?: boolean
  labels: Array<{
    label: string
    tooltip?: string
    value: number
    avatar?: string
  }>
  max: number
  onLoadSections?: () => void
  slug: string
  title: string
  unit: string
  hideHeader?: boolean
}

type MassValueProps = React.ComponentProps<typeof MassValue>

const ImpactSummary = ({
  bySection,
  current,
  displayBreakdown = false,
  labels,
  max,
  onLoadSections,
  slug,
  title,
  unit,
  hideHeader = false
}: ImpactSummaryProps) => {
  const _valueStyle = {
    color: 'var(--color-cherry)',
    fontSize: 'var(--font-size-2xl)',
    lineHeight: 'var(--font-size-2xl)'
  } as unknown as MassValueProps['valueStyle']

  const _unitStyle = {
    color: 'var(--color-cherry)',
    fontSize: 'var(--font-size-l)',
    lineHeight: 'var(--font-size-2xl)'
  } as unknown as MassValueProps['unitStyle']

  return (
    <div className={styles.ImpactSummary}>
      {!hideHeader && (
        <div
          className={classNames(
            styles.ImpactSummary__header,
            styles['ImpactSummary__header--center']
          )}>
          <h2 className={'h2 h--tertiary'}>
            {current && (
              <MassValue
                value={Math.trunc(current)}
                unit={unit}
                valueStyle={_valueStyle}
                unitStyle={_unitStyle}
              />
            )}
          </h2>
          {<h3 className="h3 h--tertiary">{title}</h3>}
        </div>
      )}
      <div className={styles.ImpactSummary__content}>
        <ProgressChart
          current={current}
          displayLabels
          labels={labels}
          max={max}
          slug={slug}
          title={title}
        />
        {displayBreakdown && (
          <BreakdownBarChart
            loaded={bySection.loaded}
            loading={bySection.loading}
            onLoadItems={onLoadSections}
            items={bySection.sections}
            unit={unit || ''}
          />
        )}
      </div>
    </div>
  )
}

export default ImpactSummary
