import { action, computed, observable } from 'mobx'
import { APIRoutes, uploadFile } from 'API'
import OrganizationsStore, { ITEM_ROLES } from './OrganizationsStore'
import { SingleItemStore, TranformContextType } from 'Stores/Base'
import { SerializedSuperGroup } from 'Constants'

interface SuperGroupParams {
  banner?: string
  bannerFile?: string
  logo?: string
  logoFile?: string
  name?: string
}

export class SuperGroupStore extends SingleItemStore {
  @observable public attributes: SerializedSuperGroup['attributes']
  @observable public editable: boolean
  @observable public excluded: OrganizationsStore
  @observable public included: OrganizationsStore
  protected _superReset = this.reset

  constructor() {
    super()
    this._itemType = 'super_group'
    this.excluded = new OrganizationsStore(this, ITEM_ROLES.EXCLUDED)
    this.included = new OrganizationsStore(this, ITEM_ROLES.INCLUDED)
  }

  @action
  _postInit = async () => {
    if (!this.editable) return await Promise.resolve()

    await this.included.init()
    await this.excluded.init()
  }

  @computed
  get _itemRoute() {
    return APIRoutes.superGroups.item(this.id)
  }

  _transformParams = async (
    params: SuperGroupParams,
    context?: TranformContextType
  ) => {
    const _transformedParams = Object.assign({}, params)

    if (_transformedParams.bannerFile) {
      const bannerUrl = await uploadFile(
        _transformedParams.bannerFile,
        'banner'
      )
      _transformedParams.banner = bannerUrl
      delete _transformedParams.bannerFile
    }

    if (_transformedParams.logoFile) {
      const logoUrl = await uploadFile(_transformedParams.logoFile, 'logo')
      _transformedParams.logo = logoUrl
      delete _transformedParams.logoFile
    }

    return _transformedParams
  }

  @action
  reset = () => {
    this._superReset()
    this.excluded.reset()
    this.included.reset()
  }
}

export default SuperGroupStore
