import React from 'react'
import { ItemInput } from 'Elements'
import * as styles from '../styles.module.scss'

interface SettingsInputProps extends React.ComponentProps<typeof ItemInput> {
  label?: string
}

const SettingsInput = ({ label, name, ...props }: SettingsInputProps) => {
  return (
    <div className={styles.SettingsInput}>
      {label && (
        <label className={styles.SettingsInput__label} htmlFor={name}>
          {label}
        </label>
      )}
      <ItemInput bottomSpaced name={name} {...props} />
    </div>
  )
}

export default SettingsInput
