import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import * as styles from './styles.module.scss'

interface ToggleButtonProps {
  disabled?: boolean
  toggle: (toggled: boolean) => void
  untoggledLabel: string
  toggledLabel: string
  toggled: boolean
  bottomSpaced?: boolean
  id?: string
  labelPrefix?: string
}

const ToggleButton = ({
  disabled = false,
  labelPrefix,
  toggle,
  toggled,
  toggledLabel,
  untoggledLabel,
  bottomSpaced = false,
  id
}: ToggleButtonProps) => {
  const { I18n } = useLocalI18n('elements/ToggleButton/Lang')

  const [_toggled, _setToggled] = useState(toggled)

  const _toggle = () => toggle(!_toggled)

  let _labelPrefix = I18n.t('toggle_button.label_prefix')

  if (labelPrefix) {
    _labelPrefix = `${labelPrefix}: ${_labelPrefix}`
  }

  const _labelSeparator = I18n.t('toggle_button.label_separator')

  useEffect(() => {
    _setToggled(toggled)
  }, [toggled])

  return (
    <div
      className={classNames(styles.ToggleButton, {
        [styles['ToggleButton--bs']]: bottomSpaced
      })}>
      <span
        className={classNames(styles.ToggleButton__label, {
          [styles['ToggleButton__label--active']]: !toggled
        })}>
        {untoggledLabel}
      </span>
      <label className={styles.ToggleButton__button}>
        <input
          disabled={disabled}
          id={id}
          name={id}
          onChange={_toggle}
          type="checkbox"
          checked={_toggled}
        />
        <span
          className={classNames(
            styles.Slider,
            styles['Slider--rounded']
          )}></span>
        {[_labelPrefix, untoggledLabel, _labelSeparator, toggledLabel].join(
          ' '
        )}
      </label>
      <span
        className={classNames(styles.ToggleButton__label, {
          [styles['ToggleButton__label--active']]: toggled
        })}>
        {toggledLabel}
      </span>
    </div>
  )
}

export default ToggleButton
