import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { LoadMoreButton, Spinner, LinksList } from 'Elements'
import { PublicTeamDataTableFilters } from 'Molecules'
import { useListFocus } from 'Hooks/ListFocus'
import * as styles from './styles.module.scss'

const PublicTeamsDataTable = observer(
  ({
    onJoin,
    onFilterUpdate,
    fetchTeams,
    loaded,
    loading,
    pagination,
    teams
  }) => {
    const { I18n } = useLocalI18n('molecules/PublicTeamsDataTable/Lang')

    const { _listRef, _listFocus } = useListFocus()

    const _fetchTeams = () => {
      _listFocus()
      fetchTeams({ number: pagination.next })
    }

    return (
      <div className={styles.Teams}>
        <PublicTeamDataTableFilters onFilterUpdate={onFilterUpdate} />
        <section className={styles.Teams__content}>
          {loading && (
            <div className={styles.Teams__loader}>
              <Spinner absolute />
            </div>
          )}
          {loaded && teams.length > 0 && (
            <LinksList
              items={teams.map((team) => {
                return {
                  onClick: () => onJoin(team),
                  linkLabel: I18n.t('public_teams_data_table.actions.join'),
                  title: team.attributes.name,
                  avatar: team.attributes.logo,
                  avatarFirstName: team.attributes.name
                }
              })}
              ref={_listRef}
            />
          )}
          {loaded && !teams.length && (
            <div className={styles.Teams__placeholder}>
              {I18n.t('public_teams_data_table.placeholder')}
            </div>
          )}
          {pagination.next && (
            <div className={styles.Teams__pagination}>
              <LoadMoreButton loading={loading} onLoadMore={_fetchTeams} />
            </div>
          )}
        </section>
      </div>
    )
  }
)

PublicTeamsDataTable.propTypes = {
  fetchTeams: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onJoin: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired
}

export default PublicTeamsDataTable
