import React, { createContext, useContext } from 'react'

export const ExperimentalFeatureContext = createContext({ showExperimental: false })

export interface Props {
  children: React.ReactNode | React.ReactNode[]
}

export const ExperimentalFeature = ({ children }: Props) => {
  const { showExperimental } = useContext(ExperimentalFeatureContext)

  return showExperimental ? <>{children}</> : null
}