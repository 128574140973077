import { NavigationContext } from 'clarity'
import React from 'react'
import { safelyNavigate } from '../utils/navigation'

export const NavigationContextProvider = ({ children }) => {
  const provider = {
    navigate: (target: string) => safelyNavigate(target),
    transform: (uri: string): string => uri
  }

  return (
    <NavigationContext.Provider value={provider}>
      {children}
    </NavigationContext.Provider>
  )
}
