export const filterByAttribute = (array, attribute, value) => {
  return (array || []).filter((item) => item[attribute] === value)
}

export const filterById = (array, id) => {
  return filterByAttribute(array, 'id', id)
}

export const filterByType = (array, type) => {
  return filterByAttribute(array, 'type', type)
}

export const findByAttribute = (array, attribute, value) => {
  return (array || []).find((item) => item[attribute] === value)
}

export const findById = (array, id) => {
  return findByAttribute(array, 'id', id)
}

export const findByType = (array, type) => {
  return findByAttribute(array, 'type', type)
}

export const findIndexByAttribute = (array, attribute, value) => {
  return (array || []).findIndex((item) => item[attribute] === value)
}

export const findIndexById = (array, type) => {
  return findIndexByAttribute(array, 'id', type)
}

export const findIndexByType = (array, type) => {
  return findIndexByAttribute(array, 'type', type)
}

export const someByAttribute = (array, attribute, value) => {
  return array.some((item) => item[attribute] === value)
}

export const someById = (array, id) => {
  return someByAttribute(array, 'id', id)
}

export const addOrReplace = (from = [], into = []) => {
  from.forEach((obj) => {
    const index = findIndexById(into, obj.id)
    if (index !== -1) {
      into[index] = obj
    } else {
      into.push(obj)
    }
  })
}

export const arrayOfStrings = (items) => {
  return Array.isArray(items) ? items : [items]
}

export const allTrue = (items) => {
  return items.every((item) => item)
}

export const allFalse = (items) => {
  return items.every((item) => !item)
}

export const anyFalse = (items) => {
  return items.some((item) => !item)
}
