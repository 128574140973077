import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { SerializedNotification } from 'Constants'
import { camelizeKeys } from 'Utilities/objects'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Notification from './Notification'
import styles from './styles.module.scss'
import { Spinner } from 'Elements'
import useLocalI18n from 'Hooks/LocalI18n'

interface NotificationsProps {
  loaded?: boolean
  loading?: boolean
  notifications: SerializedNotification[]
  onMarkItemsRead: (ids: string | string[]) => Promise<void>
  onMarkItemsUnread: (ids: string | string[]) => Promise<void>
}

const ANIMATION_DELAY = 300

const Notifications = observer(
  ({
    loaded = false,
    loading = false,
    notifications,
    onMarkItemsRead,
    onMarkItemsUnread
  }: NotificationsProps) => {
    const { I18n } = useLocalI18n('molecules/Notifications/Lang')

    const [_initialized, _setInitialized] = useState(false)

    const [_displayPlaceholder, _setDisplayPlaceholder] = useState(false)

    /**
     * Manage the appearance/disapperance of the placeholder
     * around the animation of the notifications items.
     */
    useEffect(() => {
      const _update = loaded && notifications.length === 0

      if (_update) {
        if (_initialized) {
          window.setTimeout(() => {
            _setDisplayPlaceholder(true)
          }, ANIMATION_DELAY)
        } else {
          _setDisplayPlaceholder(true)
        }
      } else {
        _setDisplayPlaceholder(false)
      }
      _setInitialized(true)
    }, [loaded, notifications.length])

    return (
      <>
        <TransitionGroup className={styles.Notifications} component={'ul'}>
          {notifications.map((notification) => {
            return (
              <CSSTransition
                classNames="fade-in"
                key={notification.id}
                timeout={ANIMATION_DELAY}>
                <li className={styles.Notifications__item}>
                  <Notification
                    id={notification.id}
                    onMarkRead={async () =>
                      await onMarkItemsRead(notification.id)
                    }
                    onMarkUnread={async () =>
                      await onMarkItemsUnread(notification.id)
                    }
                    {...camelizeKeys(notification.attributes)}
                  />
                </li>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
        {loading && <Spinner absolute />}
        {_displayPlaceholder && (
          <p className={styles.Notifications__placeholder}>
            {I18n.t('notifications.notifications.placeholder')}
          </p>
        )}
      </>
    )
  }
)

export default Notifications
