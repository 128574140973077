import React, { useEffect, useState } from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import { observer } from 'mobx-react'
import DatePicker from 'react-datepicker'
import { Button, ButtonColors, Spinner } from 'Elements'
import DetailTab from './Tab'
import { SerializedCampaign } from 'Constants/models'
import 'react-datepicker/dist/react-datepicker.css'
import * as styles from './styles.module.scss'

const DATE_FORMAT = 'd MMMM yyyy h:mm aa'

const TRANSLATEABLE_ATTRIBUTES = ['title', 'description']

const mapBasicAttributes = (attributes: SerializedCampaign['attributes']) => {
  return {
    name: attributes.name ?? '',
    template: attributes.template ?? false
  }
}

const mapDateAttributes = (attributes: SerializedCampaign['attributes']) => {
  if (attributes.template) {
    return {
      ends_at: null,
      starts_at: null
    }
  }

  const _defaultStartDate = new Date()
  _defaultStartDate.setDate(_defaultStartDate.getDate() + 7)
  _defaultStartDate.setHours(0)
  _defaultStartDate.setMinutes(30)
  _defaultStartDate.setSeconds(0)
  _defaultStartDate.setMilliseconds(0)

  const _defaultEndDate = new Date(_defaultStartDate)
  _defaultEndDate.setDate(_defaultStartDate.getDate() + 4)
  _defaultEndDate.setHours(23)
  _defaultEndDate.setMinutes(30)
  _defaultEndDate.setSeconds(0)
  _defaultEndDate.setMilliseconds(0)

  return {
    starts_at: attributes.starts_at
      ? new Date(attributes.starts_at)
      : _defaultStartDate,
    ends_at: attributes.ends_at
      ? new Date(attributes.ends_at)
      : _defaultEndDate
  }
}

const mapTranslatedAttributes = (
  attributes: SerializedCampaign['attributes'],
  availableLocales: string[]
) => {
  const attributeArray = availableLocales.map((locale) => {
    const entries = TRANSLATEABLE_ATTRIBUTES.map((attribute) => {
      const key = [attribute, locale].join('_')
      const value = attributes[key] || ''
      return [key, value]
    })

    return Object.fromEntries(entries)
  })

  return Object.assign({}, ...attributeArray)
}

export const mapAttributes = (
  attributes: SerializedCampaign['attributes'],
  availableLocales: string[]
) => {
  const basicAttributes = mapBasicAttributes(attributes)

  const dateAttributes = mapDateAttributes(attributes)

  const translatedAttributes = mapTranslatedAttributes(
    attributes,
    availableLocales
  )

  return Object.assign(
    {},
    basicAttributes,
    dateAttributes,
    translatedAttributes
  )
}

interface SettingsProps {
  attributes: SerializedCampaign['attributes']
  availableLocales?: string[]
  disabled?: boolean
  loading?: boolean
  loaded?: boolean
  onCancel: () => void
  onSubmit: (values) => void
}

const Settings = observer(
  ({
    attributes,
    availableLocales = ['en'],
    disabled = false,
    loading = false,
    loaded = false,
    onCancel,
    onSubmit
  }: SettingsProps) => {
    const { I18n } = useLocalI18n('molecules/Campaigns/Settings/Lang')

    const [_inputState, _setInputState] = useState(
      mapAttributes(attributes, availableLocales)
    )

    const _disabled = disabled || loading

    const _displaySpinner = loading || !loaded

    const _handleInputChange = (ev: React.FormEvent<HTMLInputElement>) => {
      const { name, value } = ev.currentTarget
      _handleSpecificInputChange(name, value)
    }

    const _handleSpecificInputChange = (name, value) => {
      _setInputState((prevState) => ({
        ...prevState,
        [name]: value
      }))
    }

    const _onSubmit = (ev) => {
      ev.preventDefault()
      onSubmit(_inputState)
    }

    const _started = attributes.started

    useEffect(() => {
      _setInputState(
        mapAttributes(attributes, availableLocales)
      )
    }, [attributes])

    return (
      <div className={styles.Settings} data-testid="CampaignSettings">
        {_displaySpinner && <Spinner absolute />}
        {loaded && (
          <form className={styles.Settings__form} onSubmit={_onSubmit}>
            <div className={styles.Settings__panel}>
              <div className="form-row form-row--inline-center form-row--column">
                <label htmlFor="name">
                  {I18n.t('campaigns.settings.fields.name.label')}
                </label>
                <input
                  className="text-field text-field--constrained text-field--bs"
                  disabled={_disabled}
                  id="name"
                  name="name"
                  onChange={_handleInputChange}
                  placeholder={I18n.t(
                    'campaigns.settings.fields.name.placeholder'
                  )}
                  required
                  type="text"
                  value={_inputState.name}
                />
              </div>
              {!attributes.template && (
                <>
                  <div className="form-row form-row--inline-center form-row--column">
                    <label>
                      {I18n.t('campaigns.settings.fields.starts_at.label')}
                    </label>
                    <DatePicker
                      className={styles.DateField}
                      dateFormat={DATE_FORMAT}
                      disabled={_disabled || _started}
                      endDate={_inputState.ends_at}
                      onChange={(date) =>
                        _handleSpecificInputChange('starts_at', date)
                      }
                      selected={_inputState.starts_at}
                      selectsStart
                      showTimeSelect
                      startDate={_inputState.starts_at}
                      popperPlacement="bottom"
                    />
                  </div>
                  <div className="form-row form-row--inline-center form-row--column">
                    <label>
                      {I18n.t('campaigns.settings.fields.ends_at.label')}
                    </label>
                    <DatePicker
                      className={styles.DateField}
                      dateFormat={DATE_FORMAT}
                      disabled={_disabled || _started}
                      endDate={_inputState.ends_at}
                      onChange={(date) =>
                        _handleSpecificInputChange('ends_at', date)
                      }
                      selected={_inputState.ends_at}
                      selectsEnd
                      showTimeSelect
                      startDate={_inputState.starts_at}
                      popperPlacement="bottom"
                    />
                  </div>
                </>
              )}
              <DetailTab
                inputState={_inputState}
                locale="en"
                onChange={_handleInputChange}
                visible={true}
              />
            </div>
            <input
              id="template"
              name="template"
              type="hidden"
              value={_inputState.template}
            />
            <div className={styles.Settings__actions}>
              <Button
                label={I18n.t('campaigns.settings.cancel')}
                color={ButtonColors.DEFAULT}
                onClick={onCancel}
                type="button"
              />
              <Button
                label={I18n.t('campaigns.settings.submit')}
                color={ButtonColors.BRAND}
                type="submit"
              />
            </div>
          </form>
        )}
      </div>
    )
  }
)

export default Settings
