import React from 'react'

const UserResetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M12.971 20.983a8.233 8.233 0 100-16.466 8.234 8.234 0 00-8.234 8.233v.633M2.204 10.85l2.533 2.533 2.534-2.533m9.905 6.333a5.604 5.604 0 00-8.411 0m1.04-5.7a3.168 3.168 0 004.749 2.743 3.166 3.166 0 10-4.75-2.743h0z"
      stroke="currentColor"
      strokeWidth="1.667"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UserResetIcon
