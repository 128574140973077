import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import { IObservableArray } from 'mobx'
import { observer } from 'mobx-react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import {
  Button,
  ButtonColors,
  ButtonIcons,
  LoadMoreButton,
  Spinner
} from 'Elements'
import { useListFocus } from 'Hooks/ListFocus'
import * as styles from './styles.module.scss'
import { SerializedAction, SerializedPagination } from 'Constants'
import { StepCard, CardActions } from 'clarity'
import Markdown from 'markdown-to-jsx'

interface ActionsProps {
  actions: IObservableArray<SerializedAction>
  disabled?: boolean
  onExclude?: (actionId: string) => void
  fetchActions: ({ number }) => Promise<void>
  onInclude?: (actionId: string) => void
  included?: boolean
  loaded: boolean
  loading: boolean
  pagination: SerializedPagination
}

const Actions = observer(
  ({
    actions,
    disabled = false,
    onExclude,
    fetchActions,
    onInclude,
    included = false,
    loaded,
    loading,
    pagination
  }: ActionsProps) => {
    const { I18n } = useLocalI18n('molecules/Campaigns/Actions/Lang')

    const { _listRef, _listFocus } = useListFocus()

    const _displayExcludeButton =
      !disabled && included && onExclude !== undefined

    const _displayIncludeButton =
      !disabled && !included && onInclude !== undefined

    const _fetchActions = () => {
      fetchActions({ number: pagination.next })?.then(_listFocus)
    }

    const _populated = actions.length > 0

    return (
      <div className={styles.Actions} data-testid="CampaignActions">
        {loading && <Spinner absolute />}
        {loaded && _populated && (
          <div className={styles.Actions__items} ref={_listRef}>
            <TransitionGroup component={null}>
              {actions.map((action, index) => {
                return (
                  <CSSTransition
                    appear
                    classNames="fade-in"
                    in
                    key={`actions-item-${index}-${action.id}`}
                    timeout={300}>
                    <StepCard
                      title={action.attributes.summary}
                      impact={action.attributes.impact}
                      themes={action.attributes.themes}
                      isHighlighted={action.attributes.is_highlighted}
                    >
                      <CardActions>
                        {_displayExcludeButton && (
                          <Button
                            label={I18n.t('campaigns.actions.exclude')}
                            onClick={() => onExclude(action.id)}
                            icon={ButtonIcons.DeleteIcon}
                            small
                          />
                        )}
                        {_displayIncludeButton && (
                          <Button
                            color={ButtonColors.BRAND}
                            label={I18n.t('campaigns.actions.include')}
                            onClick={() => onInclude(action.id)}
                            icon={ButtonIcons.LikeIcon}
                            small
                          />
                        )}
                      </CardActions>
                    </StepCard>
                  </CSSTransition>
                )
              })}
            </TransitionGroup>
          </div>
        )}
        {pagination.next && (
          <div className={styles.Actions__loader}>
            <LoadMoreButton loading={loading} onLoadMore={_fetchActions} />
          </div>
        )}
        {loaded && !_populated && (
          <div className={styles.Actions__placeholder}>
            <Markdown>
              {included
                ? I18n.t('campaigns.actions.placeholder.included')
                : I18n.t('campaigns.actions.placeholder.default')}
            </Markdown>
          </div>
        )}
      </div>
    )
  }
)

export default Actions
