import { camelize } from './strings'

export const camelizeKeys = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map((entry) => {
      const [key, value] = entry
      return [camelize(key), value]
    })
  )
}
