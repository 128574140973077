import useLocalI18n from 'Hooks/LocalI18n'
import { GROUP_MEMBERSHIP_REQUEST_STATES } from 'Constants'

const { I18n } = useLocalI18n('molecules/GroupMembershipRequests/Lang')

export const DEFAULT_STATE = GROUP_MEMBERSHIP_REQUEST_STATES.PENDING

export const FILTER_STATES = [
  {
    label: I18n.t(
      `group_membership_requests.state.${GROUP_MEMBERSHIP_REQUEST_STATES.PENDING}.title`
    ),
    value: GROUP_MEMBERSHIP_REQUEST_STATES.PENDING
  },
  {
    label: I18n.t(
      `group_membership_requests.state.${GROUP_MEMBERSHIP_REQUEST_STATES.ACCEPTED}.title`
    ),
    value: GROUP_MEMBERSHIP_REQUEST_STATES.ACCEPTED
  },
  {
    label: I18n.t(
      `group_membership_requests.state.${GROUP_MEMBERSHIP_REQUEST_STATES.DECLINED}.title`
    ),
    value: GROUP_MEMBERSHIP_REQUEST_STATES.DECLINED
  }
]
