import { action, computed, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import {
  SerializedOrganization,
  SerializedSuperGroup,
  SerializedTeam,
  MODEL_TYPES
} from 'Constants'
import { filterByType, findById } from 'Utilities/arrays'
import { BaseStore } from 'Stores/Base'

export interface SerializedTeamWithMeta extends SerializedTeam {
  meta: {
    current: boolean
  }
}

export interface SerializedOrganizationWithChildren
  extends SerializedOrganization {
  meta: {
    current: boolean
    teams: SerializedTeamWithMeta[]
  }
}

export interface SerializedSuperGroupWithChildren extends SerializedSuperGroup {
  meta: {
    current: boolean
    organizations: SerializedOrganizationWithChildren[]
  }
}

class GroupMembershipsStore extends BaseStore {
  readonly memberships = observable<any>([])
  readonly organizationMemberships = observable<any>([])
  readonly organizations = observable<SerializedOrganization>([])
  readonly superGroups = observable<SerializedSuperGroup>([])
  readonly teamMemberships = observable<any>([])
  readonly teams = observable<SerializedTeam>([])

  constructor(public rootStore: any) {
    super()
    this.rootStore = rootStore
    this.setDefaults()
  }

  private setDefaults() {
    this.initialized = false
    this.memberships.clear()
    this.loaded = false
    this.loading = false
    this.organizationMemberships.clear()
    this.organizations.clear()
    this.teamMemberships.clear()
    this.teams.clear()
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return await Promise.resolve()

      await this.fetchMemberships()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  deleteMembership = async (membershipId) => {
    try {
      const route = APIRoutes.person.groups.membership(
        this.rootStore.id,
        membershipId
      )
      await API.delete(route)
      const membership = findById(this.memberships, membershipId)
      const _organizations =
        membership?.relationships?.organizations?.data || []
      const _organizationMemberships =
        membership?.relationships?.group_membership_organizations?.data || []
      const _teams = membership?.relationships?.teams?.data || []
      const _teamMemberships =
        membership?.relationships?.group_membership_teams?.data || []
      _organizations.forEach(({ id }) => {
        const _organization = findById(this.organizations, id)
        this.organizations.remove(_organization)
      })
      _organizationMemberships.forEach(({ id }) => {
        const _organizationMembership = findById(
          this.organizationMemberships,
          id
        )
        this.organizationMemberships.remove(_organizationMembership)
      })
      _teams.forEach(({ id }) => {
        const _team = findById(this.teams, id)
        this.teams.remove(_team)
      })
      _teamMemberships.forEach(({ id }) => {
        const _teamMembership = findById(this.teamMemberships, id)
        this.teamMemberships.remove(_teamMembership)
      })
      this.memberships.remove(membership)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  deleteTeamMembership = async (teamMembershipId) => {
    try {
      const route = APIRoutes.person.groups.team.membership(
        this.rootStore.id,
        teamMembershipId
      )
      await API.delete(route)
      const teamMembership = this.teamMemberships.find(
        ({ id }) => id === teamMembershipId
      )
      const _teamId = teamMembership?.relationships?.team?.data?.id || undefined
      if (_teamId) {
        const _team = this.teams.find((item) => item.id === _teamId)
        if (_team) {
          this.teams.remove(_team)
        }
      }
      this.teamMemberships.remove(teamMembership)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  fetchMemberships = async () => {
    this.loading = true
    try {
      const route = APIRoutes.person.groups.memberships(this.rootStore.id)
      const { data } = await API.get(route)
      if (data.included?.length) {
        this.receiveItemsOfType(data.included, MODEL_TYPES.SUPER_GROUP)
        this.receiveItemsOfType(data.included, MODEL_TYPES.ORGANIZATION)
        this.receiveItemsOfType(
          data.included,
          MODEL_TYPES.GROUP_MEMBERSHIP_ORGANIZATION
        )
        this.receiveItemsOfType(data.included, MODEL_TYPES.TEAM)
        this.receiveItemsOfType(
          data.included,
          MODEL_TYPES.GROUP_MEMBERSHIP_TEAM
        )
      }
      this.receiveItemsOfType(data.data, MODEL_TYPES.GROUP_MEMBERSHIP)
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  getOrganization = (organizationId: string) => {
    return findById(this.organizations, organizationId)
  }

  getOrganizationMemberships = (membershipIds) => {
    return this.organizationMemberships.filter(({ id }) =>
      membershipIds.includes(id)
    )
  }

  getOrganizationsForSuperGroup: (
    superGroupId: string
  ) => SerializedOrganization[] = (superGroupId) => {
    return this.organizations.filter(({ relationships }) => {
      return relationships?.super_groups?.data?.some(({ id }) => {
        return id === superGroupId
      })
    })
  }

  getTeamsForOrganization: (organizationId: string) => SerializedTeam[] = (
    organizationId
  ) => {
    return this.teams.filter(({ relationships }) => {
      return relationships?.organization.data?.id === organizationId
    })
  }

  getSuperGroup = (superGroupId: string) => {
    return findById(this.superGroups, superGroupId)
  }

  getTeam = (teamId: string) => {
    return findById(this.teams, teamId)
  }

  getTeamMemberships = (membershipIds) => {
    return this.teamMemberships.filter(({ id }) => membershipIds.includes(id))
  }

  getMembershipForOrganization = (organizationId) => {
    const organizationMembership = this.organizationMemberships.find(
      (membership) =>
        membership.relationships.organization.data.id === organizationId
    )

    return this.memberships.find(
      (membership) =>
        membership.relationships.group_membership_organizations.data.find(
          (organization) => organization.id === organizationMembership?.id
        ) !== undefined
    )
  }

  getMembershipForTeam = (teamId) => {
    return this.teamMemberships.find(
      (membership) => membership.relationships.team.data.id === teamId
    )
  }

  @action
  receiveItemsOfType = (data = [], type: string) => {
    const _items = filterByType(data, type)

    const _typeMapping = {
      [MODEL_TYPES.GROUP_MEMBERSHIP]: this.memberships,
      [MODEL_TYPES.GROUP_MEMBERSHIP_ORGANIZATION]: this.organizationMemberships,
      [MODEL_TYPES.GROUP_MEMBERSHIP_TEAM]: this.teamMemberships,
      [MODEL_TYPES.ORGANIZATION]: this.organizations,
      [MODEL_TYPES.SUPER_GROUP]: this.superGroups,
      [MODEL_TYPES.TEAM]: this.teams
    }

    return this.receiveIndex(_items, _typeMapping[type])
  }

  @action
  reset = async () => this.setDefaults()

  serializeOrganizationWithChildren: (
    organization: SerializedOrganization
  ) => SerializedOrganizationWithChildren = (organization) => {
    const current = window.location.pathname.includes(organization.id)

    const teams = this.getTeamsForOrganization(organization.id).map((team) => {
      return this.serializeTeam(team)
    })

    return {
      ...organization,
      ...{
        meta: { current, teams }
      }
    }
  }

  serializeTeam: (team: SerializedTeam) => SerializedTeamWithMeta = (team) => {
    const current = window.location.pathname.includes(team.id)

    return {
      ...team,
      ...{
        meta: { current }
      }
    }
  }

  @computed
  get organizationsWithoutPublicSuperGroups() {
    return this.organizations
      .filter((organization) => {
        return !organization.relationships?.super_groups?.data?.some(
          (superGroup) =>
            !!this.getSuperGroup(superGroup.id)?.attributes?.public
        )
      })
      .map((organization) => {
        return this.serializeOrganizationWithChildren(organization)
      })
  }

  @computed
  get publicSuperGroups() {
    return this.superGroups.filter((superGroup) => {
      return superGroup.attributes?.public
    })
  }

  @computed
  get publicSuperGroupsWithChildren() {
    return this.publicSuperGroups.map((superGroup) => {
      const current = window.location.pathname.includes(superGroup.id)

      const organizations = this.getOrganizationsForSuperGroup(
        superGroup.id
      ).map((organization) => {
        return this.serializeOrganizationWithChildren(organization)
      })

      return {
        ...superGroup,
        ...{
          meta: { current, organizations }
        }
      }
    })
  }
}

export default GroupMembershipsStore
