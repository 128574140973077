import React from 'react'

const PendingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60">
    <g transform="matrix(2.5,0,0,2.5,0,0)">
      <path
        d="M.75,21.75V11.25h1.5a7.5,7.5,0,0,0,7.5-7.5V3a2.25,2.25,0,0,1,4.5,0V6.75a1.5,1.5,0,0,0,1.5,1.5h3A4.5,4.5,0,0,1,23.227,13.2l-.465,4.649a6,6,0,0,1-5.97,5.4h-6.2a6.006,6.006,0,0,1-1.648-.231L5.308,21.981A6.006,6.006,0,0,0,3.66,21.75H.75"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
    </g>
  </svg>
)

export default PendingIcon
