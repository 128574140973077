import { action, computed, IObservableArray, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { BaseStore } from 'Stores/Base'
import {
  SerializedNotification,
  SerializedPersonNotificationsPreferences,
  SerializedPagination
} from 'Constants'
import { addOrReplace, arrayOfStrings, findById } from 'Utilities/arrays'

interface NotificationsStorePreferences
  extends SerializedPersonNotificationsPreferences {
  loaded: boolean
  loading: boolean
}

export const STATUSES = {
  READ: 'read',
  UNREAD: 'unread'
}

const READ_PARAM_KEY = STATUSES.READ

class NotificationsStore extends BaseStore {
  @observable public preferences: NotificationsStorePreferences

  @observable public read: {
    items: IObservableArray<SerializedNotification>
    loaded: boolean
    loading: boolean
    pagination: SerializedPagination
    count: number
  }

  @observable public unread: {
    items: IObservableArray<SerializedNotification>
    loaded: boolean
    loading: boolean
    pagination: SerializedPagination
    count: number
  }

  @observable public selectedStatus: string

  constructor(public rootStore: any) {
    super()
    this.rootStore = rootStore
    this.reset()
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return await Promise.resolve()

      await this.fetchNotifications()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @computed
  get anyUnread() {
    return this.unread.count > 0
  }

  @action
  decrementCount = (status: string, subtraction: number) => {
    const newCount = (this[status].count as number) - subtraction
    if (newCount < 0) {
      this[status].count = 0
      return
    }

    this[status].count = newCount
  }

  @action
  ensureItemsForStatus = async (status: string) => {
    if (this[status].items.length > 0) return await Promise.resolve()

    return await this.loadPageForStatus(status, 1)
  }

  @action
  fetchNotifications = async (status = 'unread', page = 1) => {
    try {
      this[status].loaded = false
      this[status].loading = true
      const route = APIRoutes.person.notifications.index(this.rootStore.id)
      const { data } = await API.get(route, {
        params: {
          'page[number]': page,
          read: status === 'read'
        }
      })
      this.receiveData(status, data)
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this[status].loaded = true
      this[status].loading = false
    }
  }

  @action
  fetchNotificationPreferences = async () => {
    try {
      this.preferences.loaded = false
      this.preferences.loading = true
      const route = APIRoutes.person.notifications.preferences(
        this.rootStore.id
      )
      const { data } = await API.get(route)
      this.preferences = Object.assign({}, this.preferences, data.data, {
        loaded: true,
        loading: false
      })
      return data
    } catch (err) {
      this.preferences.loaded = true
      this.preferences.loading = false
      throw new Error(standardizeError(err))
    }
  }

  @action
  incrementCount = (status: string, addition: number) => {
    this[status].count = (this[status].count as number) + addition
  }

  @action
  loadMoreSelected = async () => {
    if (!this.moreSelectedAvailable) return await Promise.resolve()

    return await this.loadPageForStatus(this.selectedStatus)
  }

  @action
  loadPageForStatus = async (status: string, page?: number) => {
    const _page = page || this[status].pagination.next
    return await this.fetchNotifications(status, _page)
  }

  @computed
  get moreSelectedAvailable() {
    return (
      this.selected.pagination.next !== null &&
      this.selected.pagination.next !== undefined
    )
  }

  @action
  markAllItemsRead = async () => {
    const _notificationIds = this.unread.items.map(({ id }) => id)
    await this.updateNotificationsStatuses(_notificationIds, STATUSES.READ)
    _notificationIds.forEach((id) => this.setItemRead(id))
    this.incrementCount(STATUSES.READ, _notificationIds.length)
    this.decrementCount(STATUSES.UNREAD, _notificationIds.length)
    return await this.ensureItemsForStatus(STATUSES.UNREAD)
  }

  @action
  markItemsRead = async (notificationIds: string | string[]) => {
    const _notificationIds = arrayOfStrings(notificationIds)
    await this.updateNotificationsStatuses(_notificationIds, STATUSES.READ)
    _notificationIds.forEach((id) => this.setItemRead(id))
    this.incrementCount(STATUSES.READ, _notificationIds.length)
    this.decrementCount(STATUSES.UNREAD, _notificationIds.length)
    return await this.ensureItemsForStatus(STATUSES.UNREAD)
  }

  @action
  markItemsUnread = async (notificationIds: string | string[]) => {
    const _notificationIds = arrayOfStrings(notificationIds)
    await this.updateNotificationsStatuses(_notificationIds, STATUSES.UNREAD)
    _notificationIds.forEach((id) => this.setItemUnread(id))
    this.incrementCount(STATUSES.UNREAD, _notificationIds.length)
    this.decrementCount(STATUSES.READ, _notificationIds.length)
    return await this.ensureItemsForStatus(STATUSES.READ)
  }

  @action
  receiveData = (status, data) => {
    if ('data' in data) {
      this.receiveNotifications(status, data.data)
    }
    if ('meta' in data) {
      if ('pagination' in data.meta) {
        this[status].pagination = data.meta.pagination
      }
      if ('count' in data.meta) {
        this[status].count = data.meta.count
      }
    }
  }

  @action
  receiveNotifications = (
    status: string,
    notifications: SerializedNotification[]
  ) => {
    addOrReplace(notifications, this[status].items)
  }

  @action
  reset = () => {
    this.initialized = false
    this.preferences = {
      attributes: {
        product_updates: false
      },
      id: null,
      loaded: false,
      loading: false,
      type: null
    }
    this.read = {
      count: 0,
      items: [] as IObservableArray,
      loaded: false,
      loading: false,
      pagination: {
        current: 1,
        first: 1,
        last: null,
        next: null,
        previous: null
      }
    }
    this.unread = {
      count: 0,
      items: [] as IObservableArray,
      loaded: false,
      loading: false,
      pagination: {
        current: 1,
        first: 1,
        last: null,
        next: null,
        previous: null
      }
    }
    this.selectedStatus = STATUSES.UNREAD
  }

  @computed
  get selected() {
    return this[this.selectedStatus]
  }

  @action
  selectStatusFromUrlParams = async () => {
    const readParam = new URLSearchParams(window.location.search).get(
      READ_PARAM_KEY
    )
    const read = readParam === 'true'
    this.selectedStatus = read ? STATUSES.READ : STATUSES.UNREAD
    return await this.ensureItemsForStatus(this.selectedStatus)
  }

  @action
  setItemRead = (notificationId: string) => {
    const notification = findById(this.unread.items, notificationId)
    notification.attributes.read = true
    this.read.items.push(notification)
    this.unread.items.remove(notification)
  }

  @action
  setItemUnread = (notificationId: string) => {
    const notification = findById(this.read.items, notificationId)
    notification.attributes.read = false
    this.unread.items.push(notification)
    this.read.items.remove(notification)
  }

  @action
  toggleSelectedStatus = async () => {
    const newStatus =
      this.selectedStatus === STATUSES.READ ? STATUSES.UNREAD : STATUSES.READ
    this.selectedStatus = newStatus
    this.updateReadParams()
    return await this.ensureItemsForStatus(this.selectedStatus)
  }

  @action
  updateNotificationsStatuses = async (
    notificationIds: string | string[],
    status: string
  ) => {
    try {
      const _notificationIds = arrayOfStrings(notificationIds)
      const route = APIRoutes.person.notifications.batch[status](
        this.rootStore.id
      )
      return await API.patch(route, {
        batch: { ids: _notificationIds }
      })
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  updatePreferences = async (attributes: { product_updates?: boolean }) => {
    try {
      const route = APIRoutes.person.notifications.preferences(
        this.rootStore.id
      )
      const { data } = await API.patch(route, attributes)

      this.preferences = Object.assign({}, this.preferences, data.data)

      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  updateReadParams = () => {
    const read = this.selectedStatus === STATUSES.READ
    history.pushState(null, null, `?${READ_PARAM_KEY}=${read.toString()}`)
  }
}

export default NotificationsStore
