import { action, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { GROUP_MEMBERSHIP_REQUEST_STATES, MODEL_TYPES } from 'Constants'
import { filterByType, findById, findIndexById } from 'Utilities/arrays'

class GroupMembershipRequestsStore {
  @observable public initialized = false
  readonly membershipRequests = observable<any>([])
  @observable public loaded = false
  @observable public loading = false
  readonly organizations = observable<any>([])
  @observable public pagination: any = {}

  constructor(public rootStore: any) {
    this.rootStore = rootStore
    this.setDefaults()
  }

  private setDefaults() {
    this.initialized = false
    this.membershipRequests.clear()
    this.loaded = false
    this.loading = false
    this.organizations.clear()
    this.pagination = {
      current: 1,
      first: 1,
      last: null,
      next: null,
      previous: null
    }
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return await Promise.resolve()

      await this.fetchMembershipRequests()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  revokeMembershipRequest = async (membershipRequestId) => {
    try {
      const route = APIRoutes.person.groups.membership_request.revoke(
        this.rootStore.id,
        membershipRequestId
      )

      await API.patch(route)

      const membershipRequest = findById(
        this.membershipRequests,
        membershipRequestId
      )
      this.membershipRequests.remove(membershipRequest)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  fetchMembershipRequests = async (page = { number: 1 }) => {
    this.loading = true
    try {
      const route = APIRoutes.person.groups.membership_requests(
        this.rootStore.id
      )
      const { data } = await API.get(route, {
        params: {
          state: GROUP_MEMBERSHIP_REQUEST_STATES.PENDING,
          'page[number]': page.number
        }
      })
      await this.receiveMembershipRequests(data.data)
      if (data.included) {
        const organizations = filterByType(
          data.included,
          MODEL_TYPES.ORGANIZATION
        )
        if (organizations.length) {
          this.receiveOrganizations(organizations)
        }
      }
      this.pagination = data.meta.pagination
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  getOrganization = (organizationId) => {
    return findById(this.organizations, organizationId)
  }

  @action
  receiveMembershipRequests = async (membershipRequests) => {
    membershipRequests.forEach((membershipRequest) => {
      const membershipRequestIndex = findIndexById(
        this.membershipRequests,
        membershipRequest.id
      )
      if (membershipRequestIndex !== -1) {
        this.membershipRequests[membershipRequestIndex] = membershipRequest
      } else {
        this.membershipRequests.push(membershipRequest)
      }
    })
  }

  @action
  receiveOrganizations = (organizations) => {
    organizations.forEach((organization) => {
      const organizationIndex = findIndexById(
        this.organizations,
        organization.id
      )
      if (organizationIndex !== -1) {
        this.organizations[organizationIndex] = organization
      } else {
        this.organizations.push(organization)
      }
    })
  }

  @action
  reset = async () => this.setDefaults()
}

export default GroupMembershipRequestsStore
