import React from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { pathIsActive } from 'Utilities/path'
import { DefaultContext } from 'Contexts'
import { MenuItem, CloseButton } from 'Elements'
import useLocalI18n from 'Hooks/LocalI18n'

import * as styles from './styles.module.scss'

interface Props {
  onClose: () => void
}

const MainMenu = observer(({ onClose }: Props) => {
  const {
    applicationStore: { uiStore, sessionStore }
  } = React.useContext(DefaultContext)
  const { I18n } = useLocalI18n('modals/MainMenu/Lang')

  const _displaySecondaryPriorityItems = !!uiStore.secondaryPriorityItems.length
  const _displaySecondaryItems = !!uiStore.secondaryItems.length
  const _displayTertiaryItems = !!uiStore.tertiaryItems.length

  const _hasTakeAStepButton = uiStore.secondaryPriorityItems.filter((item) => item.alias === 'takeAStep').length > 0

  const _showTakeAStepButton =
    sessionStore.authenticatedPersonId && !_hasTakeAStepButton

  const _takeAStepButton = {
    label: I18n.t('main_menu.take_a_step'),
    path: `/people/${sessionStore.authenticatedPersonId}/actions`,
    button: 'primary',
    alias: 'takeAStep'
  }

  return (
    <div className={styles.Modal}>
      <div className={styles.Modal__close}>
        <CloseButton onClick={onClose} />
      </div>
      <div className={styles.Modal__content}>
        <div className={styles.MainMenu}>
          {(_showTakeAStepButton || _displaySecondaryPriorityItems) && (
            <ul
              className={classnames(
                styles.MainMenu__items,
                styles['MainMenu__items--primary']
              )}>
              {_showTakeAStepButton && (
                <MenuItem
                  active={pathIsActive(_takeAStepButton.path)}
                  item={_takeAStepButton}
                />
              )}
              {uiStore.secondaryPriorityItems?.map((item, index) => {
                return (
                  <MenuItem
                    active={pathIsActive(item.path)}
                    item={item}
                    key={index}
                  />
                )
              })}
            </ul>
          )}
          {_displaySecondaryItems && (
            <ul
              className={classnames(
                styles.MainMenu__items,
                styles['MainMenu__items--primary']
              )}>
              {uiStore.secondaryItems.map((item, index) => {
                return (
                  <MenuItem
                    active={pathIsActive(item.path)}
                    item={item}
                    key={index}
                  />
                )
              })}
            </ul>
          )}
          {_displayTertiaryItems && (
            <ul
              className={classnames(
                styles.MainMenu__items,
                styles['MainMenu__items--secondary']
              )}>
              {uiStore.tertiaryItems.map((item, index) => {
                return (
                  <MenuItem
                    active={pathIsActive(item.path)}
                    item={item}
                    key={index}
                  />
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
})

export default MainMenu
