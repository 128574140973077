import useLocalI18n from 'Hooks/LocalI18n'
import React, { createRef, useEffect, useState } from 'react'
import classnames from 'classnames'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { observer } from 'mobx-react'
import { DefaultContext } from 'Contexts'
import InfoIcon from 'Images/icons/InfoIcon'
import { generateId } from 'Utilities/ids'
import * as styles from './styles.module.scss'
import { getContainerWidthRem } from 'Utilities/getContainerWidthRem'

const MARGIN = 12
const WIDTH = 480

interface TooltipOverlayProps {
  container: Window | Element
  coordinates: {
    x: number
    y: number
  }
  text: string
  id: string
  isDisplayed: boolean
}

const TooltipOverlay = ({
  container,
  coordinates,
  text,
  id,
  isDisplayed
}: TooltipOverlayProps) => {
  const _pointerStyle = {
    left: `${coordinates.x}px`,
    top: `${coordinates.y}px`
  }

  const _containerHeight: number =
    container === window
      ? window.innerHeight
      : (container as Element).getBoundingClientRect().height

  const _containerScrollPosition: number =
    container === window ? window.scrollY : (container as Element).scrollHeight

  const _containerWidth: number =
    container === window
      ? window.innerWidth
      : (container as Element).getBoundingClientRect().width

  const _computeAlignment = (): string[] => {
    const _offset = WIDTH / 2 + MARGIN * 2
    const _threshold = MARGIN * 2
    const _xMax = _containerWidth
    const _alignment = []
    const _yMax = _containerScrollPosition + _containerHeight * 0.5
    if (coordinates.x <= _threshold) {
      _alignment.push('extreme-right')
    } else if (coordinates.x - _offset < 0) {
      _alignment.push('right')
    } else if (coordinates.x >= _xMax - _threshold) {
      _alignment.push('extreme-left')
    } else if (coordinates.x + _offset > _xMax) {
      _alignment.push('left')
    } else {
      _alignment.push('center')
    }
    if (coordinates.y > _yMax) {
      _alignment.push('top')
    } else {
      _alignment.push('bottom')
    }
    return _alignment
  }

  const _computeItemStyle = () => {
    if (getContainerWidthRem(container) > 40) {
      return {
        left: `${coordinates.x}px`,
        top: `${coordinates.y}px`
      }
    }
    return {
      top: `${coordinates.y}px`
    }
  }

  const _alignment = _computeAlignment()

  const _alignmentClassNames = _alignment.map(
    (item) => styles[`TooltipOverlay--${item}`]
  )

  const _itemStyle = _computeItemStyle()

  return createPortal(
    <div
      id={id}
      className={classnames(styles.TooltipOverlay, _alignmentClassNames)}
      aria-live="polite"
      aria-hidden={!isDisplayed}>
      <div className={styles.TooltipOverlay__pointer} style={_pointerStyle} />
      <p className={styles.TooltipOverlay__text} style={_itemStyle}>
        {text}
      </p>
    </div>,
    document.getElementById('tooltip-container')
  )
}

interface TooltipProps {
  data?: Record<string, string>
  getScrollableAncestor?: () => Element
  inline?: boolean
  text: string
  scrollableAncestor?: string
  label?: string
}

const Tooltip = observer(
  ({
    data = {},
    getScrollableAncestor,
    inline = false,
    scrollableAncestor,
    text,
    label = ''
  }: TooltipProps) => {
    const { I18n } = useLocalI18n('elements/Tooltip/Lang')

    const {
      applicationStore: { tooltipStore }
    } = React.useContext(DefaultContext)

    const [_currentCoordinates, _setCurrentCoordinates] = useState({
      x: 0,
      y: 0
    })
    const [_displayOverlay, _setDisplayOverlay] = useState(false)
    const [_isClick, _setIsClick] = useState(false)
    const [_scrollableAncestor, _setScrollableAncestor] = useState<
      Window | Element
    >(window)
    const [_unqiueId] = useState(generateId())

    const _data = Object.fromEntries(
      Object.entries(data).map((item) => {
        return [`data-${item[0]}`, item[1]]
      })
    )

    const _iconRef = createRef<HTMLButtonElement>()

    const _computeAndSetCurrentCoordinates = () => {
      const _boundingClientRect = _iconRef.current.getBoundingClientRect()

      const _scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      const _scrollLeft =
        window.pageXOffset ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft

      const _clientTop =
        document.documentElement.clientTop || document.body.clientTop || 0
      const _clientLeft =
        document.documentElement.clientLeft || document.body.clientLeft || 0

      /**
       * In order to deal with situations where the <Tooltip /> is used within
       * a <Modal />, which will have locked the document scroll and effectively
       * saved the scroll position with a negative top property on the document,
       * we need to check for this here and compensate if present.
       *
       * See `Utilities/scroll.js` for the implementation of this.
       */
      const _documentOffset =
        (document.documentElement.style.top &&
          parseInt(document.documentElement.style.top)) ||
        0

      const _top =
        _boundingClientRect.top + _scrollTop - _clientTop - _documentOffset
      const _left = _boundingClientRect.left + _scrollLeft - _clientLeft
      const _height = _boundingClientRect.height
      const _width = _boundingClientRect.width

      const _xCenter = _left + _width / 2
      const _yCenter = _top + _height / 2

      _setCurrentCoordinates({ x: _xCenter, y: _yCenter })
    }

    const _computeAndSetScrollableAncestor = () => {
      if (scrollableAncestor === undefined || scrollableAncestor === null) {
        if (getScrollableAncestor) {
          const _element = getScrollableAncestor()
          return _setScrollableAncestor(_element || window)
        }
        return _setScrollableAncestor(window)
      }

      const _element = document.querySelector(scrollableAncestor)
      _setScrollableAncestor(_element || window)
    }

    const _closeTooltip = (force?: boolean) => {
      if (!_displayOverlay || (_isClick && !force)) return

      _setIsClick(false)
      setTimeout(() => _setDisplayOverlay(false), 600)
    }

    const _openTooltip = () => {
      if (_displayOverlay) return

      tooltipStore.setCloseTooltip(() => _setDisplayOverlay(false))

      _computeAndSetScrollableAncestor()
      _computeAndSetCurrentCoordinates()
      _setDisplayOverlay(true)
    }

    const _onClick = (evt: React.MouseEvent) => {
      evt.preventDefault()
      evt.stopPropagation()

      _setIsClick(true)
      _openTooltip()
    }

    const _onScrollClose = () => {
      _closeTooltip()
    }

    const _onClickClose = () => {
      if (!_displayOverlay) return
      _closeTooltip(true)
      tooltipStore.unsetCloseTooltip()
    }

    const _docKeyup = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape' && _displayOverlay) {
        _closeTooltip(true)
        tooltipStore.unsetCloseTooltip()
      }
    }

    const _addEventListeners = () => {
      _scrollableAncestor.addEventListener('scroll', _onScrollClose)
      window.addEventListener('click', _onClickClose)
      document.addEventListener('keyup', _docKeyup)
    }

    const _removeEventListeners = () => {
      _scrollableAncestor.removeEventListener('scroll', _onScrollClose)
      window.removeEventListener('click', _onClickClose)
      document.removeEventListener('keyup', _docKeyup)
    }

    useEffect(() => {
      _addEventListeners()

      return () => {
        _removeEventListeners()
      }
    })

    return (
      <span
        className={classnames(styles.Tooltip, {
          [styles['Tooltip--inline']]: inline
        })}>
        <button
          {..._data}
          aria-label={`${I18n.t('tooltip.button_label')}, ${label}`}
          aria-describedby={_unqiueId}
          type="button"
          className={styles.Tooltip__icon}
          onMouseEnter={_openTooltip}
          onMouseLeave={() => _closeTooltip(true)}
          ref={_iconRef}
          onClick={_onClick}>
          <InfoIcon />
        </button>
        <CSSTransition
          appear={_displayOverlay}
          classNames="fade-in"
          in={_displayOverlay}
          timeout={400}
          unmountOnExit>
          <TooltipOverlay
            container={_scrollableAncestor}
            coordinates={_currentCoordinates}
            text={text}
            id={_unqiueId}
            isDisplayed={_displayOverlay}
          />
        </CSSTransition>
      </span>
    )
  }
)

export default Tooltip
