import React from 'react'

const InviteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
    <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
      <path
        d="M1.4,3.743A1.5,1.5,0,0,1,3.373,1.666l19.014,9.659a.75.75,0,0,1,0,1.337L3.373,22.334A1.5,1.5,0,0,1,1.4,20.257l5.058-8.264Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M22.797 11.993L6.453 11.993"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
    </g>
  </svg>
)

export default InviteIcon
