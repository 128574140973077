import { action, computed, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { SerializedUser } from 'Constants'
import { BaseStore } from 'Stores/Base'

class UserStore extends BaseStore {
  @observable public attributes: SerializedUser['attributes']
  @observable public id: string

  constructor() {
    super()
    this.reset()
  }

  @action
  init = async ({ id }) => {
    try {
      const _update = id !== this.id
      if (this.initialized && !_update) return await Promise.resolve()

      if (this.initialized && _update) this.reset()

      this.id = id

      await this.fetchUserAttributes()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchUserAttributes = async () => {
    try {
      this.setLoading()
      const route = APIRoutes.user(this.id)
      const { data } = await API.get(route)
      this.receiveUserAttributes(data.data.attributes)
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }

  @action
  receiveUserAttributes = (attributes: SerializedUser['attributes']) => {
    this.attributes = Object.assign({}, this.attributes, attributes)
  }

  @action
  reset = () => {
    this.attributes = {}
    this.id = null
    this.initialized = false
    this.loaded = false
    this.loading = false
  }

  @computed
  get isAuthenticated() {
    return this.id !== null
  }
}

export default UserStore
