import classnames from 'classnames'
import * as styles from './styles.module.scss'
import React from 'react'

interface CheckboxListProps {
  children: React.ReactNode
  className?: string
}

export default function CheckboxList({
  children,
  className
}: CheckboxListProps) {
  return (
    <div className={classnames(styles.container, className)}>{children}</div>
  )
}
