import React, { useState } from 'react'
import { ToggleButton } from 'Elements'
import * as styles from './styles.module.scss'

interface Props {
  children: React.ReactNode
  labelPrefix?: string
  toggledLabel: string
  untoggledLabel: string
}

const ToggleSection = ({
  children,
  labelPrefix = '',
  toggledLabel,
  untoggledLabel
}: Props) => {
  const [_toggled, _setTogggled] = useState(false)
  const _toggle = () => _setTogggled(!_toggled)

  return (
    <div className={styles.ToggleSection}>
      <div className={styles.ToggleSection__children}>
        {_toggled ? children[1] : children[0]}
      </div>
      <div className={styles.ToggleSection__actions}>
        <ToggleButton
          labelPrefix={labelPrefix}
          toggle={_toggle}
          toggled={_toggled}
          toggledLabel={toggledLabel}
          untoggledLabel={untoggledLabel}
        />
      </div>
    </div>
  )
}

export default ToggleSection
