import classnames from 'classnames'
import React from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import { Button, ButtonColors, GikiConfetti, CloseButton } from 'Elements'
import { ACTION_STATES } from 'Constants'
import * as styles from './styles.module.scss'

const CommitmentStateTypes = [
  ACTION_STATES.PENDING,
  ACTION_STATES.SUCCESSFUL,
  ACTION_STATES.EXISTING,
  ACTION_STATES.COMPLETE
] as const

interface CommitmentProps {
  actionLink: string
  commitmentState: typeof CommitmentStateTypes[number]
  footprintLink?: string
  modalDisplayed?: boolean
  onClose: () => void
  personLink: string
  personScore: number
  todoLink: string
}

const Commitment = ({
  actionLink,
  commitmentState,
  footprintLink,
  modalDisplayed = false,
  onClose,
  personLink,
  personScore,
  todoLink
}: CommitmentProps) => {
  const { I18n } = useLocalI18n('modals/Commitment/Lang')

  const _commitmentCompleted =
    commitmentState === ACTION_STATES.SUCCESSFUL ||
    commitmentState === ACTION_STATES.EXISTING ||
    commitmentState === ACTION_STATES.COMPLETE

  const _modalType = _commitmentCompleted ? 'complete' : 'pending'

  const _continueLink = _commitmentCompleted ? personLink : todoLink

  const _numberFormatter = (value) => I18n.toNumber(value, { precision: 0 })

  const _hasFootprintLink = !!footprintLink && _commitmentCompleted

  return (
    <div
      className={classnames(
        styles.Modal,
        styles.Commitment,
        styles[`Commitment--${_modalType}`]
      )}
      data-test-id="Commitment">
      {modalDisplayed && <GikiConfetti />}
      <div className={styles.Modal__content}>
        <div className={styles.Modal__contentBox}>
          <div className={styles.Modal__close}>
            <CloseButton onClick={onClose} />
          </div>
          <div className={styles.Commitment__header}>
            <h2 className={styles.Commitment__title}>
              {I18n.t(`commitment.${_modalType}.title`)}
            </h2>
          </div>
          <p className={styles.Commitment__body}>
            {I18n.t(`commitment.${_modalType}.body`)}
          </p>
          {I18n.t(`commitment.${_modalType}.score_prefix`, {
            defaultValue: ''
          }) && (
              <p className={styles.Commitment__scorePrefix}>
                {I18n.t(`commitment.${_modalType}.score_prefix`)}
              </p>
            )}
          <p className={styles.Commitment__score}>
            {I18n.t(`commitment.score`, {
              score: _numberFormatter(personScore)
            })}
          </p>
          <div className={styles.Commitment__actions}>
            <Button
              color={ButtonColors.BRAND}
              href={_continueLink}
              label={I18n.t(`commitment.${_modalType}.continue`)}
              onClick={onClose}
            />
            {_hasFootprintLink && (
              <Button
                color={ButtonColors.DEFAULT}
                href={footprintLink}
                label={I18n.t('commitment.update_footprint')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Commitment
