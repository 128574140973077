import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Button, ButtonColors } from 'Elements'
import * as styles from './styles.module.scss'
import { NavigationItem } from 'Stores/UIStore'
import { getContainerWidthRem } from 'Utilities/getContainerWidthRem'

interface Props {
  active?: boolean
  item: NavigationItem
}

const MenuItem = ({ active = false, item }: Props) => {
  const [_isLarge, _setIsLarge] = useState(false)

  const _resize = () => {
    _setIsLarge(getContainerWidthRem() >= 48)
  }

  useEffect(() => {
    window.addEventListener('resize', _resize)
    _resize()

    return () => {
      window.removeEventListener('resize', _resize)
    }
  }, [])

  return (
    <li className={styles.MenuItem}>
      {!item.button ? (
        <a
          className={classnames(styles.MenuItem__link, {
            [styles['MenuItem__link--active']]: active,
            [styles['MenuItem__link--inactive']]: !active
          })}
          href={item.path}
          title={item.label}>
          {item.label}
        </a>
      ) : (
        <Button
          href={item.path}
          color={
            item.button === 'primary' ? ButtonColors.BRAND : ButtonColors.DEFAULT
          }
          label={item.label}
          large={_isLarge}
        />
      )}
    </li>
  )
}

export default MenuItem
