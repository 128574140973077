import { action, extendObservable } from 'mobx'
import { APIRoutes } from 'API'

import { addOrReplace } from 'Utilities/arrays'
import {
  initalize,
  reset,
  createPaginatedState,
  fetchIndex
} from 'Utilities/store'

const initialState = createPaginatedState({
  publicTeams: [],
  query: null
})

export class PublicTeams {
  constructor(rootStore) {
    this.rootStore = rootStore
    extendObservable(this, initialState)
  }

  @action
  init = async (values = {}) => {
    await initalize(this, values, async () => {
      await this.fetchPublicTeams()
    })
  }

  @action
  updateFilter = async (filter, value) => {
    if (this.loading) return Promise.resolve()

    this.query = value
    this.publicTeams = []

    return this.fetchPublicTeams()
  }

  @action
  fetchPublicTeams = async (page = { number: 1 }) => {
    return fetchIndex(this, {
      route: APIRoutes.organizations.public_teams.all(this.rootStore.id),
      params: {
        'page[number]': page.number,
        'order[field]': 'name',
        'order[direction]': 'asc',
        query: this.query || null
      },
      onRecieve: this.receivePublicTeams
    })
  }

  @action
  receivePublicTeams = (publicTeams) => {
    addOrReplace(publicTeams, this.publicTeams)
  }

  @action
  reset = async () => {
    reset(this, initialState)
  }
}

export default PublicTeams
