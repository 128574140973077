import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'

const ActionButtons = () => {
  const { I18n } = useLocalI18n('molecules/PrimaryNavigation/Lang')

  return (
    <div className="navigation-action-buttons hide-on-tablet hide-on-mobile">
      <a
        className="button button--color-brand button--size-s button-static-navigation"
        href="/">
        {I18n.t('primary_navigation.actions.sign_up')}
      </a>
      <a
        className="button button--size-s button-static-navigation button-static-navigation--ml"
        href="/">
        {I18n.t('primary_navigation.actions.log_in')}
      </a>
    </div>
  )
}

export default ActionButtons
