import React from 'react'
import { observer } from 'mobx-react'
import useLocalI18n from 'Hooks/LocalI18n'
import { Button, ButtonColors } from 'Elements'
import * as styles from './styles.module.scss'

interface NavigationProps {
  nextHref?: string
  nextLabel?: string
  onNext?: (evt?: React.MouseEvent<HTMLAnchorElement>) => void
  onPrevious?: (evt?: React.MouseEvent<HTMLAnchorElement>) => void
  previousHref?: string
  previousLabel?: string
  title?: string
}

const Navigation = observer(
  ({
    nextHref,
    nextLabel,
    onNext,
    onPrevious,
    previousHref,
    previousLabel,
    title
  }: NavigationProps) => {
    const { I18n } = useLocalI18n('molecules/Campaigns/Navigation/Lang')

    const _displayTitle = !!title

    const _displayNextButton = !!onNext || !!nextHref

    const _displayPreviousButton = !!onPrevious || !!previousHref

    return (
      <div className={styles.Navigation}>
        <div className={styles.Navigation__action}>
          {_displayPreviousButton && (
            <Button
              ariaLabel={I18n.t('campaigns.navigation.previous.aria_label')}
              href={previousHref}
              label={
                previousLabel || I18n.t('campaigns.navigation.previous.label')
              }
              onClick={onPrevious}
            />
          )}
        </div>
        {_displayTitle && <h3 className={styles.Navigation__title}>{title}</h3>}
        <div className={styles.Navigation__action}>
          {_displayNextButton && (
            <Button
              ariaLabel={I18n.t('campaigns.navigation.next.aria_label')}
              color={ButtonColors.BRAND}
              href={nextHref}
              label={nextLabel || I18n.t('campaigns.navigation.next.label')}
              onClick={onNext}
            />
          )}
        </div>
      </div>
    )
  }
)

export default Navigation
