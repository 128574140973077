import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import * as styles from './styles.module.scss'

const _markerId = 'Content'

export const SkipToContentMain = ({ children = null, ...props }) => {
  return (
    <main
      id={_markerId}
      tabIndex={-1}
      data-testid="SkipToContentMain"
      {...props}>
      {children}
    </main>
  )
}

interface SkipToContentLinkProps {
  markerId?: string
  label?: string
  tabIndex?: number
}

export const SkipToContentLink = ({
  markerId,
  label,
  tabIndex
}: SkipToContentLinkProps) => {
  const { I18n } = useLocalI18n('elements/SkipToContent/Lang')

  const _handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    const _marker = document.getElementById(markerId || _markerId)

    if (_marker) {
      evt.preventDefault()

      let _offset = parseFloat(
        document.defaultView
          .getComputedStyle(document.documentElement, null)
          .getPropertyValue('scroll-padding-top')
      )

      if (isNaN(_offset)) {
        _offset = 0
      }

      window.scrollTo({
        top: _marker.offsetTop - _offset,
        behavior: 'smooth'
      })

      _marker.focus()
    }
  }

  return (
    <a
      href={`#${markerId || _markerId}`}
      className={styles.SkipToContentLink}
      onClick={_handleClick}
      tabIndex={tabIndex}>
      {label || I18n.t('skip_to_content.label')}
    </a>
  )
}
