import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

interface GroupProps {
  children: React.ReactNode | React.ReactNode[]
  title?: string
}

const Group = ({ children, title }: GroupProps) => {
  const _displayTitle = title !== undefined

  // Required to preserve the styling of the items on the admin summary.
  // If this is ever removed, or restyled to negate the need for 4 stats
  // in a row, then this operation can be refactored out.
  const _quarterDivision = Array.isArray(children) && children.length % 4 === 0

  return (
    <section
      className={classnames(styles.Group, {
        [styles['Group--default']]: !_quarterDivision,
        [styles['Group--quartile']]: _quarterDivision
      })}>
      {_displayTitle && (
        <header className={styles.Group__header}>
          <h3 className={styles.Group__title}>{title}</h3>
        </header>
      )}
      <div className={styles.Group__children}>{children}</div>
    </section>
  )
}

export default Group
