import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import { ToggleSection, Tooltip } from 'Elements'
import { ACTION_STATES } from 'Constants'
import * as styles from './styles.module.scss'

const SELECTED_ACTION_STATES = [ACTION_STATES.PENDING, ACTION_STATES.SUCCESSFUL]

const COMMITMENT_ATTRIBUTES = {
  [ACTION_STATES.PENDING]: 'pending_commitments',
  [ACTION_STATES.SUCCESSFUL]: 'successful_commitments'
}

const COMMITMENT_PERIODS = ['last_week', 'last_month', 'all_time']

interface AchievementsProps {
  commitments: Record<string, unknown>
  impacts?: Array<{
    avoided: {
      pending_commitments: number
      successful_commitments: number
    }
    slug: string
    unit: string
  }>
  score?: {
    label: string
    tooltip?: string
    value: number
  }
  toggleLabelPrefix?: string
}

const Achievements = observer(
  ({
    commitments,
    impacts = [],
    score,
    toggleLabelPrefix
  }: AchievementsProps) => {
    const { I18n } = useLocalI18n('molecules/Achievements/Lang')

    const _numberFormatter = (value: number) =>
      I18n.toNumber(value, { precision: 0 })

    const _displayCommitments =
      commitments !== undefined && commitments !== null

    const _displayImpacts = impacts.length > 0

    const _displayScore = score !== undefined && score !== null

    return (
      <div className={styles.Achievements}>
        <ToggleSection
          labelPrefix={toggleLabelPrefix}
          toggledLabel={I18n.t(
            `achievements.${ACTION_STATES.SUCCESSFUL}.title`
          )}
          untoggledLabel={I18n.t(
            `achievements.${ACTION_STATES.PENDING}.title`
          )}>
          {SELECTED_ACTION_STATES.map((state) => (
            <div key={state} className={styles.Achievements__body}>
              <div className={styles.Achievements__row}>
                {_displayScore && (
                  <div
                    className={classnames(
                      styles.Achievement,
                      styles['Achievement--pending']
                    )}>
                    <div className={styles.Achievement__value}>
                      <span className={styles.Achievement__number}>
                        {score.value}
                      </span>
                    </div>
                    <span className={styles.Achievement__label}>
                      {score.label}
                      {score.tooltip && (
                        <>
                          {' '}
                          <Tooltip
                            inline
                            text={score.tooltip}
                            getScrollableAncestor={null}
                            scrollableAncestor={null}
                          />
                        </>
                      )}
                    </span>
                  </div>
                )}
                {_displayCommitments &&
                  COMMITMENT_PERIODS.map((commitment) => {
                    const value = commitments[commitment][state].total
                    const label = I18n.t(
                      `achievements.${state}.commitments.${commitment}`
                    )
                    return (
                      <div key={commitment} className={styles.Achievement}>
                        <div className={styles.Achievement__value}>
                          <span className={styles.Achievement__number}>
                            {_numberFormatter(value)}
                          </span>
                        </div>
                        <span className={styles.Achievement__label}>
                          {label}
                        </span>
                      </div>
                    )
                  })}
              </div>
              {_displayImpacts && (
                <div className={styles.Achievements__row}>
                  {impacts.map((impact) => {
                    const value = impact.avoided[COMMITMENT_ATTRIBUTES[state]]
                    const unit = impact.unit
                    const slug = impact.slug

                    return (
                      <div key={slug} className={styles.Achievement}>
                        <div className={styles.Achievement__value}>
                          <span className={styles.Achievement__number}>
                            {_numberFormatter(value)}
                          </span>
                          <span className={styles.Achievement__unit}>
                            {unit}
                          </span>
                        </div>
                        <span className={styles.Achievement__label}>
                          {I18n.t(`achievements.${state}.attributes.${slug}`)}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          ))}
        </ToggleSection>
      </div>
    )
  }
)

export default Achievements
