import React from 'react'
import { ConfigurationContextProvider } from './ConfigurationContextProvider'
import { NavigationContextProvider } from './NavigationContextProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { UseWindowDimensionsProvider, SafeAreaProvider } from 'clarity'
import { AnalyticsContextProvider } from './AnalyticsContextProvider'
import { LinkContextProvider } from './LinkContextProvider'
import { AuthContextProvider } from './AuthContextProvider'
import { ContainerProps } from 'Containers'

interface ClarityContextProviderProps extends Partial<ContainerProps> {
  children: React.ReactNode
}

export const ClarityContextProvider = ({ children, ...props }: ClarityContextProviderProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  })

  return (
    <SafeAreaProvider>
      <UseWindowDimensionsProvider>
        <QueryClientProvider client={queryClient}>
          <ConfigurationContextProvider>
            <LinkContextProvider {...props}>
              <AuthContextProvider {...props}>
                <NavigationContextProvider>
                  <AnalyticsContextProvider>
                    {children}
                  </AnalyticsContextProvider>
                </NavigationContextProvider>
              </AuthContextProvider>
            </LinkContextProvider>
          </ConfigurationContextProvider>
        </QueryClientProvider>
      </UseWindowDimensionsProvider>
    </SafeAreaProvider>
  )
}