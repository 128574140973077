import React from 'react'
import { observer } from 'mobx-react'
import useLocalI18n from 'Hooks/LocalI18n'
import { ToggleButton } from 'Elements'
import styles from './styles.module.scss'

interface HeaderProps {
  count: number
  onToggleStatus: () => void
  selectedStatus: string
}

const Header = observer(
  ({ count, onToggleStatus, selectedStatus }: HeaderProps) => {
    const { I18n } = useLocalI18n('molecules/Notifications/Lang')

    const _read = selectedStatus === 'read'

    const _title = _read
      ? I18n.t('notifications.header.title.read')
      : I18n.t('notifications.header.title.unread')

    return (
      <div className={styles.Header}>
        <h1 className={styles.Header__title}>
          {_title} <span className={styles.Header__count}>({count})</span>
        </h1>
        <span className={styles.Header__controls}>
          <ToggleButton
            id="read"
            toggle={onToggleStatus}
            toggled={_read}
            toggledLabel={I18n.t('notifications.header.toggle.read')}
            untoggledLabel={I18n.t('notifications.header.toggle.unread')}
          />
        </span>
      </div>
    )
  }
)

export default Header
