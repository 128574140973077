import I18n from 'i18n-js'

export const loadAsOrderedArray = (name) => {
  const loadedBlocks = I18n.t(name) || []

  const keys = Object.keys(loadedBlocks)
  keys.sort()

  return keys.map(function (key) {
    return loadedBlocks[key]
  })
}
