import React, { useState } from 'react'
import classnames from 'classnames'
import useLocalI18n from 'Hooks/LocalI18n'
import { SerializedAnnouncement } from 'Constants/models'
import Tab from './Tab'
import * as styles from './styles.module.scss'

interface TabsProps {
  availableLocales: string[]
  attributes: SerializedAnnouncement['attributes']
  disabled: boolean
  onChange: (key: string, value: string) => void
}

const Tabs = ({
  availableLocales,
  attributes,
  disabled = false,
  onChange
}: TabsProps) => {
  const { I18n } = useLocalI18n('molecules/Announcements/Lang')

  const [_currentTab, _setCurrentTab] = useState(0)

  const _getBodyForLocale = (locale: string) => {
    return attributes[`body_${locale}`] || ''
  }

  const _tabIsActive = (_tabIndex: number) => _currentTab === _tabIndex

  return (
    <div className={styles.Tabs}>
      {availableLocales.length > 1 && (
        <ul className={styles.Tabs__tabs}>
          {availableLocales.map((locale, index) => {
            return (
              <li className={styles.Tabs__tab} key={index}>
                <button
                  onClick={(e) => {
                    e.preventDefault()

                    if (disabled) return

                    _setCurrentTab(index)
                  }}
                  className={classnames(styles.Tabs__itemLink, {
                    [styles['Tabs__itemLink--active']]: _tabIsActive(index)
                  })}>
                  {I18n.t(`announcements.admin.locales.${locale}`)}
                </button>
              </li>
            )
          })}
        </ul>
      )}
      <div className={styles.Tabs__items}>
        {availableLocales.map((locale, index) => {
          const _body = _getBodyForLocale(locale)
          return (
            <Tab
              body={_body}
              disabled={disabled}
              key={`tab_${locale}`}
              locale={locale}
              onChange={onChange}
              visible={_tabIsActive(index)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
