import * as d3 from 'd3'
import { action, observable, onBecomeObserved, onBecomeUnobserved } from 'mobx'
import { API } from 'API'
import GroupSnapshot from './GroupSnapshot'

class GroupSnapshotRepository {
  @observable public records: GroupSnapshot[] = []
  @observable ready = false

  private isObserved = false

  constructor(private readonly urlFactory: () => string) {
    onBecomeObserved(this, 'records', () => {
      void this.fetch()
    })
    onBecomeUnobserved(this, 'records', () => this.purge())
  }

  @action public reset() {
    if (this.isObserved) {
      this.purge()
      void this.fetch()
    }
  }

  @action private async fetch() {
    this.isObserved = true
    const url = this.urlFactory()
    const { data } = await API.get(url)
    const snapshots = this.extractSnapshotsFromPayload(data)
    snapshots.sort((a, b) => {
      const diff = d3.timeMillisecond.count(a.effective_date, b.effective_date)
      return -diff
    })
    this.records.push(...snapshots)
    this.ready = true
  }

  @action private purge() {
    this.ready = false
    this.records.length = 0
    this.isObserved = false
  }

  private extractSnapshotsFromPayload(data: any): GroupSnapshot[] {
    const snapshots = data.data.map((x) => x.attributes)
    const dateFormat = d3.timeParse('%Y-%m-%d')
    const dateTimeFormat = d3.isoParse
    snapshots.forEach((snapshot) => {
      snapshot.effective_date = dateFormat(snapshot.effective_date)
      snapshot.created_at = dateTimeFormat(snapshot.created_at)
      snapshot.updated_at = dateTimeFormat(snapshot.updated_at)
    })
    return snapshots
  }
}

export default GroupSnapshotRepository
