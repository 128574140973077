import React from 'react'
import { observer } from 'mobx-react'
import { SerializedNotification } from 'Constants'
import { LoadMoreButton, Button, ButtonColors } from 'Elements'
import Notifications from './Notifications'
import useLocalI18n from 'Hooks/LocalI18n'
import styles from './styles.module.scss'

interface ListingProps {
  loaded?: boolean
  loading?: boolean
  moreAvailable?: boolean
  notifications: SerializedNotification[]
  onLoadMore: () => void
  onMarkAllItemsRead: () => void
  onMarkItemsRead: (ids: string | string[]) => Promise<void>
  onMarkItemsUnread: (ids: string | string[]) => Promise<void>
  selectedStatus: string
}

const Listing = observer(
  ({
    loaded = false,
    loading = false,
    moreAvailable = false,
    notifications,
    onLoadMore,
    onMarkAllItemsRead,
    onMarkItemsRead,
    onMarkItemsUnread,
    selectedStatus
  }: ListingProps) => {
    const { I18n } = useLocalI18n('molecules/Notifications/Lang')

    const _notificationsPopulated = notifications.length > 0

    const _displayReadButton =
      _notificationsPopulated && selectedStatus === 'unread'

    return (
      <div className={styles.Listing}>
        <div className={styles.Listing__main}>
          <Notifications
            loaded={loaded}
            loading={loading}
            notifications={notifications}
            onMarkItemsRead={onMarkItemsRead}
            onMarkItemsUnread={onMarkItemsUnread}
          />
          <div className={styles.Listing__actions}>
            {moreAvailable && (
              <span className={styles.Listing__action}>
                <LoadMoreButton loading={loading} onLoadMore={onLoadMore} />
              </span>
            )}
            {_displayReadButton && (
              <span className={styles.Listing__action}>
                <Button
                  color={ButtonColors.BRAND}
                  label={I18n.t('notifications.listing.mark_all_read')}
                  onClick={onMarkAllItemsRead}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
)

export default Listing
