import useLocalI18n from 'Hooks/LocalI18n'
import React, { useState } from 'react'
import DefaultContext from 'Contexts/default'
import { TextSkeleton } from 'Molecules'
import * as styles from './styles.module.scss'

interface ShareLinkButtonProps {
  loading?: boolean
  onClick?: () => void
  url: string
}

const ShareLinkButton = ({
  loading = false,
  onClick,
  url
}: ShareLinkButtonProps) => {
  const { I18n } = useLocalI18n('elements/ShareLinkButton/Lang')

  const {
    applicationStore: { modalStore }
  } = React.useContext(DefaultContext)

  const [_isDisabled, _setIsDisabled] = useState(false)

  const _onClick = async () => {
    if (onClick !== undefined && onClick !== null) {
      onClick()
    }
    _setIsDisabled(true)
    setTimeout(() => {
      _setIsDisabled(false)
    }, 5000)

    await navigator.clipboard.writeText(url)

    modalStore.createAlert({
      message: I18n.t('share_link_button.message')
    })
  }

  if (!navigator.clipboard || !navigator.clipboard.writeText) {
    return null
  }

  return (
    <button className={styles.Button} onClick={_onClick} disabled={_isDisabled}>
      {loading ? (
        <TextSkeleton className={styles.Button__url} lines={1} tag="span" />
      ) : (
        <span className={styles.Button__url}>{url}</span>
      )}
      <span className={styles.Button__label}>
        {I18n.t('share_link_button.label')}
      </span>
    </button>
  )
}

export default ShareLinkButton
