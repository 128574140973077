import { action, computed, IObservableArray, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'

interface Metric {
  current: number
}

interface Footprint {
  param: string
  slug: string
  applicable: boolean
  average_only: boolean
  complete: boolean
  source: string
  co2e?: Metric
  land?: Metric
  po43e?: Metric
  water?: Metric
}

interface Section {
  param: string
  complete: boolean
  progress: number
  footprints?: Footprint[]
  co2e?: Metric
  land?: Metric
  po43e?: Metric
  water?: Metric
}

export class SectionsStore {
  @observable public includeImpacts: string
  @observable public initialized: boolean
  @observable public loaded: boolean
  @observable public loading: boolean
  @observable public sections: IObservableArray<Section>

  constructor(public rootStore: any) {
    this.rootStore = rootStore
    this.reset()
  }

  @action
  init = async ({ includeImpacts = 'none' } = {}) => {
    try {
      if (this.initialized) {
        this.reset()
      }

      this.includeImpacts = includeImpacts

      await this.fetchSections()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchSections = async () => {
    this.loading = true
    const { data } = await API.get(
      APIRoutes.person.sections(this.rootStore.id),
      { params: { include_impacts: this.includeImpacts } }
    )

    this.sections = data.data || []
    this.loaded = true
    this.loading = false
  }

  moreImpactDetailIsRequired = (previous: string, current: string) => {
    const detailLevels = {
      all: 2,
      sections: 1,
      none: 0
    }
    return detailLevels[current] > detailLevels[previous]
  }

  @action
  reset = () => {
    this.includeImpacts = 'none'
    this.initialized = false
    this.loaded = false
    this.loading = false
    this.sections = [] as IObservableArray
  }

  @computed
  get carbonImpacts() {
    if (this.includeImpacts === 'none') {
      return {
        loaded: false,
        loading: false,
        sections: []
      }
    }

    return {
      loaded: this.loaded,
      loading: this.loading,
      sections: this.sections.map((section) => ({
        slug: section.param,
        value: section.co2e.current,
        items: section.footprints.map((footprint) => ({
          slug: footprint.slug,
          value: footprint.co2e.current
        }))
      }))
    }
  }
}

export default SectionsStore
