import React from 'react'
import * as styles from '.././styles.module.scss'
import SettingsHeader from '../SettingsHeader'

interface SettingsListItemProps {
  title?: string
  tooltip?: string
  children: React.ReactNode
}

const SettingListItem = (props: SettingsListItemProps) => {
  return (
    <div className={styles.SettingsList__item}>
      <SettingsHeader title={props.title} tooltip={props.tooltip} />
      {props.children}
    </div>
  )
}

export default SettingListItem
