import React from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

interface TabProps {
  body?: string
  disabled?: boolean
  locale: string
  onChange: (key: string, value: string) => void
  visible?: boolean
}

const Tab = ({
  body = '',
  disabled = false,
  locale,
  onChange,
  visible = false
}: TabProps) => {
  const { I18n } = useLocalI18n('molecules/Announcements/Lang')

  const _onChange = (
    ev: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = ev.currentTarget
    onChange(name, value)
  }

  return (
    <div className={classnames(styles.Tab, {
      [styles['Tab--hidden']]: !visible
    })}>
      <div className="form-row form-row--inline-center form-row--column">
        <textarea
          aria-label={I18n.t('announcements.admin.fields.body.label', {
            locale_name: I18n.t(`announcements.admin.locales.${locale}`)
          })}
          className="textarea textarea--bs"
          disabled={disabled}
          id={`body_${locale}`}
          name={`body_${locale}`}
          onChange={_onChange}
          placeholder={I18n.t('announcements.admin.fields.body.placeholder', {
            locale_name: I18n.t(`announcements.admin.locales.${locale}`)
          })}
          rows={4}
          value={body}
        />
      </div>
    </div>
  )
}

export default Tab
