import React from 'react'
import classNames from 'classnames'
import * as ICONS from 'Constants'

export const Icons = {
  ...ICONS
}

export const Types = {
  DEFAULT: 'default',
  OUTLINED: 'outlined',
  RADIOPILL: 'radio-pill',
  RADIOPILLSELECTED: 'radio-pill--selected',
  BLANK: 'blank'
}

export const Colors = {
  DEFAULT: '',
  BRAND: 'brand',
  PAPER: 'paper',
  WHITE: 'white'
}

interface TargetProps {
  target: string
  rel?: string
}

export interface ButtonProps {
  ariaLabel?: string
  ariaHidden?: boolean
  href?: string
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void
  label?: string
  variant?: string
  type?: 'submit' | 'reset' | 'button'
  icon?: string | React.ElementType
  small?: boolean
  large?: boolean
  color?: string
  disabled?: boolean
  actionType?: string
  className?: string
  disableTurbolinks?: boolean
  name?: string
  data?: Record<string, string>
  target?: string
  isFake?: boolean
  tabIndex?: number
  isFullWidth?: boolean
}

const Button = React.forwardRef(function Button(
  {
    ariaLabel,
    ariaHidden,
    color = Colors.DEFAULT,
    disabled,
    icon,
    type = 'button',
    onClick,
    label,
    variant = Types.DEFAULT,
    small,
    large,
    href,
    actionType = '',
    className = '',
    disableTurbolinks = false,
    name,
    data = {},
    target,
    isFake = false,
    tabIndex,
    isFullWidth = false
  }: ButtonProps,
  ref
) {
  const HTMLTag = isFake ? 'span' : href ? 'a' : 'button'

  const _handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation()
    onClick(evt)
  }

  let _buttonType = null

  if (HTMLTag === 'button') {
    _buttonType = {
      type
    }
  }

  const _data = Object.fromEntries(
    Object.entries(data).map((item) => {
      return [`data-${item[0]}`, item[1]]
    })
  )

  const _turbolinks = disableTurbolinks ? { 'data-turbolinks': false } : {}

  const _computeTargetProps = () => {
    if (target === null || target === undefined) return {}

    const _targetProps: TargetProps = { target: target }

    if (target === '_blank') {
      _targetProps.rel = 'noopener noreferrer'
    }

    return _targetProps
  }

  const _target = _computeTargetProps()

  const Icon = typeof icon === 'string' ? null : (icon as unknown as React.ElementType)

  return (
    <HTMLTag
      {..._buttonType}
      {..._turbolinks}
      {..._data}
      {..._target}
      aria-label={ariaLabel}
      onClick={onClick && _handleClick}
      href={href}
      className={classNames(
        {
          button: variant !== 'blank',
          [`button--${variant}`]: variant !== 'default',
          'button--size-s': small,
          'button--size-l': large,
          [`button--color-${color}`]: !!color,
          [`button--${actionType}`]: !!actionType,
          'button--icon': icon && !label,
          'button--full-width': isFullWidth
        },
        className
      )}
      disabled={disabled}
      title={ariaLabel}
      ref={ref}
      name={name}
      tabIndex={tabIndex}
      aria-hidden={ariaHidden}>
      {Icon && (
        <span className="button__icon">
          <Icon />
        </span>
      )}
      {!Icon && typeof icon === 'string' && (
        <img src={icon} alt="" className="button__icon" />
      )}
      {label ? <span className="button__label">{label}</span> : null}
    </HTMLTag>
  )
})

export default Button
