import { action, computed, observable } from 'mobx'
import { API, APIRoutes, uploadFile } from 'API'
import { standardizeError } from 'Utilities/errors'
import CampaignStore from 'Stores/Common/CampaignStore'
import CampaignsStore from 'Stores/Common/CampaignsStore'
import Memberships from 'Stores/Common/Memberships'
import MembershipRequests from 'Stores/Common/MembershipRequests'
import SettingsStore from './SettingsStore'
import Teams from './Teams'
import PublicTeams from './PublicTeams'
import { CAMPAIGN_CONTEXTS, MODEL_TYPES } from 'Constants'
import { filterByType } from 'Utilities/arrays'
import GroupSnapshotStore, { DateRange } from 'Stores/Common/GroupSnapshotStore'
import BenchmarkStore from './BenchmarkStore'

class OrganizationStore {
  @observable public admin = false
  @observable public attributes: any = {}
  @observable public id: string = null
  @observable public initialized = false
  @observable public links: any = {}
  @observable public loading = false
  @observable public loaded = false
  @observable public relationships: any = {}

  public type = 'organizations'
  public benchmarkStore = new BenchmarkStore(this)
  public campaignStore = new CampaignStore(this, CAMPAIGN_CONTEXTS.ORGANIZATION)
  public campaignsStore = new CampaignsStore(
    this,
    CAMPAIGN_CONTEXTS.ORGANIZATION
  )

  public memberships = new Memberships(this)
  public membershipRequests = new MembershipRequests(this)
  public settingsStore = new SettingsStore(this)
  public teams = new Teams(this)
  public publicTeams = new PublicTeams(this)
  snapshots: GroupSnapshotStore

  constructor(public rootStore: any) {
    const urlFactory = (dateRange: DateRange) =>
      APIRoutes.organizations.snapshots(this.id, dateRange)
    this.snapshots = new GroupSnapshotStore(urlFactory)
    this.setDefaults()
  }

  private setDefaults() {
    this.admin = false
    this.attributes = {}
    this.id = null
    this.initialized = false
    this.links = {}
    this.loading = false
    this.loaded = false
    this.relationships = {}
  }

  @action
  init = async (values = {}) => {
    try {
      const _update = Object.keys(values).some(
        (key) => this[key] !== values[key]
      )
      if (this.initialized && !_update) return await Promise.resolve()

      if (this.initialized && _update) await this.reset()

      Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })

      await this.fetchOrganizationAttributes()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchOrganizationAttributes = async () => {
    this.loading = true
    try {
      const route = APIRoutes.organization(this.id)
      const { data } = await API.get(route)
      this.attributes = data.data.attributes
      this.links = data.data.links
      this.relationships = data.data.relationships
      const settings = filterByType(
        data.data.included,
        MODEL_TYPES.ORGANIZATION_SETTINGS
      )
      await this.settingsStore.init(settings)
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @action
  receiveOrganizationAttributes = async (attributes) => {
    this.attributes = {
      ...this.attributes,
      ...attributes
    }
  }

  @action
  reset = async () => {
    this.setDefaults()
    this.benchmarkStore.reset()
    this.campaignStore.reset()
    this.campaignsStore.reset()
    await this.memberships.reset()
    await this.membershipRequests.reset()
    await this.teams.reset()
    await this.publicTeams.reset()
    await this.settingsStore.reset()

    this.snapshots.reset()
  }

  @action
  updateOrganization = async (params) => {
    try {
      const route = APIRoutes.organization(this.id)
      if (params.bannerFile) {
        const bannerUrl = await uploadFile(params.bannerFile, 'banner')
        params.banner = bannerUrl
        delete params.bannerFile
      }

      if (params.logoFile) {
        const logoUrl = await uploadFile(params.logoFile, 'logo')
        params.logo = logoUrl
        delete params.logoFile
      }
      const { data } = await API.patch(route, { organization: params })
      this.attributes = data.data.attributes
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @computed
  get pendingCommitmentsPopulated() {
    return (
      this.loaded &&
      this.relationships.commitments.meta.all_time.pending.total > 0
    )
  }

  @computed
  get personId() {
    return this.rootStore.personStore.id
  }

  @computed
  get successfulCommitmentsPopulated() {
    return (
      this.loaded &&
      this.relationships.commitments.meta.all_time.successful.total > 0
    )
  }

  @computed
  get teamsPopulated() {
    return this.loaded && this.relationships.teams.meta.total > 0
  }
}

export default OrganizationStore
