import React from 'react'

const FootstepsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M25.376 16.064c-.064 0-.096 0-.16-.032l-6.528-1.568c-.288-.064-.48-.32-.48-.608V9.568c0-1.248.128-2.208.416-3.008 0-.032.032-.032.032-.064l.096-.16c.256-.672.512-1.12.8-1.472.32-.416.768-.768 1.248-1.088.576-.352 1.536-.736 2.4-.736.992 0 2.336.384 2.944.768.672.384 1.184.928 1.536 1.6.352.736.512 1.632.512 2.688v.128c0 1.216-.224 2.368-.64 3.456-.384 1.088-.736 2.016-1.056 2.784l-.544 1.216c-.096.224-.32.384-.576.384zM6.528 20.448c-.256 0-.48-.16-.576-.384L5.44 18.88c-.32-.768-.672-1.728-1.056-2.816-.416-1.152-.608-2.336-.608-3.488v-.128c0-1.056.16-1.952.512-2.688.416-.768.896-1.28 1.568-1.632.672-.384 1.728-.64 2.752-.64.992 0 1.952.256 2.592.64.48.288.928.64 1.248 1.056.288.352.544.832.8 1.472l.096.16c0 .032.032.032.032.064.288.832.448 1.824.448 3.008v4.288c0 .288-.192.544-.48.608l-6.592 1.6c-.128.064-.16.064-.224.064zM20 24.544h-.064c-.672 0-1.184-.128-1.568-.384-.064-.032-.096-.032-.128-.064-.288-.224-.544-.448-.736-.704 0-.032-.032-.032-.032-.064-.192-.288-.352-.544-.512-.832-.224-.416-.32-.96-.32-1.568v-.32c0-.864.192-1.696.544-2.56l.608-1.376c.128-.288.448-.448.736-.352l6.112 1.6c.16.032.32.16.384.32.096.16.096.352.032.512l-.32 1.088c-.256.8-.512 1.472-.8 2.048l-.064.128c-.224.48-.512.928-.864 1.248-.288.288-.608.512-.96.736l-.128.064c-.032 0-.032.032-.064.032-.576.32-1.184.448-1.856.448zm-7.52 4.416h-.096c-.768 0-1.376-.128-1.888-.448-.48-.288-.832-.512-1.152-.832-.384-.352-.704-.896-.864-1.28l-.064-.128c-.288-.576-.544-1.248-.8-2.048l-.352-1.088c-.064-.16-.032-.352.064-.512.096-.16.224-.256.416-.32l6.144-1.44c.288-.064.608.064.736.352l.576 1.216c.384.832.576 1.664.576 2.56v.32c0 .608-.096 1.152-.32 1.568-.16.32-.32.608-.512.864s-.448.512-.768.736c-.416.32-.992.48-1.696.48z"
    />
  </svg>
)

export default FootstepsIcon
