import { action, computed, IObservableArray, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import {
  MODEL_TYPES,
  SerializedAction,
  SerializedCampaign,
  SerializedCampaignsTarget,
  SerializedPagination
} from 'Constants'
import {
  findById,
  findIndexById,
  filterByType,
  someById
} from 'Utilities/arrays'
import { PaginatedStore } from 'Stores/Base'

interface SerializedCampaignWithActionAndTarget extends SerializedCampaign {
  actions: {
    items: IObservableArray<SerializedAction>
    loading: boolean
    pagination: SerializedPagination
    seed: string
  }
  target: {
    attribute: string
    current: number
    displayPublicly: boolean
    selected: boolean
    target: number
  }
}

class CampaignsStore extends PaginatedStore {
  @observable
  public items: IObservableArray<SerializedCampaignWithActionAndTarget>

  constructor(public rootStore: any) {
    super()
    this.rootStore = rootStore
    this.reset()
  }

  @action
  init = async () => {
    try {
      if (this.initialized) {
        if (this.promoted?.id) {
          await this.fetchCampaignActions(this.promoted.id)
        }
        return await Promise.resolve()
      }

      await this.fetchCampaigns()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchCampaignActions = async (campaignId, page = { number: 1 }) => {
    const campaign = findById(this.items, campaignId)

    try {
      const route = APIRoutes.person.campaigns.actions(
        this.rootStore.id,
        campaignId
      )
      const params = {
        'page[number]': page.number,
        'page[size]': 5,
        ...(campaign.actions.seed && { seed: campaign.actions.seed })
      }

      if (page.number === 1) {
        campaign.actions.loading = true
        campaign.actions.items = []
      }

      const {
        data: { data: actions, meta }
      } = await API.get(route, { params })

      actions.forEach((action) => {
        const actionIndex = findIndexById(campaign.actions.items, action.id)
        if (actionIndex === -1) {
          campaign.actions.items.push(action)
        } else {
          campaign.actions.items[actionIndex] = action
        }
      })

      campaign.actions.pagination = meta.pagination
      campaign.actions.seed = meta.seed
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      campaign.actions.loading = false
    }
  }

  @action
  fetchCampaigns = async () => {
    this.loading = true
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const route = APIRoutes.person.campaigns.show(this.rootStore.id, timezone)
      const params = { 'page[number]': 1 }
      const {
        data: { data: campaigns, included, meta }
      } = await API.get(route, { params })

      const targets = filterByType(included, MODEL_TYPES.CAMPAIGNS_TARGET)

      this.receiveCampaignsWithTargets(campaigns, targets)

      this.pagination = meta.pagination
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  extractAndFormatTarget = (
    campaign: SerializedCampaign,
    targets: SerializedCampaignsTarget[] = []
  ) => {
    const defaultResponse = {
      attribute: 'participants_count',
      displayPublicly: false,
      current: 0,
      selected: true,
      target: 0
    }

    const targetId = campaign?.relationships?.target?.data?.id

    if (!targetId) return defaultResponse

    const target = targets.find(({ id, type }) => {
      return type === MODEL_TYPES.CAMPAIGNS_TARGET && id === targetId
    })

    if (!target) return defaultResponse

    const selectedTargetAttrbute = target.attributes.selected

    const formattedTargetAttributes = {
      attribute: selectedTargetAttrbute,
      displayPublicly: target.attributes.display_publicly,
      current: campaign.attributes[selectedTargetAttrbute],
      target: target.attributes[selectedTargetAttrbute]
    }

    return Object.assign({}, defaultResponse, formattedTargetAttributes)
  }

  @action
  receiveCampaignsWithTargets = (campaigns, targets = []) => {
    const initialCampaignActionState = {
      items: [],
      loading: true,
      pagination: {},
      seed: null
    }

    campaigns.forEach((campaign) => {
      const campaignLoaded = someById(this.items, campaign.id)
      if (campaignLoaded) return

      const serializedTarget = this.extractAndFormatTarget(campaign, targets)

      this.items.push({
        ...campaign,
        actions: initialCampaignActionState,
        target: serializedTarget
      })
      void this.fetchCampaignActions(campaign.id)
    })
  }

  @action
  reset = () => {
    this.initialized = false
    this.items = [] as IObservableArray
    this.loaded = false
    this.loading = false
    this.pagination = {
      current: 1,
      first: 1,
      last: null,
      next: null,
      previous: null
    }
  }

  @computed
  get promoted() {
    return this.items.length > 0 ? this.items[0] : undefined
  }

  @computed
  get promotedActions() {
    return this.promoted?.actions || {}
  }

  @computed
  get promotedActionTotal() {
    return this.promoted ? this.promoted.relationships.actions.meta.total : 0
  }
}

export default CampaignsStore
