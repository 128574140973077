import I18n from 'i18n-js'

const LOCALES_WITH_REGIONS = {
  en: 'en-GB',
  fr: 'fr-FR',
  nl: 'nl-NL',
  de: 'de-DE'
}

/**
 * Currently our locales do not have a region attached.
 * This is fine for most cases, but there are some quirks
 * in Chrome for locales without a region. This includes
 * formatting midnight as 24:00, where setting the hourCycle
 * to 'h23' seems to be ignored. This is a workaround for the
 * moment, allowing us to attach regions to locales and
 * try to standardise behaviour in line with expecations.
 */
export const dateFormatter = new Intl.DateTimeFormat(
  LOCALES_WITH_REGIONS[I18n.locale],
  {
    dateStyle: 'long',
    timeStyle: 'short',
    hour12: false
  }
)

export const formatDate = (dateTimeString: string) => {
  const _date = new Date(dateTimeString)

  return dateFormatter.format(_date)
}

export const relativeTimestamp = (dateTimeString: string) => {
  const formatter = new Intl.RelativeTimeFormat(I18n.locale, { style: 'long' })
  const now = Date.now()
  const before = new Date(dateTimeString)
  const millisecondChange = before.valueOf() - now.valueOf()
  const secondChange = millisecondChange / 1000

  if (secondChange > -60) {
    return formatter.format(Math.round(secondChange), 'seconds')
  }

  const minuteChange = secondChange / 60

  if (minuteChange > -60) {
    return formatter.format(Math.round(minuteChange), 'minute')
  }

  const hourChange = minuteChange / 60

  if (hourChange > -24) {
    return formatter.format(Math.round(hourChange), 'hour')
  }

  const dayChange = hourChange / 24

  if (dayChange > -7) {
    return formatter.format(Math.round(dayChange), 'day')
  }

  const weekChange = dayChange / 7

  return formatter.format(Math.round(weekChange), 'week')
}

export const formatRange = (startsAt: string, endsAt: string) => {
  return `${formatDate(startsAt)}–${formatDate(endsAt)}`
}
