import { action, computed, extendObservable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { MODEL_TYPES } from 'Constants'
import { filterByType, findById, findIndexById } from 'Utilities/arrays'

const initialState = {
  filters: {
    admin: undefined,
    query: ''
  },
  initialized: false,
  loaded: false,
  loading: false,
  memberships: [],
  organizationMemberships: [],
  organizations: [],
  people: [],
  pagination: {
    current: 1,
    first: 1,
    last: null,
    next: null,
    previous: null
  },
  selectedMembershipIds: [],
  teamMemberships: [],
  teams: []
}

class Memberships {
  constructor(rootStore) {
    this.rootStore = rootStore
    extendObservable(this, initialState)
  }

  @action
  init = async (values = {}) => {
    try {
      if (this.initialized) return Promise.resolve()

      await Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })

      await this.fetchMemberships()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @computed
  get allMembershipsSelected() {
    return this.selectedMembershipIds.length === this.memberships.length
  }

  @computed
  get anyMembershipSelected() {
    return this.selectedMembershipIds.length > 0
  }

  @action
  batchDeleteMemberships = async (membershipEmails = []) => {
    try {
      const route = APIRoutes[this.rootStore.type].memberships.batch(
        this.rootStore.id
      )
      await API.delete(route, {
        data: {
          batch: {
            emails: membershipEmails
          }
        }
      })

      this.deselectAll()
      await this.fetchMemberships()
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  deleteSelectedMemberships = async () => {
    try {
      const route = APIRoutes[this.rootStore.type].memberships.batch(
        this.rootStore.id
      )
      await API.delete(route, {
        data: {
          batch: {
            ids: this.selectedMembershipIds
          }
        }
      })

      this.selectedMembershipIds.forEach((membershipId) => {
        const _membership = this.getMembership(membershipId)
        const _person = this.getPerson(_membership.relationships.person.data.id)
        this.memberships.remove(_membership)
        this.people.remove(_person)
      })

      this.deselectAll()
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  deselectAll = () => {
    this.selectedMembershipIds.clear()
  }

  @action
  fetchMemberships = async (page = { number: 1 }) => {
    this.loading = true
    try {
      const route = APIRoutes[this.rootStore.type].memberships.all(
        this.rootStore.id
      )
      const { data } = await API.get(route, {
        params: Object.assign({}, this.filtersAsParams, {
          'page[number]': page.number
        })
      })
      this.receiveIncludedItems(data.included)
      if (page.number === 1) {
        this.memberships = data.data
      } else {
        this.receiveMemberships(data.data)
      }
      this.pagination = data.meta.pagination
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @computed
  get filtersAsParams() {
    const _populatedFilters = Object.keys(this.filters).filter(
      (key) =>
        this.filters[key] !== null &&
        this.filters[key] !== undefined &&
        this.filters[key].length !== 0
    )
    return _populatedFilters.reduce((accumulator, filter) => {
      return Object.assign(accumulator, { [filter]: this.filters[filter] })
    }, {})
  }

  getMembership = (membershipId) => {
    return findById(this.memberships, membershipId)
  }

  getOrganization = (organizationId) => {
    return findById(this.organizations, organizationId)
  }

  getOrganizationMemberships = (membershipIds) => {
    return this.organizationMemberships.filter(({ id }) =>
      membershipIds.includes(id)
    )
  }

  getPerson = (personId) => {
    return findById(this.people, personId)
  }

  getTeam = (teamId) => {
    return findById(this.teams, teamId)
  }

  getTeamMemberships = (membershipIds) => {
    return this.teamMemberships.filter(({ id }) => membershipIds.includes(id))
  }

  isMembershipSelected = (membershipId) => {
    return this.selectedMembershipIds.includes(membershipId)
  }

  @action
  receiveIncludedItems = (items = []) => {
    if (!items.length) return

    const _people = filterByType(items, MODEL_TYPES.PERSON)
    const _organizations = filterByType(items, MODEL_TYPES.ORGANIZATION)
    const _organizationMemberships = filterByType(
      items,
      MODEL_TYPES.GROUP_MEMBERSHIP_ORGANIZATION
    )
    const _teams = filterByType(items, MODEL_TYPES.TEAM)
    const _teamMemberships = filterByType(
      items,
      MODEL_TYPES.GROUP_MEMBERSHIP_TEAM
    )
    if (_organizations.length) {
      this.receiveOrganizations(_organizations)
    }
    if (_organizationMemberships.length) {
      this.receiveOrganizationMemberships(_organizationMemberships)
    }
    if (_people.length) {
      this.receivePeople(_people)
    }
    if (_teams.length) {
      this.receiveTeams(_teams)
    }
    if (_teamMemberships.length) {
      this.receiveTeamMemberships(_teamMemberships)
    }
  }

  @action
  receiveMemberships = (memberships) => {
    memberships.forEach((membership) => {
      const membershipIndex = findIndexById(this.memberships, membership.id)
      if (this.filters.admin !== null && this.filters.admin !== undefined) {
        if (
          membership.attributes &&
          membership.attributes.admin !== this.filters.admin
        ) {
          return this.memberships.remove(this.memberships[membershipIndex])
        }
      }
      if (membershipIndex !== -1) {
        this.memberships[membershipIndex] = membership
      } else {
        this.memberships.push(membership)
      }
    })
  }

  @action
  receivePeople = (people) => {
    people.forEach((person) => {
      const personIndex = findIndexById(this.people, person.id)
      if (personIndex !== -1) {
        this.people[personIndex] = person
      } else {
        this.people.push(person)
      }
    })
  }

  @action
  receiveOrganizationMemberships = (organizationMemberships) => {
    organizationMemberships.forEach((organizationMembership) => {
      const organizationMembershipIndex = findIndexById(
        this.organizationMemberships,
        organizationMembership.id
      )
      if (organizationMembershipIndex !== -1) {
        this.organizationMemberships[organizationMembershipIndex] =
          organizationMembership
      } else {
        this.organizationMemberships.push(organizationMembership)
      }
    })
  }

  @action
  receiveOrganizations = (organizations) => {
    organizations.forEach((organization) => {
      const organizationIndex = findIndexById(
        this.organizations,
        organization.id
      )
      if (organizationIndex !== -1) {
        this.organizations[organizationIndex] = organization
      } else {
        this.organizations.push(organization)
      }
    })
  }

  @action
  receiveTeamMemberships = (teamMemberships) => {
    teamMemberships.forEach((teamMembership) => {
      const teamMembershipIndex = findIndexById(
        this.teamMemberships,
        teamMembership.id
      )
      if (teamMembershipIndex !== -1) {
        this.teamMemberships[teamMembershipIndex] = teamMembership
      } else {
        this.teamMemberships.push(teamMembership)
      }
    })
  }

  @action
  receiveTeams = (teams) => {
    teams.forEach((team) => {
      const teamIndex = findIndexById(this.teams, team.id)
      if (teamIndex !== -1) {
        this.teams[teamIndex] = team
      } else {
        this.teams.push(team)
      }
    })
  }

  @action
  reset = async () => {
    await Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key]
    })
  }

  @action
  selectAll = () => {
    this.selectedMembershipIds = this.memberships.map(({ id }) => id)
  }

  @action
  toggleSelect = (membershipId) => {
    if (this.selectedMembershipIds.includes(membershipId)) {
      this.selectedMembershipIds.remove(membershipId)
    } else {
      this.selectedMembershipIds.push(membershipId)
    }
  }

  @action
  updateFilter = async (filter, value) => {
    if (this.loading) return Promise.resolve()

    this.filters[filter] = value

    this.deselectAll()

    return this.fetchMemberships()
  }

  @action
  updateSelectedMemberships = async (attributes = {}) => {
    try {
      const route = APIRoutes[this.rootStore.type].memberships.batch(
        this.rootStore.id
      )
      const { data } = await API.patch(route, {
        batch: {
          ids: this.selectedMembershipIds,
          attributes
        }
      })
      this.receiveIncludedItems(data.included)
      this.receiveMemberships(data.data)
      this.deselectAll()
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }
}

export default Memberships
