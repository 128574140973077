import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

interface TextProps {
  className?: string
  lines?: number
  tag?: keyof JSX.IntrinsicElements
}

const Text = ({ className, lines = 5, tag: Tag = 'p' }: TextProps) => {
  const _lines = Array.from(Array(lines).keys())

  return (
    <Tag
      data-testid="TextSkeleton"
      className={classnames(styles.Text, {
        [className]: !!className
      })}>
      {_lines.map((item) => {
        return <span key={item}></span>
      })}
    </Tag>
  )
}

export default Text
