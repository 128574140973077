import React from 'react'
import { observer } from 'mobx-react'
import useLocalI18n from 'Hooks/LocalI18n'
import { Spinner } from 'Elements'
import styles from './styles.module.scss'

interface ContactDetailsProps {
  sso?: boolean
  email: string
  loading?: boolean
}

const ContactDetails = observer(
  ({ sso, email = '', loading }: ContactDetailsProps) => {
    const { I18n } = useLocalI18n('molecules/Settings/Lang')

    const onClick = (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.Beacon) {
        window.Beacon('open')
      } else if (document.getElementById('giki-help-button')) {
        document.getElementById('giki-help-button').click()
      } else {
        window.open('https://giki-social-enterprise.helpscoutdocs.com/')
      }
    }

    const DefaultMessage = () => {
      return (
        <>
          {I18n.t('settings.contact_details.disabled.other.prefix')}
          {` `}
          <a href="#" onClick={onClick}>
            {I18n.t('settings.contact_details.disabled.other.label')}
          </a>
          {` `}
          {I18n.t('settings.contact_details.disabled.other.suffix')}
        </>
      )
    }

    return (
      <div className={styles.ContactDetails}>
        {loading && <Spinner absolute />}
        <p className={styles.ContactDetails__disabled}>
          {sso ? (
            I18n.t('settings.contact_details.disabled.sso')
          ) : (
            <DefaultMessage />
          )}
        </p>
        <fieldset className="FormRow FormRow--bs">
          <div className="text-field__wrapper FormRow--column">
            <label htmlFor="email">
              {I18n.t('settings.contact_details.fields.email')}
            </label>
            <input
              className="text-field text-field--constrained"
              disabled
              readOnly
              id="email"
              type="email"
              value={email}
            />
          </div>
        </fieldset>
      </div>
    )
  }
)

export default ContactDetails
