import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonIcons } from 'Elements'
import * as styles from './styles.module.scss'

const GroupMembershipActions = ({
  allMembershipsSelected,
  anyMembershipSelected,
  membershipsPopulated,
  onDeleteSelected,
  onSelectAll,
  onSelectNone,
  onUpdateSelected
}) => {
  const { I18n } = useLocalI18n('molecules/GroupMembershipActions/Lang')

  return (
    <section className={styles.GroupMembershipActions}>
      <div className={styles.GroupMembershipActions__group}>
        <Button
          onClick={onSelectAll}
          ariaLabel={I18n.t('group_membership_actions.select_all')}
          icon={ButtonIcons.SelectAllIcon}
          large
          disabled={!membershipsPopulated || allMembershipsSelected}
        />
        <Button
          onClick={onSelectNone}
          ariaLabel={I18n.t('group_membership_actions.select_none')}
          icon={ButtonIcons.SelectNoneIcon}
          large
          disabled={!anyMembershipSelected}
        />
        <Button
          onClick={onUpdateSelected}
          ariaLabel={I18n.t('group_membership_actions.update_selected')}
          icon={ButtonIcons.PencilIcon}
          large
          disabled={!anyMembershipSelected}
        />
        <Button
          onClick={onDeleteSelected}
          ariaLabel={I18n.t('group_membership_actions.delete_selected')}
          icon={ButtonIcons.DeleteIcon}
          disabled={!anyMembershipSelected}
          large
        />
      </div>
    </section>
  )
}

GroupMembershipActions.defaultProps = {
  allMembershipsSelected: false,
  anyMembershipSelected: false,
  membershipsPopulated: false
}

GroupMembershipActions.propTypes = {
  allMembershipsSelected: PropTypes.bool,
  anyMembershipSelected: PropTypes.bool,
  membershipsPopulated: PropTypes.bool,
  onDeleteSelected: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSelectNone: PropTypes.func.isRequired,
  onUpdateSelected: PropTypes.func.isRequired
}

export default GroupMembershipActions
