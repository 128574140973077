export const getContainerWidthRem = (
  container: Window | Element = document.documentElement
) => {
  const _container =
    container === window ? document.documentElement : (container as Element)
  let _fontSizeRem = 16

  try {
    _fontSizeRem = parseFloat(
      window.getComputedStyle(_container, null).getPropertyValue('font-size')
    )
  } catch (e) {
    //
  }

  return _container.clientWidth / _fontSizeRem
}
