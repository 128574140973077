import React from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import * as styles from './styles.module.scss'
import classnames from 'classnames'

interface CategoryProps {
  category?: string
  read?: boolean
}

const Category = ({ category, read = false }: CategoryProps) => {
  const { I18n } = useLocalI18n('molecules/Notifications/Lang')

  const CATEGORIES = ['product_update', 'reminder']

  const COLOR_SCHEMES = {
    default: 'primary',
    product_update: 'primary',
    reminder: 'reminder'
  }

  const _category = CATEGORIES.includes(category) ? category : 'default'

  const _colorScheme = COLOR_SCHEMES[_category]

  const _label = I18n.t(`notifications.category.labels.${_category}`)

  return (
    <span
      className={classnames(
        styles.Category,
        styles[`Category--${_colorScheme as string}`],
        {
          [styles['Category--read']]: read,
          [styles['Category--unread']]: !read
        }
      )}>
      {_label}
    </span>
  )
}

export default Category
