import React, { useRef, createRef, useEffect, useState } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import DefaultContext from 'Contexts/default'
import { Avatar, Spinner } from 'Elements'
import { CSSTransition } from 'react-transition-group'
import { WORKPLACE, ManageAccountIcon, SignOutIcon, MailIcon } from 'Constants'
import ListItem from './ListItem'
import {
  SerializedOrganizationWithChildren,
  SerializedSuperGroupWithChildren
} from 'Stores/PersonStore/GroupMembershipsStore'
import * as styles from './styles.module.scss'

interface OrganizationsWithChildrenProps {
  items: SerializedOrganizationWithChildren[]
}

const OrganizationsWithChildren = observer(
  ({ items }: OrganizationsWithChildrenProps) => {
    return (
      <>
        {items.map((item) => {
          return (
            <ListItem
              key={item.id}
              current={item.meta?.current}
              image={item.attributes?.logo ?? WORKPLACE}
              label={item.attributes?.name}
              path={item.links?.html_progress}
              theme="secondary">
              {!!item.meta?.teams?.length && (
                <ul className={styles.AvatarMenu__sublist}>
                  {item.meta?.teams.map((team) => {
                    return (
                      <ListItem
                        key={team.id}
                        current={team.meta?.current}
                        image={team.attributes?.logo}
                        label={team.attributes.name}
                        path={team.links.html_progress}
                      />
                    )
                  })}
                </ul>
              )}
            </ListItem>
          )
        })}
      </>
    )
  }
)

interface SuperGroupsWithChildrenProps {
  items: SerializedSuperGroupWithChildren[]
}

const SuperGroupsWithChildren = observer(
  ({ items }: SuperGroupsWithChildrenProps) => {
    return (
      <>
        {items.map((item) => {
          return (
            <ListItem
              key={item.id}
              current={item.meta?.current}
              image={item.attributes?.logo ?? WORKPLACE}
              label={item.attributes?.name}
              path={item.links?.html_progress}
              theme="primary">
              <ul className={styles.AvatarMenu__sublist}>
                <OrganizationsWithChildren items={item.meta.organizations} />
              </ul>
            </ListItem>
          )
        })}
      </>
    )
  }
)

const AvatarMenu = observer(() => {
  const {
    applicationStore: { modalStore, personStore, uiStore, userStore }
  } = React.useContext(DefaultContext)

  const { groupMembershipsStore } = personStore

  const _buttonRef = useRef<HTMLButtonElement>(null)

  const _menuRef = createRef<HTMLDivElement>()

  const [_isToggled, _setIsToggled] = useState(false)

  const _displayAccountLink = uiStore?.accountItems?.editAccount?.path

  const _displaySignOutLink =
    uiStore.initialized && uiStore.accountItems?.logOut

  const _docClick = (evt: MouseEvent) => {
    if (
      _isToggled &&
      _menuRef.current &&
      !_menuRef.current.contains(evt.target as Node) &&
      _buttonRef.current &&
      !_buttonRef.current.contains(evt.target as Node)
    ) {
      _setIsToggled(false)
    }
  }

  const _docKeyup = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape' && _isToggled) {
      _setIsToggled(false)
      _buttonRef?.current?.focus()
    }
  }

  const _loadGroupMemberships = async () => {
    await groupMembershipsStore
      .init()
      .catch((err) => modalStore.handleError(err))
  }

  const _handleClick: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    _setIsToggled(!_isToggled)
  }

  useEffect(() => {
    document.addEventListener('click', _docClick)
    document.addEventListener('keyup', _docKeyup)

    return () => {
      document.removeEventListener('click', _docClick)
      document.removeEventListener('keyup', _docKeyup)
    }
  })

  useEffect(() => {
    if (_isToggled) void _loadGroupMemberships()
  }, [_isToggled])

  return (
    <div className={styles.AvatarMenu}>
      <div className={styles.AvatarMenu__icon}>
        {personStore.loaded && (
          <button
            data-tour-id="AvatarMenu"
            onClick={_handleClick}
            aria-expanded={_isToggled}
            ref={_buttonRef}>
            <Avatar
              firstName={personStore.attributes.first_name}
              imageSrc={personStore.attributes.avatar}
              lastName={personStore.attributes.last_name}
              size="small"
            />
          </button>
        )}
      </div>
      <CSSTransition
        classNames="fade-in"
        in={_isToggled}
        mountOnEnter
        timeout={400}
        unmountOnExit>
        <div className={styles.AvatarMenu__menu} ref={_menuRef}>
          {_displayAccountLink && (
            <a
              href={uiStore.accountItems.editAccount.path}
              aria-label={uiStore.accountItems.editAccount.label}
              title={uiStore.accountItems.editAccount.label}
              className={styles.AvatarMenu__manage}>
              <ManageAccountIcon />
            </a>
          )}
          <a
            href={uiStore?.accountItems?.invitations?.path}
            aria-label={uiStore?.accountItems?.invitations?.label}
            title={uiStore?.accountItems?.invitations?.label}
            className={styles.AvatarMenu__invite}>
            <MailIcon />
          </a>
          {_displaySignOutLink && (
            <a
              onClick={() => {
                userStore.reset()
              }}
              href={uiStore.accountItems.logOut.path}
              aria-label={uiStore.accountItems.logOut.label}
              title={uiStore.accountItems.logOut.label}
              className={styles.AvatarMenu__signout}
              data-turbolinks="false">
              <SignOutIcon />
            </a>
          )}
          <div className={styles.AvatarMenu__user}>
            <a
              href={uiStore?.accountItems?.dashboard?.path}
              className={styles.AvatarMenu__user__icon}>
              <Avatar
                firstName={personStore.attributes.first_name}
                imageSrc={personStore.attributes.avatar}
                lastName={personStore.attributes.last_name}
                size="small"
              />
            </a>
            <div
              className={styles.AvatarMenu__user__name}
              translate="no">{`${personStore.attributes.first_name} ${personStore.attributes.last_name}`}</div>
          </div>
          <ul className={styles.AvatarMenu__list}>
            {groupMembershipsStore.loading && (
              <li
                className={classnames(
                  styles.AvatarMenu__list__item,
                  styles['AvatarMenu__list__item--loading']
                )}>
                <Spinner centered />
              </li>
            )}
            {groupMembershipsStore.loaded && (
              <>
                <SuperGroupsWithChildren
                  items={groupMembershipsStore.publicSuperGroupsWithChildren}
                />
                <OrganizationsWithChildren
                  items={
                    groupMembershipsStore.organizationsWithoutPublicSuperGroups
                  }
                />
              </>
            )}
          </ul>
        </div>
      </CSSTransition>
    </div>
  )
})

export default AvatarMenu
