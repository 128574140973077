import React from 'react'

const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
    <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
      <path
        d="M12 14.25L12 23.25"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M15.75 19.5L12 23.25 8.25 19.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M16.5,15.75h1.875a4.875,4.875,0,1,0-2.764-8.885,7.5,7.5,0,1,0-8.149,8.844"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
    </g>
  </svg>
)

export default DownloadIcon
