import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'
import DefaultContext from 'Contexts/default'
import { NotificationsIcon } from 'Constants'
import useLocalI18n from 'Hooks/LocalI18n'
import * as styles from './styles.module.scss'
import Panel from './Panel'
import classnames from 'classnames'

const Menu = observer(() => {
  const { I18n } = useLocalI18n('molecules/Notifications/Lang')

  const {
    applicationStore: { modalStore, personStore, uiStore }
  } = React.useContext(DefaultContext)

  const [_animate, _setAnimate] = useState(false)

  const [_initialized, _setInitialized] = useState(false)

  const [_interval, _setInterval] = useState(null)

  const [_unreadCount, _setUnreadCount] = useState(0)

  const _buttonRef = useRef(null)

  const _containerRef = useRef(null)

  const _menuRef = useRef(null)

  const _onMarkItemsRead = async (notificationIds: string | string[]) => {
    try {
      await personStore.notificationsStore.markItemsRead(notificationIds)
    } catch (err) {
      modalStore.handleError(err)
    }
  }

  const _onMarkItemsUnread = async (notificationIds: string | string[]) => {
    try {
      await personStore.notificationsStore.markItemsUnread(notificationIds)
    } catch (err) {
      modalStore.handleError(err)
    }
  }

  const _runAnimation = () => {
    if (_interval) {
      window.clearInterval(_interval)
      _setInterval(null)
    }

    _setAnimate(true)

    _setInterval(
      window.setInterval(() => {
        _setAnimate(false)
      }, 1000)
    )
  }

  const _processUnreadCount = (newCount: number) => {
    if (_initialized && newCount > _unreadCount) {
      _runAnimation()
    }

    _setUnreadCount(newCount)

    if (!_initialized) {
      _setInitialized(true)
    }
  }

  const _docClick = (e: MouseEvent) => {
    const _button = document.getElementById('notifications-button')

    if (
      uiStore.displayNotificationsMenu &&
      _menuRef.current &&
      !_menuRef.current.contains(e.target as Node) &&
      _button &&
      !_button.contains(e.target as Node)
    ) {
      uiStore.toggleNotifications()
    }
  }

  const _docKeyup = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && uiStore.displayNotificationsMenu) {
      uiStore.toggleNotifications()
      _buttonRef?.current?.focus()
    }
  }

  const _unreadCountForDisplay = () => {
    return personStore.notificationsStore.unread.count < 10
      ? personStore.notificationsStore.unread.count
      : '9+'
  }

  useEffect(() => {
    _processUnreadCount(personStore.notificationsStore.unread.count)
  }, [personStore.notificationsStore.unread.count])

  useEffect(() => {
    document.addEventListener('click', _docClick)
    document.addEventListener('keyup', _docKeyup)

    return () => {
      document.removeEventListener('click', _docClick)
      document.removeEventListener('keyup', _docKeyup)
    }
  })

  return (
    <div className={styles.Menu} ref={_containerRef}>
      <button
        className={classnames(styles.Button, styles['Button--icon'])}
        onClick={uiStore.toggleNotifications}
        ref={_buttonRef}
        title={I18n.t('notifications.menu.title')}
        id="notifications-button">
        <span
          className={classnames(styles.Button__icon, {
            [styles['Button__icon--ring']]: _animate
          })}>
          <NotificationsIcon />
        </span>
        <CSSTransition
          in={personStore.notificationsStore.anyUnread}
          classNames="fade-in"
          mountOnEnter
          timeout={400}
          unmountOnExit>
          <div className={styles.Button__indicator}>
            {_unreadCountForDisplay()}
          </div>
        </CSSTransition>
      </button>
      <Panel
        buttonRef={_buttonRef}
        containerRef={_containerRef}
        display={uiStore.displayNotificationsMenu}
        loaded={personStore.notificationsStore.unread.loaded}
        loading={personStore.notificationsStore.unread.loading}
        menuRef={_menuRef}
        notifications={personStore.notificationsStore.unread.items}
        onMarkItemsRead={_onMarkItemsRead}
        onMarkItemsUnread={_onMarkItemsUnread}
        personId={personStore.id}
        unreadCount={personStore.notificationsStore.unread.count}
      />
    </div>
  )
})

export default Menu
