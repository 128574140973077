export const removeNavigationIf = (items, setting, toRemove) => {
  if (!setting) {
    const _itemIndex = items.findIndex(({ path }) => path === toRemove)
    items.splice(_itemIndex, 1)
  }
}

export const navigationItem = (label, path, inclusive = false) => {
  return {
    label,
    path,
    inclusive
  }
}

export const safelyNavigate = (path: string) => {
  // open different origin in a new window / tab
  if (path.toLowerCase().startsWith('http')) {
    const _url = new URL(path)

    if (_url.origin !== window.location.origin) {
      window.open(path, '_blank')
      return
    }
  }

  // If Turbolinks is running, then we should use this for navigation changes
  // to preserve store references and avoid reloading data. There's no reason
  // why it should not be running, but worth a little safety check!
  if (window.Turbolinks) {
    window.Turbolinks.visit(path)
  } else {
    window.location.assign(path)
  }
}
