import useLocalI18n from 'Hooks/LocalI18n'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonIcons } from 'Elements'
import * as styles from './styles.module.scss'

const Search = ({ label, onSearch, placeholder }) => {
  const { I18n } = useLocalI18n('elements/Search/Lang')

  const [_currentSearchString, _setCurrentSearchString] = useState('')
  const [_previousSearchString, _setPreviousSearchString] = useState('')

  const _onClear = () => {
    _setCurrentSearchString('')
    _setPreviousSearchString('')
    onSearch({ value: '' })
  }

  const _onInputChange = (ev) => _setCurrentSearchString(ev.target.value)

  const _onSubmit = (ev) => {
    ev.preventDefault()
    onSearch({ value: _currentSearchString })
    _setPreviousSearchString(_currentSearchString)
  }

  const _currentSearchPopulated = _currentSearchString.length > 0
  const _previousSearchPopulated = _previousSearchString.length > 0

  const _id =
    typeof jest !== 'undefined' ? 'search-input' : `search-${Date.now()}`

  return (
    <form className={styles.Form} onSubmit={_onSubmit}>
      <label htmlFor={_id}>{label}</label>
      <input
        className={styles.Input}
        id={_id}
        onChange={_onInputChange}
        placeholder={placeholder}
        type="text"
        value={_currentSearchString}
      />
      <div className={styles.Form__actions}>
        <Button
          ariaLabel={I18n.t('search.search')}
          icon={ButtonIcons.SearchIcon}
          disabled={!_currentSearchPopulated}
          type="submit"
        />
        <Button
          onClick={_onClear}
          ariaLabel={I18n.t('search.clear')}
          icon={ButtonIcons.ResetIcon}
          disabled={!_currentSearchPopulated && !_previousSearchPopulated}
        />
      </div>
    </form>
  )
}

Search.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
}

export default Search
