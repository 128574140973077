import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import FootstepsIcon from 'Images/icons/FootstepsIcon'
import CompleteIcon from 'Images/icons/CompleteIcon'
import PendingIcon from 'Images/icons/PendingIcon'
import * as styles from './styles.module.scss'

interface DataItemPieProps {
  value: number
}

const DataItemPie = ({ value }: DataItemPieProps) => {
  const _strokeWidth = 8
  const _circleWidth = 32

  const _halfCircleWidth = _circleWidth / 2
  const _radius = _halfCircleWidth - _strokeWidth / 2
  const _cir = 2 * Math.PI * _radius
  const _dashValue = _cir * (value / 100)
  const _dashRemainder = _cir * ((100 - value) / 100)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <g>
        <circle
          className={styles.DataItemPie__bg}
          cy={_halfCircleWidth}
          cx={_halfCircleWidth}
          r={_radius}
          strokeWidth={_strokeWidth}
          stroke="currentColor"
          fill="none"
        />
        <circle
          className={styles.DataItemPie__fg}
          cy={_halfCircleWidth}
          cx={_halfCircleWidth}
          r={_radius}
          strokeWidth={_strokeWidth}
          strokeDasharray={`${_dashValue} ${_dashRemainder}`}
          strokeDashoffset={0}
          stroke="currentColor"
          fill="none"
          style={{
            transform: `rotate(${-90}deg)`,
            transformOrigin: '50% 50%'
          }}
        />
      </g>
    </svg>
  )
}

interface DataItemIconProps {
  type: string
  value: number
}

const DataItemIcon = ({ type, value }: DataItemIconProps) => {
  switch (type) {
    case 'carbon':
      return <FootstepsIcon />
    case 'footprint':
      return <DataItemPie value={Math.abs(value)} />
    case 'actions_complete':
      return <CompleteIcon />
    case 'actions_pending':
      return <PendingIcon />
  }
}

interface DataItemProps {
  micro?: boolean
  type: string
  value: number
}

const DataItem = observer(({ micro = false, type, value }: DataItemProps) => {
  const { I18n } = useLocalI18n('elements/DataItem/Lang')

  let _isPositive = false

  switch (type) {
    case 'carbon':
    case 'footprint':
      if (value < 0) return null
      break
    case 'actions_complete':
    case 'actions_pending':
      _isPositive = value > 0
      break
  }

  return (
    <div
      className={classnames(styles.DataItem, {
        [styles['DataItem--pending']]: type === 'actions_pending',
        [styles['DataItem--positive']]: _isPositive,
        [styles['DataItem--micro']]: micro
      })}>
      <div className={styles.DataItem__icon}>
        <DataItemIcon type={type} value={value} />
      </div>
      <div className={styles.DataItem__content}>
        <div className={styles.DataItem__value}>
          {I18n.toNumber(value, { precision: 0 })}
          {!micro && (
            <span className={styles.DataItem__value__unit}>
              {type === 'steps' && value === 1
                ? I18n.t(`data_item.${type}.unit_single`)
                : I18n.t(`data_item.${type}.unit`)}
            </span>
          )}
        </div>
        {!micro && (
          <div className={styles.DataItem__label}>
            {I18n.t(`data_item.${type}.label`)}
          </div>
        )}
      </div>
    </div>
  )
})

export default DataItem
