import { computed, IObservableArray, observable } from 'mobx'
import { APIRoutes } from 'API'
import {
  CAMPAIGN_CONTEXTS,
  CAMPAIGN_TYPES,
  SerializedCampaign
} from 'Constants'
import { PaginatedStore } from 'Stores/Base'
import CampaignsStore from '.'

class TypeStore extends PaginatedStore {
  public context: string
  public type: string
  @observable items: IObservableArray<SerializedCampaign>

  constructor(public rootStore: CampaignsStore, context: string, type: string) {
    super()
    this.rootStore = rootStore
    this.context = context
    this.type = type
    this.reset()
  }

  @computed
  get _itemsRoute() {
    return `${APIRoutes.campaigns()}?${this._itemsRouteParams}`
  }

  @computed
  get _itemsRouteParams() {
    const params = [`template=${this.type === CAMPAIGN_TYPES.TEMPLATE}`]

    if (this.context === CAMPAIGN_CONTEXTS.ORGANIZATION) {
      if (this.type === CAMPAIGN_TYPES.STANDARD) {
        params.push(`organization_id=${this.rootStore.organizationId}`)
      }

      if (this.type === CAMPAIGN_TYPES.TEMPLATE) {
        params.push('public=true')
      }
    }

    return params.join('&')
  }
}

export default TypeStore
