import React from 'react'
import { API } from 'API'
import { Button, ButtonIcons, Tooltip } from 'Elements'
import * as styles from './styles.module.scss'
import classnames from 'classnames'

export enum DownloadType {
  link,
  alternating
}

interface DownloadButtonProps {
  label: string
  id?: string
  path: string
  onError: (error: string) => void
  onSuccess: () => void
  type?: DownloadType
}

export const DownloadButton = ({
  label,
  id,
  path,
  onError,
  onSuccess,
  type = DownloadType.link
}: DownloadButtonProps) => {
  const _onClick = async () => {
    try {
      await API.post(path)
      onSuccess()
    } catch (err) {
      onError(err)
    }
  }

  if (type === DownloadType.alternating) {
    return (
      <Button
        ariaLabel={label}
        label={label}
        onClick={_onClick}
        color="brand"
        icon={ButtonIcons.DownloadIcon}
      />
    )
  }

  return (
    <Button
      ariaLabel={label}
      icon={ButtonIcons.DownloadIcon}
      large
      onClick={_onClick}
    />
  )
}

interface DownloadProps {
  buttonLabel: string
  description?: string
  id?: string
  path: string
  onError: (error: string) => void
  onSuccess: () => void
  title: string
  image?: string
  type?: DownloadType
  index?: number
}

export const Download = ({
  buttonLabel,
  description,
  id,
  path,
  onError,
  onSuccess,
  title,
  image,
  type = DownloadType.link,
  index
}: DownloadProps) => {

  if (type === DownloadType.alternating) {
    const _isOdd = index % 2 === 0

    return (
      <div className={classnames(styles.DownloadAlternating, _isOdd && styles['DownloadAlternating--odd'])}>
        {image && <div className={styles.DownloadAlternating__image}><img src={image} alt="" /></div>}
        <div className={styles.DownloadAlternating__content}>
          <h4 className={styles.DownloadAlternating__title}>{title}</h4>
          <p className={styles.DownloadAlternating__description}>{description}</p>
          <DownloadButton
            label={buttonLabel}
            id={id}
            path={path}
            onError={onError}
            onSuccess={onSuccess}
            type={type}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.DownloadLink}>
      <span className={styles.DownloadLink__title}>{title}</span>
      {description && (
        <span className={styles.DownloadLink__description}>
          <Tooltip inline text={description} label={title} />
        </span>
      )}
      <span className={styles.DownloadLink__actions}>
        <DownloadButton
          label={buttonLabel}
          id={id}
          path={path}
          onError={onError}
          onSuccess={onSuccess}
        />
      </span>
    </div>
  )
}