import React from 'react'

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
    <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
      <path
        d="M4.438 9.602 A5.164 5.164 0 1 0 14.766 9.602 A5.164 5.164 0 1 0 4.438 9.602 Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M17.029,14.421,22.71,20.1A1.844,1.844,0,0,1,20.1,22.709l-5.68-5.68h0a8.855,8.855,0,1,1,2.607-2.608Z"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
    </g>
  </svg>
)

export default SearchIcon
