import React from 'react'
import {  
  DefaultConfiguration,
  ConfigurationContextProvider as Provider } from 'clarity'

export const ConfigurationContextProvider = ({ children }) => {
  /* 
   * Note that the API Namespace is inherited from the default 
   * config, and thus comes from Clarity right now.
   */
  const config = {
    ...DefaultConfiguration,
    BaseUrl: `//${window.location.host}`,
    ClientApplication: "zero"
  }

  return (
    <>
      <Provider value={config}>
        {children}
      </Provider>
    </>
  )
}
