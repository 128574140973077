import React from 'react'
import { observer } from 'mobx-react'
import { Spinner } from 'Elements'
import {
  SerializedEmailList,
  SerializedUsersEmailsListsSubscription
} from 'Constants'
import useLocalI18n from 'Hooks/LocalI18n'
import Item from './Item'
import styles from './styles.module.scss'

interface SubscriptionsProps {
  lists: SerializedEmailList[]
  loading?: boolean
  loaded?: boolean
  subscriptions: SerializedUsersEmailsListsSubscription[]
  onChange: (key: string, value: string | number | boolean) => void
}

const Subscriptions = observer(
  ({
    lists,
    loaded,
    loading = false,
    subscriptions,
    onChange
  }: SubscriptionsProps) => {
    const { I18n } = useLocalI18n('molecules/Communications/Lang')

    const findEmailListSubscriptionByListId = (listId: string) => {
      return subscriptions.find((subscription) => {
        return subscription?.relationships?.email_list?.data?.id === listId
      })
    }

    if (!loaded || loading) {
      return (
        <div className={styles.Subscriptions}>
          <Spinner centered />
        </div>
      )
    }

    return (
      <ul className={styles.Subscriptions}>
        {lists.map((list) => {
          const _subscription = findEmailListSubscriptionByListId(list.id)
          return (
            <Item
              description={I18n.t(
                'communications.subscriptions.default.description'
              )}
              id={list.id}
              key={list.id}
              onChange={onChange}
              subscribed={!!_subscription}
              title={list.attributes.name}
            />
          )
        })}
      </ul>
    )
  }
)

export default Subscriptions
