import { action, computed, observable, IObservableArray } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import OrganizationStore from './index'
import { PaginatedStore } from '../Base'
import { SerializedTeam } from 'Constants'

export class Teams extends PaginatedStore {
  @observable public items: IObservableArray<SerializedTeam>

  constructor(public rootStore: OrganizationStore) {
    super()
    this._itemType = 'team'
    this.rootStore = rootStore
  }

  @action
  batchCreateItems = async (names) => {
    try {
      const route = APIRoutes.organizations.teams.batch.create(
        this.rootStore.id
      )
      const { data } = await API.post(route, { batch: { names } })
      this.receiveItems(data.data)
      this.loaded = true
      return data
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @action
  fetchAllRemainingItems = async () => {
    try {
      if (!this.pagination.next) return await Promise.resolve()

      await this.fetchItems({ number: this.pagination.next })

      return this.fetchAllRemainingItems()
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  @computed
  get _itemsRoute(): string {
    return APIRoutes.organizations.teams.all(this.rootStore.id)
  }

  _itemRoute = (itemId: string) => {
    return APIRoutes.team(itemId)
  }
}

export default Teams
