import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import PropTypes from 'prop-types'
import { Search, Selector } from 'Elements'
import * as styles from './styles.module.scss'
import { FILTER_STATES } from './filters'

const GroupMembershipFilters = ({ onFilterUpdate }) => {
  const { I18n } = useLocalI18n('molecules/GroupMembershipFilters/Lang')

  const _onQueryChange = (state) => onFilterUpdate('query', state.value)

  const _onRoleChange = (state) => onFilterUpdate('admin', state.value)

  return (
    <section className={styles.GroupMembershipFilters}>
      <div className={styles.GroupMembershipFilters__filter}>
        <label htmlFor="role-select">
          {I18n.t('group_membership_filters.role')}
        </label>
        <Selector
          inputId="role-select"
          isSmall
          onChange={_onRoleChange}
          options={FILTER_STATES}
        />
      </div>
      <div className={styles.GroupMembershipFilters__filter}>
        <Search
          onSearch={_onQueryChange}
          placeholder={I18n.t('group_membership_filters.query.placeholder')}
          label={I18n.t('group_membership_filters.query.label')}
        />
      </div>
    </section>
  )
}

GroupMembershipFilters.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired
}

export default GroupMembershipFilters
