import React, { useState } from 'react'
import useLocalI18n from 'Hooks/LocalI18n'

const FIELDS = ['title', 'description']

const mapAttributes = (inputState: Record<string, unknown>, locale: string) => {
  const entries = FIELDS.map((field) => {
    const key = `${field}_${locale}`
    return [key, inputState[key]]
  })
  return Object.fromEntries(entries)
}

interface TabProps {
  disabled?: boolean
  locale: string
  inputState: Record<string, unknown>
  onChange: (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  visible: boolean
}

const Tab = ({
  disabled = false,
  inputState,
  locale,
  onChange,
  visible
}: TabProps) => {
  const { I18n } = useLocalI18n('molecules/Campaigns/Settings/Lang')

  const [_inputState, _setInputState] = useState(
    mapAttributes(inputState, locale)
  )

  const _isRequired = locale === 'en'

  const _onChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = ev.currentTarget
    _setInputState((prevState) => ({
      ...prevState,
      [name]: value
    }))
    onChange(ev)
  }

  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <div className="form-row form-row--inline-center form-row--column">
        <label htmlFor={`title_${locale}`}>
          {I18n.t('campaigns.settings.fields.title.label')}
        </label>
        <input
          className="text-field text-field--constrained text-field--bs"
          disabled={disabled}
          id={`title_${locale}`}
          name={`title_${locale}`}
          onChange={_onChange}
          placeholder={I18n.t('campaigns.settings.fields.title.placeholder')}
          required={_isRequired}
          type="text"
          value={_inputState[`title_${locale}`]}
        />
      </div>
      <div className="form-row form-row--inline-center form-row--column">
        <label htmlFor={`description_${locale}`}>
          {I18n.t('campaigns.settings.fields.description.label')}
        </label>
        <textarea
          className="textarea textarea--bs"
          disabled={disabled}
          id={`description_${locale}`}
          name={`description_${locale}`}
          onChange={_onChange}
          placeholder={I18n.t('campaigns.settings.fields.description.placeholder')}
          required={_isRequired}
          rows={4}
          value={_inputState[`description_${locale}`]}
        />
      </div>
    </div>
  )
}

export default Tab
