import { Tooltip } from 'Elements'
import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'

interface PublicTransportJourneyProps {
  fields: {
    quantity: {
      default: number
      maximum: Record<string, unknown>
    }
    distance?: {
      default: number
      maximum?: number
    }
    chosenTimePeriod?: {
      available: string[]
      default: string
    }
    chosenUnits: {
      available: string[]
      default: string
    }
    name: {
      default: string
    }
    tripType: {
      available: string[]
      default: string
    }
  }
  index?: number
  modelType: 'estimate' | 'journey'
  parentModel: string
  values: {
    quantity?: number
    distance?: number
    chosenTimePeriod?: string
    chosenUnits?: string
    name?: string
    tripType?: string
  }
  allowAdditionalItems?: boolean
}

const PublicTransportJourney = ({
  fields,
  index = 0,
  parentModel,
  modelType,
  values,
  allowAdditionalItems = false
}: PublicTransportJourneyProps) => {
  const { I18n } = useLocalI18n('molecules/PublicTransportJourneys/Lang')

  const _getFieldValue: (
    fieldName: string,
    fallback?: number | string
  ) => string | number = (fieldName, fallback) => {
    return values[fieldName] || fields[fieldName]?.default || fallback
  }

  const _getFieldOptions: (fieldName: string) => string[] = (fieldName) => {
    return fields[fieldName]?.available || []
  }

  const [_destroy, _setDestroy] = useState(false)

  const [_distance, _setDistance] = useState(_getFieldValue('distance', 1))

  const [_name, _setName] = useState(_getFieldValue('name', ''))

  const [_quantity, _setQuantity] = useState(_getFieldValue('quantity', 1))

  const [_maximumQuantity, _setMaximumQuantity] = useState(0)

  const [_chosenUnit, _setChosenUnit] = useState(
    _getFieldValue('chosenUnit', 'km')
  )

  const [_chosenTimePeriod, _setChosenTimePeriod] = useState(
    _getFieldValue('chosenTimePeriod', 'day')
  )

  const [_tripType, _setTripType] = useState(
    _getFieldValue('tripType', 'single')
  )

  const _availableChosenTimePeriods = _getFieldOptions('chosenTimePeriod')

  const _availableChosenUnits = _getFieldOptions('chosenUnits')

  const _availableTripTypes = _getFieldOptions('tripType')

  const _computeAndSetMaximumQuantity = () => {
    const _maximumForChosenTimePeriod = (fields.quantity.maximum[
      _chosenTimePeriod
    ] || 100) as number

    _setMaximumQuantity(_maximumForChosenTimePeriod)
  }

  useEffect(() => {
    _computeAndSetMaximumQuantity()
  }, [_chosenTimePeriod])

  return (
    <div
      className="public_transport_row"
      data-test-id={`${parentModel}_${modelType}`}>
      <input
        id={`${parentModel}_${modelType}s_attributes_${index}__destroy`}
        name={`${parentModel}[${modelType}s_attributes][${index}][_destroy]`}
        type="hidden"
        value={_destroy.toString()}
      />
      {!_destroy && (
        <div className="public_transport_row__content footprint-wizard__row footprint-wizard__row--flex">
          {allowAdditionalItems && (<button
            className="close-button close-button--remove close-button--ts"
            onClick={() => _setDestroy(true)}
            type="button"
          />)}
          <div className="footprint-wizard__col">
            {fields.name && (
              <div className="text-field__wrapper">
                <div className="form-row form-row--inline-center form-row--bs form-row--column">
                  <div className="form-row--bs--small">
                    <label
                      htmlFor={`${parentModel}_${modelType}s_attributes_${index}_name`}
                      aria-label={I18n.t(
                        'public_transport_journey.name.label'
                      )}>
                      {I18n.t('public_transport_journey.name.label')}
                      {' '}
                      <Tooltip
                        inline
                        text={I18n.t('public_transport_journey.name.tooltip')}
                        label={I18n.t('public_transport_journey.name.label')}
                      />
                    </label>
                  </div>
                  <input
                    className="text-field"
                    type="text"
                    name={`${parentModel}[${modelType}s_attributes][${index}][name]`}
                    id={`${parentModel}_${modelType}s_attributes_${index}_name`}
                    value={_name}
                    onChange={(ev) => _setName(ev.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="text-field__wrapper">
              <div className="form-row form-row--bs form-row--column">
                <label
                  htmlFor={`${parentModel}_${modelType}s_attributes_${index}_quantity`}>
                  {I18n.t(`public_transport_journey.${modelType}.label`)}
                </label>
                <input
                  className="text-field text-field--no-arrows"
                  inputMode="numeric"
                  id={`${parentModel}_${modelType}s_attributes_${index}_quantity`}
                  min="0"
                  max={_maximumQuantity}
                  name={`${parentModel}[${modelType}s_attributes][${index}][quantity]`}
                  required
                  step="1"
                  type="number"
                  value={_quantity}
                  onChange={(ev) => _setQuantity(ev.target.value)}
                />
              </div>
            </div>
            {fields.tripType && (
              <div className="radio-group form-row--bs">
                {_availableTripTypes.map((tripType) => {
                  return (
                    <label
                      className="radio-pill radio-pill--small"
                      htmlFor={`${parentModel}_${modelType}s_attributes_${index}_trip_type_${tripType}`}
                      key={tripType}>
                      <input
                        checked={_tripType === tripType}
                        className="radio-pill__control"
                        id={`${parentModel}_${modelType}s_attributes_${index}_trip_type_${tripType}`}
                        name={`${parentModel}[${modelType}s_attributes][${index}][trip_type]`}
                        onChange={() => _setTripType(tripType)}
                        type="radio"
                        value={tripType}
                      />
                      <span className="radio-pill__label radio-pill--small">
                        {I18n.t(
                          `public_transport_journey.trip_type.${tripType}`
                        )}
                      </span>
                    </label>
                  )
                })}
              </div>
            )}
            {fields.chosenTimePeriod && (
              <div className="footprint-wizard__subsection radio-group">
                <h4 className="h5 h--bold h--upper">
                  {I18n.t('public_transport_journey.chosen_time_period.label')}
                </h4>
                {_availableChosenTimePeriods.map((timePeriod) => {
                  return (
                    <label
                      className="radio-pill radio-pill--small"
                      htmlFor={`${parentModel}_${modelType}s_attributes_${index}_chosen_time_period_${timePeriod}`}
                      key={timePeriod}>
                      <input
                        checked={_chosenTimePeriod === timePeriod}
                        className="radio-pill__control"
                        id={`${parentModel}_${modelType}s_attributes_${index}_chosen_time_period_${timePeriod}`}
                        name={`${parentModel}[${modelType}s_attributes][${index}][chosen_time_period]`}
                        onChange={() => _setChosenTimePeriod(timePeriod)}
                        type="radio"
                        value={timePeriod}
                      />
                      <span className="radio-pill__label">
                        {I18n.t(
                          `public_transport_journey.chosen_time_period.${timePeriod}`
                        )}
                      </span>
                    </label>
                  )
                })}
              </div>
            )}
            {fields.distance && (
              <div className="text-field__wrapper">
                <div className="form-row form-row--inline-center form-row--bs form-row--column">
                  <label
                    htmlFor={`${parentModel}_${modelType}s_attributes_${index}_distance`}>
                    {I18n.t('public_transport_journey.distance')}
                  </label>
                  <input
                    className="text-field text-field--no-arrows"
                    id={`${parentModel}_${modelType}s_attributes_${index}_distance`}
                    inputMode="decimal"
                    min="0"
                    max={
                      typeof fields.distance === 'object'
                        ? fields.distance.maximum
                        : 100
                    }
                    name={`${parentModel}[${modelType}s_attributes][${index}][distance]`}
                    required
                    type="number"
                    value={_distance}
                    onChange={(ev) => _setDistance(ev.target.value)}
                  />
                </div>
              </div>
            )}
            {fields.chosenUnits && (
              <div className="radio-group">
                {_availableChosenUnits.map((chosenUnit) => {
                  return (
                    <label
                      className="radio-pill radio-pill--small"
                      htmlFor={`${parentModel}_${modelType}s_attributes_${index}_chosen_units_${chosenUnit}`}
                      key={chosenUnit}>
                      <input
                        checked={_chosenUnit === chosenUnit}
                        className="radio-pill__control"
                        id={`${parentModel}_${modelType}s_attributes_${index}_chosen_units_${chosenUnit}`}
                        name={`${parentModel}[${modelType}s_attributes][${index}][chosen_units]`}
                        onChange={() => _setChosenUnit(chosenUnit)}
                        type="radio"
                        value={chosenUnit}
                      />
                      <span className="radio-pill__label radio-pill--small">
                        {I18n.t(
                          `public_transport_journey.chosen_units.${chosenUnit}`
                        )}
                      </span>
                    </label>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

interface PublicTransportJourneysProps {
  allowAdditionalItems?: boolean
  fields: {
    quantity: {
      default: number
      maximum: Record<string, unknown>
    }
    distance?: {
      default: number
      maximum?: number
    }
    chosenTimePeriod?: {
      available: string[]
      default: string
    }
    chosenUnits: {
      available: string[]
      default: string
    }
    name: {
      default: string
    }
    tripType: {
      available: string[]
      default: string
    }
  }
  items: Array<{
    quantity?: number
    distance?: number
    chosenTimePeriod?: string
    chosenUnits?: string
    name?: string
    tripType?: string
  }>
  modelType: 'estimate' | 'journey'
  parentModel: string
}

const PublicTransportJourneys = ({
  allowAdditionalItems = false,
  fields,
  items,
  modelType,
  parentModel
}: PublicTransportJourneysProps) => {
  const { I18n } = useLocalI18n('molecules/PublicTransportJourneys/Lang')

  const [_items, _setItems] = useState(items)

  const _addItem = () => {
    const _newItemEntries = Object.entries(fields).map(
      ([fieldName, options]) => {
        return [fieldName, options?.default]
      }
    )

    const _newItem = Object.fromEntries(_newItemEntries)

    _setItems([..._items, _newItem])
  }

  return (
    <div>
      {_items.map((item, index) => {
        return (
          <PublicTransportJourney
            fields={fields}
            index={index}
            key={index}
            modelType={modelType}
            parentModel={parentModel}
            values={item}
            allowAdditionalItems={allowAdditionalItems}
          />
        )
      })}
      {allowAdditionalItems && (
        <button
          className="add-fields button footprint-wizard-actions button--add"
          onClick={_addItem}
          type="button">
          {I18n.t(`public_transport_journeys.${modelType}.add`)}
        </button>
      )}
    </div>
  )
}

export default PublicTransportJourneys
