import { action, computed, IObservableArray, observable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { PaginatedStore } from 'Stores/Base'
import SuperGroupStore from './index'
import { SerializedOrganization } from 'Constants'

export const ITEM_ROLES = {
  EXCLUDED: 'excluded',
  INCLUDED: 'included'
}

export class OrganizationsStore extends PaginatedStore {
  public role: string
  @observable public items: IObservableArray<SerializedOrganization>

  constructor(public rootStore: SuperGroupStore, role: string) {
    super()
    this.rootStore = rootStore
    this.role = role
  }

  @action
  excludeItem = async (itemId: string) => {
    if (this.role !== ITEM_ROLES.INCLUDED) return

    try {
      const route = this._excludeRoute(itemId)
      const { data } = await API.post(route)
      this.rootStore[ITEM_ROLES.EXCLUDED].receiveItems([data.data])
      const loadedAction = this.getItem(itemId)
      this.items.remove(loadedAction)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  _excludeRoute(itemId: string) {
    return APIRoutes.superGroups.organizations.exclude(
      this.rootStore.id,
      itemId
    )
  }

  @action
  includeItem = async (itemId: string) => {
    if (this.role !== ITEM_ROLES.EXCLUDED) return

    try {
      const route = this._includeRoute(itemId)
      const { data } = await API.post(route)
      this.rootStore[ITEM_ROLES.INCLUDED].receiveItems([data.data])
      const loadedAction = this.getItem(itemId)
      this.items.remove(loadedAction)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  _includeRoute(itemId: string) {
    return APIRoutes.superGroups.organizations.include(
      this.rootStore.id,
      itemId
    )
  }

  @computed
  get _itemsRoute() {
    return APIRoutes.superGroups.organizations[this.role](this.rootStore.id)
  }
}

export default OrganizationsStore
