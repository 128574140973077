/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { DateRange } from 'Stores/Common/GroupSnapshotStore'

const V1_API_PREFIX = '/api/v1'

const V2_API_PREFIX = '/api/v2'

export default {
  action: (actionParam) => `${V1_API_PREFIX}/actions/${actionParam}`,
  actions: {
    stat: (actionParam, statParam) =>
      `${V1_API_PREFIX}/actions/${actionParam}/stats/${statParam}`
  },
  admin: {
    organization: (organizationId) =>
      `${V1_API_PREFIX}/organizations/${organizationId}`,
    organization_action: (id) => `${V2_API_PREFIX}/organization_actions/${id}`,
    organization_actions: () => `${V2_API_PREFIX}/organization_actions`,
    organizations: () => `${V1_API_PREFIX}/organizations`,
    user: (userId) => `${V1_API_PREFIX}/users/${userId}`,
    users: () => `${V1_API_PREFIX}/users`
  },
  announcements: () => `${V2_API_PREFIX}/announcements`,
  campaign: {
    action: {
      exclude: (campaignId, actionId) => {
        return `${V1_API_PREFIX}/campaigns/${campaignId}/actions/${actionId}/exclude`
      },
      include: (campaignId, actionId) => {
        return `${V1_API_PREFIX}/campaigns/${campaignId}/actions/${actionId}/include`
      }
    },
    actions: {
      excluded: (campaignId) => {
        return `${V1_API_PREFIX}/campaigns/${campaignId}/actions/excluded`
      },
      included: (campaignId) => {
        return `${V1_API_PREFIX}/campaigns/${campaignId}/actions/included`
      }
    },
    show: (campaignId) => `${V1_API_PREFIX}/campaigns/${campaignId}`,
    target: (campaignId, targetId) =>
      `${V1_API_PREFIX}/campaigns/${campaignId}/targets/${targetId}`
  },
  campaigns: () => `${V1_API_PREFIX}/campaigns`,
  organization: (organizationId) =>
    `${V1_API_PREFIX}/organizations/${organizationId}`,
  organizations: {
    benchmarks: (organizationId) =>
      `${V1_API_PREFIX}/organizations/${organizationId}/benchmarks`,
    report: (organizationId, report, dateRange: DateRange = null) => {
      const _sanitizedReport = report.replace(/_/g, '-')

      const basicPath = `${V1_API_PREFIX}/organizations/${organizationId}/reports/${_sanitizedReport}/enqueue`

      if (!dateRange) return basicPath

      return `${basicPath}?date_range=${dateRange}`
    },
    settings: (organizationId) =>
      `${V1_API_PREFIX}/organizations/${organizationId}/settings`,
    snapshots: (organizationId, dateRange: DateRange) => {
      return `${V1_API_PREFIX}/organizations/${organizationId}/snapshots?date_range=${dateRange}`
    },
    teams: {
      all: (organizationId) =>
        `${V1_API_PREFIX}/organizations/${organizationId}/teams`,
      batch: {
        create: (organizationId) =>
          `${V1_API_PREFIX}/organizations/${organizationId}/teams/batch`
      }
    },
    public_teams: {
      all: (organizationId) =>
        `${V1_API_PREFIX}/organizations/${organizationId}/public-teams`,
      join: (organizationId, teamId) =>
        `/organizations/${organizationId}/public_teams/${teamId}/join`
    }
  },
  people: {
    read: (personId) => `${V1_API_PREFIX}/people/${personId}`,
    update: (personId) => `${V1_API_PREFIX}/people/${personId}`
  },
  person: {
    action: (personId, actionId) =>
      `${V1_API_PREFIX}/people/${personId}/actions/${actionId}`,
    actions: (personId) => `${V1_API_PREFIX}/people/${personId}/actions`,
    checklist_items: {
      complete: (personId, checklistItemId) =>
        `${V1_API_PREFIX}/people/${personId}/checklist-items/${checklistItemId}/complete`,
      uncomplete: (personId, checklistItemId) =>
        `${V1_API_PREFIX}/people/${personId}/checklist-items/${checklistItemId}/uncomplete`
    },
    campaigns: {
      actions: (personId, campaignId) => {
        return `${V1_API_PREFIX}/people/${personId}/campaigns/${campaignId}/actions`
      },
      show: (personId, timezone) => {
        return `${V1_API_PREFIX}/people/${personId}/campaigns?timezone=${timezone}`
      }
    },
    commitment: (personId, commitmentId) => {
      return `${V1_API_PREFIX}/people/${personId}/commitments/${commitmentId}`
    },
    groups: {
      membership: (personId, membershipId) => {
        return `${V1_API_PREFIX}/people/${personId}/groups/memberships/${membershipId}`
      },
      memberships: (personId) => {
        return `${V1_API_PREFIX}/people/${personId}/groups/memberships`
      },
      membership_request: {
        revoke: (personId, membershipRequestId) =>
          `${V1_API_PREFIX}/people/${personId}/groups/membership_requests/${membershipRequestId}/revoke`
      },
      membership_requests: (personId) => {
        return `${V1_API_PREFIX}/people/${personId}/groups/membership_requests`
      },
      team: {
        membership: (personId, membershipId) => {
          return `${V1_API_PREFIX}/people/${personId}/groups/team/memberships/${membershipId}`
        }
      }
    },
    metrics: (personId) => `${V1_API_PREFIX}/people/${personId}/metrics`,
    notifications: {
      index: (personId) => `${V1_API_PREFIX}/people/${personId}/notifications`,
      batch: {
        read: (personId) =>
          `${V1_API_PREFIX}/people/${personId}/notifications/batch/read`,
        unread: (personId) =>
          `${V1_API_PREFIX}/people/${personId}/notifications/batch/unread`
      },
      preferences: (personId) =>
        `${V1_API_PREFIX}/people/${personId}/notifications/preferences`
    },
    sections: (personId) => `${V1_API_PREFIX}/people/${personId}/sections`
  },
  locations: {
    countries: () => `${V1_API_PREFIX}/locations/countries`,
    country: {
      regions: (countryId) =>
        `${V1_API_PREFIX}/locations/countries/${countryId}/regions`
    }
  },
  report: (report) => {
    const _sanitizedReport = report.replace(/_/g, '-')

    return `${V1_API_PREFIX}/reports/${_sanitizedReport}/enqueue`
  },
  superGroups: {
    index: () => `${V1_API_PREFIX}/groups`,
    item: (superGroupId: string) => `${V1_API_PREFIX}/groups/${superGroupId}`,
    organizations: {
      exclude: (superGroupId: string, itemId: string) =>
        `${V1_API_PREFIX}/groups/${superGroupId}/organizations/${itemId}/exclude`,
      excluded: (superGroupId: string) =>
        `${V1_API_PREFIX}/groups/${superGroupId}/organizations/excluded`,
      include: (superGroupId: string, itemId: string) =>
        `${V1_API_PREFIX}/groups/${superGroupId}/organizations/${itemId}/include`,
      included: (superGroupId: string) =>
        `${V1_API_PREFIX}/groups/${superGroupId}/organizations/included`
    }
  },
  team: (teamId) => `${V1_API_PREFIX}/teams/${teamId}`,
  user: (userId) => `${V1_API_PREFIX}/users/${userId}`,
  users: {
    emails: {
      subscriptions: (userId) =>
        `${V1_API_PREFIX}/users/${userId}/emails/subscriptions`,
      lists: {
        all: (userId) => `${V1_API_PREFIX}/users/${userId}/emails/lists`,
        subscription: (userId, subscriptionId) =>
          `${V1_API_PREFIX}/users/${userId}/emails/lists/subscriptions/${subscriptionId}`,
        subscriptions: (userId) =>
          `${V1_API_PREFIX}/users/${userId}/emails/lists/subscriptions`
      }
    }
  }
}
