import ActionStore from 'Stores/ActionStore'
import CampaignStore from 'Stores/Common/CampaignStore'
import CampaignsStore from 'Stores/Common/CampaignsStore'
import CreateTeamsStore from 'Stores/CreateTeamsStore'
import EmailPreferencesStore from 'Stores/EmailPreferencesStore'
import EnergyFootprintStore from 'Stores/EnergyFootprintStore'
import PersonStore from 'Stores/PersonStore'
import LocationStore from 'Stores/LocationStore'
import ModalStore from 'Stores/ModalStore'
import OrganizationStore from 'Stores/OrganizationStore'
import OrganizationsStore from 'Stores/OrganizationsStore'
import SessionStore from 'Stores/SessionStore'
import SuperGroupStore from 'Stores/SuperGroupStore'
import SuperGroupsStore from 'Stores/SuperGroupsStore'
import TeamStore from 'Stores/TeamStore'
import TooltipStore from 'Stores/TooltipStore'
import UIStore from 'Stores/UIStore'
import UserStore from 'Stores/UserStore'
import UsersStore from 'Stores/UsersStore'
import { CAMPAIGN_CONTEXTS } from 'Constants'

export class ApplicationStore {
  public actionStore = new ActionStore()
  public createTeamsStore = new CreateTeamsStore()
  public energyFootprintStore = new EnergyFootprintStore()
  public emailPreferencesStore = new EmailPreferencesStore()
  public locationStore = new LocationStore(this)
  public modalStore = new ModalStore()
  public organizationStore = new OrganizationStore(this)
  public personStore = new PersonStore(this)
  public sessionStore = new SessionStore()
  public superGroupStore = new SuperGroupStore()
  public teamStore = new TeamStore(this)
  public tooltipStore = new TooltipStore()
  public uiStore = new UIStore()
  public userStore = new UserStore()
  // Admin-only
  public campaignStore = new CampaignStore(this, CAMPAIGN_CONTEXTS.ADMIN)
  public campaignsStore = new CampaignsStore(this, CAMPAIGN_CONTEXTS.ADMIN)
  public organizationsStore = new OrganizationsStore()
  public superGroupsStore = new SuperGroupsStore()
  public usersStore = new UsersStore()

  reset = async () => {
    this.actionStore.reset()
    this.energyFootprintStore.reset()
    this.emailPreferencesStore.reset()
    this.modalStore.reset()
    await this.organizationStore.reset()
    await this.personStore.reset()
    this.sessionStore.reset()
    this.superGroupStore.reset()
    await this.teamStore.reset()
    this.tooltipStore.reset()
    await this.uiStore.reset()
    this.userStore.reset()
    // Admin-only
    this.organizationsStore.reset()
    this.superGroupsStore.reset()
    this.usersStore.reset()
  }
}

export default new ApplicationStore()
