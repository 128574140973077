import React from 'react'
import classNames from 'classnames'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'Elements'
import { SkipToContentMain } from 'Elements/SkipToContent'

interface MainProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  constrained?: boolean
  offset?: boolean
  isFlex?: boolean
}

const Main = ({
  children,
  className = '',
  constrained = false,
  offset = false,
  isFlex = false,
  ...props
}: MainProps) => {
  return (
    <SkipToContentMain
      className={classNames('Main', {
        [className]: !!className,
        'Main--constrained': constrained,
        'Main--offset': offset,
        'Main--flex': isFlex
      })}
      {...props}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </SkipToContentMain>
  )
}

export default Main
