import { AuthContext } from 'clarity'
import React from 'react'
import { ContainerProps } from 'Containers'

interface AuthContextProviderProps extends Partial<ContainerProps> {
  children: React.ReactNode
}

export const AuthContextProvider = ({ children, authenticated }: AuthContextProviderProps) => {
  const context = {
    isAuthenticated: authenticated,
    login: () => window.location.assign('/auth/redirect'),
    signup: () => window.location.assign('/auth/redirect')
  }

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  )
}
