import React from 'react'
import classNames from 'classnames'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'Elements'

interface HeaderProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  style?: React.CSSProperties
}

const Header = ({ children, className, style }: HeaderProps) => {
  return (
    <header
      className={classNames('header', { [className]: !!className })}
      style={style}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </header>
  )
}

export default Header
