import useLocalI18n from 'Hooks/LocalI18n'
import React, { createRef, useRef, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import SectionIcon from 'Images/icons/SectionIcon'
import * as styles from './styles.module.scss'

interface ItemProps {
  slug: string
  _label: string
}

interface Props {
  isCollapsed?: boolean
  items: ItemProps[]
  onClick: (slug: string) => void
  selectedItem?: ItemProps
}

const BreakdownBarChartFilter = ({
  isCollapsed = false,
  items,
  onClick,
  selectedItem
}: Props) => {
  const { I18n } = useLocalI18n('elements/BreakdownBarChart/Lang')

  const _buttonRef = useRef<HTMLButtonElement>()
  const _menuRef = createRef<HTMLDivElement>()

  const [_isToggled, _setIsToggled] = useState(false)

  const _handleClick = (slug) => {
    _setIsToggled(false)
    onClick(slug)

    if (_isToggled && slug) {
      _buttonRef.current.focus()
    }
  }

  const _docClick = (e) => {
    if (
      _isToggled &&
      _menuRef.current &&
      !_menuRef.current.contains(e.target) &&
      e.target !== _buttonRef.current &&
      !_buttonRef.current?.contains(e.target)
    ) {
      _setIsToggled(false)
    }
  }

  const _docKeyup = (e) => {
    if (e.key === 'Escape') {
      _setIsToggled(false)
      _buttonRef?.current?.focus()
    }
  }

  useEffect(() => {
    document.addEventListener('click', _docClick)
    document.addEventListener('keyup', _docKeyup)

    return () => {
      document.removeEventListener('click', _docClick)
      document.removeEventListener('keyup', _docKeyup)
    }
  })

  return (
    <div className={styles.BreakdownBarChartFilter}>
      <span className={styles.BreakdownBarChartFilter__content}>
        <button
          className={`button button--size-s ${styles.BreakdownBarChartFilter__button}`}
          onClick={() => _setIsToggled(!_isToggled)}
          aria-expanded={_isToggled}
          tabIndex={isCollapsed ? -1 : 0}
          ref={_buttonRef}>
          <span>
            {selectedItem
              ? ReactHtmlParser(
                I18n.t('breakdown_bar_chart.filter.item.toggle_button', {
                  label: selectedItem._label
                })
              )
              : ReactHtmlParser(
                I18n.t('breakdown_bar_chart.filter.all.toggle_button')
              )}
          </span>
        </button>
        {_isToggled && (
          <div className={styles.BreakdownBarChartFilter__menu} ref={_menuRef}>
            <button
              className={styles.BreakdownBarChartFilter__menu__button}
              onClick={_handleClick}>
              {I18n.t('breakdown_bar_chart.filter.all.toggle_menu')}
            </button>
            <div className={styles.BreakdownBarChartFilter__menu__divider} />
            {items.map((item, index) => (
              <button
                key={index}
                className={styles.BreakdownBarChartFilter__menu__button}
                onClick={() => _handleClick(item.slug)}>
                {I18n.t('breakdown_bar_chart.filter.item.toggle_menu', {
                  label: item._label
                })}
                <SectionIcon type={item.slug} />
              </button>
            ))}
          </div>
        )}
      </span>
    </div>
  )
}

export default BreakdownBarChartFilter
