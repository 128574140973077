import { action, observable } from 'mobx'
import { standardizeError } from 'Utilities/errors'

class SessionStore {
  @observable public authenticatedPersonId: string = null
  @observable public authenticatedUserId: string = null
  @observable public initialized = false

  @action
  init = (authenticatedPersonId: string, authenticatedUserId: string) => {
    try {
      const _update =
        authenticatedPersonId !== this.authenticatedPersonId ||
        authenticatedUserId !== this.authenticatedUserId
      if (this.initialized && !_update) return

      if (this.initialized && _update) this.reset()

      this.authenticatedPersonId = authenticatedPersonId
      this.authenticatedUserId = authenticatedUserId
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  reset = () => {
    this.authenticatedPersonId = null
    this.authenticatedUserId = null
    this.initialized = false
  }
}

export default SessionStore
