import React from 'react'
import PropTypes from 'prop-types'

const RadioPill = ({ selected, label, name, index, footprintType, value, onClick }) => (
  <label
    className="radio-pill"
    htmlFor={`${footprintType}_${name}_${index}__destroy_${value}`}>
    <input
      defaultChecked={selected}
      id={`${footprintType}_${name}_${index}__destroy_${value}`}
      name={`${footprintType}_footprint[${name}][${index}][_destroy]`}
      className="radio-pill__control"
      type="radio"
      value={value}
      onClick={onClick}
    />
    <span className="radio-pill__label">{label}</span>
  </label>
)

RadioPill.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  footprintType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func
}

export default RadioPill
