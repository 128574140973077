import { Impact, Theme } from 'clarity'

export const ACTION = 'action'
export const CAMPAIGN = 'campaign'
export const CAMPAIGNS_TARGET = 'campaigns_target'
export const COMMITMENT = 'commitment'
export const COUNTRY = 'locations_country'
export const GROUP_MEMBERSHIP = 'group_membership'
export const GROUP_MEMBERSHIP_ORGANIZATION = 'group_membership_organization'
export const GROUP_MEMBERSHIP_TEAM = 'group_membership_team'
export const NOTIFICATION = 'notification'
export const ORGANIZATION = 'organization'
export const ORGANIZATION_SETTINGS = 'organizations_settings'
export const PERSON = 'person'
export const REGION = 'locations_region'
export const SUPER_GROUP = 'super_group'
export const TEAM = 'team'
export const USER = 'user'

export interface SerializedModal {
  category: string
  content?: {
    [key: string]: unknown
  }
}

export interface SerializedAlert {
  id?: string
  message: string
  type: string
}

export interface SerializedPagination {
  current: number
  first: number
  last: number
  next: number
  previous: number
}

export interface SerializedPaginationLinks {
  first: string
  last: string
  previous: string
  next: string
  self: string
}
export interface SerializedModel {
  id: string
  type: string
  attributes?: Record<string, unknown>
  links?: Record<string, unknown>
  relationships?: Record<string, unknown>
}

export interface SerializedRelationshipSingular {
  data?: {
    id: string
    type: string
  }
  links?: Record<string, unknown>
  meta?: {
    included?: boolean
  }
}

interface SerializedActionCommitments extends SerializedRelationshipSingular {
  meta?: {
    all_time?: {
      complete: {
        total: number
      }
      pending: {
        total: number
      }
      successful: {
        total: number
      }
    }
    included?: boolean
  }
}

export interface SerializedRelationshipPlural {
  data?: Array<{
    id: string
    type: string
  }>
  links?: Record<string, unknown>
  meta?: {
    included: boolean
  }
}

export interface SerializedAnnouncement extends SerializedModel {
  attributes?: {
    body: string
    body_en: string
  }
}

export interface SerializedAction extends SerializedModel {
  attributes?: {
    name?: string
    benefits?: Array<{
      id: string
      description: string
      title: string
    }>
    checklist?: Array<{
      id: string
      description: string
      completed: boolean
    }>
    completion_criterion: string
    description: string
    ease?: {
      description: string
      rating: number
      title: string
    }
    facts?: Array<{
      id: string
      description: string
    }>
    impact?: Impact
    improvements?: string[]
    information?: Array<{
      id: string
      description: string
    }>
    resources?: Array<{
      id: string
      category: string
      title: string
      url: string
    }>
    slug?: string
    summary?: string
    tags?: string[]
    tips?: Array<{
      description: string
      id: string
    }>
    themes?: Theme[]
    updates_footprint?: boolean
    is_highlighted?: boolean
  }
  links?: {
    permalink: string
    html_summary: string
    html_new_related_footprint?: string
    html_actions?: string
    html_todolist?: string
  }
  relationships?: {
    commitments: SerializedActionCommitments
    current_commitment: SerializedRelationshipSingular
    last_pending_commitment: SerializedRelationshipSingular
    last_successful_commitment: SerializedRelationshipSingular
    suppressions: SerializedRelationshipPlural
  }
}

export interface SerializedCommitment extends SerializedModel {
  attributes: {
    state?: string
  }
}

export interface SerializedUsersEmailsSubscription extends SerializedModel {
  attributes: {
    summary: boolean
    pending_commitments: boolean
  }
}

export interface SerializedEmailList extends SerializedModel {
  attributes: {
    name: string
    default: boolean
  }
  relationships: {
    organization_email_list: {
      meta?: {
        included: boolean
      }
    }
  }
}

export interface SerializedUsersEmailsListsSubscription
  extends SerializedModel {
  relationships: {
    email_list: SerializedRelationshipSingular
    user: SerializedRelationshipSingular
  }
}

export interface SerializedSectionMetric {
  label: string
  slug: string
  value: number
}

export interface SerializedMetric {
  current_by_section?: SerializedPersonSection[]
  avoided?: { pending_commitments: number; successful_commitments: number }
  current: number
  emissions?: number
  reductions?: number
  time_remaining_to_net_zero?: string
}

export interface SerializedNotification extends SerializedModel {
  attributes: {
    category: string
    created_at: string
    body: string
    read: boolean
    title: string
    url: string
  }
}

export interface SerializedPersonNotificationsPreferences
  extends SerializedModel {
  attributes: {
    product_updates: boolean
  }
}

export interface SerializedPerson extends SerializedModel {
  attributes: {
    avatar?: string
    first_name?: string
    last_name?: string
    score?: number
    co2e?: SerializedMetric
    land?: SerializedMetric
    water?: SerializedMetric
    abandoned_actions_count?: number
    active_actions_count?: number
    complete_actions_count?: number
    existing_actions_count?: number
    pending_actions_count?: number
    successful_actions_count?: number
  }
  links: {
    self?: string
    html_actions?: string
  }
  relationships: {
    commitments?: SerializedRelationshipPlural
    group_memberships?: SerializedRelationshipPlural
    organizations?: SerializedRelationshipPlural
    region?: SerializedRelationshipSingular
    teams?: SerializedRelationshipPlural
    user?: SerializedRelationshipSingular
  }
}

export interface SerializedPersonSectionFootprint {
  param: string
  slug: string
  applicable: boolean
  average_only: boolean
  complete: boolean
  source: string
}

export interface SerializedPersonSection {
  param: string
  complete: boolean
  progress: number
  co2e: {
    current: number
  }
  water: {
    current: number
  }
  land: {
    current: number
  }
  po43e: {
    current: number
  }
  footprints: SerializedPersonSectionFootprint[]
}

export interface SerializedUser extends SerializedModel {
  attributes: {
    days_since_created?: number
    engagement_bucket?: string
    email?: string
    sso?: boolean
    locale?: string
    sign_in_count?: number
  }
}

interface SerializedCampaignActions extends SerializedRelationshipPlural {
  meta?: {
    included: boolean
    total: number
  }
}

interface SerializedCampaignTarget extends SerializedRelationshipSingular {
  meta?: {
    included: boolean
  }
}
export interface SerializedCampaign extends SerializedModel {
  attributes: {
    action_updates_restricted?: boolean
    description: string
    draft?: boolean
    ends_at?: string
    name: string
    participants_count?: number
    pending_commitments_count?: number
    successful_commitments_count?: number
    public: boolean
    selected_commitments_count?: number
    starts_at?: string
    started?: boolean
    template: boolean
    target_updates_restricted?: boolean
    title: string
    underway?: boolean
    leaderboard_avalible?: boolean
  }
  relationships: {
    actions: SerializedCampaignActions
    target: SerializedCampaignTarget
  }
  links: {
    html_admin_actions?: string
    html_admin_settings?: string
    html_admin_summary?: string
    html_public?: string
  }
}

export interface SerializedCampaignsTarget extends SerializedModel {
  attributes: {
    display_publicly?: boolean
    maximum_participants?: number
    participants_count?: number
    pending_commitments_count?: number
    selected?: string
    successful_commitments_count?: number
    suggested_participants_count?: number
    suggested_pending_commitments_count?: number
    suggested_successful_commitments_count?: number
  }
}

interface SerializedGroupCommitments extends SerializedRelationshipPlural {
  meta?: {
    all_time: {
      complete: {
        total: number
      }
      existing: {
        total: number
      }
      pending: {
        by_ease: number
        by_impact: number
        total: number
      }
      successful: {
        by_ease: number
        by_impact: number
        total: number
      }
    }
    last_week: {
      pending: {
        total: number
      }
      successful: {
        total: number
      }
    }
    last_month: {
      pending: {
        total: number
      }
      successful: {
        total: number
      }
    }
    included: boolean
  }
}

interface SerializedGroupMembers extends SerializedRelationshipPlural {
  meta?: {
    change?: {
      last_month: number
    }
    included: boolean
    total: number
  }
}

interface SerializedGroup extends SerializedModel {
  attributes: {
    banner?: {
      small: string
      medium: string
      large: string
    }
    co2e?: SerializedMetric
    land?: SerializedMetric
    logo?: string
    name?: string
    public?: boolean
    score?: number
    water?: SerializedMetric
  }
  relationships?: Record<string, unknown>
}

export interface SerializedOrganization extends SerializedGroup {
  links?: {
    html_progress?: string
    html_leaderboard?: string
    html_leaderboard_top3?: string
    html_leaderboard_teams?: string
    html_leaderboard_challenge?: string
    html_leaderboard_challenge_teams?: string
    html_public_teams?: string
    html_admin_memberships?: string
    html_admin_reports?: string
    html_admin_settings?: string
    html_admin_teams?: string
    achievements_time_series_download?: string
    actions_summary_download?: string
    engagement_time_series_download?: string
    members_summary_download?: string
    members_list_download?: string
    organization_summary_download?: string
    teams_summary_download?: string
  }
  relationships?: {
    admins?: SerializedRelationshipPlural
    commitments?: SerializedGroupCommitments
    members?: SerializedGroupMembers
    super_groups?: SerializedRelationshipPlural
    teams?: SerializedRelationshipPlural
  }
}

export interface SerializedOrganizationSettings extends SerializedModel {
  attributes: {
    campaigns: boolean
    public: boolean
    public_greeting: string
    sso_greeting: string
    public_slug: string
    requires_verification: boolean
    verification_question: string
    public_teams: boolean
    requires_team_verification: boolean
    team_verification_question: string
    compulsory_teams: boolean
    share_url_type: string
  }
}

export interface SerializedTeam extends SerializedGroup {
  links?: {
    html_progress?: string
    html_leaderboard?: string
    html_leaderboard_top3?: string
    html_trends?: string
    html_admin_assign_users?: string
    html_admin_memberships?: string
    html_admin_settings?: string
  }
  relationships?: {
    commitments?: SerializedGroupCommitments
    members?: SerializedGroupMembers
    organization?: SerializedRelationshipSingular
  }
}

interface SerializedSuperGroupOrganizations
  extends SerializedRelationshipPlural {
  meta?: {
    included: boolean
    total: number
  }
}
export interface SerializedSuperGroup extends SerializedGroup {
  links?: {
    html_admin_settings?: string
    html_leaderboard_organizations?: string
    html_leaderboard_top3?: string
    html_progress?: string
  }
  relationships?: {
    commitments?: SerializedGroupCommitments
    members?: SerializedGroupMembers
    organizations?: SerializedSuperGroupOrganizations
  }
}

export interface SerializedOrganizationsBenchmark extends SerializedModel {
  attributes: {
    average_members_kg_co2e_emissions: number
    average_members_score: number
    total_members_count: number
    total_members_count_one_month_ago: number
    members_count_change_since_one_month_ago: number
    total_teams_count: number
    average_pro_kg_co2e_emissions: number
    lowest_pro_kg_co2e_emissions: number
    average_pro_score: number
    highest_pro_score: number
  }
  type: 'organizations_benchmark'
}

export interface SerializedCountry {
  id: string
  name: string
}

export interface SerializedRegion {
  id: string
  name: string
}

export interface Refinement {
  id: string
  interrogation: string
  tooltip_hint: string
}

export interface FootprintRefinement {
  id: string
  refinement_id: string
}

export interface ElectricityEstimate {
  id: string
  created_at: string
  updated_at: string
  consumption: number
  lookup_slug: string
  min_bedrooms: number
  max_bedrooms: number
  unique_slug: string
}

export interface GasEstimate {
  id: string
  created_at: string
  updated_at: string
  consumption: number
  lookup_slug: string
  min_bedrooms: number
  max_bedrooms: number
  unique_slug: string
}
