import ABANDON_ICON from 'Images/icons/buttons/cross-cherry.svg'
import WORKPLACE from 'Images/workplace-icon.svg'
import CheckIcon from 'Images/icons/buttons/check'
import CreateIcon from 'Images/icons/buttons/create'
import DeleteIcon from 'Images/icons/buttons/delete'
import DownloadIcon from 'Images/icons/buttons/download'
import InviteIcon from 'Images/icons/buttons/invite'
import LikeIcon from 'Images/icons/buttons/like'
import LockIcon from 'Images/icons/LockIcon'
import MailIcon from 'Images/icons/buttons/mail'
import ManageAccountIcon from 'Images/icons/ManageAccountIcon'
import NotificationsIcon from 'Images/icons/buttons/notifications'
import PencilIcon from 'Images/icons/buttons/pencil'
import ResetIcon from 'Images/icons/buttons/reset'
import RevokeIcon from 'Images/icons/buttons/revoke'
import SearchIcon from 'Images/icons/buttons/search'
import SelectAllIcon from 'Images/icons/buttons/select-all'
import SelectNoneIcon from 'Images/icons/buttons/select-none'
import SignOutIcon from 'Images/icons/SignOutIcon'
import TickIcon from 'Images/icons/buttons/tick'
import UserResetIcon from 'Images/icons/buttons/user-reset'
import VerticalDotsIcon from 'Images/icons/buttons/vertical-dots'

const SUPPRESS_ICON = ABANDON_ICON

export {
  ABANDON_ICON,
  SUPPRESS_ICON,
  WORKPLACE,
  CheckIcon,
  CreateIcon,
  DeleteIcon,
  DownloadIcon,
  InviteIcon,
  LikeIcon,
  LockIcon,
  MailIcon,
  ManageAccountIcon,
  NotificationsIcon,
  PencilIcon,
  ResetIcon,
  RevokeIcon,
  SearchIcon,
  SelectAllIcon,
  SelectNoneIcon,
  SignOutIcon,
  TickIcon,
  UserResetIcon,
  VerticalDotsIcon
}
