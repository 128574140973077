import React from 'react'
import classNames from 'classnames'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback, FeatureFallback } from 'Elements'
import { useIdleTimer } from 'react-idle-timer'
import { DefaultContext } from 'Contexts'
import { safelyNavigate } from 'Utilities/navigation'

interface ContainerProps {
  allowTimeout?: boolean
  children: React.ReactNode[] | React.ReactNode
  className?: string
  expand?: boolean
  offsetHeader?: boolean
  fullHeight?: boolean
}

const Container = ({
  children,
  className = '',
  allowTimeout = false,
  expand = false,
  offsetHeader = false,
  fullHeight = false
}: ContainerProps) => {
  const {
    applicationStore: { userStore }
  } = React.useContext(DefaultContext)

  if (allowTimeout && userStore.isAuthenticated) {
    const handleOnIdle = () => {
      userStore.reset()
      safelyNavigate('/?timed_out=1')
    }

    useIdleTimer({
      timeout: 1000 * 60 * 15,
      onIdle: handleOnIdle,
      crossTab: true
    })
  }

  return (
    <div className={
      classNames(
        'container',
        {
          [className]: !!className,
          'container--expand': expand,
          'container--offset': offsetHeader,
          'container--full-height': fullHeight
        })}>
      <FeatureFallback />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </div>
  )
}

export default Container
