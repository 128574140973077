import React from 'react'
import {
  AnalyticsContext,
  DefaultAnalyticsProvider,
  AnalyticsContextProps
} from 'clarity'

export const AnalyticsContextProvider = ({ children }) => {
  const provider: AnalyticsContextProps = {
    ...DefaultAnalyticsProvider
  }

  return (
    <AnalyticsContext.Provider value={provider}>
      {children}
    </AnalyticsContext.Provider>
  )
}
