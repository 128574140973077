import React, { useState } from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import * as styles from './styles.module.scss'
import classnames from 'classnames'

interface StatusProps {
  onToggleStatus: () => void
  read?: boolean
}

const Status = ({ onToggleStatus, read = false }: StatusProps) => {
  const { I18n } = useLocalI18n('molecules/Notifications/Lang')
  const [_disabled, _setDisabled] = useState(false)

  const _title = read
    ? I18n.t('notifications.status.mark_unread')
    : I18n.t('notifications.status.mark_read')

  const _onClick = () => {
    if (_disabled) return
    onToggleStatus()
    _setDisabled(true)
  }

  return (
    <button
      aria-label={_title}
      className={classnames(styles.Status, {
        [styles[`Status--read`]]: read,
        [styles[`Status--unread`]]: !read
      })}
      title={_title}
      type="button"
      onClick={_onClick}
    />
  )
}

export default Status
