import { action, observable } from 'mobx'
import { standardizeError } from 'Utilities/errors'
import { BaseStore } from 'Stores/Base'
import { API, APIRoutes } from 'API'
import { SerializedAnnouncement } from 'Constants/models'

export class AdminStore extends BaseStore {
  @observable public attributes: SerializedAnnouncement['attributes']
  @observable public id: string

  constructor() {
    super()
    this.reset()
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return await Promise.resolve()

      await this.fetchCurrentAnnouncement()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  createAnnouncement = async (
    attributes: SerializedAnnouncement['attributes']
  ) => {
    try {
      this.setLoading()
      const _attributes = { ...attributes }
      delete _attributes.body
      const _route = APIRoutes.announcements()
      const { data } = await API.post(_route, {
        data: {
          type: 'announcements',
          attributes: _attributes
        }
      })
      this.id = data.data.id
      this.attributes = data.data.attributes
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }

  @action
  fetchCurrentAnnouncement = async () => {
    try {
      this.setLoading()
      const _route = `${APIRoutes.announcements()}/current`
      const { data } = await API.get(_route)
      const _currentAnnouncement = data?.data
      if (_currentAnnouncement) {
        this.id = _currentAnnouncement.id
        this.attributes = _currentAnnouncement.attributes
      }
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.setLoaded()
    }
  }

  @action
  reset = () => {
    this.attributes = {
      body: null,
      body_en: null
    }
    this.id = null
    this.initialized = false
    this.loaded = false
    this.loading = false
  }
}

export default new AdminStore()
