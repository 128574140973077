import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState } from 'react'
import QuestionIcon from 'Images/icons/QuestionIcon'
import CloseIcon from 'Images/icons/CloseIcon'
import * as styles from './styles.module.scss'

declare global {
  interface Window {
    Beacon?: any
    attachEvent?: any
  }
}

const HelpButton = () => {
  const { I18n } = useLocalI18n('elements/HelpButton/Lang')
  const [_isOpen, _setIsOpen] = useState(false)

  const _init = () => {
    if (window.Beacon) {
      window.Beacon('config', {
        enablePreviousMessages: false
      })
      window.Beacon('init', '4e640751-b04f-484a-8134-653fd4ddd665')
      window.Beacon('on', 'open', () => _setIsOpen(true))
      window.Beacon('on', 'close', () => _setIsOpen(false))
      window.Beacon('once', 'ready', () => {
        window.Beacon('toggle')
      })
    }
  }

  const _loadScript = () => {
    // code provided by Helpscout has been updated
    // to append the script to the <body> and
    // data attribute data-testid has been added for testing

    const _testid = 'helpButtonScript'

    // @ts-expect-error code imported from Help Scout
    // eslint-disable-next-line
    !(function (e, t, n) {
      function a() {
        // eslint-disable-next-line
        var n = t.createElement('script')

        n.dataset.testid = _testid
        n.type = 'text/javascript'
        n.setAttribute('data-turbolinks-eval', 'false')
        n.src = 'https://beacon-v2.helpscout.net'
        document.body.appendChild(n)
      }
      if (
        ((e.Beacon = n =
          function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a })
          }),
        (n.readyQueue = []),
        t.readyState === 'complete')
      )
        return a()
      e.attachEvent
        ? e.attachEvent('onload', a)
        : e.addEventListener('load', a, !1)
    })(
      window,
      document,
      window.Beacon ||
        function () {
          /**/
        }
    )

    _init()
  }

  const _handleClick = () => {
    if (window.Beacon) {
      window.Beacon('toggle')
    } else {
      _loadScript()
    }
  }

  useEffect(() => {
    return () => {
      if (window.Beacon) window.Beacon('destroy')
    }
  }, [])

  return (
    <button
      className={styles.HelpButton}
      onClick={_handleClick}
      id="giki-help-button">
      {I18n.t('help_button.label')}
      {_isOpen ? <CloseIcon /> : <QuestionIcon />}
    </button>
  )
}

export default HelpButton
