import React from 'react'

const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g transform="matrix(2.5,0,0,2.5,0,0)">
      <path
        d="M15.75,9.749l-3.981,5.308a.75.75,0,0,1-1.13.08L8.25,12.749"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
      <path
        d="M10.73,1.357a1.63,1.63,0,0,1,2.54,0l1.512,1.881a1.629,1.629,0,0,0,1.446.6l2.4-.261a1.63,1.63,0,0,1,1.8,1.8l-.261,2.4a1.629,1.629,0,0,0,.6,1.446l1.881,1.512a1.63,1.63,0,0,1,0,2.54L20.761,14.78a1.632,1.632,0,0,0-.6,1.447l.261,2.4a1.629,1.629,0,0,1-1.8,1.8l-2.4-.261a1.628,1.628,0,0,0-1.446.6L13.27,22.64a1.629,1.629,0,0,1-2.54,0L9.219,20.76a1.631,1.631,0,0,0-1.447-.6l-2.4.261a1.628,1.628,0,0,1-1.8-1.8l.261-2.4a1.631,1.631,0,0,0-.6-1.447l-1.88-1.511a1.629,1.629,0,0,1,0-2.54l1.88-1.512a1.628,1.628,0,0,0,.6-1.446l-.261-2.4a1.629,1.629,0,0,1,1.8-1.8l2.4.261a1.632,1.632,0,0,0,1.447-.6Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"></path>
    </g>
  </svg>
)

export default CheckIcon
