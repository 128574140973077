import React from 'react'
import { observer } from 'mobx-react'
import useLocalI18n from 'Hooks/LocalI18n'
import { Spinner } from 'Elements'
import {
  SerializedUsersEmailsSubscription,
  SerializedPersonNotificationsPreferences
} from 'Constants'
import Item from './Item'
import styles from './styles.module.scss'

interface PreferencesProps {
  loaded?: boolean
  loading?: boolean
  subscriptions:
    | SerializedUsersEmailsSubscription['attributes']
    | SerializedPersonNotificationsPreferences['attributes']
  onChange: (key: string, value: string | number | boolean) => void
}

const Preferences = observer(
  ({
    loaded = false,
    loading = false,
    subscriptions,
    onChange
  }: PreferencesProps) => {
    const { I18n } = useLocalI18n('molecules/Communications/Lang')

    if (!loaded || loading) {
      return (
        <div className={styles.Preferences}>
          <Spinner centered />
        </div>
      )
    }

    return (
      <ul className={styles.Preferences}>
        {Object.entries(subscriptions).map((subscription) => {
          const [id, subscribed] = subscription

          return (
            <Item
              description={I18n.t(
                `communications.preferences.${id}.description`
              )}
              id={id}
              key={id}
              onChange={onChange}
              subscribed={subscribed}
              title={I18n.t(`communications.preferences.${id}.title`)}
            />
          )
        })}
      </ul>
    )
  }
)

export default Preferences
