import useLocalI18n from 'Hooks/LocalI18n'

const { I18n } = useLocalI18n('molecules/GroupMembershipFilters/Lang')

export const FILTER_STATES = [
  {
    label: I18n.t('group_membership_filters.roles.all.title'),
    value: undefined
  },
  {
    label: I18n.t('group_membership_filters.roles.admin.title'),
    value: true
  },
  {
    label: I18n.t('group_membership_filters.roles.normal.title'),
    value: false
  }
]
