import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import SectionIcon from 'Images/icons/SectionIcon'
import * as styles from './styles.module.scss'

interface ItemProps {
  slug: string
  _label: string
  value: number
}

interface Props {
  item: ItemProps
  firstItem?: ItemProps
  co2eUnit?: string
}

const TREE_PARAM = ['trees', 'forest_conservation', 'tree_planting']

const BreakdownBarChartItem = ({
  item,
  firstItem,
  co2eUnit = ''
}: Props) => {
  const { I18n } = useLocalI18n('elements/BreakdownBarChart/Lang')

  const _numberFormatter = (value) => I18n.toNumber(value, { precision: 0 })

  return (
    <div className={styles.BreakdownBarChartItem} title={item._label}>
      <span className={styles.BreakdownBarChartItem__title}>{item._label}</span>
      <span
        className={`${styles.BreakdownBarChartItem__progress} ${item.value < 0
          ? TREE_PARAM.includes(item.slug)
            ? styles['BreakdownBarChartItem__progress--trees']
            : styles['BreakdownBarChartItem__progress--negative']
          : ''
          }`}>
        <span
          className={styles.BreakdownBarChartItem__progress__bar}
          style={{
            width: `${Math.ceil(
              (Math.abs(item.value) / Math.abs(firstItem.value)) * 100
            )}%`
          }}
        />
        <span className={styles.BreakdownBarChartItem__progress__label}>
          {item.value < 0 && TREE_PARAM.includes(item.slug) ? (
            <>
              <SectionIcon type="trees" />
              <span className="desktop">
                {I18n.t('breakdown_bar_chart.offset', {
                  value: `${_numberFormatter(Math.abs(item.value))}${co2eUnit}`
                })}
              </span>
              <span className="mobile">
                {I18n.t('breakdown_bar_chart.offset_short', {
                  value: `${_numberFormatter(Math.abs(item.value))}${co2eUnit}`
                })}
              </span>
              <SectionIcon type="trees" />
            </>
          ) : (
            `${_numberFormatter(
              item.value === 0 ? Math.abs(item.value) : item.value
            )}${co2eUnit}`
          )}
        </span>
      </span>
    </div>
  )
}

export default BreakdownBarChartItem
