import { action, computed, observable, IObservableArray } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'
import { findById, someById } from 'Utilities/arrays'
import { ACTION_ROLES, SerializedAction, SerializedPagination } from 'Constants'
import CampaignStore from '.'

class ActionsStore {
  public context: string
  public role: string
  @observable initialized: boolean
  @observable items: IObservableArray<SerializedAction>
  @observable loading: boolean
  @observable loaded: boolean
  @observable pagination: SerializedPagination

  constructor(public rootStore: CampaignStore, role: string, context: string) {
    this.rootStore = rootStore
    this.role = role
    this.context = context
    this.reset()
  }

  @action
  init = async (values = {}) => {
    try {
      Object.keys(values).forEach((key) => {
        this[key] = values[key]
      })

      await this.fetchActions()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @computed
  get _actionsRoute() {
    return APIRoutes.campaign.actions[this.role](this.rootStore.id)
  }

  @action
  excludeAction = async (actionId) => {
    if (this.role !== ACTION_ROLES.INCLUDED) return

    try {
      const route = this._excludeRoute(actionId)
      const { data } = await API.post(route)
      this.rootStore.actions[ACTION_ROLES.EXCLUDED].receiveActions([data.data])
      const loadedAction = findById(this.items, actionId)
      this.items.remove(loadedAction)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  _excludeRoute(actionId) {
    return APIRoutes.campaign.action.exclude(this.rootStore.id, actionId)
  }

  @action
  fetchActions = async (page = { number: 1 }) => {
    this.loading = true
    try {
      const route = this._actionsRoute
      const { data } = await API.get(route, {
        params: {
          'page[number]': page.number,
          'page[size]': 24
        }
      })
      this.receiveActions(data.data)
      this.pagination = data.meta.pagination
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @action
  includeAction = async (actionId) => {
    if (this.role !== ACTION_ROLES.EXCLUDED) return

    try {
      const route = this._includeRoute(actionId)
      const { data } = await API.post(route)
      this.rootStore.actions[ACTION_ROLES.INCLUDED].receiveActions([data.data])
      const loadedAction = findById(this.items, actionId)
      this.items.remove(loadedAction)
    } catch (err) {
      throw new Error(standardizeError(err))
    }
  }

  _includeRoute(actionId) {
    return APIRoutes.campaign.action.include(this.rootStore.id, actionId)
  }

  @action
  receiveActions = (actions) => {
    actions.forEach((action) => {
      const actionLoaded = someById(this.items, action.id)
      if (actionLoaded) return

      this.items.push(action)
    })
  }

  @action
  reset = () => {
    this.initialized = false
    this.items = [] as IObservableArray
    this.loading = false
    this.loaded = false
    this.pagination = {
      current: 1,
      first: 1,
      last: null,
      next: null,
      previous: null
    }
  }

  @computed
  get populated() {
    return this.loaded && this.items.length > 0
  }
}

export default ActionsStore
