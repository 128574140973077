import useLocalI18n from 'Hooks/LocalI18n'
import PropTypes from 'prop-types'
import React from 'react'
import { observer } from 'mobx-react'
import {
  LoadMoreButton,
  LinksList,
  Search,
  Spinner,
  ButtonIcons
} from 'Elements'
import { useListFocus } from 'Hooks/ListFocus'
import * as styles from './styles.module.scss'

const Users = observer(
  ({
    fetchUsers,
    loaded,
    loading,
    onQueryUpdate,
    onDelete,
    users,
    pagination
  }) => {
    const { I18n } = useLocalI18n('molecules/Users/Lang')

    const { _listRef, _listFocus } = useListFocus('a')

    const _fetchUsers = () => {
      fetchUsers({ number: pagination.next })?.then(_listFocus)
    }

    const _onQueryChange = (state) => onQueryUpdate(state.value)

    return (
      <div className={styles.Users}>
        <section className={styles.Users__filters}>
          <Search
            onSearch={_onQueryChange}
            placeholder={I18n.t('users.query.placeholder')}
            label={I18n.t('users.query.label')}
          />
        </section>
        <section className={styles.Users__content}>
          {loading && (
            <div className={styles.Users__loader}>
              <Spinner absolute />
            </div>
          )}
          {loaded && users.length > 0 && (
            <LinksList
              items={users.map((user) => {
                return {
                  secondaryLinks: [
                    {
                      onClick: () => onDelete(user.id),
                      linkLabel: I18n.t('users.actions.delete'),
                      linkIcon: ButtonIcons.DeleteIcon
                    }
                  ],
                  title: user.attributes.email
                }
              })}
              ref={_listRef}
            />
          )}
          {loaded && !users.length && (
            <div className={styles.Users__placeholder}>
              {I18n.t('users.placeholder')}
            </div>
          )}
          {pagination.next && (
            <div className={styles.Users__pagination}>
              <LoadMoreButton loading={loading} onLoadMore={_fetchUsers} />
            </div>
          )}
        </section>
      </div>
    )
  }
)

Users.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onQueryUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired
}

export default Users
