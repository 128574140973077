import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { LoadMoreButton, LinksList, Spinner } from 'Elements'
import { GroupMembershipActions, GroupMembershipFilters } from 'Molecules'
import { useListFocus } from 'Hooks/ListFocus'
import * as styles from './styles.module.scss'

const TeamMemberships = observer(
  ({
    allMembershipsSelected,
    anyMembershipSelected,
    fetchMemberships,
    getPerson,
    getTeamMemberships,
    groupMemberships,
    isMembershipSelected,
    loaded,
    loading,
    onDeleteSelected,
    onFilterUpdate,
    onSelectAll,
    onSelectNone,
    onToggleSelect,
    onUpdateSelected,
    pagination,
    teamId
  }) => {
    const { I18n } = useLocalI18n('molecules/TeamMemberships/Lang')

    const { _listRef, _listFocus } = useListFocus()

    const _fetchMemberships = () => {
      fetchMemberships({ number: pagination.next })?.then(_listFocus)
    }

    return (
      <div className={styles.TeamMemberships}>
        <GroupMembershipFilters onFilterUpdate={onFilterUpdate} />
        <GroupMembershipActions
          allMembershipsSelected={allMembershipsSelected}
          anyMembershipSelected={anyMembershipSelected}
          onDeleteSelected={onDeleteSelected}
          onSelectAll={onSelectAll}
          onSelectNone={onSelectNone}
          onUpdateSelected={onUpdateSelected}
          membershipsPopulated={groupMemberships.length > 0}
        />
        <section className={styles.TeamMemberships__content}>
          {loading && <Spinner absolute />}
          {loaded && groupMemberships.length > 0 && (
            <LinksList
              items={groupMemberships.map((groupMembership) => {
                const _person = getPerson(
                  groupMembership.relationships.person.data.id
                )

                const _displayName = `${_person.attributes.first_name} ${_person.attributes.last_name}`

                const _allTeamMemberships = getTeamMemberships(
                  groupMembership.relationships.group_membership_teams.data.map(
                    ({ id }) => id
                  )
                )

                const _currentTeamMembership = _allTeamMemberships.find(
                  ({ relationships }) => {
                    return relationships.team.data.id === teamId
                  }
                )

                const _personIsTeamAdmin =
                  _currentTeamMembership.attributes.admin

                const _tags = []

                if (_personIsTeamAdmin) {
                  _tags.push({
                    text: I18n.t('team_memberships.admin')
                  })
                }

                return {
                  onClick: () => onToggleSelect(groupMembership.id),
                  linkLabel: _displayName,
                  title: _displayName,
                  tags: _tags,
                  avatar: _person.attributes.avatar,
                  avatarFirstName: _person.attributes.first_name,
                  avatarLastName: _person.attributes.last_name,
                  selectable: true,
                  selected: isMembershipSelected(groupMembership.id)
                }
              })}
              ref={_listRef}
              wide
            />
          )}
          {loaded && !groupMemberships.length && (
            <div className={styles.TeamMemberships__placeholder}>
              {I18n.t('team_memberships.placeholder')}
            </div>
          )}
          {pagination.next && (
            <div className={styles.TeamMemberships__pagination}>
              <LoadMoreButton
                loading={loading}
                onLoadMore={_fetchMemberships}
              />
            </div>
          )}
        </section>
      </div>
    )
  }
)

TeamMemberships.defaultProps = {
  allMembershipsSelected: false,
  anyMembershipSelected: false
}

TeamMemberships.propTypes = {
  allMembershipsSelected: PropTypes.bool,
  anyMembershipSelected: PropTypes.bool,
  fetchMemberships: PropTypes.func.isRequired,
  getPerson: PropTypes.func.isRequired,
  getTeamMemberships: PropTypes.func.isRequired,
  groupMemberships: PropTypes.array.isRequired,
  isMembershipSelected: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSelectNone: PropTypes.func.isRequired,
  onToggleSelect: PropTypes.func.isRequired,
  onUpdateSelected: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  teamId: PropTypes.string.isRequired
}

export default TeamMemberships
