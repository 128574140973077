import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import useLocalI18n from 'Hooks/LocalI18n'
import { Spinner } from 'Elements'
import styles from './styles.module.scss'

interface PersonProps {
  firstName: string
  lastName: string
  loading?: boolean
  onUpdate: ({ first_name, last_name }) => void
}

const Person = observer(
  ({ firstName = '', lastName = '', loading, onUpdate }: PersonProps) => {
    const { I18n } = useLocalI18n('molecules/Settings/Lang')

    const [_firstName, _setFirstName] = useState(firstName)

    const [_lastName, _setLastName] = useState(lastName)

    const [_hasChanges, _setHasChanges] = useState(false)

    const _onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      _setFirstName(e.target.value)

      if (!_hasChanges) _setHasChanges(true)
    }

    const _onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      _setLastName(e.target.value)

      if (!_hasChanges) _setHasChanges(true)
    }

    const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      onUpdate({ first_name: _firstName, last_name: _lastName })

      _setHasChanges(false)
    }

    useEffect(() => {
      _setFirstName(firstName)
    }, [firstName])

    useEffect(() => {
      _setLastName(lastName)
    }, [lastName])

    return (
      <form className={styles.Person} onSubmit={_onSubmit}>
        {loading && <Spinner absolute />}
        <fieldset className="FormRow FormRow--bs">
          <div className="text-field__wrapper FormRow--column">
            <label htmlFor="first-name">
              {I18n.t('settings.person.fields.first_name')}
            </label>
            <input
              autoComplete="given-name"
              className="text-field text-field--constrained"
              disabled={loading}
              id="first-name"
              onChange={_onFirstNameChange}
              type="text"
              value={_firstName}
            />
          </div>
        </fieldset>
        <fieldset className="FormRow FormRow--bs">
          <div className="text-field__wrapper FormRow--column">
            <label htmlFor="last-name">
              {I18n.t('settings.person.fields.last_name')}
            </label>
            <input
              autoComplete="family-name"
              className="text-field text-field--constrained"
              disabled={loading}
              id="last-name"
              onChange={_onLastNameChange}
              type="text"
              value={_lastName}
            />
          </div>
        </fieldset>
        <fieldset className="FormRow FormRow--inline-center">
          <button
            className={classnames(styles.Button, styles['Button--color-brand'])}
            disabled={loading || !_hasChanges}
            type="submit">
            {I18n.t('settings.common.save')}
          </button>
        </fieldset>
      </form>
    )
  }
)

export default Person
