import React from 'react'

const ThickArrowIcon = () => (
  <svg
    viewBox="0 0 17 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor">
    <path d="M8.41389 18L0.655273 10.125H5.08877L5.08877 0L11.739 0L11.739 10.125H16.1725L8.41389 18Z" />
  </svg>
)

export default ThickArrowIcon
