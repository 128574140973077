export const camelize = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, character) => character.toUpperCase())
}

export const capitalize = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (word) =>
      word.replace(/^\w/, (character) => character.toUpperCase())
    )
}

export const pluralize = (word) => {
  return /.*y$/.test(word) ? word.replace(/y$/, 'ies') : word + 's'
}

export const desnakeize = (str) => {
  return str.replace(/(_+)/g, () => ' ')
}

export const truncate = (str, count) => {
  if (str.length <= count) return str

  return str.slice(0, count) + '...'
}
