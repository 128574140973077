import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import PropTypes from 'prop-types'
import { Search } from 'Elements'
import styles from './styles.module.scss'

const PublicTeamDataTableFilters = ({ onFilterUpdate }) => {
  const { I18n } = useLocalI18n('molecules/PublicTeamDataTableFilters/Lang')

  const _onQueryChange = (state) => onFilterUpdate('query', state.value)

  return (
    <section className={styles.PublicTeamDataTableFilters}>
      <div className={styles.PublicTeamDataTableFilters__filter}>
        <Search
          onSearch={_onQueryChange}
          placeholder={I18n.t('public_team_data_table_filters.placeholder')}
          label={I18n.t('public_team_data_table_filters.label')}
        />
      </div>
    </section>
  )
}

PublicTeamDataTableFilters.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired
}

export default PublicTeamDataTableFilters
