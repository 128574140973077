import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'

interface ErrorProps {
  message: string
}

interface ErrorFallbackProps {
  error: ErrorProps
}

const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const { I18n } = useLocalI18n('elements/ErrorFallback/Lang')

  return (
    <div className="error-fallback" role="alert">
      <h3 className="error-fallback__title">
        {I18n.t('error_fallback.title')}
      </h3>
      <pre className="error-fallback__message">{error.message}</pre>
      <p className="error-fallback__suffix">
        {I18n.t('error_fallback.suffix')}{' '}
        <a href={`mailto:${I18n.t('error_fallback.email')}`}>
          {I18n.t('error_fallback.email')}
        </a>
      </p>
    </div>
  )
}

export default ErrorFallback
