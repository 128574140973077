import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Spinner, Tooltip, Button, ButtonTypes } from 'Elements'
import { DefaultContext } from 'Contexts'
import autocomplete, { AutocompleteItem } from 'autocompleter'
import classnames from 'classnames'
import * as styles from './styles.module.scss'
import { SerializedModal, SerializedAlert } from 'Constants'

interface Props {
  alerts?: SerializedAlert[]
  regionIdName: string
  autoSubmit?: boolean
  showHeading?: boolean
  modal?: SerializedModal
}

interface RegionAutocompleteItem extends AutocompleteItem {
  id: string
}

const RegionSelector = ({
  alerts,
  regionIdName,
  autoSubmit,
  showHeading,
  modal
}: Props) => {
  const { I18n } = useLocalI18n('elements/RegionSelector/Lang')

  const _fieldsetRef = useRef(null)

  const {
    applicationStore: { locationStore, modalStore }
  } = React.useContext(DefaultContext)

  const _globalCountryRegionId = '00000000-0000-0000-0000-000000000000'

  const _maxRegionsToDisplay = 9

  const _regionIdInput = useRef<HTMLInputElement>()

  const [_initialized, _setInitialized] = useState(false)
  const [_loading, _setLoading] = useState(false)

  const [_countryId, _setCountryId] = useState('')
  const [_regionId, _setRegionId] = useState('')

  const [_regions, _setRegions] = useState([])
  const [_countries, _setCountries] = useState([])
  const _regionsRef = useRef(_regions)

  _regionsRef.current = _regions

  const [_showRegions, _setShowRegions] = useState(false)
  const [_showCountries, _setShowCountries] = useState(false)

  const _setupAutocomplete = (regionAutoCompleteInput: HTMLInputElement) => {
    autocomplete<RegionAutocompleteItem>({
      preventSubmit: true,
      input: regionAutoCompleteInput,
      emptyMsg: I18n.t('region_selector.region.not_found'),
      fetch: (text, update) => {
        if (_regionsRef.current) {
          const loweredText = text.toLowerCase()
          const matchingRegions = _regionsRef.current
            .filter(
              (region) => region.name.toLowerCase().indexOf(loweredText) >= 0
            )
            .map((region) => ({
              label: region.name,
              id: region.id
            }))
          update(matchingRegions)
        } else {
          update([])
        }
      },
      onSelect: (region) => {
        regionAutoCompleteInput.value = region.label
        _setRegionId(region.id)
      },
      render: (item) => {
        const parentElement = document.createElement('div')
        parentElement.className = 'autocomplete__withButtons'

        const itemElement = document.createElement('button')
        itemElement.type = 'button'
        itemElement.textContent = item.label
        itemElement.className = 'autocomplete__button'

        parentElement.appendChild(itemElement)

        return parentElement
      }
    })
  }

  const _fetchCountries = async () => {
    _setLoading(true)

    await locationStore
      .fetchCountries()
      .catch((err) => modalStore.handleError(err))

    _setCountries(locationStore.countries)
    _setLoading(false)
  }

  const _moveSelectedRegionToStart = (regions) => {
    if (_regionId !== '' && regions.length > _maxRegionsToDisplay) {
      const regionIndex = regions.findIndex((region) => region.id === _regionId)

      if (regionIndex > 0) {
        regions.unshift(regions.splice(regionIndex, 1)[0])
      }
    }

    return regions
  }

  const _fetchRegions = async () => {
    if (!_countryId || _countryId === _globalCountryRegionId) {
      return
    }

    _setLoading(true)

    await locationStore
      .fetchRegions(_countryId)
      .catch((err) => modalStore.handleError(err))

    const regions = _moveSelectedRegionToStart(locationStore.regions)

    _setRegions(regions)

    _setLoading(false)
  }

  const _computeVisibility = () => {
    _setShowRegions(_countryId !== '' && _regions && _regions.length > 1)
    _setShowCountries(
      _countries &&
        _countries.length > 0 &&
        (_countryId === '' || (_regions && _regions.length <= 1))
    )
  }

  const _goBackToCountries = () => {
    _setRegionId('')
    _setCountryId('')
    _setRegions([])
  }

  const _submitForm = () => {
    if (autoSubmit && _regionId && _regionId.length > 0 && _initialized) {
      _regionIdInput.current.closest('form').submit()
    }
  }

  const _selectRegion = (regionId: string) => {
    if (regionId !== _regionId) {
      _setRegionId(regionId)
    } else {
      _submitForm()
    }
  }

  const _selectCountry = (countryId: string) => {
    if (countryId === _globalCountryRegionId) {
      _selectRegion(_globalCountryRegionId)
    } else if (countryId !== _countryId) {
      _setCountryId(countryId)
    } else {
      void _fetchRegions()
    }
  }

  const _initialize = async () => {
    modalStore
      .init({ alerts, modal })
      .catch((err) => modalStore.handleError(err))

    if (_countryId !== '') {
      await _fetchRegions()
    }

    await _fetchCountries()

    _setInitialized(true)
  }

  useEffect(() => {
    _computeVisibility()
  }, [_regions, _countries])

  useEffect(() => {
    _fieldsetRef?.current?.querySelector('button')?.focus()
  }, [_showRegions, _showCountries])

  useEffect(() => {
    if (_initialized && _regions.length === 1) {
      _selectRegion(_regions[0].id)
    }
  }, [_regions])

  useEffect(() => {
    void _fetchRegions()
  }, [_countryId])

  useEffect(() => {
    _submitForm()
  }, [_regionId])

  const _onRegionAutoCompleteInputRefChange = useCallback((element) => {
    if (element != null) {
      _setupAutocomplete(element)
    }
  }, [])

  useEffect(() => {
    void _initialize()
  }, [])

  return (
    <fieldset
      ref={_fieldsetRef}
      className={styles.RegionSelector}
      disabled={_loading}>
      {showHeading && (
        <legend>
          <h1 className={classnames(styles.RegionSelector__heading, 'h2')}>
            {_showCountries && I18n.t('region_selector.heading.country_title')}
            {_showRegions && I18n.t('region_selector.heading.region_title')}
          </h1>
        </legend>
      )}
      {_showCountries && (
        <>
          <div className={styles.RegionSelector__countries}>
            {_countries.map((country) => {
              return (
                <Button
                  key={country.id}
                  variant={ButtonTypes.RADIOPILL}
                  label={country.name}
                  onClick={() => {
                    _selectCountry(country.id)
                  }}
                />
              )
            })}
          </div>
          <h3 className={styles.RegionSelector__subheading}>
            {I18n.t('region_selector.global.heading')}{' '}
            <Tooltip
              text={I18n.t('region_selector.global.tooltip')}
              inline
              label={I18n.t('region_selector.global.heading')}
            />
          </h3>
          <Button
            variant={ButtonTypes.RADIOPILL}
            label={I18n.t('region_selector.global.title')}
            onClick={() => {
              _selectCountry(_globalCountryRegionId)
            }}
          />
        </>
      )}
      {_showRegions && (
        <>
          <div className={styles.RegionSelector__regions}>
            {_regions.slice(0, _maxRegionsToDisplay).map((region, index) => {
              return (
                <Button
                  key={index}
                  variant={ButtonTypes.RADIOPILL}
                  label={region.name}
                  onClick={() => {
                    _selectRegion(region.id)
                  }}
                />
              )
            })}
          </div>
          {_regions.length > _maxRegionsToDisplay && (
            <div className={styles.RegionSelector__autocomplete}>
              <h4 className={styles['RegionSelector__autocomplete-heading']}>
                {I18n.t('region_selector.region.search_heading')}
              </h4>
              <input
                className="text-field"
                type="text"
                ref={_onRegionAutoCompleteInputRefChange}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
            </div>
          )}
          <Button
            onClick={_goBackToCountries}
            label={I18n.t('region_selector.region.back_to_countries')}
            variant={ButtonTypes.OUTLINED}
            small
          />
          <p className={styles.RegionSelector__description}>
            {I18n.t('region_selector.region.description')}
          </p>
        </>
      )}
      {_loading && (
        <div className={styles.RegionSelector__spinner}>
          <Spinner />
        </div>
      )}
      <input
        type="hidden"
        value={_regionId}
        name={regionIdName}
        ref={_regionIdInput}
        data-testid={`input_${regionIdName}`}
      />
    </fieldset>
  )
}

export default RegionSelector
