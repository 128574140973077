import React, { Component } from 'react'
import * as d3 from 'd3'
import * as styles from './styles.module.scss'
import { SizeMe } from 'react-sizeme'

interface Props<TDataType> {
  size: { width: number }
  title: string
  description: string
  controls?: JSX.Element
  footer?: JSX.Element
  data: TDataType[]
  colour: string
  unit: string | null
  dataReady: boolean
  selectX: (datum: TDataType) => Date
  selectY: (datum: TDataType) => number
}

export class LineChart<TDataType> extends Component<Props<TDataType>> {
  chartRef: React.RefObject<HTMLDivElement>
  svg: d3.Selection<SVGElement, unknown, null, undefined>
  tooltipBg: d3.Selection<SVGElement, unknown, null, undefined>
  xScale: d3.ScaleTime<number, number, never>
  yScale: d3.ScaleLinear<number, number, never>
  xAxis: d3.Selection<SVGGElement, unknown, null, undefined>
  yAxis: d3.Selection<SVGGElement, unknown, null, undefined>
  gridLines: d3.Selection<SVGGElement, unknown, null, undefined>
  line: d3.Selection<SVGPathElement, unknown, null, undefined>
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined>

  margin = { top: 10, right: 0, bottom: 30, left: 70 }
  height = 400 - this.margin.top - this.margin.bottom
  grayColour = '#eae3e9'

  constructor(props: Props<TDataType>) {
    super(props)
    this.chartRef = React.createRef()
  }

  componentDidMount() {
    this.svg = d3
      .select(this.chartRef.current)
      .append('svg')
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)

    this.yAxis = this.svg.append('g').attr('class', styles.LineChart__axis)

    this.gridLines = this.svg.append('g').attr('class', styles.LineChart__axis)

    this.xAxis = this.svg
      .append('g')
      .attr('class', styles.LineChart__axis)
      .attr('transform', `translate(0,${this.height})`)

    this.line = this.svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke-width', 5)

    this.tooltip = d3
      .select(this.chartRef.current)
      .append('div')
      .style('opacity', 0)
      .attr('class', styles.LineChart__tooltip)

    this.tooltipBg = this.svg
      .append('rect')
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', (e) => this.mouseleave(e))
      .on('mousemove', (e) => this.mousemove(e))

    this.xScale = d3.scaleTime()
    this.yScale = d3.scaleLinear()

    this.drawChart()
  }

  componentDidUpdate() {
    this.drawChart()
  }

  private drawChart() {
    if (!this.props.dataReady) {
      return
    }

    const width = this.props.size.width - this.margin.left - this.margin.right

    this.tooltipBg.attr('width', width)

    const xExtent = this.xExtent()

    this.xScale.domain(xExtent).range([0, width])

    let [yMin, yMax] = d3.extent(this.props.data, this.props.selectY)
    const average = (yMax + yMin) / 2

    yMin -= average * 0.1
    yMax += average * 0.1

    if (yMax - yMin < 10) {
      yMax += 1
      yMin = yMax - 10
    }

    if (yMin < 0) {
      yMax += -1 * yMin
      yMin = 0
    }

    this.yScale.domain([yMin, yMax]).range([this.height, 0])

    const yAxisGridLines = d3
      .axisLeft(this.yScale)
      .ticks(5)
      .tickSize(-width)
      .tickFormat(() => '')

    this.gridLines.call(yAxisGridLines)

    const yAxisGenerator = d3.axisLeft(this.yScale).ticks(5)

    this.yAxis.call(yAxisGenerator)

    const xAxisGenerator = d3.axisBottom(this.xScale).ticks(5)

    this.xAxis.call(xAxisGenerator)

    const lineGenerator = d3
      .line<TDataType>()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d) => this.xScale(this.props.selectX(d)))
      .y((d) => this.yScale(this.props.selectY(d)))

    this.line
      .datum(this.props.data)
      .attr('stroke', this.props.colour)
      .attr('d', lineGenerator)
  }

  private mousemove(event) {
    if (!this.props.dataReady) {
      this.tooltip.style('opacity', 0)
      return
    }

    this.tooltip.style('opacity', 1)

    const bisect = d3.bisector<TDataType, Date>(this.props.selectX).center
    const lineOffset = d3.pointer(event, this.tooltipBg[0])
    const lineOffsetX = lineOffset[0]
    const index = bisect(this.props.data, this.xScale.invert(lineOffsetX))
    const d = this.props.data[index]
    const date = this.props.selectX(d)
    const value = this.props.selectY(d)

    // toFixed(2) would always generate decimal places, even when not necessary
    const roundedValue = Math.round((value + Number.EPSILON) * 100) / 100

    let valueWithUnit = roundedValue.toString()
    if (this.props.unit != null) {
      valueWithUnit += ' ' + this.props.unit
    }

    const x = this.xScale(date) + this.margin.left
    const y = 400 - this.yScale(value)

    const dateFormat = d3.timeFormat('%-d %B %Y')
    this.tooltip
      .html(
        `
        <div class='${styles.LineChart__tooltip__date}'>${dateFormat(
          date
        )}</div>
        <div class='${styles.LineChart__tooltip__value}'>${valueWithUnit}</div>
        <div class='${styles.LineChart__tooltip__arrow}'></div>
      `
      )
      .style('left', `${x}px`)
      .style('bottom', `${y}px`)
  }

  private mouseleave(event) {
    this.tooltip.style('opacity', 0)
  }

  private xExtent() {
    const extent = d3.extent(this.props.data, this.props.selectX) as [
      Date,
      Date
    ]
    const today = d3.timeDay()
    const yesterday = d3.timeDay.offset(today, -1)
    if (extent[1] < yesterday) {
      extent[1] = yesterday
    }
    const daysDifference = d3.timeDay.count(extent[0], extent[1])
    if (daysDifference < 30) {
      extent[0] = d3.timeDay.offset(extent[1], -30)
    }
    return extent
  }

  render() {
    return (
      <div className={styles.LineChart}>
        <div className={styles.LineChart__header}>
          <h4>{this.props.title}</h4>
          <div className={styles.LineChart__header__controls}>
            {this.props.controls}
          </div>
        </div>
        <div ref={this.chartRef} className={styles.LineChart__chart}></div>
        <div className={styles.LineChart__footer}>
          <div></div>
          <div className={styles.LineChart__description}>
            <p>{this.props.description}</p>
          </div>
          <div className={styles.LineChart__footer__content}>
            {this.props.footer}
          </div>
        </div>
      </div>
    )
  }
}

type ResponsiveProps<TDataType> = Omit<Props<TDataType>, 'size'>
class ResponsiveLineChart<TDataType> extends Component<
  ResponsiveProps<TDataType>
> {
  render() {
    return (
      <SizeMe>{({ size }) => <LineChart {...this.props} size={size} />}</SizeMe>
    )
  }
}

export default ResponsiveLineChart
