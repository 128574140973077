import { action, extendObservable } from 'mobx'
import { API, APIRoutes } from 'API'
import { standardizeError } from 'Utilities/errors'

const initialState = {
  co2e: {},
  initialized: false,
  land: {},
  loaded: false,
  loading: false,
  plastic: {},
  water: {}
}

class MetricsStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    extendObservable(this, initialState)
  }

  @action
  init = async () => {
    try {
      if (this.initialized) return Promise.resolve()

      return this.fetchMetrics()
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.initialized = true
    }
  }

  @action
  fetchMetrics = async () => {
    this.loading = true
    try {
      const route = APIRoutes.person.metrics(this.rootStore.id)
      const { data } = await API.get(route)
      const { co2e, land, plastic, water } = data.data

      this.co2e = co2e
      this.land = land
      this.plastic = plastic
      this.water = water
    } catch (err) {
      throw new Error(standardizeError(err))
    } finally {
      this.loaded = true
      this.loading = false
    }
  }

  @action
  reset = async () => {
    await Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key]
    })
  }
}

export default MetricsStore
