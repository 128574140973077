import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { generateId } from 'Utilities/ids'
import * as styles from './styles.module.scss'

const RadioInput = ({ defaultChecked, label, name, onChange, value }) => {
  const [_unqiueId] = useState(generateId())

  const _id = `${name}-${value}--${_unqiueId}`

  return (
    <label className={styles.RadioInput} htmlFor={_id}>
      <input
        defaultChecked={defaultChecked}
        className={styles.RadioInput__control}
        id={_id}
        name={name}
        onChange={() => onChange(name, value)}
        type="radio"
        value={value}
      />
      <span className={styles.RadioInput__label}>{label}</span>
    </label>
  )
}

RadioInput.defaultProps = {
  selected: false
}

RadioInput.propTypes = {
  defaultChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default RadioInput
