import React, { useState } from 'react'
import useLocalI18n from 'Hooks/LocalI18n'
import { truncate } from 'Utilities/strings'
import * as styles from './styles.module.scss'

interface TruncatedTextProps {
  characterCount?: number
  text: string
}

const TruncatedText = ({ characterCount = 147, text }: TruncatedTextProps) => {
  const { I18n } = useLocalI18n('elements/TruncatedText/Lang')

  const _textWithinLimit = text.length <= characterCount

  const [_displayFullText, _setDisplayFullText] = useState(_textWithinLimit)

  const _labelText = _displayFullText
    ? I18n.t('truncated_text.less')
    : I18n.t('truncated_text.more')

  const _truncatedText = truncate(text, characterCount)

  const _toggleDisplayFullText = () => _setDisplayFullText(!_displayFullText)

  if (_textWithinLimit) return <>{text}</>

  return (
    <>
      {_displayFullText ? text : _truncatedText}
      <button
        className={styles.TruncatedText__button}
        onClick={_toggleDisplayFullText}
        type="button">
        {_labelText}
      </button>
    </>
  )
}

export default TruncatedText
