import useLocalI18n from 'Hooks/LocalI18n'
import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import {
  Avatar,
  Button,
  ButtonColors,
  CloseButton,
  ItemInput,
  Tooltip
} from 'Elements'
import styles from './styles.module.scss'
import { getCsrfToken } from 'Utilities/forms'
import { APIRoutes } from 'API'
import { SerializedTeam } from 'Constants'
import SettingsStore from 'Stores/OrganizationStore/SettingsStore'

interface PublicTeamRequestProps {
  admin?: boolean
  organizationId: string
  publicTeam: SerializedTeam
  settingsStore: SettingsStore
  onClose: () => void
}

const PublicTeamRequest = observer(
  ({
    admin = false,
    organizationId,
    publicTeam,
    settingsStore,
    onClose
  }: PublicTeamRequestProps) => {
    const { I18n } = useLocalI18n('modals/PublicTeamRequest/Lang')

    const _modalRef = useRef(null)

    const _token = getCsrfToken()

    const _formAction = APIRoutes.organizations.public_teams.join(
      organizationId,
      publicTeam.id
    )

    const _logo = publicTeam.attributes.logo

    const _name = publicTeam.attributes.name

    const _requiresTeamVerification =
      settingsStore.attributes.requires_team_verification

    const _verificationQuestion =
      settingsStore.attributes.team_verification_question

    const _displayVerificationQuestion =
      _requiresTeamVerification && _verificationQuestion && !admin

    const [verificationAnswer, setVerificationAnswer] = React.useState('')

    return (
      <div
        className={classnames(styles.Modal, styles.PublicTeamRequest)}
        ref={_modalRef}>
        <div className={styles.Modal__close}>
          <CloseButton onClick={onClose} />
        </div>
        <div className={styles.Modal__content}>
          <header className="header">
            <div className="header__primary">
              <h1 className="h1 h--center h--secondary modal__title">
                {I18n.t('public_teams_request.title', {
                  name: _name
                })}
              </h1>
            </div>
          </header>
          <section className={styles.PublicTeamRequest__logo}>
            <Avatar border firstName={_name} imageSrc={_logo} />
          </section>
          <section>
            <form method="post" action={_formAction}>
              <input type="hidden" name="authenticity_token" value={_token} />
              {_displayVerificationQuestion && (
                <div className="form-row form-row--inline-center form-row--column">
                  <label
                    htmlFor="verification_answer"
                    className="label--tooltip"
                    aria-label={_verificationQuestion}>
                    {_verificationQuestion}{' '}
                    <Tooltip
                      inline={true}
                      text={I18n.t('public_teams_request.verification_tooltip')}
                      label={_verificationQuestion}
                    />
                  </label>
                  <ItemInput
                    bottomSpaced
                    type="text"
                    name="verification_answer"
                    required
                    value={verificationAnswer}
                    onChange={(_key, value: string) => {
                      setVerificationAnswer(value)
                    }}
                  />
                </div>
              )}
              <div className="form-row form-row--inline-center form-row--column">
                <Button
                  color={ButtonColors.BRAND}
                  type="submit"
                  label={I18n.t('public_teams_request.actions.submit')}
                  small={false}
                />
              </div>
            </form>
          </section>
        </div>
      </div>
    )
  }
)

export default PublicTeamRequest
