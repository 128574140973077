import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { generateId } from 'Utilities/ids'
import * as styles from './styles.module.scss'
import { Button } from 'Elements'

interface ImageFileInputProps {
  current: string
  disabled?: boolean
  guidance: string
  label: string
  maxFileSize?: number
  maxHeight?: number
  maxWidth?: number
  mimeTypes?: string[]
  minHeight?: number
  minWidth?: number
  onFileChange: (file: Blob) => void
  onRemoveImage?: () => void
  previewCircle?: boolean
  square?: boolean
}

const ImageFileInput = ({
  current,
  disabled = false,
  guidance,
  label,
  maxFileSize = 0,
  maxHeight = 0,
  maxWidth = 0,
  mimeTypes = ['image/png', 'image/jpeg'],
  minHeight = 0,
  minWidth = 0,
  onFileChange,
  onRemoveImage,
  previewCircle = false,
  square = false
}: ImageFileInputProps) => {
  const { I18n } = useLocalI18n('elements/ImageFileInput/Lang')

  const [_errors, _setErrors] = useState([])

  const [_file, _setFile] = useState(null)

  const [_valid, _setValid] = useState(false)

  const [_unqiueId] = useState(generateId())

  const _inputeRef = useRef(null)

  const _buttonText =
    current || _file
      ? I18n.t('image_file_input.change_image')
      : I18n.t('image_file_input.add_image')

  const _bytesAsKb = (bytes: number) => {
    // Backend validations use binary kilobytes, so we must use a
    // binary conversion of 1 byte = 1,024 KB, rather than the
    // decimal conversion of 1 byte = 1,000 KB.
    const kb = 1024
    return bytes / kb
  }

  const _clearInput = () => {
    if (!_inputeRef?.current?.value) return

    _inputeRef.current.value = ''
  }

  const _displayGuidance = guidance && !_valid && !disabled

  const _displayPlaceholder = disabled && !current && !_file

  const _displayPreview = current && !_file

  const _displayRemoveButton = [
    onRemoveImage !== undefined && current,
    _file !== null
  ].some((value) => value)

  const _filename = _file?.name || ''

  const _id = `image--${_unqiueId}`

  const _onFileChange = (event) => _setFile(event.target.files[0])

  const _onRemoveImage = () => {
    if (_file) {
      _reset()
    } else {
      onRemoveImage()
    }
  }

  const _reset = () => {
    _setErrors([])
    _setFile(null)
    _setValid(false)
    _clearInput()
  }

  const _validateFile = async (file: Blob) => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        const image = new Image()
        image.src = event.target.result as string
        image.onload = (e: Event) => {
          const target = e.target as HTMLImageElement
          const errors = []

          if (maxFileSize && _bytesAsKb(file.size) > _bytesAsKb(maxFileSize)) {
            errors.push(
              I18n.t('image_file_input.validations.max_file_size', {
                actual_file_size: I18n.toHumanSize(file.size),
                max_file_size: I18n.toHumanSize(maxFileSize)
              })
            )
          }

          if (maxWidth && target.width > maxWidth) {
            errors.push(
              I18n.t('image_file_input.validations.max_width', {
                max_width: maxWidth
              })
            )
          }
          if (minWidth && target.width < minWidth) {
            errors.push(
              I18n.t('image_file_input.validations.min_width', {
                min_width: minWidth
              })
            )
          }
          if (maxHeight && target.height > maxHeight) {
            errors.push(
              I18n.t('image_file_input.validations.max_height', {
                max_height: maxHeight
              })
            )
          }
          if (minHeight && target.height < minHeight) {
            errors.push(
              I18n.t('image_file_input.validations.min_height', {
                min_height: minHeight
              })
            )
          }
          if (square && target.width !== target.height) {
            errors.push(I18n.t('image_file_input.validations.square'))
          }
          if (errors.length > 0) {
            return reject(errors)
          }
          return resolve(image)
        }
      }
    })
  }

  const _validateFileAndNotify = async () => {
    try {
      await _validateFile(_file)
      onFileChange(_file)
      _setErrors([])
      _setValid(true)
    } catch (errors) {
      onFileChange(null)
      _setErrors(errors)
      _setValid(false)
    }
  }

  useEffect(() => {
    if (!_file) return

    void _validateFileAndNotify()
  }, [_file])

  useEffect(() => {
    _reset()
  }, [current])

  return (
    <div
      className={classnames(styles.ImageFileInput, {
        [styles['ImageFileInput--populated']]: _file,
        [styles['ImageFileInput--valid']]: _valid,
        [styles['ImageFileInput--invalid']]: _file && !_valid
      })}>
      <label className={styles.ImageFileInput__mainLabel} htmlFor={_id}>
        {label}
      </label>
      {_displayPlaceholder && (
        <p className={styles.ImageFileInput__placeholder}>
          {I18n.t('image_file_input.placeholder')}
        </p>
      )}
      {_displayPreview && (
        <div
          className={classnames(styles.ImageFileInput__preview, {
            [styles['ImageFileInput__preview--circle']]: previewCircle
          })}>
          <img className={styles.ImageFileInput__thumbnail} src={current} />
        </div>
      )}
      {!disabled && (
        <div className={styles.ImageFileInput__main}>
          {_filename && (
            <p className={styles.ImageFileInput__filename}>{_filename}</p>
          )}
          <input
            accept={mimeTypes.join(', ')}
            className={styles.ImageFileInput__input}
            id={_id}
            name={_id}
            onChange={_onFileChange}
            ref={_inputeRef}
            type="file"
          />
          <label className={styles.ImageFileInput__inlineLabel} htmlFor={_id}>
            {_buttonText}
          </label>
        </div>
      )}
      {_displayRemoveButton && (
        <div className={styles.ImageFileInput__auxiliary}>
          <Button
            label={I18n.t('image_file_input.remove_image')}
            small
            onClick={_onRemoveImage}
          />
        </div>
      )}
      {_displayGuidance && (
        <p className={styles.ImageFileInput__guidance}>{guidance}</p>
      )}
      <ul className={styles.ImageFileInput__errors}>
        {_errors.map((error, index) => {
          return (
            <li className={styles.ImageFileInput__guidance} key={index}>
              {error}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ImageFileInput
